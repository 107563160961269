import client from "../../../Configurations/apollo";
import {
	GET_PRODUCT_SEARCH,
	PRODUCT_SEARCH,
	GET_PRODUCT_DETAILS,
	GET_MASTER_PRODUCT_DETAILS,
	UPDATE_PRODUCT_IMG,
	UPDATE_PRODUCT,
	GET_DEPARTMENT,
	GET_CATEGORY,
	CreateMasterProduct,
	UPDATE_PRODUCT_STATUS,
	DELETE_PRODUCT,
	UPDATE_ALL_PRODUCT_STATUS
} from "./gqlQUery";
import { message } from "antd";
import {
	getSellerWorking
} from "../SignInActions/SignInActions";

const token = localStorage.getItem('token');
export const getProductSearch = (params) => {
	return async (dispatch) => {
		if(params.from===0){dispatch({ type: "SEARCH_PRODUCT_LOADING" });}
		function onSuccess(success) {
			dispatch({ type: "SEARCH_PRODUCT_SUCCESS", payload: success });
			return success;
		}
		function onError(error) {
		dispatch({ type: "SEARCH_PRODUCT_FAILED", payload: error });
			return error;
		}
		function onNetworkError() {
			dispatch({ type: "SEARCH_PRODUCT_ERROR" });
		}
		
		const success = await client.query({
			query: GET_PRODUCT_SEARCH,
			variables: {
				from:params.from,
				size:20,
				storeid: localStorage.getItem('storeid'),
				departmentid: params.department,
				categoryid: params.category,
				categoryid1: params.category1,
				categoryid2: params.category2,
				status:params.status,
				searchKey:params.key,
			},
			context: {
				headers: {
				Authorization:'Bearer ' + token
				}
			},
		});
		if (success) {
			return onSuccess(success);
		} else if (success.errors) {
			return onError(success.errors[0]);
		} else return onNetworkError();
	};
};


export const ProductSearch = (params) => {
	return async (dispatch) => {
		if(params.from===0){dispatch({ type: "PRODUCT_SEARCH_LOADING" });}
		function onSuccess(success) {
			dispatch({ type: "PRODUCT_SEARCH_SUCCESS", payload: success });
			return success;
		}
		function onError(error) {
		dispatch({ type: "PRODUCT_SEARCH_FAILED", payload: error });
			return error;
		}
		function onNetworkError() {
			dispatch({ type: "PRODUCT_SEARCH_ERROR" });
		}
		
		const success = await client.query({
			query: PRODUCT_SEARCH,
			variables: {
				pagination:{offset:params.from,
					first:params.first},
				storeid: localStorage.getItem('storeid'),
				salesdepartment: params.department,
				salescategory: params.category,
				salescategory1: params.category1,
				salescategory2: params.category2,
				status:params.status,
				searchKey:params.key,
			},
			context: {
				headers: {
				Authorization:'Bearer ' + token
				}
			},
		});
		if (success) {
			return onSuccess(success);
		} else if (success.errors) {
			return onError(success.errors[0]);
		} else return onNetworkError();
	};
};


export const getProductDetails = (params) => {
	return async (dispatch) => {
		function onSuccess(success) {
			dispatch({ type: "PRODUCT_DETAILS_SUCCESS", payload: success });
			return success;
		}
		function onError(error) {
		dispatch({ type: "PRODUCT_DETAILS_FAILED", payload: error });
			return error;
		}
		function onNetworkError() {
			dispatch({ type: "PRODUCT_DETAILS_ERROR" });
		}
		const success = await client.query({
			query: GET_PRODUCT_DETAILS,
			variables: {
				id:params
			},
			context: {
				headers: {
				Authorization:'Bearer ' + token
				}
			},
		});
		if (success) {
			return onSuccess(success);
		} else if (success.errors) {
			return onError(success.errors[0]);
		} else return onNetworkError();
	};
};


export const getMasterProductDetails = (params) => {
	return async (dispatch) => {
		dispatch({ type: "MASTER_PRODUCT_DETAILS_LOADING" });
		function onSuccess(success) {
			dispatch({ type: "MASTER_PRODUCT_DETAILS_SUCCESS", payload: success });
			return success;
		}
		function onError(error) {
		dispatch({ type: "MASTER_PRODUCT_DETAILS_FAILED", payload: error });
			return error;
		}
		function onNetworkError() {
			dispatch({ type: "MASTER_PRODUCT_DETAILS_ERROR" });
		}
		const success = await client.query({
			query: GET_MASTER_PRODUCT_DETAILS,
			variables: {
				productid:params
			},
			context: {
				headers: {
				Authorization:'Bearer ' + token
				}
			},
		});
		if (success) {
			return onSuccess(success);
		} else if (success.errors) {
			return onError(success.errors[0]);
		} else return onNetworkError();
	};
};



export const UpdateProduct = (params,department,category,category1,category2,productid,thumbnailUrl,multi,primary) => {
	return (dispatch) => {
		dispatch({
			type: "UPDATING_PRODUCTS",
		});
		client
			.mutate({
				mutation:  UPDATE_PRODUCT_IMG ,
				variables: {
					productid:`${productid}`,
					productname:`${params.productname}`,
					desc:`${params.desc}`,
					mfgname:params.mfgname===""||params.mfgname==="undefined"?null:`${params.mfgname}`,
					brand:params.brand===""||params.brand==="undefined"?null:`${params.brand}`,
					price:`${params.price}`,
					barcode:`${params.barcode}`,
					promoprice:params.promoprice===""?" ":`${params.promoprice}`,
					quantity:params.quantity===""||params.quantity==="undefined"?null:`${params.quantity}`,
					uom:params.uom===""||params.uom==="undefined"?null:`${params.uom}`,
					image:{primary:primary,
						thumbnail:thumbnailUrl?thumbnailUrl:null,
						secondary:multi.length===0?null:multi
								},
					status:`${params.status}`,
					displayrank:Number(params.displayrank),
					foodType: params.foodType ? { id: params.foodType.id, value: params.foodType.value } : null,
					salesdepartment:department,
					salescategory:category!==null?category:null,
					salescategory1:category1!==null?category1:null,
					salescategory2:category2!==null?category2:null
				},
				context: {
					headers: {
					Authorization:'Bearer ' + token
					}
				},
			})
			.then((res) => {
				if (res.data) {
					getSellerWorking(localStorage.getItem('retailerid'))
					localStorage.removeItem('updateUrl')
					dispatch({
						type: "UPDATE_PRODUCT_SUCCESS",
						payload:  getSellerWorking(localStorage.getItem('retailerid')),
					});
				} else {
					dispatch({
						type: "UPDATE_PRODUCT_FAILED",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "UPDATE_PRODUCT_FAILED",
					payload: err.message,
				});
			});
	};
};

export const ProductUpload = (params,department,category,category1,category2,productid) => {
	
	return (dispatch) => {
		dispatch({
			type: "UPDATING_PRODUCTS",
		});
		client
			.mutate({
				mutation:  UPDATE_PRODUCT ,
				variables: {
					productid:`${productid}`,
					productname:`${params.productname}`,
					desc:`${params.desc}`,
					mfgname:params.mfgname===""||params.mfgname==="undefined"?null:`${params.mfgname}`,
					brand:params.brand===""||params.brand==="undefined"?null:`${params.brand}`,
					price:`${params.price}`,
					barcode:params.barcode===""?" ":`${params.barcode}`,
					promoprice:params.promoprice===""?" ":`${params.promoprice}`,
					quantity:params.quantity===""||params.quantity==="undefined"?null:`${params.quantity}`,
					uom:params.uom===""||params.uom==="undefined"?null:`${params.uom}`,
					status:`${params.status}`,
					displayrank:Number(params.displayrank),
					foodType: params.foodType ? { id: params.foodType.id, value: params.foodType.value } : null,
					salesdepartment:department,
					salescategory:category!==null?category:" ",
					salescategory1:category1!==null?category1:" ",
					salescategory2:category2!==null?category2:" "
				},
				context: {
					headers: {
					Authorization:'Bearer ' + token
					}
				},
			})
			.then((res) => {
				if (res.data) {
					getSellerWorking(localStorage.getItem('retailerid'))
					dispatch({
						type: "UPDATE_PRODUCT_SUCCESS",
						payload:getSellerWorking(localStorage.getItem('retailerid')),
					});
				} else {
					dispatch({
						type: "UPDATE_PRODUCT_FAILED",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "UPDATE_PRODUCT_FAILED",
					payload: err.message,
				});
			});
	};
};


export const getDepartmentList = () => {
    return async (dispatch) => {
        function onSuccess(success) {
            dispatch({ type: "DEPARTMENT_LIST_SUCCESS", payload: success });
            return success;
        }
        function onError(error) {
            dispatch({ type: "DEPARTMENT_LIST_FAILED", payload: error });
            return error;
        }
        // function onNetworkError() {
        //     dispatch({ type: "SELLER_VIEW_ERROR" });
        // }
        const success = await client.query({
            query: GET_DEPARTMENT,
            variables: {
                storeid:localStorage.getItem('storeid'),
            },
        });
        if (success) {
            return onSuccess(success);
        } else if (success.errors) {
            return onError(success.errors[0]);
		}
		//  else return onNetworkError();
		
    };
};

export const clearCategories = () => {
    return async (dispatch) => {
            dispatch({ type: "CLEAR_CATEGORIES"});
       
		
    };
};

export const getCategoryList = (params) => {
    return async (dispatch) => {
        function onSuccess(success) {
            dispatch({ type: "CATEGORY_LIST_SUCCESS", payload: success });
            return success;
        }
        function onError(error) {
            dispatch({ type: "CATEGORY_LIST_FAILED", payload: error });
            return error;
        }
        // function onNetworkError() {
        //     dispatch({ type: "SELLER_VIEW_ERROR" });
        // }
        const success = await client.query({
            query: GET_CATEGORY,
            variables: {
				storeid:localStorage.getItem('storeid'),
				id:params
            },
        });
        if (success) {
            return onSuccess(success);
        } else if (success.errors) {
            return onError(success.errors[0]);
		}
		//  else return onNetworkError();
		
    };
};


export const getCategory1List = (params) => {
    return async (dispatch) => {
        function onSuccess(success) {
            dispatch({ type: "CATEGORY1_LIST_SUCCESS", payload: success });
            return success;
        }
        function onError(error) {
            dispatch({ type: "CATEGORY2_LIST_FAILED", payload: error });
            return error;
        }
        // function onNetworkError() {
        //     dispatch({ type: "SELLER_VIEW_ERROR" });
        // }
        const success = await client.query({
            query: GET_CATEGORY,
            variables: {
				storeid:localStorage.getItem('storeid'),
				id:params
            },
        });
        if (success) {
            return onSuccess(success);
        } else if (success.errors) {
            return onError(success.errors[0]);
		}
		//  else return onNetworkError();
		
    };
};


export const getCategory2List = (params) => {
    return async (dispatch) => {
        function onSuccess(success) {
            dispatch({ type: "CATEGORY2_LIST_SUCCESS", payload: success });
            return success;
        }
        function onError(error) {
            dispatch({ type: "CATEGORY2_LIST_FAILED", payload: error });
            return error;
        }
        // function onNetworkError() {
        //     dispatch({ type: "SELLER_VIEW_ERROR" });
        // }
        const success = await client.query({
            query: GET_CATEGORY,
            variables: {
				storeid:localStorage.getItem('storeid'),
				id:params
            },
        });
        if (success) {
            return onSuccess(success);
        } else if (success.errors) {
            return onError(success.errors[0]);
		}
		//  else return onNetworkError();
		
    };
};


export const CREATEMASTERPRODUCTS = (params,primary,secondary,thumbnail) => {
	let secondaryImages=[]
	if(secondary!==null)
	{secondaryImages=secondary.map((m)=>
	( m.Location)
 )}
 else{
	secondaryImages=null
 }
	return (dispatch) => {
		dispatch({
			type: "CREATING_MASTER_PRODUCTS",
		});
		client
			.mutate({
				mutation: CreateMasterProduct,
				variables: {
					productname:`${params.Productname}`,
					desc:`${params.Description}`,
					mfgname:params.MfgName===""||params.MfgName==="undefined"?null:`${params.MfgName}`,
					brand:params.brand===""||params.brand==="undefined"?null:`${params.brand}`,
					price:`${params.Price}`,
					barcode:`${params.barcode}`,
					promoprice:params.PromoPrice===null?" ":`${params.PromoPrice}`,
					quantity:params.Quantity===""||params.Quantity==="undefined"?null:`${params.Quantity}`,
					uom:params.unit===""||params.unit==="undefined"?null:`${params.unit}`,
					image:{primary:primary!==null?primary:null,
						secondary:secondaryImages,
						thumbnail:thumbnail,
						
								},
					status:`${params.status}`,
					displayrank:Number(params.displayRank),
					
				},
				context: {
					headers: {
					Authorization:'Bearer ' + token
					}
				},
			})
			.then((res) => {
				if (res.data) {
					getSellerWorking(localStorage.getItem('retailerid'))
					localStorage.removeItem('updateUrl')
					dispatch({
						type: "CREATING_MASTER_SUCCESS",
						payload:res.data,
					});
				} else {
					dispatch({
						type: "CREATING_MASTER_FAILED",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "CREATING_MASTER_FAILED",
					payload: err.message,
				});
			});
	};
};

export const CREATEMASTER_NOIMAGE = (params,secondary,thumbnail) => {

	return (dispatch) => {
		dispatch({
			type: "CREATING_MASTER_PRODUCTS",
		});
		client
			.mutate({
				mutation: CreateMasterProduct,
				variables: {
					productname:`${params.Productname}`,
					desc:`${params.Description}`,
					mfgname:params.MfgName===""||params.MfgName==="undefined"?null:`${params.MfgName}`,
					brand:params.brand===""||params.brand==="undefined"?null:`${params.brand}`,
					price:`${params.Price}`,
					barcode:`${params.barcode}`,
					promoprice:params.PromoPrice===null?" ":`${params.PromoPrice}`,
					quantity:params.Quantity===""||params.Quantity==="undefined"?null:`${params.Quantity}`,
					uom:params.unit===""||params.unit==="undefined"?null:`${params.unit}`,
					image:{
						secondary:secondary,
						thumbnail:thumbnail,
						
								},
					status:`${params.status}`,
					displayrank:Number(params.displayRank),
					
				},
				context: {
					headers: {
					Authorization:'Bearer ' + token
					}
				},
			})
			.then((res) => {
				if (res.data) {
					getSellerWorking(localStorage.getItem('retailerid'))
					localStorage.removeItem('updateUrl')
					dispatch({
						type: "CREATING_MASTER_SUCCESS",
						payload:res.data,
					});
				} else {
					dispatch({
						type: "CREATING_MASTER_FAILED",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "CREATING_MASTER_FAILED",
					payload: err.message,
				});
			});
	};
};


export const Editproductstatus = (productid,params) => {
	return (dispatch) => {
		dispatch({
			type: "UPDATING_STATUS",
		});
		client
			.mutate({
				mutation:  UPDATE_PRODUCT_STATUS,
				variables: {
					productid:`${productid}`,
					status:params
				},
				context: {
					headers: {
					Authorization:'Bearer ' + token
					}
				},
			})
			.then((res) => {
				if (res.data) {
					getSellerWorking(localStorage.getItem('retailerid'))
					dispatch({
						type: "UPDATE_STATUS_SUCCESS",
						payload:{
							id:productid,
							productstatus:params
						},
					});
				} else {
					dispatch({
						type: "UPDATE_STATUS_FAILED",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "UPDATE_PRODUCT_FAILED",
					payload: err.message,
				});
			});
	};
};

export const Editallproductstatus = (params1,params2) => {
	return (dispatch) => {
		dispatch({
			type: "UPDATING_ALL_PRODUCT_STATUS",
		});
		client
			.mutate({
				mutation:  UPDATE_ALL_PRODUCT_STATUS,
				variables: {
					productIds:params1,
					status:params2
				},
				context: {
					headers: {
					Authorization:'Bearer ' + token
					}
				},
			})
			.then((res) => {
				if (res.data) {
					getSellerWorking(localStorage.getItem('retailerid'))
					dispatch({
						type: "UPDATE_PRODUCT_STATUS_SUCCESS",
						payload:{
							productIds:params1,
							status:params1
						},
					});
				} else {
					dispatch({
						type: "UPDATE_PRODUCT_STATUS_FAILED",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "UPDATE_ALL_PRODUCT_FAILED",
					payload: err.message,
				});
			});
	};
};

export const Deleteproduct = (productid) => {
	return (dispatch) => {
		dispatch({
			type: "DELETING_PRODUCT",
		});
		client
			.mutate({
				mutation:  DELETE_PRODUCT,
				variables: {
					id:`${productid}`,
				},
				context: {
					headers: {
					Authorization:'Bearer ' + token
					}
				},
			})
			.then((res) => {
				if (res.data) {
					getSellerWorking(localStorage.getItem('retailerid'))
					dispatch({
						type: "DELETE_PRODUCT_SUCCESS",
						payload:{
							id:productid,
						},
					});
						message.success({
						  content: "Product Details Deleted Successfully",
						  className: "custom-class",
						  style: {
							marginTop: "20vh",
						  },
						  duration: 2.5,
						});
					
				} else {
					dispatch({
						type: "DELETE_PRODUCT_FAILED",
						payload: res.message,
					});
					message.error({
						content: `${res.message} ,Cannot be deleted`,
						className: "custom-class",
						style: {
						  marginTop: "20vh",
						},
						duration: 2.5,
					  });
				}
			})
			.catch((err) => {
				dispatch({
					type: "DELETE_PRODUCT_FAILED",
					payload: err.message,
				});
				message.error({
					content: `${err.message} ,Cannot be deleted`,
					className: "custom-class",
					style: {
					  marginTop: "20vh",
					},
					duration: 2.5,
				  });
			});
	};
};
