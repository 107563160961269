import React, { Component } from "react";
import HomePage from "../HomePage/home";
import SignInPage from "../SignInPage";
import SignUpNew from "../SignUpNew";

export class SellerPortal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const loginformNumber = localStorage.getItem("loginformNumber");
    return (
      <div>
        {localStorage.getItem("role") === "Shop Assistant" && localStorage.getItem("token") ? (
          <SignInPage {...this.props}/>
        ) : localStorage.getItem("token") &&
          localStorage.getItem("isLoggedin") ? (
          loginformNumber < 4 ? (
            <SignUpNew />
          ) : (
            <SignInPage {...this.props} />
          )
        ) : (
          <HomePage {...this.props} />
        )}
      </div>
    );
  }
}

export default SellerPortal;
