// import React, { Component } from "react";
// import GoogleMapReact from "google-map-react";
// import Marker from "../Marker";

// class EditGoogleMap extends Component {
// 	constructor(props) {
// 		super(props);

// 		this.state = {
// 			center: {
// 				lat: this.props.retailerDetails.data.retailerdetails.stores[0]
// 					? Number(this.props.retailerDetails.data.retailerdetails.stores[0]
// 							.storeCoordinates.coordinates[1])
// 					: 10.850516,
// 				lng: this.props.retailerDetails.data.retailerdetails.stores[0]
// 					? Number(this.props.retailerDetails.data.retailerdetails.stores[0]
// 							.storeCoordinates.coordinates[0])
// 					: 76.27108,
// 			},
// 		};
// 	}

// 	static defaultProps = {
// 		zoom: 15,
// 		center: {
// 			lat: 10.850516,
// 			lng: 76.27108,
// 		},
// 	};
// 	changeMarkerPostion = (e) => {
// 		let { lat, lng } = e;
// 		let MapValue = { lat, lng };
// 		this.setState({
// 			...this.state,
// 			center: {
// 				...this.state.center,
// 				lat: Number(e.lat),
// 				lng: Number(e.lng),
// 			},
// 		});

// 		this.props.getNewCoordinates({
// 			geocode: MapValue,
// 			details: this.props.retailerDetails.data.retailerdetails,
// 		});
// 	};
// 	render() {
// 		return (
// 			// Important! Always set the container height explicitly
// 			<div style={{ height: "50vh", width: "100%"}}>
// 				<GoogleMapReact
// 					bootstrapURLKeys={{ key: "AIzaSyA1Tzy_rLkPkeAkyk8yHPh-5GBU2NxUm5Y" }}
// 					defaultCenter={this.state.center}
// 					defaultZoom={this.props.zoom}
// 					onClick={this.changeMarkerPostion}>
// 					<Marker
// 						draggable={true}
// 						lat={this.state.center.lat}
// 						lng={this.state.center.lng}
// 						text="My Marker"
// 					/>
// 				</GoogleMapReact>
// 			</div>
// 		);
// 	}
// }

// export default EditGoogleMap;


//new code

import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import Marker from "../Marker";

const EditGoogleMap = ({props,setMapCoordinates,mapCoordinates}) => {
	const [center, setCenter] = useState({
		lat: props?.retailerDetails?.data?.retailerdetails?.stores[0]
			? Number(props?.retailerDetails?.data?.retailerdetails?.stores[0]?.storeCoordinates?.coordinates[1])
			: 10.850516,
		lng: props?.retailerDetails?.data?.retailerdetails?.stores[0]
			? Number(props?.retailerDetails?.data?.retailerdetails?.stores[0]?.storeCoordinates?.coordinates[0])
			: 76.27108,
	});
 

	
useEffect(()=>{
	if(mapCoordinates){
		
		setCenter({
			lat: Number(mapCoordinates.lat),
			lng: Number(mapCoordinates.lng),
		});
	}

},[mapCoordinates])

	const changeMarkerPosition = (e) => {
		const { lat, lng } = e;
		const MapValue = { lat, lng };
		
		setCenter({
			...center,
			lat: Number(e.lat),
			lng: Number(e.lng),
		});
				
		props.getNewCoordinates({
			geocode: MapValue,
			details: props?.retailerDetails?.data?.retailerdetails,
		});
	};

	return (
		<div style={{ height: "50vh", width: "100%" }}>
			<GoogleMapReact
				bootstrapURLKeys={{ key: "AIzaSyC8fl3_hu8DuU1UUlpRpyLQssuN0ZSYunU" }}
				defaultCenter={center}
				defaultZoom={15}
				center={center}
				onClick={changeMarkerPosition}
			>
				<Marker draggable={true} lat={center.lat} lng={center.lng} text="My Marker" />
			</GoogleMapReact>
		</div>
	);
};

export default EditGoogleMap;
