import React, { useEffect } from "react";
import NavBar from "../../Components/NavBar";
import MobileNavBar from "../../Components/NavBar/MobileNavbar";
// import Tabnavbar from "../../Components/NavBar/Tabnavbar";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import EditableForm from "../../Components/MyAccountForm/EditableForm ";
import MyorderSearch from "../../Components/MyorderSearch/index";
import Add from "../../Components/AddProduct/add";
import CriticalOrders from "../../Components/CriticalOrders";
import CriticalDetails from "../../Components/CriticalOrders/CriticalDetails";
import UpdateProduct from "../../Components/UpdateProduct";
import SearchDetails from "../../Components/SearchDetails";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import AlertTitle from "@mui/material/AlertTitle";
import Heirarchy from "../../Components/Heirarchy";
import Logout from "../../Components/Logout/index";
import ProductDetailsCard from "../../Components/UpdateProduct/ProductDetailsCard";
import AdditionalDetails from "../../Components/AdditionalDetailsEdit";
import Help from "../../Components/Help";
import Dashboard from "../../Components/Dashboard";
import GetUrl from "../../Components/GetUrl";
import ShopAssistant from "../../Components/ShopAssistant";
import Orders from "../../Components/ShopAssistant/Orders";
import EditRegister from "../../Components/ShopAssistant/EditRegister";
import View from "../../Components/ShopAssistant/View";
import Register from "../../Components/ShopAssistant/Register";
import OrderDetails from "../../Components/NavBar/OrderDetails";
import SubscriptionPlan from "../../Components/SubscriptionPlan";
import CatalogBusiness from "../../Components/CatalogBuilder/CatalogBusiness";
import Catalog from "../../Components/CatalogBuilder/Catalog";
import Business from "../../Components/CatalogBuilder/Business";
import CategoryProducts from "../../Components/CatalogBuilder/CategoryProducts";
import Subcategory from "../../Components/CatalogBuilder/Subcategory";
import Coupons from "../../Components/Coupons";
import PercentageCoupon from "../../Components/Coupons/PercentageCoupon";
import FlatDiscount from "../../Components/Coupons/FlatDiscount";
import MobileStepper from "../../Components/CheckoutPage/MobileStepper";
import BuyXgetYCoupon from "../../Components/Coupons/BuyXgetYCoupon";
import FreeDEliveryCoupon from "../../Components/Coupons/FreeDeliveryCoupon";
import SignUpNew from "../SignUpNew";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material";
import Profile from "../../Components/Profile";

import ProfileUpdate from "../../Components/Profile/ProfileUpdate";
import StoreUpdate from "../../Components/Profile/StoreUpdate";
import ManageSlots from "../../Components/Profile/ManageSlots";
import ChangePassword from "../../Components/Profile/ChangePassword";
import Coupontype from "../../Components/Coupons/Coupontype";
import SideNavBar from "../../Components/Sidebar/SideNavBar";
import NewOrder from "../../Components/MyorderSearch/NewOrder";
import ActiveOrder from "../../Components/MyorderSearch/ActiveOrder";
import ReadyForDelivery from "../../Components/MyorderSearch/ReadyForDelivery";
import OutForDelivery from "../../Components/MyorderSearch/OutForDelivery";
import OrderHistory from "../../Components/MyorderSearch/OrderHistory";
import ActiveOrderFrontPage from "../../Components/MyorderSearch/ActiveOrderFrontPage";
import ReadyForDeliveryFrontPage from "../../Components/MyorderSearch/ReadyForDeliveryFrontPage";
import OutForDeliveryFrontPage from "../../Components/MyorderSearch/OutForDeliveryFrontPage";
import OrderHistoryDetails from "../../Components/MyorderSearch/OrderHistoryDetails";
import Product from "../../Components/AddProduct/Product";
import UpcomingCoupons from "../../Components/Coupons/UpcomingCoupons";
import Businessproduct from "../../Components/CatalogBuilder/Businessproduct";
import Categorynext from "../../Components/Heirarchy/Categorynext";
import GoogleMapOrder from "../../Components/MyorderSearch/GoogleMap/GoogleMapOrder";
import Index from "../../Components/Payment/Index";
import Completed from "../../Components/Payment/Completed";
import Pending from "../../Components/Payment/Pending";

const SignIn = (props) => {
  const role = localStorage.getItem("role");
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      if (role === "Shop Assistant") {
        navigate("/home/MyOrders");
      } else {
        navigate("/");
      }
    }
  }, [navigate, role]);
  const [state, setState] = React.useState({ mainList: 0, SubList: 0 });

  function Alert(props) {
    return <Alert elevation={6} variant="filled" {...props} />;
  }
  const selectedList = (item, index) => {
    setState({
      mainList: item.id,
      SubList: index,
    });
  };

  useEffect(
    () => {
      props.getSellerDetail(`${localStorage.getItem("retailerid")}`);
      props.getDepartmentList(`${localStorage.getItem("storeid")}`);
      props.getSellerWorking(`${localStorage.getItem("retailerid")}`);
      props.ShareLink();
      props.getBusinessType();
      props.getDashboard();
      props.getNewDashboard(`${localStorage.getItem("storeid")}`);
      localStorage.setItem("imgUpload", false);
      localStorage.setItem("imageUrl", null);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  //   let role=localStorage.getItem("role")

  //   if(role==="Store Manager")
  //  { React.useEffect(() => {
  //     if (props.isDataLoaded === true)
  //       props.setBusinessType(
  //         props.retailerDetails &&
  //           props.retailerDetails.data &&
  //           props.retailerDetails.data.retailerdetails &&
  //           props.retailerDetails.data.retailerdetails.stores[0] &&
  //           props.retailerDetails.data.retailerdetails.stores[0].businessTypeId
  //       );
  //   }, [props.retailerDetails]);
  // }

  // React.useEffect(() => {
  //   let role = localStorage.getItem("role");

  //   if (role === "Store Manager" && props.isDataLoaded === true) {
  //     props.setBusinessType(
  //       props.retailerDetails?.data?.retailerdetails?.stores[0]?.businessTypeId
  //     );
  //   }
  // }, [props.isDataLoaded, props.retailerDetails]);

  React.useEffect(() => {
    let role = localStorage.getItem("role");

    if (role === "Store Manager" && props.isDataLoaded === true) {
      const stores = props.retailerDetails?.data?.retailerdetails?.stores;
      if (stores && stores.length > 0) {
        const businessTypeId = stores[0]?.businessTypeId;
        if (businessTypeId) {
          props.setBusinessType(businessTypeId);
        }
      }
    }
  }, [props.isDataLoaded, props.retailerDetails]);

  React.useEffect(() => {
    if (props.EditingCouponssuccess === true) {
      props.getAllCoupons();
      props.getAllUpComingCoupons();
      props.getAllOnGoingCoupons();
    }
  }, [props.EditingCouponssuccess]);

  //commented for temporary purpose

  // useEffect(
  //   () => {
  //     if (
  //       props.percentagecouponsuccess ||
  //       props.Flatcouponsuccess ||
  //       props.Deliverycouponsuccess ||
  //       props.BXGYCouponssuccess
  //     ) {
  //       setTimeout(() => {
  //         props.history.push({
  //           pathname: `/home/Coupons`,
  //         });
  //       }, 1000);
  //     }
  //   },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [
  //     props.percentagecouponsuccess,
  //     props.Flatcouponsuccess,
  //     props.Deliverycouponsuccess,
  //     props.BXGYCouponssuccess,
  //   ]
  // );

  //commented for temporary purpose

  // useEffect(
  //   () => {
  //     if (props.productUpdate) {
  //       setTimeout(() => {
  //         props.history.push({
  //           pathname: `/home/EditProducts`,
  //         });
  //       }, 1000);
  //     }
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [props.productUpdate]
  // );

  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down("md"));
  const loginformNumber = localStorage.getItem("loginformNumber");
  const loginnumber =
    loginformNumber === "null" || loginformNumber === null
      ? null
      : loginformNumber === "0"
      ? 0
      : parseInt(loginformNumber);
  const storestatus = localStorage.getItem("storestatus");

  // console.log("shopassistant for id",props?.ShopAssistantsuccessData?.loginShopAssistant?.shopAssistantId)

  // const handleLocalStorage = async (props) => {

  //     const shopAssistantId = await props?.ShopAssistantsuccessData?.loginShopAssistant?.shopAssistantId;

  //     const localStorageShopAssistant = shopAssistantId === undefined ? undefined : shopAssistantId;
  //     localStorage.setItem("localShopAssistantId", localStorageShopAssistant);

  // };

  // handleLocalStorage(props);

  return (
    <>
      {isSmallScreen ? (
        <>
          <MobileNavBar
            selectedList={selectedList}
            {...props}
            // key={props.match.params.id}
            // id={props.match.params.id}
            {...props}
          >
            {/* {props.editRetailerSuccess ||
          props.editRetailerFailed ||
          props.passwordResetSuccess ||
          props.passwordResetFailed ||
          props.productUpdate ||
          props.productUpdateFailed ||
          props.productAdded ||
          props.productAddFail ||
          props.newImageUploaded ||
          props.newImageFailed ||
          props.multipleImageUploaded ||
          props.addCatalogProductSuccess ||
          props.multiplefailed ||
          props.multiFileEdit ||
          props.GSTImageUploaded ||
          props.GSTImageFailed ||
          props.percentagecouponsuccess ||
          props.percentagecouponFailed ||
          props.Flatcouponsuccess ||
          props.FlatcouponFailed ||
          props.Deliverycouponsuccess ||
          props.DeliverycouponFailed ||
          props.AssigningCouponssuccess ||
          props.AssigningCouponsFailed ||
          props.EditingCouponssuccess ||
          props.EditingCouponsFailed ||
          props.BXGYCouponssuccess ||
          props.BXGYCouponsFailed ? (
            <Snackbar
              style={{ width: "fit-content" }}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={
                props.editRetailerSuccess ||
                props.editRetailerFailed ||
                props.passwordResetSuccess ||
                props.passwordResetFailed ||
                props.productUpdate ||
                props.productUpdateFailed ||
                props.productAdded ||
                props.productAddFail ||
                props.newImageUploaded ||
                props.newImageFailed ||
                props.multipleImageUploaded ||
                props.multiplefailed ||
                props.multiFileEdit ||
                props.GSTImageUploaded ||
                props.addCatalogProductSuccess ||
                props.GSTImageFailed ||
                props.percentagecouponsuccess ||
                props.percentagecouponFailed ||
                props.Flatcouponsuccess ||
                props.FlatcouponFailed ||
                props.Deliverycouponsuccess ||
                props.DeliverycouponFailed ||
                props.AssigningCouponssuccess ||
                props.AssigningCouponsFailed ||
                props.EditingCouponssuccess ||
                props.EditingCouponsFailed ||
                props.BXGYCouponssuccess ||
                props.BXGYCouponsFailed
              }
              autoHideDuration={1000}
              onClose={() => props.closeSnackBars()}
            >
              <Alert
                severity={
                  props.editRetailerSuccess ||
                  props.passwordResetSuccess ||
                  props.productUpdate ||
                  props.productAdded ||
                  props.newImageUploaded ||
                  props.multipleImageUploaded ||
                  props.multiFileEdit ||
                  props.addCatalogProductSuccess ||
                  props.GSTImageUploaded ||
                  props.percentagecouponsuccess ||
                  props.Flatcouponsuccess ||
                  props.Deliverycouponsuccess ||
                  props.AssigningCouponssuccess ||
                  props.EditingCouponssuccess ||
                  props.BXGYCouponssuccess
                    ? "success"
                    : "error"
                }
              >
                <AlertTitle>
                  {props.BXGYCouponssuccess
                    ? "Coupon added successfully"
                    : props.BXGYCouponsFailed
                    ? "Coupon added failed"
                    : props.EditingCouponssuccess
                    ? "Coupon status changed successfully"
                    : props.EditingCouponsFailed
                    ? `${props.Editingerror}`
                    : props.AssigningCouponssuccess
                    ? "Coupon assigned to user successfully"
                    : props.AssigningCouponsFailed
                    ? `${props.Assignerror}`
                    : props.percentagecouponsuccess
                    ? "Coupon added successfully"
                    : props.Flatcouponsuccess
                    ? "Coupon added successfully"
                    : props.Deliverycouponsuccess
                    ? "Coupon added successfully"
                    : props.percentagecouponFailed
                    ? "Coupon added failed"
                    : props.FlatcouponFailed
                    ? "Coupon added failed"
                    : props.DeliverycouponFailed
                    ? "Coupon added failed"
                    : props.addCatalogProductSuccess
                    ? "Products added successfully"
                    : props.editRetailerSuccess || props.productUpdate
                    ? "Changes have been saved successfully"
                    : props.editRetailerFailed
                    ? "Edit Unsuccessful"
                    : props.passwordResetSuccess
                    ? "Password have been changed successfully"
                    : props.passwordResetFailed
                    ? `${props.passErrMsg}`
                    : props.productUpdateFailed
                    ? `${props.ProductUpadteError}`
                    : props.productAdded
                    ? "Product added successfully"
                    : props.productAddFail
                    ? `${props.ProductErrorData}`
                    : props.newImageUploaded
                    ? "Image uploaded successfully"
                    : props.newImageFailed
                    ? "Image upload failed"
                    : props.multipleImageUploaded
                    ? "Images uploaded successfully"
                    : props.multiplefailed
                    ? "Images upload failed"
                    : props.multiFileEdit
                    ? "Images uploaded successfully"
                    : props.GSTImageUploaded
                    ? "Gst uploaded successfully"
                    : props.GSTImageFailed
                    ? "Upload failed"
                    : null}
                </AlertTitle>
              </Alert>
            </Snackbar>
          ) : null}
          {props.editStoreSuccess || props.editStoreFailed ? (
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              style={{ width: "fit-content" }}
              open={props.editStoreSuccess || props.editStoreFailed}
              autoHideDuration={1000}
              onClose={() => props.closeSnackBars()}
            >
              <Alert severity={props.editStoreSuccess ? "success" : "error"}>
                <AlertTitle>
                  {props.editStoreSuccess
                    ? "Changes have been saved successfully"
                    : props.editStoreFailed
                    ? `${props.editStoreMessage}`
                    : null}
                </AlertTitle>
              </Alert>
            </Snackbar>
          ) : null} */}

            <Routes>
              <Route
                exact
                path="/home/Sharemylink"
                element={<GetUrl {...state} {...props} />}
              />

              <Route
                exact
                path="/"
                element={
                  role === "Shop Assistant" ? (
                    <MyorderSearch {...state} {...props} />
                  ) : storestatus === "active" ? (
                    <Dashboard {...state} {...props} />
                  ) : loginnumber === 4 ||
                    props.formNumber === 4 ||
                    loginnumber === null ? (
                    <Dashboard {...state} {...props} />
                  ) : (
                    <SignUpNew {...state} {...props} />
                  )
                }
              />

              {/* <Route exact path="/">
            <SignUpNew {...state} {...props} />
          </Route> */}

              <Route
                exact
                path="/home/Dashboard"
                element={<Dashboard {...state} {...props} />}
              />

              <Route
                exact
                path="/home/Register"
                element={<SignUpNew {...state} {...props} />}
              />

              <Route
                exact
                path="/home/Myaccount"
                element={<EditableForm {...state} {...props} />}
              />

              <Route
                exact
                path="/home/CatalogBuilder"
                element={<CatalogBusiness {...props} {...state} />}
              />

              <Route
                exact
                path="/home/CategoryBuilder/Departments"
                element={<Catalog {...props} {...state} />}
              />

              <Route
                exact
                path="/home/CategoryBuilder/Products"
                element={<CategoryProducts {...props} />}
              />

              <Route
                exact
                path="/home/AdditionalDetails"
                element={<AdditionalDetails {...state} {...props} />}
              />

              <Route
                exact
                path="/home/Subscription"
                element={<SubscriptionPlan {...state} {...props} />}
              />

              <Route
                exact
                path="/home/Coupons"
                element={<Coupons {...state} {...props} />}
              />

              <Route
                exact
                path="/home/Coupons/Percentagediscount"
                element={<PercentageCoupon {...props} />}
              />

              <Route
                exact
                path="/home/Coupons/Flatdiscount"
                element={<FlatDiscount {...props} />}
              />

              <Route
                exact
                path="/home/Coupons/BuyXGetYfree"
                element={<BuyXgetYCoupon {...props} />}
              />

              <Route
                exact
                path="/home/Coupons/Freedelivery"
                element={<FreeDEliveryCoupon {...props} />}
              />

              <Route
                exact
                path="/home/ManageHierarchy"
                element={<Heirarchy {...props} />}
              />

              <Route
                exact
                path="/home/AddProducts"
                element={<Add {...props} />}
              />

              <Route
                exact
                path="/home/EditProducts"
                element={<UpdateProduct {...props} />}
              />

              <Route
                exact
                path="/home/MyOrders"
                element={<MyorderSearch {...props} />}
              />

              <Route
                exact
                path="/home/MyOrders/NewOrdersDetail"
                element={<NewOrder {...props} />}
              />

              <Route
                exact
                path="/home/MyOrders/ActiveOrders"
                element={<ActiveOrderFrontPage {...props} />}
              />
              <Route
                exact
                path="/home/MyOrders/ActiveOrdersDetails"
                element={<ActiveOrder {...props} />}
              />
              <Route
                exact
                path="/home/MyOrders/ReadyForDelivery"
                element={<ReadyForDeliveryFrontPage {...props} />}
              />
              <Route
                exact
                path="/home/MyOrders/ReadyForDeliveryDetails"
                element={<ReadyForDelivery {...props} />}
              />
              <Route
                exact
                path="/home/MyOrders/OutForDelivery"
                element={<OutForDeliveryFrontPage {...props} />}
              />
              <Route
                exact
                path="/home/MyOrders/OutForDeliveryDetails"
                element={<OutForDelivery {...props} />}
              />
              <Route
                exact
                path="/home/MyOrders/OrderHistory"
                element={<OrderHistory {...props} />}
              />
              <Route
                exact
                path="/home/MyOrders/OrderHistoryDetails"
                element={<OrderHistoryDetails {...props} />}
              />
              <Route
                exact
                path="/home/EditProducts/details"
                element={<ProductDetailsCard {...props} />}
              />

              <Route
                exact
                path="/home/Criticalorders"
                element={<CriticalOrders {...props} />}
              />

              <Route
                exact
                path="/home/OrderDetails"
                element={<OrderDetails {...props} />}
              />
              <Route
                exact
                path="/home/MyOrders/GoogleMap"
                element={<GoogleMapOrder {...props} />}
              />
              <Route
                exact
                path="/home/ShopAssistant"
                // element={<ShopAssistant {...state} {...props} />}
                element={<View {...props} />}
              />

              <Route
                exact
                path="/home/ShopAssistant/Register"
                element={<Register {...props} />}
              />

              <Route
                exact
                path="/home/ShopAssistant/orders"
                element={<Orders {...state} {...props} />}
              />
              <Route
                exact
                path="/home/ShopAssistant/EditRegister"
                element={<EditRegister {...state} {...props} />}
              />

              <Route
                exact
                path="/home/ShopAssistant/Viewshopassistants"
                element={<View {...props} />}
              />

              <Route
                exact
                path="/home/Criticalorders/details"
                element={<CriticalDetails {...state} {...props} />}
              />

              <Route exact path="/home/Help" element={<Help />} />
              <Route
                exact
                path="/home/Profile"
                element={<Profile {...props} />}
              />

              <Route
                exact
                path="/home/Product"
                element={<Product {...props} />}
              />
              <Route
                exact
                path="/home/ManageHierarchy/Product"
                element={<Categorynext {...props} />}
              />
              <Route
                exact
                path="/home/CatalogProducts"
                element={<Businessproduct {...props} />}
              />
                <Route
                exact
                path="/home/Payments"
                element={<Index {...props} />}
              />
                <Route
                exact
                path="/home/Payments/PendingDeposits"
                element={<Pending {...props} />}
              />
                <Route
                exact
                path="/home/Payments/CompletedTransactions"
                element={<Completed {...props} />}
              />
              <Route
                exact
                path="/home/UpdateProfile"
                element={<ProfileUpdate {...props} />}
              />
              <Route
                exact
                path="/home/UpdateStore"
                element={<StoreUpdate {...props} />}
              />
              <Route
                exact
                path="/home/ManageSlots"
                element={<ManageSlots {...props} />}
              />
              <Route
                exact
                path="/home/ChangePassword"
                element={<ChangePassword {...props} />}
              />
              <Route exact path="/home/Coupontype" element={<Coupontype />} />
              <Route
                exact
                path="/home/UpcomingCoupons"
                element={<UpcomingCoupons {...props} />}
              />

              <Route
                exact
                path="/home/LogOut"
                element={<Logout {...props} />}
              />
            </Routes>
          </MobileNavBar>
        </>
      ) : (
        <SideNavBar
          selectedList={selectedList}
          {...props}
          // key={props.match.params.id}
          // id={props.match.params.id}
          {...props}
        >
          {/* {props.editRetailerSuccess ||
          props.editRetailerFailed ||
          props.passwordResetSuccess ||
          props.passwordResetFailed ||
          props.productUpdate ||
          props.productUpdateFailed ||
          props.productAdded ||
          props.productAddFail ||
          props.newImageUploaded ||
          props.newImageFailed ||
          props.multipleImageUploaded ||
          props.addCatalogProductSuccess ||
          props.multiplefailed ||
          props.multiFileEdit ||
          props.GSTImageUploaded ||
          props.GSTImageFailed ||
          props.percentagecouponsuccess ||
          props.percentagecouponFailed ||
          props.Flatcouponsuccess ||
          props.FlatcouponFailed ||
          props.Deliverycouponsuccess ||
          props.DeliverycouponFailed ||
          props.AssigningCouponssuccess ||
          props.AssigningCouponsFailed ||
          props.EditingCouponssuccess ||
          props.EditingCouponsFailed ||
          props.BXGYCouponssuccess ||
          props.BXGYCouponsFailed ? (
            <Snackbar
              style={{ width: "fit-content", left: "50%" }}
              anchorOrigin={{ vertical: "center", horizontal: "center" }}
              open={
                props.editRetailerSuccess ||
                props.editRetailerFailed ||
                props.passwordResetSuccess ||
                props.passwordResetFailed ||
                props.productUpdate ||
                props.productUpdateFailed ||
                props.productAdded ||
                props.productAddFail ||
                props.newImageUploaded ||
                props.newImageFailed ||
                props.multipleImageUploaded ||
                props.multiplefailed ||
                props.multiFileEdit ||
                props.GSTImageUploaded ||
                props.addCatalogProductSuccess ||
                props.GSTImageFailed ||
                props.percentagecouponsuccess ||
                props.percentagecouponFailed ||
                props.Flatcouponsuccess ||
                props.FlatcouponFailed ||
                props.Deliverycouponsuccess ||
                props.DeliverycouponFailed ||
                props.AssigningCouponssuccess ||
                props.AssigningCouponsFailed ||
                props.EditingCouponssuccess ||
                props.EditingCouponsFailed ||
                props.BXGYCouponssuccess ||
                props.BXGYCouponsFailed
              }
              autoHideDuration={1000}
              onClose={() => props.closeSnackBars()}
            >
              <Alert
                severity={
                  props.editRetailerSuccess ||
                  props.passwordResetSuccess ||
                  props.productUpdate ||
                  props.productAdded ||
                  props.newImageUploaded ||
                  props.multipleImageUploaded ||
                  props.multiFileEdit ||
                  props.addCatalogProductSuccess ||
                  props.GSTImageUploaded ||
                  props.percentagecouponsuccess ||
                  props.Flatcouponsuccess ||
                  props.Deliverycouponsuccess ||
                  props.AssigningCouponssuccess ||
                  props.EditingCouponssuccess ||
                  props.BXGYCouponssuccess
                    ? "success"
                    : "error"
                }
              >
                <AlertTitle>
                  {props.BXGYCouponssuccess
                    ? "Coupon added successfully"
                    : props.BXGYCouponsFailed
                    ? "Coupon added failed"
                    : props.EditingCouponssuccess
                    ? "Coupon status changed successfully"
                    : props.EditingCouponsFailed
                    ? `${props.Editingerror}`
                    : props.AssigningCouponssuccess
                    ? "Coupon assigned to user successfully"
                    : props.AssigningCouponsFailed
                    ? `${props.Assignerror}`
                    : props.percentagecouponsuccess
                    ? "Coupon added successfully"
                    : props.Flatcouponsuccess
                    ? "Coupon added successfully"
                    : props.Deliverycouponsuccess
                    ? "Coupon added successfully"
                    : props.percentagecouponFailed
                    ? "Coupon added failed"
                    : props.FlatcouponFailed
                    ? "Coupon added failed"
                    : props.DeliverycouponFailed
                    ? "Coupon added failed"
                    : props.addCatalogProductSuccess
                    ? "Products added successfully"
                    : props.editRetailerSuccess || props.productUpdate
                    ? "Changes have been saved successfully"
                    : props.editRetailerFailed
                    ? "Edit Unsuccessful"
                    : props.passwordResetSuccess
                    ? "Password have been changed successfully"
                    : props.passwordResetFailed
                    ? `${props.passErrMsg}`
                    : props.productUpdateFailed
                    ? `${props.ProductUpadteError}`
                    : props.productAdded
                    ? "Product added successfully"
                    : props.productAddFail
                    ? `${props.ProductErrorData}`
                    : props.newImageUploaded
                    ? "Image uploaded successfully"
                    : props.newImageFailed
                    ? "Image upload failed"
                    : props.multipleImageUploaded
                    ? "Images uploaded successfully"
                    : props.multiplefailed
                    ? "Images upload failed"
                    : props.multiFileEdit
                    ? "Images uploaded successfully"
                    : props.GSTImageUploaded
                    ? "Gst uploaded successfully"
                    : props.GSTImageFailed
                    ? "Upload failed"
                    : null}
                </AlertTitle>
              </Alert>
            </Snackbar>
          ) : null}
          {props.editStoreSuccess || props.editStoreFailed ? (
            <Snackbar
              style={{ width: "fit-content", left: "50%" }}
              anchorOrigin={{ vertical: "center", horizontal: "right" }}
              open={props.editStoreSuccess || props.editStoreFailed}
              autoHideDuration={2000}
              onClose={() => props.closeSnackBars()}
            >
              <Alert severity={props.editStoreSuccess ? "success" : "error"}>
                <AlertTitle>
                  {props.editStoreSuccess
                    ? "Changes have been saved successfully"
                    : props.editStoreFailed
                    ? `${props.editStoreMessage}`
                    : null}
                </AlertTitle>
              </Alert>
            </Snackbar>
          ) : null} */}

          <Routes>
            <Route
              exact
              path="/home/Sharemylink"
              element={<GetUrl {...state} {...props} />}
            />

            <Route
              exact
              path="/"
              element={
                role === "Shop Assistant" ? (
                  <MyorderSearch {...state} {...props} />
                ) : storestatus === "active" ? (
                  <Dashboard {...state} {...props} />
                ) : loginnumber === 4 ||
                  props.formNumber === 4 ||
                  loginnumber === null ? (
                  <Dashboard {...state} {...props} />
                ) : (
                  <SignUpNew {...state} {...props} />
                )
              }
            />

            {/* <Route exact path="/">
            <SignUpNew {...state} {...props} />
          </Route> */}

            <Route
              exact
              path="/home/Dashboard"
              element={<Dashboard {...state} {...props} />}
            />

            <Route
              exact
              path="/home/Register"
              element={<SignUpNew {...state} {...props} />}
            />

            <Route
              exact
              path="/home/Myaccount"
              element={<EditableForm {...state} {...props} />}
            />

            <Route
              exact
              path="/home/CatalogBuilder"
              element={<CatalogBusiness {...props} {...state} />}
            />

            <Route
              exact
              path="/home/CategoryBuilder/Departments"
              element={<Catalog {...props} {...state} />}
            />

            <Route
              exact
              path="/home/CategoryBuilder/Products"
              element={<CategoryProducts {...props} />}
            />

            <Route
              exact
              path="/home/AdditionalDetails"
              element={<AdditionalDetails {...state} {...props} />}
            />

            <Route
              exact
              path="/home/Subscription"
              element={<SubscriptionPlan {...state} {...props} />}
            />

            <Route
              exact
              path="/home/Coupons"
              element={<Coupons {...state} {...props} />}
            />

            <Route
              exact
              path="/home/Coupons/Percentagediscount"
              element={<PercentageCoupon {...props} />}
            />

            <Route
              exact
              path="/home/Coupons/Flatdiscount"
              element={<FlatDiscount {...props} />}
            />
            <Route
              exact
              path="/home/MyOrders/GoogleMap"
              element={<GoogleMapOrder {...props} />}
            />
            <Route
              exact
              path="/home/Coupons/BuyXGetYfree"
              element={<BuyXgetYCoupon {...props} />}
            />

            <Route
              exact
              path="/home/Coupons/Freedelivery"
              element={<FreeDEliveryCoupon {...props} />}
            />

            <Route
              exact
              path="/home/ManageHierarchy"
              element={<Heirarchy {...props} />}
            />

            <Route
              exact
              path="/home/AddProducts"
              element={<Add {...props} />}
            />

            <Route
              exact
              path="/home/EditProducts"
              element={<UpdateProduct {...props} />}
            />

            <Route
              exact
              path="/home/MyOrders"
              element={<MyorderSearch {...props} />}
            />

            <Route
              exact
              path="/home/MyOrders/NewOrderDetail"
              element={<NewOrder {...state} {...props} />}
            />
            <Route
              exact
              path="/home/MyOrders/ActiveOrders"
              element={<ActiveOrderFrontPage {...props} />}
            />
            <Route
              exact
              path="/home/MyOrders/ActiveOrdersDetails"
              element={<ActiveOrder {...props} />}
            />
            <Route
              exact
              path="/home/MyOrders/ReadyForDelivery"
              element={<ReadyForDeliveryFrontPage {...props} />}
            />
            <Route
              exact
              path="/home/MyOrders/ReadyForDeliveryDetails"
              element={<ReadyForDelivery {...props} />}
            />
            <Route
              exact
              path="/home/MyOrders/OutForDelivery"
              element={<OutForDeliveryFrontPage {...props} />}
            />
            <Route
              exact
              path="/home/MyOrders/OutForDeliveryDetails"
              element={<OutForDelivery {...props} />}
            />
            <Route
              exact
              path="/home/MyOrders/OrderHistory"
              element={<OrderHistory {...props} />}
            />
            <Route
              exact
              path="/home/MyOrders/OrderHistoryDetails"
              element={<OrderHistoryDetails {...props} />}
            />
            <Route
              exact
              path="/home/EditProducts/details"
              element={<ProductDetailsCard {...props} />}
            />

            <Route
              exact
              path="/home/Criticalorders"
              element={<CriticalOrders {...props} />}
            />

            <Route
              exact
              path="/home/OrderDetails"
              element={<OrderDetails {...props} />}
            />

            <Route
              exact
              path="/home/ShopAssistant"
              // element={<ShopAssistant {...state} {...props} />}
              element={<View {...props} />}
            />

            <Route
              exact
              path="/home/ShopAssistant/Register"
              element={<Register {...props} />}
            />

            <Route
              exact
              path="/home/ShopAssistant/orders"
              element={<Orders {...state} {...props} />}
            />
            <Route
              exact
              path="/home/ShopAssistant/EditRegister"
              element={<EditRegister {...state} {...props} />}
            />

            <Route
              exact
              path="/home/ShopAssistant/Viewshopassistants"
              element={<View {...props} />}
            />

            <Route
              exact
              path="/home/Criticalorders/details"
              element={<CriticalDetails {...state} {...props} />}
            />

            <Route exact path="/home/Help" element={<Help />} />

            <Route exact path="/home/LogOut" element={<Logout {...props} />} />
            <Route
              exact
              path="/home/Profile"
              element={<Profile {...props} />}
            />

            <Route
              exact
              path="/home/Product"
              element={<Product {...props} />}
            />
            <Route
              exact
              path="/home/ManageHierarchy/Product"
              element={<Categorynext {...props} />}
            />
            <Route
              exact
              path="/home/CatalogProducts"
              element={<Businessproduct {...props} />}
            />
              <Route
                exact
                path="/home/Payments"
                element={<Index {...props} />}
              />
               <Route
                exact
                path="/home/Payments/PendingDeposits"
                element={<Pending {...props} />}
              />
                <Route
                exact
                path="/home/Payments/CompletedTransactions"
                element={<Completed {...props} />}
              />
            <Route
              exact
              path="/home/UpdateProfile"
              element={<ProfileUpdate {...props} />}
            />
            <Route
              exact
              path="/home/UpdateStore"
              element={<StoreUpdate {...props} />}
            />
            <Route
              exact
              path="/home/ManageSlots"
              element={<ManageSlots {...props} />}
            />
            <Route
              exact
              path="/home/ChangePassword"
              element={<ChangePassword {...props} />}
            />
            <Route exact path="/home/Coupontype" element={<Coupontype />} />
            <Route
              exact
              path="/home/UpcomingCoupons"
              element={<UpcomingCoupons {...props} />}
            />
          </Routes>
        </SideNavBar>
      )}
    </>
  );
};

export default SignIn;
