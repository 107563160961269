import React, { useState, useEffect } from "react";
import {
  AdapterDayjs,
  DateTimePicker,
  DemoContainer,
  Grid,
  InputAdornment,
  LocalizationProvider,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "../MUIComponents/index";
import {
  HeaderFontSize_24,
  HeaderNavigationFontSize_20,
  HeaderNavigationFontSize_22,
} from "../Typography";
import CustomBadge from "../Commoncomponents/badge";
import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Dialog } from "../MUIComponents/index";
import { DialogContent } from "../MUIComponents/index";
import closeIconPayment from "../../utils/images/closeIconPayment.png";
import PaymentIconImage from "../../utils/images/PaymentIconImage.png";
import copyPayment from "../../utils/images/copyPayment.png";
import dayjs from "dayjs";
import calander_new from "../../utils/Icon/calander_new.png";
import searchIcon from "../../utils/Icon/search.svg";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "./Completed.css";
import CustomButton from "../Commoncomponents/button";
import * as Yup from "yup";
import { useFormik } from "formik";

import {
  contentFontSize_18,
  contentFontsize_14_xs_10,
  contentFontSize_22,
  ButtonFontSize_18,
} from "../Typography";
import CustomTextField from "../Commoncomponents/input";

function createData(no, name, calories, fat, carbs) {
  return { no, name, calories, fat, carbs };
}

const rows = [
  createData("01", "#123", "159", "350.00", "1234"),
  createData("02", "#123", "237", "350.00", "1234"),
  createData("03", "#123", "262", "350.00", "1234"),
  createData("04", "#123", "305", "350.00", "1234"),
  createData("05", "#123", "356", "350.00", "1234"),
  createData("06", "#123", "234", "350.00", "1234"),
];

function createData1(name, calories, fat, carbs) {
  return { name, calories, fat, carbs };
}

const rows1 = [
  createData1("Frozen yoghurt", 159, 6.0, 24),
  createData1("Ice cream sandwich", 237, 9.0, 37),
  createData1("Eclair", 262, 16.0, 24),
  createData1("Cupcake", 305, 3.7, 67),
];

function createData2(name, calories, fat, carbs) {
  return { name, calories, fat, carbs };
}
const rows2 = [
  createData2("", "", "SubTotal", 500),
  createData2("", "", "Tax", 0),
];

function createData3(name, calories, fat, carbs) {
  return { name, calories, fat, carbs };
}

const rows3 = [createData3("", "", "Total", 500)];

const headerStyle = {
  backgroundColor: "#4D7E08",
  color: "white",
  textAlign: "center",
  border: "1px solid rgba(224, 224, 224, 1)",
};

const headerStyle_new1 = {
  borderBottom: "1px solid black",
  borderTop: "1px solid black",
};

const headerStyle_new = {
  textAlign: "center",
  color: "black",
  borderTop: "1px solid black",
  borderBottom: "1px solid black",
};

const cellStyle_new = {
  textAlign: "center",
  borderTop: "1px solid black",
  borderBottom: "1px solid black",
};

const cellStyle_new1 = {
  textAlign: "left",
  borderTop: "1px solid black",
  borderBottom: "1px solid black",
};
const cellStyle_new2 = {
  textAlign: "center",
};
const cellStyle_new3 = {
  textAlign: "center",
  //borderBottom:"1px solid black"
};
const cellStyle_new4 = {
  textAlign: "center",
  borderTop: "1px solid black",
};

const cellStyle_none = {
  border: "none",
  textAlign: "center",
};
const cellStyle = {
  textAlign: "center",
  borderTop: "1px solid #4D7E08",
  borderRight: "1px solid #4D7E08",
  borderBottom: "1px solid #4D7E08",
  borderLeft: "1px solid #4D7E08",
};

const cellStyle2 = {
  textAlign: "center",
  borderTop: "1px solid #4D7E08",
  borderRight: "1px solid #4D7E08",
  borderBottom: "1px solid #4D7E08",
};

const cellStyle4 = {
  textAlign: "center",
  borderTop: "1px solid #4D7E08",
  borderLeft: "1px solid #4D7E08",
  borderBottom: "1px solid #4D7E08",
  color: "blue",
  textDecoration: "underline",
  cursor: "pointer",
};

const cellStyle1 = {
  textAlign: "center",
  borderTop: "1px solid #4D7E08",
  borderRight: "1px solid #4D7E08",
  borderBottom: "1px solid #4D7E08",
  borderLeft: "1px solid #4D7E08",
  color: "blue",
  textDecoration: "underline",
  cursor: "pointer",
};
function Completed(props) {
  const [newState, setNewState] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState([]);
  const [searchOrder, setSearchOrder] = useState(false);

  const handleClickOpen = (order) => {
    setOpen(true);
    setSelectedTransaction(order);
    console.log("selected", selectedTransaction);
  };

  const [offset, setOffset] = useState(0);
  const [first, setFirst] = useState(10);
  const [orders, setOrders] = useState([]);
  const [hasNext, setHasNext] = useState(false);
  const [show, setShow] = useState(false);
  const [searchLoad, setSearchLoad] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    props.getAllPendingPayments({ offset, first });
  }, []);

  useEffect(() => {
    props.getAllPendingSettlements();
  }, []);

  const validationSchema = Yup.object().shape({
    dateFrom: Yup.date().nullable(),
    dateTo: Yup.date().nullable(),
  });

  useEffect(() => {
    setOrders([]);
    const searchOrders =
      props?.completedSearchPaymentData?.searchCompletedSettlementsWithFilters
        ?.items || [];
    setOrders(searchOrders);
  }, [props?.completedSearchPaymentData]);
  const formik = useFormik({
    initialValues: {
      dateFrom: null,
      dateTo: null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setSearchOrder(true);
      setHasNext(true);
      setOrders([]);
      setOffset(0);
      setSearchLoad(true);

      const processedOrderNumber = search === "" ? null : parseFloat(search);

      await props.getCompletedPaymentSearch({
        storeid: localStorage.getItem("storeid"),
        dateFrom: values.dateFrom,
        dateTo: values.dateTo,
        hasNext,
        first,
        offset,
        orderNumber: processedOrderNumber,
      });
    },
  });

  const [pendingData, setPendingData] = useState();
  useEffect(() => {
    const data =
      props?.pendingPaymentData?.listAllPendingPaymentsBySeller?.count;
    setPendingData(data);
  }, [props.pendingPaymentData]);

  useEffect(() => {
    props.getAllCompletedSettlements({ offset, first });
  }, []);

  useEffect(() => {
    const newOrders =
      props?.completedPaymentData?.listAllCompletedSettlements?.items || [];
    setOrders(newOrders);
  }, [props.completedPaymentData]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen1 = () => {
    setOpen1(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const theme = useTheme();

  const handleLoadSearchMore = () => {};

  const nav = useNavigate();

  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const uplgmatch = useMediaQuery(theme.breakpoints.up("lg"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));

  const handlePendingClick = () => {
    nav("/home/Payments/PendingDeposits");
  };

  function handleOrder(id) {
    console.log("orderid", id);
    //setOne(true)
    const one = "hey";
    nav("/home/MyOrders/OrderHistoryDetails", {
      state: { orderid: id, one: one },
    });
  }

  const Clear = () => {
    formik.resetForm();
    props.getAllCompletedSettlements({ offset, first });
    const newOrders =
      props?.completedPaymentData?.listAllCompletedSettlements?.items || [];
    setOrders(newOrders);
    setOffset(0);
    setSearch("");
  };

  const handleLoadMore = () => {
    const newOffset = offset + 10;
    setOffset(newOffset);
    props.getAllCompletedSettlements({ offset: newOffset });
  };

  const handleSettlementsClick = () => {
    nav("/home/Payments");
  };
  function MuiIcon() {
    return (
      <img
        src={calander_new}
        alt="Calendar icon"
        width="22px"
        height="21.99px"
      />
    );
  }
  return (
    <>
      <div>
        <Typography
          style={{ fontFamily: "Roboto", fontWeight: "600" }}
          fontSize={HeaderNavigationFontSize_22}
        >
          Payment Details
        </Typography>
      </div>
      <div style={{ display: "flex", marginTop: "20px", gap: "100px" }}>
        <Typography
          style={{
            fontFamily: "Roboto",
            fontWeight: "700",
            color: "#8B8989",
            cursor: "pointer",
          }}
          fontSize={HeaderNavigationFontSize_20}
          onClick={handleSettlementsClick}
        >
          Settlements In-progress
          <CustomBadge
            badgeContent={
              props?.pendingSettlementData?.listAllPendingSettlements?.count
            }
            backgroundColor={"#8B8989"}
            color={"white"}
            borderColor={newState ? "none" : "#646464"}
            border="2px solid"
            fontSize={mdmatch ? "8px" : "12px"}
            height={mdmatch ? "15px" : "25px"}
            minWidth={mdmatch ? "15px" : "25px"}
            borderRadius="50%"
            marginLeft={mdmatch ? (smmatch ? "8px" : "20px") : "15px"}
          ></CustomBadge>
        </Typography>
        <Typography
          style={{
            fontFamily: "Roboto",
            fontWeight: "700",
            color: "#8B8989",
            cursor: "pointer",
          }}
          fontSize={HeaderNavigationFontSize_20}
          onClick={handlePendingClick}
        >
          Pending Deposits
          <CustomBadge
            badgeContent={pendingData}
            backgroundColor={"#8B8989"}
            color={"white"}
            borderColor={newState ? "none" : "#646464"}
            border="2px solid"
            fontSize={mdmatch ? "8px" : "12px"}
            height={mdmatch ? "15px" : "25px"}
            minWidth={mdmatch ? "15px" : "25px"}
            borderRadius="50%"
            marginLeft={mdmatch ? (smmatch ? "8px" : "20px") : "15px"}
          ></CustomBadge>
        </Typography>
        <Typography
          style={{
            fontFamily: "Roboto",
            fontWeight: "700",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          fontSize={HeaderNavigationFontSize_20}
          color={newState ? "#659B1B" : "#646464"}
        >
          Completed Transactions
          <CustomBadge
            badgeContent={
              props?.completedPaymentData?.listAllCompletedSettlements?.count
            }
            backgroundColor={newState ? "#659B1B" : "White"}
            color={newState ? "white" : "#659B1B"}
            borderColor={newState ? "none" : "#646464"}
            border="2px solid"
            fontSize={mdmatch ? "8px" : "12px"}
            height={mdmatch ? "15px" : "25px"}
            minWidth={mdmatch ? "15px" : "25px"}
            borderRadius="50%"
            marginLeft={mdmatch ? (smmatch ? "8px" : "20px") : "15px"}
          ></CustomBadge>
        </Typography>
      </div>
      {/* {props?.completedPaymentData?.listAllCompletedSettlements?.count > 0 && ( */}
      <div>
        <div>
          <div style={{ marginTop: "40px" }}>
            <TextField
              placeholder="Search"
              value={search}
              onChange={(event) => {
                setSearch(event.target.value);
              }}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#D1D5DB",
                  },
                  borderRadius: "20px",
                  "&.Mui-focused fieldset": {
                    borderColor: "#D1D5DB",
                  },
                },
                "& .MuiInputBase-input": {
                  backgroundColor: "transparent",
                  borderRadius: "20px",
                },
              }}
              InputProps={{
                sx: {
                  fontSize: matches && "13px",
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <img
                      src={searchIcon}
                      alt="Search"
                      style={{ marginRight: "8px" }}
                    />
                  </InputAdornment>
                ),
              }}
              size={matches ? "small" : "medium"}
            />
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div style={{ width: "70%", margin: "40px 0px 30px 0px" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <div style={{ flex: 1 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      value={formik.values.dateFrom}
                      onChange={(newValue) =>
                        formik.setFieldValue("dateFrom", newValue)
                      }
                      renderInput={(props) => <TextField {...props} />}
                      error={
                        formik.touched.dateFrom &&
                        Boolean(formik.errors.dateFrom)
                      }
                      helperText={
                        formik.touched.dateFrom && formik.errors.dateFrom
                      }
                      slots={{ openPickerIcon: MuiIcon }}
                      slotProps={{
                        textField: {
                          placeholder: "From Date",
                          size: matches && "small",
                        },
                        openPickerIcon: { sx: { fontSize: "50px" } },
                        action: {
                          sx: { marginRight: "-8px", padding: "4px" },
                        },
                      }}
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": { borderColor: "#D1D5DB" },
                          "&.Mui-focused fieldset": {
                            borderColor: "#D1D5DB",
                          },
                          borderRadius: "20px",
                          background: "transparent",
                        },
                        "& .MuiInputBase-input": {
                          backgroundColor: "transparent",
                          borderRadius: "30px",
                          fontSize: matches ? "13px" : "16px",
                        },
                        "& .MuiIconButton-root": { marginRight: "5px" },
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
              <div style={{ flex: 1 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      value={formik.values.dateTo}
                      onChange={(newValue) =>
                        formik.setFieldValue("dateTo", newValue)
                      }
                      renderInput={(props) => <TextField {...props} />}
                      error={
                        formik.touched.dateTo && Boolean(formik.errors.dateTo)
                      }
                      helperText={formik.touched.dateTo && formik.errors.dateTo}
                      slots={{ openPickerIcon: MuiIcon }}
                      slotProps={{
                        textField: {
                          placeholder: "To Date",
                          size: matches && "small",
                        },
                      }}
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": { borderColor: "#D1D5DB" },
                          "&.Mui-focused fieldset": {
                            borderColor: "#D1D5DB",
                          },
                          borderRadius: "20px",
                          background: "transparent",
                        },
                        "& .MuiInputBase-input": {
                          backgroundColor: "transparent",
                          borderRadius: "30px",
                          fontSize: matches ? "13px" : "16px",
                        },
                        "& .MuiIconButton-root": { marginRight: "5px" },
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
              <div>
                <CustomButton
                  color="rgb(255, 255, 255)"
                  backgroundColor="rgb(137, 199, 74, 1)"
                  height="50px"
                  borderRadius="20px"
                  width="280px"
                  type="submit"
                >
                  Apply
                </CustomButton>
              </div>
              <div>
                <CustomButton
                  color="black"
                  background="transparent"
                  height="50px"
                  borderRadius="20px"
                  borderColor="rgb(137, 199, 74, 1)"
                  width="280px"
                  onClick={() => {
                    Clear();
                  }}
                >
                  Clear
                </CustomButton>
              </div>
            </div>
          </div>
        </form>
        {orders.length > 0 && (
          <TableContainer
            component={Paper}
            sx={{
              borderLeft: "1px solid #4D7E08",
              borderRight: "1px solid #4D7E08",
              borderTop: "1px solid #4D7E08",
            }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={headerStyle}>No.</TableCell>
                  <TableCell sx={headerStyle}>Order No.</TableCell>
                  <TableCell align="center" sx={headerStyle}>
                    Date & Time
                  </TableCell>
                  <TableCell align="center" sx={headerStyle}>
                    Amount
                  </TableCell>
                  <TableCell align="center" sx={headerStyle}>
                    Invoice
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row" sx={cellStyle2}>
                      {index + 1}
                    </TableCell>
                    <TableCell
                      sx={cellStyle1}
                      onClick={() => handleOrder(row.id)}
                    >
                      {row.orderNumber}
                    </TableCell>
                    <TableCell align="center" sx={cellStyle}>
                      {row.transferSettlement[0]?.created},{" "}
                      {row.transferSettlement[0]?.createdTime}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={cellStyle1}
                      onClick={() => handleClickOpen(row)}
                    >{`₹${row.totalPayable.toFixed(2)}`}</TableCell>
                    <TableCell
                      align="center"
                      sx={cellStyle4}
                      onClick={handleClickOpen1}
                    >
                      {row.carbs}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
      {/* )} */}
      {searchLoad &&
        props?.completedSearchPaymentData?.searchCompletedSettlementsWithFilters
          ?.hasNext && (
          <CustomButton width={"20%"} borderRadius="10px">
            <Typography
              fontSize={ButtonFontSize_18}
              className="Add-product-btn"
              onClick={handleLoadSearchMore}
            >
              Load More
            </Typography>
          </CustomButton>
        )}

      {!searchLoad &&
        props?.completedPaymentData?.listAllCompletedSettlements?.hasNext && (
          <div style={{ marginTop: "10px" }}>
            <CustomButton width={"20%"} borderRadius="10px">
              <Typography
                fontSize={ButtonFontSize_18}
                className="Add-product-btn"
                onClick={handleLoadMore}
              >
                Load More
              </Typography>
            </CustomButton>
          </div>
        )}
      {props?.completedPaymentData?.listAllCompletedSettlements?.count ===
        0 && (
        <div
          style={{
            marginTop: "8%",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            fontSize={HeaderNavigationFontSize_20}
            className="Add-product-btn"
          >
            No completed transactions available.
          </Typography>
        </div>
      )}
      {searchOrder && orders.length === 0 && (
        <div
          style={{
            marginTop: "8%",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            fontSize={HeaderNavigationFontSize_20}
            className="Add-product-btn"
          >
            No Results Found.
          </Typography>
        </div>
      )}
      {/* Render the dialog conditionally */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div
            style={{
              height: "100%",
              width: "100%",
              position: "relative",
              padding: "20px 0px 0px 0px",
            }}
          >
            <div
              style={{
                position: "absolute",
                left: "98%",
                top: "-5px",
                cursor: "pointer",
              }}
              onClick={handleClose}
            >
              <img src={closeIconPayment} alt="closeIcon" />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <Typography
                style={{ fontFamily: "Roboto", fontWeight: "700" }}
                fontSize={contentFontSize_22}
              >
                ₹
                {selectedTransaction?.totalPayable
                  ? selectedTransaction.totalPayable.toFixed(2)
                  : "0.00"}
              </Typography>
              <Typography
                style={{ fontFamily: "Roboto", fontWeight: "500" }}
                fontSize={contentFontsize_14_xs_10}
              >
                autopay by razor pay/03245676543
              </Typography>
            </div>
            <div
              style={{
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                padding: "20px",
                marginTop: "35px",
                borderRadius: "10px",
                position: "relative",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontWeight: "500",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                fontSize={contentFontSize_18}
              >
                Transaction Details
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "30px",
                  marginTop: "30px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "20px",
                  }}
                >
                  <Typography
                    style={{ fontFamily: "Roboto", fontWeight: "500" }}
                    fontSize={contentFontsize_14_xs_10}
                  >
                    Transaction ID
                  </Typography>
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: "500",
                      color: "#AAAAAA",
                    }}
                    fontSize={contentFontsize_14_xs_10}
                  >
                    S6578654 <span style={{ margin: "0 1px" }}></span>
                    <img src={copyPayment} alt="no image" />
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "20px",
                  }}
                >
                  <Typography
                    style={{ fontFamily: "Roboto", fontWeight: "500" }}
                    fontSize={contentFontsize_14_xs_10}
                  >
                    Status
                  </Typography>
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: "500",
                      color: "#AAAAAA",
                    }}
                    fontSize={contentFontsize_14_xs_10}
                  >
                    {selectedTransaction?.transferSettlement?.length > 0
                      ? selectedTransaction.transferSettlement[0].status
                      : "No status available"}
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "20px",
                  }}
                >
                  <Typography
                    style={{ fontFamily: "Roboto", fontWeight: "500" }}
                    fontSize={contentFontsize_14_xs_10}
                  >
                    Transaction Type
                  </Typography>
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: "500",
                      color: "#AAAAAA",
                    }}
                    fontSize={contentFontsize_14_xs_10}
                  >
                    Credit
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "20px",
                  }}
                >
                  <Typography
                    style={{ fontFamily: "Roboto", fontWeight: "500" }}
                    fontSize={contentFontsize_14_xs_10}
                  >
                    Date & Time
                  </Typography>
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: "500",
                      color: "#AAAAAA",
                    }}
                    fontSize={contentFontsize_14_xs_10}
                  >
                    {selectedTransaction?.transferSettlement?.[0]?.created ||
                      "N/A"}{" "}
                    |{" "}
                    {selectedTransaction?.transferSettlement?.[0]
                      ?.createdTime || "N/A"}
                  </Typography>
                </div>
              </div>

              <div style={{ position: "absolute", top: "-7%", left: "42%" }}>
                <img src={PaymentIconImage} alt="paymentIcon" />
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={open1}
        onClose={handleClose1}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div style={{ padding: "10px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>Logo</div>
              <div>Invoice</div>
            </div>
            <div style={{ marginTop: "30px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: smmatch ? "0px" : "80px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "2px",
                  }}
                >
                  <div>
                    Billed To: Imane Olwva, +123034837 93874837USA,2374,483
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "2px",
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    Invoice No.1234
                  </div>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    16 June 2025
                  </div>
                </div>
              </div>
            </div>
            <div style={{ marginTop: "20px", width: "100%" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 500 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={headerStyle_new1}>
                        Dessert (100g serving)
                      </TableCell>
                      <TableCell sx={headerStyle_new}>Calories</TableCell>
                      <TableCell sx={headerStyle_new}>Fat&nbsp;(g)</TableCell>
                      <TableCell sx={headerStyle_new}>Carbs&nbsp;(g)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows1.map((row) => (
                      <TableRow
                        key={row.name}
                        sx={{
                          "&:first-of-type td, &:first-of-type th": {
                            borderTop: "1px solid black",
                          },
                          "&:last-of-type td, &:last-of-type th": {
                            borderBottom: "1px solid black",
                          },
                        }}
                      >
                        <TableCell sx={cellStyle_new1}>{row.name}</TableCell>
                        <TableCell sx={cellStyle_new}>{row.calories}</TableCell>
                        <TableCell sx={cellStyle_new}>{row.fat}</TableCell>
                        <TableCell sx={cellStyle_new}>{row.carbs}</TableCell>
                      </TableRow>
                    ))}

                    {rows2.map((row) => (
                      <TableRow key={row.name}>
                        <TableCell sx={cellStyle_none}>{row.name}</TableCell>
                        <TableCell sx={cellStyle_none}>
                          {row.calories}
                        </TableCell>
                        <TableCell sx={cellStyle_none}>{row.fat}</TableCell>
                        <TableCell sx={cellStyle_none}>{row.carbs}</TableCell>
                      </TableRow>
                    ))}

                    {rows3.map((row) => (
                      <TableRow key={row.name}>
                        <TableCell sx={cellStyle_none}>{row.name}</TableCell>
                        <TableCell sx={cellStyle_none}>
                          {row.calories}
                        </TableCell>
                        <TableCell sx={cellStyle_new4}>{row.fat}</TableCell>
                        <TableCell sx={cellStyle_new4}>{row.carbs}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>

          <div style={{ margin: "30px 0px" }}>Thank you</div>
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "space-between",
              gap: "70px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>Payment information</div>
              <div>bank no ,Account name,Account nopay 5</div>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                Samari Hadid
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                123 Anywhere city from the world
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Completed;
