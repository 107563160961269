import {React,useState,useEffect} from "react";
import { Grid } from "@mui/material";
import TelegramIcon from "@mui/icons-material/Telegram";
import { Row, Col } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { validationSchema } from "./validation";
import { Typography,Button } from "../../MUIComponents/index";
import { Card } from "../../MUIComponents/index";
import CustomCard from "../../Commoncomponents/card";
import CustomButton from "../../Commoncomponents/button";
import { useDispatch } from "react-redux";
import { SubscriptionClick } from "../../../Redux/Action/NewSignUp/GqlRequestAction";
import client from "../../../Configurations/apollo";
import {SIGNUP_PG} from '../../../Redux/Action/NewSignUp/GqlQuery'
import { format } from 'date-fns';
import { useLocation, useNavigate } from "react-router-dom";
import { message } from "antd";
// import "./index.scss";

export default function Subscription() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

const nav=useNavigate()
const location = useLocation()
  useEffect(() => {
    if (location.pathname === "/Subscription") {
      window.history.pushState(null, document.title, window.location.href);
      window.addEventListener("popstate", function (event) {
        window.history.pushState(null, document.title, window.location.href);
      });
    }
    return () => {
      window.removeEventListener("popstate", function (event) {
        window.history.pushState(null, document.title, window.location.href);
      });
    };
  }, [location.pathname]);
  const [buttonText, setButtonText] = useState("APPLY");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [couponApplied, setCouponApplied] = useState(false);
  const [isLogged,setIsLogged]= useState(localStorage.getItem("isLoggedin"))
  const handleFormSubmit = (values) => {
    if(isLogged=== false){
      localStorage.setItem("Registration",false)
    message.success({
      content: "Thank you for signing up with NearShopz. You will get an email with further instructions",
      className: "custom-class",
      style: {
        marginTop: "10vh",
        // marginLeft: "15vw",
      },
      duration: 3.5,
    });

    setTimeout(() => {
      nav('/');
      window.location.reload();
    }, 1000);
  }
  else{
    localStorage.setItem("Registration",false)
    message.success({
      content:"Thank you for signing up with NearShopz. You will get an email with further instructions",
      className: "custom-class",
      style: {
        marginTop: "10vh",
        // marginLeft: "15vw",
      },
      duration: 2,
    })
    localStorage.removeItem("token")
    localStorage.removeItem("isLoggedin")
    setTimeout(() => {
      nav('/');
      window.location.reload();
    }, 1000);
  }
  };

  const dispatch = useDispatch();
  useEffect(() => {
    document.body.scrollTop = 0;
  }, []);
  const formik = useFormik({
    initialValues: {
      coupon: "NSWELCOME",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (!couponApplied) {
        message.error("Please apply the coupon code");
      } else {
        handleFormSubmit(values);
      }
    },
  });
  const handleCouponApply = () => {
    if (formik.values.coupon !== "NSWELCOME") {
      formik.setFieldError("coupon", "Invalid coupon code");
    } else {
      dispatch(SubscriptionClick());
      setButtonText("APPLIED");
      setIsButtonDisabled(true);
      setCouponApplied(true); 
    }
  };
 
  return (
    <>
      <div style={{ height: "100%", width: "100%", background: "white" }}>
        <div style={{ background: "#85BE49", padding: "10px" }}>
          <Typography variant="h4" align="center" gutterBottom color="white">
            Registration
          </Typography>
        </div>
        <Grid container sx={{ padding: "20px" }}>
          <h5
            style={{
              paddingLeft: 10,
              paddingTop: 10,
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Subscription
          </h5>
        </Grid>
        <Grid>
          <div
            style={{
              display: "flex",
              padding: "30px",
              paddingTop: "0px",
              justifyContent: "center",
            }}
          >
            <CustomCard width={"50%"}>
              <div style={{ background: "#85BE49",width:"100%",height:"140px",padding:"20px",display:"flex",gap:"10px",justifyContent:"center",position:"relative"}}>
                <Typography style={{color:"white",fontWeight:"400"}}>
                  SUBSCRIBE NOW
                </Typography>
                <TelegramIcon/>
                <div style={{position:"absolute",height:"100px",width:"100px",background:"white",borderRadius:"50%",border:"5px solid #85BE49",top:"65%"}}>
                  <div style={{height:"100%",width:"100%",display:"flex",alignItems:"center",justifyContent:"center",flexWrap:"wrap"}}>
                    <div style={{display:"flex",flexDirection:"column",gap:"5px"}}>
                        <div style={{fontWeight:"700"}}>₹ 2000</div> 
                        <div>/ Month</div>
                    </div>
                        
                  </div>
                </div>
              </div>

              <ul style={{ textAlign: "left",marginTop:"70px",paddingLeft:"20px" }}>
                <li>Unlimited products</li>
                <li>Unlimited shop assistants</li>
                <li>Unlimited access to the portal features</li>
                <li>Access to 10,000+ buyer database</li>
                <li>Free Local Promotion</li>
                <li>24/7 Live Seller Customer Support</li>
              </ul>
              <p style={{ opacity: 0.5 }}>
                {
                  'Apply the coupon code "NSWELCOME" to avail your free subscription'
                }
              </p>

              <form style={{ width: "100%" }} onSubmit={formik.handleSubmit}>
                <Row style={{ width: "100%" }}>
                  <Col md={9}>
                    <TextField
                      style={{ width: "100%" }}
                      name="coupon"
                      value={formik.values.coupon}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.coupon && Boolean(formik.errors.coupon)
                      }
                      helperText={formik.touched.coupon && formik.errors.coupon}
                      id="outlined-basic"
                      label="Coupon code"
                      variant="outlined"
                      InputProps={{
                        style: {
                          display: "flex",
                          justifyContent: "center",
                          borderRadius: "6px",
                          height: 35,
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Col>
                  <Col md={3}>
                    <Button
                      style={{ width: "100%", border: "none" }}
                      type="submit"
                     sx={{background: isButtonDisabled ?"grey": "#85BE49",color:"white"}}
                      disabled={isButtonDisabled}
                      onClick={handleCouponApply}
                    >
                      {" "}
                   {buttonText}
                    </Button>
                  </Col>
                </Row>
                <Grid
            container
            justifyContent="flex-end"
            sx={{ marginTop: 2, paddingRight: "30px" }}
          >
            <Button
              type="submit"
              variant="contained"
              color="primary"
              // background="#85BE49"
              style={{ marginTop: "20px",background:"#85BE49" }}
              // onClick={handleSubmit}
            >
              Next
            </Button>
          </Grid>
              </form>
            </CustomCard>
          </div>
        </Grid>
        {/* {props.subsriptionPlan?
      <Grid
      item
      xs={12}
      style={{
        display:'flex',flexDirection:'column',
        width:'100%',
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        paddingLeft:'30px',
        paddingRight:'30px'
      }}
    >
      <div>
          <div class="pricing-card basic">
            <div class="pricing-header">
              <span class="plan-title">
              CONGRATULATIONS<TelegramIcon sx={{ fontSize: 40 }}  />
              </span>
              <div class="price-circle">
                <span class="price-title" style={{textDecoration:'line-through'}}>
                  <small>₹</small>
                  <span>1000</span>
                </span>
                <span class="info">/ Month</span>
              </div>
            </div>
            <div class="badge-box">
              <span>FREE FOR 6 MONTHS</span>
            </div>
            <ul >
              <li >
                <strong>{'\u2B24' + '\t' +`${'Unlimited products'}`}</strong>
              </li>
              <li >
                <strong>{'\u2B24' + '\t' + `${'Unlimited shop assistants'}`}</strong>
              </li>
              <li >
                <strong>{'\u2B24' + '\t' + `${'Unlimited access to the portal features'}`}</strong>
              </li>
              <li >
                <strong>{'\u2B24' + '\t' + `${'Access to 10,000+ buyer database.'}`}</strong>
              </li>
              <li >
                <strong>{'\u2B24' + '\t' + `${'Free Local Promotion.'}`}</strong>
              </li>
              <li >
                <strong>{'\u2B24' + '\t' +`${'24/7 Live Seller Customer Support'}`}</strong>
              </li>
            </ul>
            <div class="buy-button-box">

           </div>
          </div>
         
          
        </div>
    </Grid>
    
    
    : 
    <Grid
        item
        xs={12}
        style={{
          display:'flex',flexDirection:'column',
          width:'100%',
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div>
          <div class="pricing-card basic">
            <div class="pricing-header">
              <span class="plan-title">
              SUBSCRIBE NOW<TelegramIcon sx={{ fontSize: 40 }}  />
              </span>
              <div class="price-circle">
                <span class="price-title">
                  <small>₹</small>
                  <span>1000</span>
                </span>
                <span class="info">/ Month</span>
              </div>
            </div>
            <div class="badge-box">
              
            </div>
            <ul >
             
              <li >
                <strong>{'\u2B24' + '\t' +`${'Unlimited products'}`}</strong>
              </li>
              <li >
                <strong>{'\u2B24' + '\t' + `${'Unlimited shop assistants'}`}</strong>
              </li>
              <li >
                <strong>{'\u2B24' + '\t' + `${'Unlimited access to the portal features'}`}</strong>
              </li>
              <li >
                <strong>{'\u2B24' + '\t' + `${'Access to 10,000+ buyer database.'}`}</strong>
              </li>
              <li >
                <strong>{'\u2B24' + '\t' + `${'Free Local Promotion.'}`}</strong>
              </li>
              <li >
                <strong>{'\u2B24' + '\t' +`${'24/7 Live Seller Customer Support'}`}</strong>
              </li>
            </ul>
            <div class="buy-button-box">
            <p style={{opacity:0.5}}>
        {'Apply the coupon code "NSWELCOME" to avail your free subscription'}
        </p> 
           </div>
          </div>
          <div class="buy-button-box">
        
              <form    
     
       style={{width:'100%'}}
       onSubmit={formik.handleSubmit}>  
   
     <Row   style={{width:'100%'}}>
                <Col md={9} >
      
                <TextField
                
                style={{width:'100%'}}
                name="coupon"
                value={formik.values.coupon}
                onChange={formik.handleChange}
                error={
                  formik.touched.coupon && Boolean(formik.errors.coupon)
                }
                helperText={
                  formik.touched.coupon&& formik.errors.coupon
                }
                
                 id="outlined-basic" label="Coupon code" variant="outlined" 
                InputProps={{ style: { display:'flex',justifyContent:'center' ,borderRadius:'6px',height:35} }}
                InputLabelProps={{
                  shrink: true,
                }}/>
                
             </Col>
             <Col md={3}>
             <button style={{width:'100%',border:'none'}} type='submit' className="buy-now"> APPLY </button>
             </Col>
             </Row>
             </form>
          
            </div>
          
        </div>
      </Grid>} */}
      </div>
    </>
  );
}
