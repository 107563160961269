import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";

import { useLocation, useNavigate } from "react-router-dom";
import Direction from "../../../utils/Icon/Direction.svg";
import Phone from "../../../utils/Icon/Phone.svg";
import Pointer from "../../../utils/Icon/Pointer.svg";
import LineInReadyForDel from "../../../utils/Icon/LineInReadyForDel.svg";
import * as React from "react";
import UploadImage from "../../../utils/Icon/UploadImage.svg";
import Camera from "../../../utils/Icon/Camera.svg";
import "./OrderHistoryDetails.css";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import {
  HeaderNavigationFontSize_20_xs_13,
  contentFontSize_16,
  contentFontSize_18,
  contentFontSize_20,
  contentFontSize_20_xs_18,
  contentFontsize_14,
  contentFontsize_17,
} from "../../Typography";
import CustomButton from "../../Commoncomponents/button";
import BlueClock from "../../../utils/Icon/BlueClock.svg";
import CustomCard from "../../Commoncomponents/card";
import OrderImage from "../../AddProduct/ImageCropper/OrderImage";
function OrderHistoryDetails(props) {
  const location = useLocation();
  const orderid = location?.state?.orderid;
  const readyState = location?.state?.readyState;
  console.log("readystate",readyState)
  const one = location?.state?.one
  console.log("one",one)
  React.useEffect(() => {
    props.getSearchDetails(orderid);
  }, []);

  const completedOrderDetails = ("props", props && props.searchDetails);

  const connectorStyle = {
    backgroundColor: "blue", // Set the background color
    height: "200px", // Set the height of the connector
    // Set the width of the connector
    margin: "-12px", // Set the margin
    // Add any other styles you want to customize
  };
  const nav = useNavigate();
  function handleClickOne() {
    nav("/home/MyOrders/ActiveOrders");
  }
  function handleClickTwo() {
    nav("/home/MyOrders/OrderHistory");
  }
  const OrderHistoryData = JSON.parse(localStorage.getItem("selectedItems"));
  const totalProductPrice = completedOrderDetails
    ? completedOrderDetails.products.reduce(
        (total, item) => total + ((item.shopAssistantQuantity ?? item.quantity) * item.productPrice),
        0
      )
    : 0;


  const deliveryCharge = completedOrderDetails?.deliveryCharge;
  const packingCharge = completedOrderDetails?.packingCharge + completedOrderDetails?.handlingCharge;
  const discountPrice = completedOrderDetails?.discountPrice;



  // Calculate the total charges
  const totalCharges = deliveryCharge + packingCharge - discountPrice;

  // Calculate the grand total
  const grandTotal = totalProductPrice + totalCharges;
  function handleorderHistory() {
    nav("/home/MyOrders/OrderHistory");
  }
  function handlePayment(){
    {one === "hi" ? (
    nav("/home/Payments")
  ):one === "hello" ?(
    nav("/home/Payments/PendingDeposits")
  ):(
    nav("/home/Payments/CompletedTransactions")
  )}
  }
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const matchessm = useMediaQuery(theme.breakpoints.down("sm"));
  const matcheslg = useMediaQuery(theme.breakpoints.down("lg"));

  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));
  const isXsScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const timelineStyle = {
    padding: matchessm
      ? "50px 0px 0px 18px"
      : xlmatch
      ? "50px 0px 0px 80px"
      : upXlmatch
      ? "50px 0px 0px 80px"
      : "25px 2px 3px 4px",
  };

  return (
    <div style={{ margin: "0px 0px 0px 0px" }}>
      <>
        <Grid>
         
          <div style={{ display: "flex", gap: "3px" }}>
            <div
              style={{ cursor: "pointer" }}
              onClick={handleClickOne}
              className="MainActiveOrder"
            >
              <Typography
                className="MainActive"
                fontSize={HeaderNavigationFontSize_20_xs_13}
              >
               {readyState?"Ready For Delivery":"Active Orders"}
              </Typography>
            </div>
            <div>
              <Typography
                className="MainActive"
                fontSize={HeaderNavigationFontSize_20_xs_13}
              >
                /
              </Typography>
            </div>
            <div style={{ cursor: "pointer" }} onClick={handleClickTwo}>
              <Typography
                className="MainActive"
                fontSize={HeaderNavigationFontSize_20_xs_13}
              >
                Order History
              </Typography>
            </div>
            <div>
              <Typography
                className="MainActive"
                fontSize={HeaderNavigationFontSize_20_xs_13}
              >
                /
              </Typography>
            </div>
            <div style={{ cursor: "pointer" }} >
              <Typography
                className="MainActives"
                fontSize={HeaderNavigationFontSize_20_xs_13}
              >
                View Order History
              </Typography>
            </div>
          </div>
          <Grid container justifyContent="center" style={{ marginTop: "10px" }}>
            <Grid item xs={12} md={8} lg={12}>
              <div className="orderHistoryBox">
                <span>
                  <Typography
                    fontSize={contentFontsize_17}
                    className="textStyleHistory"
                  >
                    #
                    {completedOrderDetails && completedOrderDetails.orderNumber}{" "}
                  </Typography>
                </span>
              </div>
            </Grid>
          </Grid>
        
          <div style={{ marginTop: "30px" }}>
            <CustomCard width="100%" height="40%" padding="58px 12px 12px 97px">
              {completedOrderDetails?.stats?.map((status,index)=>(
               
                      

 
              
              <Timeline sx={timelineStyle}>
                <TimelineItem classes={{ root: "customTimelineItem" }}>
                  <TimelineSeparator>
                  <TimelineDot sx={{ backgroundColor: "#85BE49", margin: "0px" }} />
        {index !== completedOrderDetails.stats.length - 1 && (
         <TimelineConnector sx={{ backgroundColor: "#85BE49", marginBottom:"-26px" }} />

        )}
                    
                  </TimelineSeparator> 

                  <TimelineContent>
                    <div style={{ display: "flex", justifyContent:"unset" }}>
                      <div>
                      <Typography
                        fontSize={contentFontSize_18}
                        sx={{
                          fontWeight: "800",
                          fontFamily: "Roboto",
                          color: "#000000",
                          marginBottom: "10px",
                        }}
                      >
                       {status?.status === "Order-Accepted" && completedOrderDetails?.shopAssistantId?.firstName ? (
                         <>
                         {status.status}
                         <br/>
                         <Typography  sx={{
                        fontFamily: "Roboto",
                        fontWeight: "400",
                        color: "#000000",
                      }}>
                         ShopAssistant:{completedOrderDetails?.shopAssistantId?.firstName}{" "}{completedOrderDetails?.shopAssistantId?.lastName}
                         </Typography>
                         </>
                       ):(
                        <>
                        {status.status}
                        </>
                       )}
                        {/* Scheduled for 11-06-23, 3:43 PM */}
                      </Typography>
                    </div>
                      {!isXsScreen && (
                        <div>
                          <Typography
                            sx={{
                              fontFamily: "Roboto",
                              fontWeight: "500",
                              color: "#8B8989",
                              marginTop: matchessm ? "0px" : "0px",
                              marginLeft: "10px",
                            }}
                            fontSize={contentFontSize_16}
                          >
                            {status?.created},{status.createdTime}
                          </Typography>
                        </div>
                      )}
                    </div>
                    {isXsScreen && (
                      <Typography
                        sx={{
                          fontFamily: "Roboto",
                          fontWeight: "500",
                          color: "#8B8989",
                        }}
                        fontSize={contentFontSize_16}
                      >
                        {status.createdTime}
                      </Typography>
                    )}
                    <Typography
                      sx={{
                        fontFamily: "Roboto",
                        fontWeight: "400",
                        color: "#000000",
                      }}
                      fontSize={contentFontSize_16}
                    >{status.status === "Order-Placed" && (
                      <>
                      Scheduled for {completedOrderDetails?.deliveryDate},{completedOrderDetails?.deliveryTime}
                      </>
                    )}
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
                </Timeline>
              
              ))}
            </CustomCard>
          </div>
         


<div style={{ display: "flex", flexDirection: "column",gap:"10px", marginTop:"20px",padding: smmatch ? "10px 10px" : "10px 70px"}}>
            {completedOrderDetails &&
              completedOrderDetails?.products?.map((item) => (
                <>
                  <div style={{ display: "flex" }}>
                    <div style={{ flex: "1" }}>
                      <div style={{display:"flex",gap:"60px"}}>
                      <div className="productDetails"> 
                      <img
                    style={{
                      width: matchessm ? "15px" : "20px",
                      height: matchessm ? "15px" : "20px",
                    }}
                    src={Pointer}
                    alt={item?.name}
                  />   
                      </div>

                      <div>
                      <Typography
                    fontSize={contentFontsize_17}
                    className="productDetails"
                  >
                    {item.productid.productname}
                    <br />
                    <Typography
                      fontSize={contentFontsize_14}
                      sx={{ fontWeight: "400", fontFamily: "Roboto" }}
                    >
                      {item?.shopAssistantQuantity ? (
                        <>
                      {item.shopAssistantQuantity}X ₹{(item?.productPrice)?.toFixed(2)}
                      </>
                    ):(
                      <>
                      {item.quantity}X ₹{(item?.productPrice)?.toFixed(2)}
                      </>
                    )}
                    </Typography>
                  </Typography>
                      </div>
                      </div>
                    </div>
                    <div style={{ flex: "1" }}>
                      <div style={{display:"flex",justifyContent:'flex-end'}}>
                        <div style={{display:"flex",flexDirection:"column",gap:"1px"}}>
                        {/* Calculate price based on updated quantity if active is "change", otherwise use original quantity */}
                        <Typography
                    className="productDetails"
                    fontSize={contentFontsize_17}
                  >
                    {item.shopAssistantQuantity ? (
                      <>
                    ₹{(item?.shopAssistantQuantity * item?.productPrice)?.toFixed(2)}
                    </>
                  ):(
                    <>
                    ₹{(item?.quantity * item?.productPrice)?.toFixed(2)}
                    </>
                  )}
                  </Typography>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}


      </div>






          <Grid container>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              sm={12}
              sx={{ marginTop: "20px" }}
            >
              <img
                style={{ width: "100%" }}
                src={LineInReadyForDel}
                alt="LineInReadyForDel"
              />
            </Grid>
          </Grid>
        




<div style={{ padding: smmatch ? "10px 10px" : "10px 70px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                fontSize={contentFontSize_16}
                className="productDetails1"
              >
                Total Product price
              </Typography>
              
              <Typography
                className="productDetails1"
                fontSize={contentFontSize_16}
              >
                ₹{totalProductPrice?.toFixed(2)}
              </Typography>
            </div>
           
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                fontSize={contentFontSize_16}
                className="productDetails1"
              >
                Delivery Charge
              </Typography>
              {/* </Grid> */}

              {/* <Grid item xs={4} md={3} lg={3} sm={3} xl={3} > */}
              <Typography
                className="productDetails1"
                fontSize={contentFontSize_16}
                // sx={{marginLeft:"15px",paddingLeft:"-15px"}}
              >
                ₹ {completedOrderDetails?.deliveryCharge?.toFixed(2)}
              </Typography>
            </div>
           
          
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                fontSize={contentFontSize_16}
                sx={{ color: "rgba(45, 147, 251, 1)" }}
                className="productDetails1"
              >
                Coupon - ({completedOrderDetails?.couponID?.couponCode})
              </Typography>
              
              <Typography
                className="productDetails1"
                sx={{ color: "rgba(45, 147, 251, 1)" }}
                fontSize={contentFontSize_16}
              >
                -₹
                {completedOrderDetails?.discountPrice === null
                  ? 0.00
                  : (completedOrderDetails?.discountPrice)?.toFixed(2)}
              </Typography>
            </div>
            
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                fontSize={contentFontSize_16}
                className="productDetails1"
              >
                Packing Charges
              </Typography>
              {/* </Grid> */}

              {/* <Grid item xs={4} md={3} lg={3} sm={3} xl={3}> */}
              <Typography
                className="productDetails1"
                fontSize={contentFontSize_16}
                // sx={{marginLeft:"22px"}}
              >
                ₹
                {packingCharge === null
                  ? 0.00
                  : (packingCharge)?.toFixed(2)}
              </Typography>
            </div>
          </div>
          {/* </Grid>
        </Grid> */}
          <Grid container>
            <Grid item xs={12} md={12} lg={12} sm={12}>
              <img
                style={{ width: "100%" }}
                src={LineInReadyForDel}
                alt="LineInReadyForDel"
              />
            </Grid>
          </Grid>
          {/* <Grid
          container
          spacing={2}
          sx={{ marginLeft: matchessm ? "0px" : matches ? "15px" : "60px" }}
        >
          <Grid item xs={8} md={9} lg={9} sm={9} xl={9}> */}
          <div style={{ padding: smmatch ? "10px 10px" : "10px 70px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                fontSize={contentFontSize_20_xs_18}
                sx={{ fontWeight: "700", fontFamily: "Roboto" }}
              >
                Total
              </Typography>
              {/* </Grid>

          <Grid item xs={4} md={3} lg={3} sm={3} xl={3}> */}
              <Typography
                sx={{ fontWeight: "700", fontFamily: "Roboto" }}
                fontSize={contentFontSize_16}
              >
                ₹{grandTotal?.toFixed(2)}
              </Typography>
            </div>
          </div>





          {(completedOrderDetails?.lastStatus === "Out-For-Delivery" || 
  (completedOrderDetails?.lastStatus === "Completed" )) ? (
  <>
  
    <div  style={{ marginTop: "20px" }}>
      <Typography style={{ fontWeight: "600" }} fontSize={contentFontSize_20}>
        Uploaded Product Images<span style={{ color: "#EA4335" }}>*</span>
      </Typography>
      <div  style={{display:"flex",flexDirection:"row",gap:"15px"}}>
      {
  completedOrderDetails.packingImages.map((imageUrl, index) => (
    
        <img
        key={index}
          style={{ marginTop: "20px", width: "100px", height: "100px" }}
          src={imageUrl}
          alt={`Product Image ${index + 1}`}
        />
     
      ))}
       </div>
    </div>
  

  </>
) : (
  completedOrderDetails?.lastStatus === "Order-Ready" ? (
    <div>
      <OrderImage />
    </div>
  ) : (
    <>
      <div style={{ marginTop: "20px" }}>
        <Typography style={{ fontWeight: "600" }} fontSize={contentFontSize_20}>
          Upload Product Images<span style={{ color: "#EA4335" }}>*</span>
        </Typography>
      </div>
      <div>
        <img style={{ marginTop: "20px", marginLeft: "20px" }} src={Camera} />
        <img style={{ marginTop: "20px", marginLeft: "20px" }} src={UploadImage} />
      </div>
    </>
  )
)}

        </Grid>
        <div style={{ marginTop: "20px" }}>
          <Typography
            fontSize={contentFontSize_18}
            style={{ color: "#1D89E1" }}
          >
            <img style={{ width: "20px", height: "20px" }} src={BlueClock} />
            Waiting for Payment
          </Typography>
        </div>
        {one === "hi" || one === "hello"  || one === "hey"? (
          <div  
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "30px",
          }}
        >
          <CustomButton
            width="350px"
            height="50px"
            borderRadius="20px"
            color="white"
            background="#659B1B"
            onClick={handlePayment}
          >
            <Typography
              fontSize={contentFontSize_18}
              sx={{ fontFamily: "Roboto", fontWeight: "500" }}
            >
              Back 
            </Typography>
          </CustomButton>
        </div>
        ):(
          <div  
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "30px",
          }}
        >
          <CustomButton
            width="350px"
            height="50px"
            borderRadius="20px"
            color="white"
            background="#659B1B"
            onClick={handleorderHistory}
          >
            <Typography
              fontSize={contentFontSize_18}
              sx={{ fontFamily: "Roboto", fontWeight: "500" }}
            >
              Back to Order History
            </Typography>
          </CustomButton>
        </div>
        )}
        {/* <div  
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "30px",
          }}
        >
          <CustomButton
            width="350px"
            height="50px"
            borderRadius="20px"
            color="white"
            background="#659B1B"
            onClick={handleorderHistory}
          >
            <Typography
              fontSize={contentFontSize_18}
              sx={{ fontFamily: "Roboto", fontWeight: "500" }}
            >
              Back to Order History
            </Typography>
          </CustomButton>
        </div> */}
      </>
    </div>
  );
}

export default OrderHistoryDetails;
