// import React from "react";
// import { Grid } from "@mui/material";
// import TelegramIcon from '@mui/icons-material/Telegram';
// import { Container } from "@mui/material";
// import { useMediaQuery } from 'react-responsive';
// // import "./index.scss";

// export default function SubscriptionPlan() {
//   const isSmallScreen = useMediaQuery({ query: '(max-width: 1224px)' })
//   return (
//     isSmallScreen?
// <div  style={{ 

//       backgroundColor: "white",
//       // border: "1px solid rgba(0, 0, 0, 0.16)",
//       borderRadius: "10px",
//       Top: "40px",
//       // paddingLeft: "10px",
//       // paddingRight: "10px",
//       width: "100%",
//       justifyContent:'center',alignItems:'center'
//       //  padding: "7px",

//      }}>
// {/* <Container maxWidth="sm" style={{ 
//        width:'100%',
//        backgroundColor: "white",
//        border: "1px solid rgba(0, 0, 0, 0.16)",
//        borderRadius: "10px",
//        Top: "40px",
//       //  padding: "7px",

//      }}> */}
//      <div style={{  paddingBottom: '10px', fontWeight: 600 ,width:'100%',justifyContent:'center',alignItems:'center'}}>
//        <h5 style={{ fontWeight: 800, textAlign: 'center' ,justifyContent:'center',alignItems:'center',
//        fontSize:"23px"}}>
//          {'Subscription'}
//        </h5>
//       {/* <Grid
//         item
//         style={{width:'100%',textAlign:'center',justifyContent:'center',alignItems:'center'}}
//       > */}
//         <div  style={{ fontWeight: 600 ,width:'100%',
//         // height: '100vh', /* Magic here */
//         display: 'flex',justifyContent:'center',alignItems:'center',textAlign:'center'}}>
//           <div className="pricing-card basic" style={{width:'80vw'}}>
//             <div className="pricing-header">
//               <span className="plan-title">
//               <TelegramIcon sx={{ fontSize: 40 }}  />
//               BASIC</span>
//               <div className="price-circle">
//               {/* <div className="info-zero">0</div> */}
//                 <span className="price-title">
//                   <div >₹ 1000</div>
//                 </span>
//                 <span className="info">/ Month</span>
//               </div>
//             </div>
//             <div className="badge-box">
//               <span>FREE FOR 6 MONTHS</span>
//             </div>
//             <ul className="list">
//               <li className="list">
//                 <strong>Your Free Subscription Brings You: </strong>
//               </li>
//               <li className="list">
//                 <strong>{'\u2B24' + '\t' +`${'Unlimited products'}`}</strong>
//               </li>
//               <li className="list">
//                 <strong>{'\u2B24' + '\t' +`${'Unlimited shop assistants'}`}</strong>
//               </li>
//               <li className="list">
//                 <strong>{'\u2B24' + '\t' +`${'Unlimited access to the portal features'}`}</strong>
//               </li>
//               <li className="list">
//                 <strong>{'\u2B24' + '\t' +`${'Access to 10,000+ buyer database.'}`}</strong>
//               </li>
//               <li className="list">
//                 <strong>{'\u2B24' + '\t' +`${'Free Local Promotion.'}`}</strong>
//               </li>
//               <li className="list">
//                 <strong>{'\u2B24' + '\t' +`${'24/7 Live Seller Customer Support'}`}</strong>
//               </li>
//             </ul>
//           </div>
//         </div>
//         {/* </Grid> */}
//         </div>
//      {/* </Container> */}
//      </div>


//     :

//     <Container maxWidth="md" style={{ 
//       backgroundColor: "white",
//        border: "1px solid rgba(0, 0, 0, 0.16)",
//        borderRadius: "10px",
//        Top: "40px",
//        padding: "30px",
//        display:'flex',
//        justifyContent:'center',alignItems:'center',

//      }}>
//      <div style={{  display:'flex',flexDirection:'column',paddingBottom: '10px', fontWeight: 600 ,width:'100%',justifyContent:'center',alignItems:'center',display:'flex'}}>
//        <h5 style={{ fontWeight: 800, textAlign: 'center' ,justifyContent:'center',alignItems:'center',
//        fontSize:"23px"}}>
//          {'Subscription'}
//        </h5>
//       <Grid
//         item
//         xs={8}
//         style={{width:'100%',display: 'flex',textAlign:'center',justifyContent:'center',alignItems:'center'}}
//       >
//         <div >
//           <div className="pricing-card basic">
//             <div className="pricing-header">
//               <span className="plan-title">
//               <TelegramIcon sx={{ fontSize: 40 }}  />
//               BASIC</span>
//               <div className="price-circle">
//               {/* <div className="info-zero">0</div> */}
//                 <span className="price-title">
//                   <div >₹ 1000</div>
//                 </span>
//                 <span className="info">/ Month</span>
//               </div>
//             </div>
//             <div className="badge-box">
//               <span>FREE FOR 6 MONTHS</span>
//             </div>
//             <ul className="list">
//               <li className="list">
//                 <strong>Your Free Subscription Brings You: </strong>
//               </li>
//               <li className="list">
//                 <strong>{'\u2B24' + '\t' +`${'Unlimited products'}`}</strong>
//               </li>
//               <li className="list">
//                 <strong>{'\u2B24' + '\t' +`${'Unlimited shop assistants'}`}</strong>
//               </li>
//               <li className="list">
//                 <strong>{'\u2B24' + '\t' +`${'Unlimited access to the portal features'}`}</strong>
//               </li>
//               <li className="list">
//                 <strong>{'\u2B24' + '\t' +`${'Access to 10,000+ buyer database.'}`}</strong>
//               </li>
//               <li className="list">
//                 <strong>{'\u2B24' + '\t' +`${'Free Local Promotion.'}`}</strong>
//               </li>
//               <li className="list">
//                 <strong>{'\u2B24' + '\t' +`${'24/7 Live Seller Customer Support'}`}</strong>
//               </li>
//             </ul>
//           </div>
//         </div>
//         </Grid>
//         </div>
//      </Container>
//   );
// }



import React from 'react'
import LandingCards from '../Banner/LandingCards'
import LandingPageCard from '../Banner/LandingPageCard'

function index() {
  return (
    <div>
      {/* <LandingCards/> */}
      {/* <LandingPageCard/> */}
    </div>
  )
}

export default index

