// import React, { useEffect, useState } from "react";
// import { Row, Col } from "react-bootstrap";
// import TextField from "@mui/material/TextField";
// import Uploader from "./Uploader";
// import MultipleUploader from "./MultipleUploader";
// import MenuItem from '@mui/material/MenuItem';
// import { useFormik } from "formik";
// import { validationSchema } from "./Validation";
// import { ThemeProvider, createTheme } from '@mui/material/styles';
// import { green } from "@mui/material/colors";
// import { Modal } from "antd";
// import { ExclamationCircleOutlined } from "@ant-design/icons";
// import { Container } from "@mui/material";
// import { message } from 'antd';
// import { useMediaQuery } from 'react-responsive';
// // import {NoImage}  from '../../utils/image';
// import "./index.css";

// const customerFields = [
//   { label: "Mfg Name", value: "mfgname" },
//   { label: "Brand", value: "brand" },
//   { label: "Price", value: "price" },
//   { label: "Promo Price", value: "promoprice" },
//   // { label: "Unit of Measurment", value: "uom" },
//   { label: "Quantity", value: "quantity" },
//   { label: "Display Rank", value: "displayrank" },
// ];
// const statusFilters = [
//   { option: "active", value: "active" },
//   { option: "inactive", value: "inactive" },
// ];

// const ProductDetailsCard = (props) => {
//   document.title = "Seller Portal | Edit Products";
//   const { getProductDetails, getDepartmentList } = props;

//   useEffect(() => {
//     setState({
//       salesdepartment:
//         props.location.state.salesdepartment
//     })
//   },
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//     [])

//   useEffect(

//     () => {
//       props.getProductDetails(props.location.state.Productid);
//       props.getDepartmentList();
//       // formik.setFieldValue('status',props.location.state.status);
//       formik.setFieldValue('uom', props.location.state.uom)
//       setState({
//         productid: props.location.state.Productid,
//         displayrank:
//           props.ProductDetailsData &&
//           props.ProductDetailsData.data &&
//           props.ProductDetailsData.data.salesproducts &&
//           props.ProductDetailsData.data.salesproducts.displayrank,
//         salesdepartment:
//           props.location.state.salesdepartment,
//         salescategory:
//           props.ProductDetailsData &&
//             props.ProductDetailsData.data &&
//             props.ProductDetailsData.data.salesproducts &&
//             props.ProductDetailsData.data.salesproducts.salescategory &&
//             props.ProductDetailsData.data.salesproducts.salescategory.id &&
//             props.ProductDetailsData.data.salesproducts.salescategory.id
//             ? props.ProductDetailsData.data.salesproducts.salescategory.id
//             : '',
//         salescategory1:
//           props.ProductDetailsData &&
//             props.ProductDetailsData.data &&
//             props.ProductDetailsData.data.salesproducts &&
//             props.ProductDetailsData.data.salesproducts.salescategory1 &&
//             props.ProductDetailsData.data.salesproducts.salescategory1.id &&
//             props.ProductDetailsData.data.salesproducts.salescategory1.id
//             ? props.ProductDetailsData.data.salesproducts.salescategory1.id
//             : '',
//         salescategory2:
//           props.ProductDetailsData &&
//             props.ProductDetailsData.data &&
//             props.ProductDetailsData.data.salesproducts &&
//             props.ProductDetailsData.data.salesproducts.salescategory2 &&
//             props.ProductDetailsData.data.salesproducts.salescategory2.id &&
//             props.ProductDetailsData.data.salesproducts.salescategory2.id
//             ? props.ProductDetailsData.data.salesproducts.salescategory2.id
//             : '',
//       },

//       );
//     },
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//     [getProductDetails, getDepartmentList]
//   );

//   //
//   useEffect(() => {
//     props.getDepartmentList();
//     console.log("props.location.state",props.location.state)
//     formik.setFieldValue('status', props.location.state.status);
//     formik.setFieldValue('uom', props.location.state.uom)
//     formik.setFieldValue('uom', props.ProductDetailsData &&
//       props.ProductDetailsData.data &&
//       props.ProductDetailsData.data.salesproducts &&
//       props.ProductDetailsData.data.salesproducts.uom)
//     if (props.ProductDetailsData &&
//       props.ProductDetailsData.data &&
//       props.ProductDetailsData.data.salesproducts &&
//       props.ProductDetailsData.data.salesproducts.salesdepartment &&
//       props.ProductDetailsData.data.salesproducts.salesdepartment.id &&
//       props.ProductDetailsData.data.salesproducts.salesdepartment.id) {
//       setState({
//         salesdepartment:
//           props.location.state.salesdepartment
//       })
//       props.getCategoryList(props.ProductDetailsData &&
//         props.ProductDetailsData.data &&
//         props.ProductDetailsData.data.salesproducts &&
//         props.ProductDetailsData.data.salesproducts.salesdepartment &&
//         props.ProductDetailsData.data.salesproducts.salesdepartment.id &&
//         props.ProductDetailsData.data.salesproducts.salesdepartment.id)
//     }
//     if (props.ProductDetailsData &&
//       props.ProductDetailsData.data &&
//       props.ProductDetailsData.data.salesproducts &&
//       props.ProductDetailsData.data.salesproducts.salescategory &&
//       props.ProductDetailsData.data.salesproducts.salescategory.id &&
//       props.ProductDetailsData.data.salesproducts.salescategory.id) {
//       props.getCategory1List(props.ProductDetailsData &&
//         props.ProductDetailsData.data &&
//         props.ProductDetailsData.data.salesproducts &&
//         props.ProductDetailsData.data.salesproducts.salescategory &&
//         props.ProductDetailsData.data.salesproducts.salescategory.id &&
//         props.ProductDetailsData.data.salesproducts.salescategory.id)
//     }
//     if (props.ProductDetailsData &&
//       props.ProductDetailsData.data &&
//       props.ProductDetailsData.data.salesproducts &&
//       props.ProductDetailsData.data.salesproducts.salescategory1 &&
//       props.ProductDetailsData.data.salesproducts.salescategory1.id &&
//       props.ProductDetailsData.data.salesproducts.salescategory1.id) {
//       props.getCategory2List(props.ProductDetailsData &&
//         props.ProductDetailsData.data &&
//         props.ProductDetailsData.data.salesproducts &&
//         props.ProductDetailsData.data.salesproducts.salescategory1 &&
//         props.ProductDetailsData.data.salesproducts.salescategory1.id &&
//         props.ProductDetailsData.data.salesproducts.salescategory1.id)
//     }
//     setState({
//       productid: props.location.state.Productid,
//       displayrank:
//         props.ProductDetailsData &&
//         props.ProductDetailsData.data &&
//         props.ProductDetailsData.data.salesproducts &&
//         props.ProductDetailsData.data.salesproducts.displayrank,
//       salesdepartment:
//         props.ProductDetailsData &&
//         props.ProductDetailsData.data &&
//         props.ProductDetailsData.data.salesproducts &&
//         props.ProductDetailsData.data.salesproducts.salesdepartment &&
//         props.ProductDetailsData.data.salesproducts.salesdepartment.id &&
//         props.ProductDetailsData.data.salesproducts.salesdepartment.id,
//       salescategory:
//         props.ProductDetailsData &&
//           props.ProductDetailsData.data &&
//           props.ProductDetailsData.data.salesproducts &&
//           props.ProductDetailsData.data.salesproducts.salescategory &&
//           props.ProductDetailsData.data.salesproducts.salescategory.id &&
//           props.ProductDetailsData.data.salesproducts.salescategory.id === null ?
//           ''
//           : props.ProductDetailsData &&
//           props.ProductDetailsData.data &&
//           props.ProductDetailsData.data.salesproducts &&
//           props.ProductDetailsData.data.salesproducts.salescategory &&
//           props.ProductDetailsData.data.salesproducts.salescategory.id &&
//           props.ProductDetailsData.data.salesproducts.salescategory.id,
//       salescategory1:
//         props.ProductDetailsData &&
//           props.ProductDetailsData.data &&
//           props.ProductDetailsData.data.salesproducts &&
//           props.ProductDetailsData.data.salesproducts.salescategory1 &&
//           props.ProductDetailsData.data.salesproducts.salescategory1.id &&
//           props.ProductDetailsData.data.salesproducts.salescategory1.id === null ?
//           ''
//           :
//           props.ProductDetailsData &&
//           props.ProductDetailsData.data &&
//           props.ProductDetailsData.data.salesproducts &&
//           props.ProductDetailsData.data.salesproducts.salescategory1 &&
//           props.ProductDetailsData.data.salesproducts.salescategory1.id &&
//           props.ProductDetailsData.data.salesproducts.salescategory1.id
//       ,
//       salescategory2:
//         props.ProductDetailsData &&
//           props.ProductDetailsData.data &&
//           props.ProductDetailsData.data.salesproducts &&
//           props.ProductDetailsData.data.salesproducts.salescategory2 &&
//           props.ProductDetailsData.data.salesproducts.salescategory2.id &&
//           props.ProductDetailsData.data.salesproducts.salescategory2.id === null ?
//           ''
//           :
//           props.ProductDetailsData &&
//           props.ProductDetailsData.data &&
//           props.ProductDetailsData.data.salesproducts &&
//           props.ProductDetailsData.data.salesproducts.salescategory2 &&
//           props.ProductDetailsData.data.salesproducts.salescategory2.id &&
//           props.ProductDetailsData.data.salesproducts.salescategory2.id
//       ,
//     });
//     formik.setFieldValue('status', props.ProductDetailsData &&
//       props.ProductDetailsData.data &&
//       props.ProductDetailsData.data.salesproducts &&
//       props.ProductDetailsData.data.salesproducts.status)
//   },
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//     [props.ProductDetailsData]
//   );

// const formik = useFormik({
//   enableReinitialize: true,
//   initialValues: {
//     productname:
//       props.ProductDetailsData &&
//         props.ProductDetailsData.data &&
//         props.ProductDetailsData.data.salesproducts &&
//         props.ProductDetailsData.data.salesproducts.productname === null ? ''
//         :
//         props.ProductDetailsData &&
//         props.ProductDetailsData.data &&
//         props.ProductDetailsData.data.salesproducts &&
//         props.ProductDetailsData.data.salesproducts.productname,

//     desc:
//       props.ProductDetailsData &&
//         props.ProductDetailsData.data &&
//         props.ProductDetailsData.data.salesproducts &&
//         props.ProductDetailsData.data.salesproducts.desc === null ? ''
//         :
//         props.ProductDetailsData &&
//         props.ProductDetailsData.data &&
//         props.ProductDetailsData.data.salesproducts &&
//         props.ProductDetailsData.data.salesproducts.desc,

//     mfgname:
//       props.ProductDetailsData &&
//         props.ProductDetailsData.data &&
//         props.ProductDetailsData.data.salesproducts &&
//         props.ProductDetailsData.data.salesproducts.mfgname === null
//         ? ""
//         : props.ProductDetailsData &&
//         props.ProductDetailsData.data &&
//         props.ProductDetailsData.data.salesproducts &&
//         props.ProductDetailsData.data.salesproducts.mfgname,

//     brand:
//       (props.ProductDetailsData &&
//         props.ProductDetailsData.data &&
//         props.ProductDetailsData.data.salesproducts &&
//         props.ProductDetailsData.data.salesproducts.brand) === null
//         ? ""
//         : props.ProductDetailsData &&
//         props.ProductDetailsData.data &&
//         props.ProductDetailsData.data.salesproducts &&
//         props.ProductDetailsData.data.salesproducts.brand,

//     price:
//       props.ProductDetailsData &&
//         props.ProductDetailsData.data &&
//         props.ProductDetailsData.data.salesproducts &&
//         props.ProductDetailsData.data.salesproducts.price === null ? ''
//         :
//         props.ProductDetailsData &&
//         props.ProductDetailsData.data &&
//         props.ProductDetailsData.data.salesproducts &&
//         props.ProductDetailsData.data.salesproducts.price,

//     barcode:
//       (props.ProductDetailsData &&
//         props.ProductDetailsData.data &&
//         props.ProductDetailsData.data.salesproducts &&
//         props.ProductDetailsData.data.salesproducts.barcode) === null
//         ? ""
//         : props.ProductDetailsData &&
//         props.ProductDetailsData.data &&
//         props.ProductDetailsData.data.salesproducts &&
//         props.ProductDetailsData.data.salesproducts.barcode,

//     promoprice:
//       (props.ProductDetailsData &&
//         props.ProductDetailsData.data &&
//         props.ProductDetailsData.data.salesproducts &&
//         props.ProductDetailsData.data.salesproducts.promoprice) === null
//         ? ""
//         : props.ProductDetailsData &&
//         props.ProductDetailsData.data &&
//         props.ProductDetailsData.data.salesproducts &&
//         props.ProductDetailsData.data.salesproducts.promoprice,

//     uom:
//       (props.ProductDetailsData &&
//         props.ProductDetailsData.data &&
//         props.ProductDetailsData.data.salesproducts &&
//         props.ProductDetailsData.data.salesproducts.uom) === null
//         ? ""
//         : props.ProductDetailsData &&
//         props.ProductDetailsData.data &&
//         props.ProductDetailsData.data.salesproducts &&
//         props.ProductDetailsData.data.salesproducts.uom,

//     status:
//       props.ProductDetailsData &&
//       props.ProductDetailsData.data &&
//       props.ProductDetailsData.data.salesproducts &&
//       props.ProductDetailsData.data.salesproducts.status,

//     quantity:
//       (props.ProductDetailsData &&
//         props.ProductDetailsData.data &&
//         props.ProductDetailsData.data.salesproducts &&
//         props.ProductDetailsData.data.salesproducts.quantity) === null
//         ? ""
//         : props.ProductDetailsData &&
//         props.ProductDetailsData.data &&
//         props.ProductDetailsData.data.salesproducts &&
//         props.ProductDetailsData.data.salesproducts.quantity,

//     displayrank:
//       props.ProductDetailsData &&
//         props.ProductDetailsData.data &&
//         props.ProductDetailsData.data.salesproducts &&
//         props.ProductDetailsData.data.salesproducts.displayrank === null ? ''
//         :
//         props.ProductDetailsData &&
//         props.ProductDetailsData.data &&
//         props.ProductDetailsData.data.salesproducts &&
//         props.ProductDetailsData.data.salesproducts.displayrank,
//   },
//   validationSchema: validationSchema,

//   onReset: (values) => {
//     formik.setValues({
//       productname: "",
//       desc: "",
//       barcode: "",
//       price: "",
//       promoPrice: "",
//       brand: "",
//       mfgname: "",
//       quantity: "",
//       unit: "",
//       displayrank: "",
//       status: "",
//     });
//   },

//   onSubmit: (values) => {

//     if (props.fileListLogo === true && props.proImage === null) {
//       Modal.confirm({
//         title: "Confirm",
//         icon: <ExclamationCircleOutlined />,
//         content:
//           "Please upload the image or delete the image before Submiting.",
//         okText: "Ok",
//       });
//     }
//     // else  if(Number(values.promoPrice)>=Number(values.price)){
//     //   // message.error({
//     //   //   content: 'Promo price must be lesser than price',
//     //   //   className: 'custom-class',
//     //   //   style: {
//     //   //     marginTop: '20vh',
//     //   //   },
//     //   // });
//     //   document.body.scrollTop = 0;
//     //   formik.setFieldError(' promoPrice','Promoprice must be lesser than price.');
//     // }
//     else if (props.newMultiFile) {
//       // if (props.multiEditImage.uploadMultipleFiles.length < 4) {
//       let multi = [],
//         i = 0;
//       let department = state.salesdepartment;
//       let category = state.salescategory;
//       let subcategory = state.salescategory1;
//       let category2 = state.salescategory2;
//       let productid = state.productid;
//       let thumbnail = props.newthumbnailUrl
//         ? props.newthumbnailUrl.imageEditAndUpload.Location
//         : props.ProductDetailsData &&
//           props.ProductDetailsData.data &&
//           props.ProductDetailsData.data.salesproducts &&
//           props.ProductDetailsData.data.salesproducts.image &&
//           props.ProductDetailsData.data.salesproducts &&
//           props.ProductDetailsData.data.salesproducts.image.thumbnail !== null
//           ? props.ProductDetailsData &&
//           props.ProductDetailsData.data &&
//           props.ProductDetailsData.data.salesproducts &&
//           props.ProductDetailsData.data.salesproducts.image &&
//           props.ProductDetailsData.data.salesproducts &&
//           props.ProductDetailsData.data.salesproducts.image.thumbnail
//           : null;

//       let primary = localStorage.getItem("updateUrl")
//         ? localStorage.getItem("updateUrl")
//         : props.ProductDetailsData &&
//         props.ProductDetailsData.data &&
//         props.ProductDetailsData.data.salesproducts &&
//         props.ProductDetailsData.data.salesproducts.image &&
//         props.ProductDetailsData.data.salesproducts &&
//         props.ProductDetailsData.data.salesproducts.image.primary;
//       // props.newMultiFile.params.map((m) => {
//       //   multi[i] = m.url;
//       //   i++;
//       //   return multi;})
//       props.newMultiFile &&
//         props.newMultiFile.params.map((s) => {
//           if (i <= 4) {
//             if (s.url) {
//               multi[i] = s.url;
//               i++;
//             }
//           }
//           return multi;
//         });
//       if (values.promoprice === "") {
//         props.UpdateProduct(
//           values,
//           department,
//           category,
//           subcategory,
//           category2,
//           productid,
//           thumbnail,
//           multi,
//           primary
//         );

//       }
//       else {
//         //
//         if (Number(values.promoprice) >= Number(values.price)) {
//           document.body.scrollTop = 0;
//           formik.setFieldError('promoprice', 'Promoprice must be lesser than price.')
//           message.error({
//             content: 'Promoprice must be lesser than price',
//             className: 'custom-class',
//             style: {
//               marginTop: '20vh',
//             },
//           });
//         }
//         else {
//           props.UpdateProduct(
//             values,
//             department,
//             category,
//             subcategory,
//             category2,
//             productid,
//             thumbnail,
//             multi,
//             primary
//           );

//         }
//       }

//     }
//     // else if (props.multiFile === true && props.multiImage === null) {
//     //   Modal.confirm({
//     //     title: "Confirm",
//     //     icon: <ExclamationCircleOutlined />,
//     //     content:
//     //       "Please upload the image or delete the image before Submiting.",
//     //     okText: "Ok",
//     //   });
//     // }
//     else if (localStorage.getItem("updateUrl")) {
//       let thumbnail = props.newthumbnailUrl
//         ? props.newthumbnailUrl.imageEditAndUpload.Location
//         : props.ProductDetailsData &&
//           props.ProductDetailsData.data &&
//           props.ProductDetailsData.data.salesproducts &&
//           props.ProductDetailsData.data.salesproducts.image &&
//           props.ProductDetailsData.data.salesproducts &&
//           props.ProductDetailsData.data.salesproducts.image.thumbnail !== null
//           ? props.ProductDetailsData &&
//           props.ProductDetailsData.data &&
//           props.ProductDetailsData.data.salesproducts &&
//           props.ProductDetailsData.data.salesproducts.image &&
//           props.ProductDetailsData.data.salesproducts &&
//           props.ProductDetailsData.data.salesproducts.image.thumbnail
//           : null;

//       let primary = localStorage.getItem("updateUrl")
//         ? localStorage.getItem("updateUrl")
//         : props.ProductDetailsData &&
//         props.ProductDetailsData.data &&
//         props.ProductDetailsData.data.salesproducts &&
//         props.ProductDetailsData.data.salesproducts.image &&
//         props.ProductDetailsData.data.salesproducts &&
//         props.ProductDetailsData.data.salesproducts.image.primary;
//       let multi = [],
//         i = 0;
//       if (props.newMultiFile) {
//         props.newMultiFile.params.map((m) => {
//           multi[i] = m.url;
//           i++;
//           return multi;
//         })
//         props.newMultiFile &&
//           props.newMultiFile.params.map((s) => {
//             if (i <= 4) {
//               if (s.url) {
//                 multi[i] = s.url;
//                 i++;
//               }
//             }
//             return multi;
//           });
//       }
//       else {
//         props.ProductDetailsData &&
//           props.ProductDetailsData.data &&
//           props.ProductDetailsData.data.salesproducts &&
//           props.ProductDetailsData.data.salesproducts.image &&
//           props.ProductDetailsData.data.salesproducts.image.secondary !== null &&
//           props.ProductDetailsData.data.salesproducts.image.secondary.map(
//             (s) => {
//               multi[i] = s;
//               i++;
//               return multi;
//             }
//           );
//       }

//       let department = state.salesdepartment;
//       let category = state.salescategory;
//       let subcategory = state.salescategory1;
//       let category2 = state.salescategory2;
//       let productid = state.productid;
//       if (values.promoprice === "") {
//         props.UpdateProduct(
//           values,
//           department,
//           category,
//           subcategory,
//           category2,
//           productid,
//           thumbnail,
//           multi,
//           primary
//         );

//       }
//       else {
//         if (Number(values.promoprice) >= Number(values.price)) {
//           document.body.scrollTop = 0;
//           formik.setFieldError('promoprice', 'Promoprice must be lesser than price.')
//           message.error({
//             content: 'Promoprice must be lesser than price',
//             className: 'custom-class',
//             style: {
//               marginTop: '20vh',
//             },
//           });
//         }
//         else {
//           props.UpdateProduct(
//             values,
//             department,
//             category,
//             subcategory,
//             category2,
//             productid,
//             thumbnail,
//             multi,
//             primary
//           );

//         }
//       }
//     } else {
//       if (props.multiFileEdited === true) {
//         let thumbnail = props.newthumbnailUrl
//           ? props.newthumbnailUrl.imageEditAndUpload.Location
//           : props.ProductDetailsData &&
//             props.ProductDetailsData.data &&
//             props.ProductDetailsData.data.salesproducts &&
//             props.ProductDetailsData.data.salesproducts.image &&
//             props.ProductDetailsData.data.salesproducts &&
//             props.ProductDetailsData.data.salesproducts.image.thumbnail !==
//             null
//             ? props.ProductDetailsData &&
//             props.ProductDetailsData.data &&
//             props.ProductDetailsData.data.salesproducts &&
//             props.ProductDetailsData.data.salesproducts.image &&
//             props.ProductDetailsData.data.salesproducts &&
//             props.ProductDetailsData.data.salesproducts.image.thumbnail
//             : null;

//         let primary = localStorage.getItem("updateUrl")
//           ? localStorage.getItem("updateUrl")
//           : props.ProductDetailsData &&
//           props.ProductDetailsData.data &&
//           props.ProductDetailsData.data.salesproducts &&
//           props.ProductDetailsData.data.salesproducts.image &&
//           props.ProductDetailsData.data.salesproducts &&
//           props.ProductDetailsData.data.salesproducts.image.primary;

//         let multi = [],
//           i = 0;
//         if (props.multiEditImage) {

//           if (props.multiEditImage.uploadMultipleFiles.length < 4) {
//             props.multiEditImage.uploadMultipleFiles.map((m) => {
//               multi[i] = m.Location;

//               i++;

//               return multi;
//             });

//             props.newMultiFile &&
//               props.newMultiFile.params.map((s) => {
//                 if (i <= 4) {
//                   if (s.url) {
//                     multi[i] = s.url;

//                     i++;
//                   }
//                 }

//                 return multi;
//               });
//           }
//           else {
//             props.multiEditImage.uploadMultipleFiles.map((m) => {
//               multi[i] = m.Location;

//               i++;

//               return multi;
//             });
//           }
//         }
//         else {
//           props.ProductDetailsData &&
//             props.ProductDetailsData.data &&
//             props.ProductDetailsData.data.salesproducts &&
//             props.ProductDetailsData.data.salesproducts.image &&
//             props.ProductDetailsData.data.salesproducts.image.secondary !== null &&
//             props.ProductDetailsData.data.salesproducts.image.secondary.map(
//               (s) => {
//                 multi[i] = s;

//                 i++;

//                 return multi;
//               }
//             );
//         }

//         let department = state.salesdepartment;

//         let category = state.salescategory;

//         let subcategory = state.salescategory1;

//         let category2 = state.salescategory2;

//         let productid = state.productid;
//         if (values.promoprice === "") {
//           props.UpdateProduct(
//             values,
//             department,
//             category,
//             subcategory,
//             category2,
//             productid,
//             thumbnail,
//             multi,
//             primary
//           );

//         }
//         else {
//           if (Number(values.promoprice) >= Number(values.price)) {
//             document.body.scrollTop = 0;
//             formik.setFieldError('promoprice', 'Promoprice must be lesser than price.')
//             message.error({
//               content: 'Promoprice must be lesser than price',
//               className: 'custom-class',
//               style: {
//                 marginTop: '20vh',
//               },
//             });
//           }
//           else {
//             props.UpdateProduct(
//               values,
//               department,
//               category,
//               subcategory,
//               category2,
//               productid,
//               thumbnail,
//               multi,
//               primary
//             );

//           }
//         }
//       }
//       else {
//         let department = state.salesdepartment;
//         let category = state.salescategory;
//         let subcategory = state.salescategory1;
//         let category2 = state.salescategory2;
//         let productid = state.productid;
//         if (values.promoprice === "") {
//           props.ProductUpload(
//             values,
//             department,
//             category,
//             subcategory,
//             category2,
//             productid
//           );

//         }
//         else {
//           if (Number(values.promoprice) >= Number(values.price)) {
//             document.body.scrollTop = 0;
//             formik.setFieldError('promoprice', 'Promoprice must be lesser than price.')
//             message.error({
//               content: 'Promoprice must be lesser than price',
//               className: 'custom-class',
//               style: {
//                 marginTop: '20vh',
//               },
//             });
//           }
//           else {
//             props.ProductUpload(
//               values,
//               department,
//               category,
//               subcategory,
//               category2,
//               productid
//             );

//           }
//         }
//       }
//     }
//   },
// });

//   const [state, setState] = useState({
//     productid: props.location.state.Productid,
//     displayrank:
//       props.ProductDetailsData &&
//       props.ProductDetailsData.data &&
//       props.ProductDetailsData.data.salesproducts &&
//       props.ProductDetailsData.data.salesproducts.displayrank,
//     salesdepartment:
//       props.location.state.salesdepartment,
//     salescategory:
//       (props.ProductDetailsData &&
//         props.ProductDetailsData.data &&
//         props.ProductDetailsData.data.salesproducts &&
//         props.ProductDetailsData.data.salesproducts.salescategory &&
//         props.ProductDetailsData.data.salesproducts.salescategory.id &&
//         props.ProductDetailsData.data.salesproducts.salescategory.id) || ""
//     ,
//     salescategory1:
//       (props.ProductDetailsData &&
//         props.ProductDetailsData.data &&
//         props.ProductDetailsData.data.salesproducts &&
//         props.ProductDetailsData.data.salesproducts.salescategory1 &&
//         props.ProductDetailsData.data.salesproducts.salescategory1.id &&
//         props.ProductDetailsData.data.salesproducts.salescategory1.id) || ""
//     ,
//     salescategory2:
//       (props.ProductDetailsData &&
//         props.ProductDetailsData.data &&
//         props.ProductDetailsData.data.salesproducts &&
//         props.ProductDetailsData.data.salesproducts.salescategory2 &&
//         props.ProductDetailsData.data.salesproducts.salescategory2.id &&
//         props.ProductDetailsData.data.salesproducts.salescategory2.id) || ""
//     ,
//   });
//   const handleFormChange = (e, name) => {

//     if (e.target.name === "department") {
//       setState({
//         ...state,
//         [e.target.name]: e.target.value,
//         salescategory: null,
//         salescategory1: null,
//         salescategory2: null,
//       });
//     }
//     else if (e.target.name === "salescategory") {
//       setState({
//         ...state,
//         [e.target.name]: e.target.value,
//         salescategory1: null,
//         salescategory2: null,
//       });
//     }
//     else if (e.target.name === "salescategory1") {
//       setState({
//         ...state,
//         [e.target.name]: e.target.value,
//         salescategory2: null,
//       });
//     }
//     else {
//       setState({
//         ...state,
//         [e.target.name]: e.target.value,
//       });
//     }
//   };

//   const getCategoryList = (id) => {
//     props.getCategoryList(id);
//     props.clearCategories();
//   };

//   const getCategory1List = (id) => {
//     props.getCategory1List(id);
//   };

//   const getCategory2List = (id) => {
//     props.getCategory2List(id);
//   };

//   const theme = createTheme({
//     palette: {
//       primary: green,
//     },
//     overrides: {
//       MuiInputBase: {
//         input: {
//           "&:-webkit-autofill": {
//             transitionDelay: "9999s",
//             transitionProperty: "background-color, color",
//           },
//         },
//       },
//     },
//   });

//   const isSmallScreen = useMediaQuery({ query: '(max-width: 1224px)' })

//   return (
//     isSmallScreen ?
//       <Container maxWidth='md'
//         style={{
//           backgroundColor: "white",
//           border: "1px solid rgba(0, 0, 0, 0.16)",
//           borderRadius: "10px",
//           Top: "40px",
//           padding: "30px",
//           width: "100%",
//         }}
//       >
//         <>
//           <div
//             style={{
//               paddingLeft: "30px",
//               paddingRight: "10px",
//               paddingTop: "15px",
//               paddingBottom: "10px",
//               textAlign: "center",
//               fontWeight: 600,
//             }}
//           >
//             <h5 style={{ fontWeight: 800, fontSize: "23px" }}>Edit product</h5>
//           </div>
//           <form
//             enablereinitialize={'true'}
//             initialvalues={formik.initialValues}
//             style={{ width: "100%" }}
//             onSubmit={formik.handleSubmit}
//             id="myForm"
//           >
//             <Row style={{ width: "100%" }}>
//               {props.ProductDetailsData && (
//                 <>
//                   <ThemeProvider theme={theme}>
//                     <TextField
//                       className="form-control"
//                       style={{ marginBottom: "12px", marginLeft: 30 }}
//                       InputProps={{ style: { height: 35, width: "100%" } }}
//                       variant="outlined"
//                       label="Product name"
//                       name="productname"
//                       value={
//                         (formik.values.productname && formik.values.productname) || ""
//                       }
//                       onChange={formik.handleChange}
//                       error={
//                         formik.touched.productname &&
//                         Boolean(formik.errors.productname)
//                       }
//                       helperText={
//                         formik.touched.productname && formik.errors.productname
//                       }
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                     />
//                   </ThemeProvider>
//                   {/* <div style={{ paddingTop:'20px',width:'100%' }}> */}
//                   <ThemeProvider theme={theme}>
//                     <TextField
//                       className="form-control"
//                       style={{ marginBottom: "12px", marginLeft: 30 }}
//                       InputProps={{ style: { height: 50, width: "100%" } }}
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                       variant="outlined"
//                       value={(formik.values.desc && formik.values.desc) || ""}
//                       onChange={formik.handleChange}
//                       error={formik.touched.desc && Boolean(formik.errors.desc)}
//                       helperText={formik.touched.desc && formik.errors.desc}
//                       label="Short description about the product"
//                       // defaultValue={props.ProductDetailsData&&props.ProductDetailsData.data&&
//                       //     props.ProductDetailsData.data.salesproducts&&props.ProductDetailsData.data.salesproducts.desc
//                       // &&props.ProductDetailsData.data.salesproducts.desc}
//                       name="desc"
//                     />
//                   </ThemeProvider>
//                   {/* </div> */}
//                   <Row
//                     style={{ paddingTop: "25px", width: "100%", marginLeft: 10 }}
//                   >
//                     {/* <div style={{ paddingTop:'25px',width:'100%' }}> */}
//                     <ThemeProvider theme={theme}>
//                       <TextField
//                         className="form-control"
//                         style={{ marginBottom: "12px", marginLeft: 20 }}
//                         InputProps={{ style: { height: 35, width: "99%" } }}
//                         variant="outlined"
//                         label="Barcode number"
//                         InputLabelProps={{
//                           shrink: true,
//                         }}
//                         value={(formik.values.barcode && formik.values.barcode) || ""}
//                         onChange={formik.handleChange}
//                         error={
//                           formik.touched.barcode && Boolean(formik.errors.barcode)
//                         }
//                         helperText={
//                           formik.touched.barcode && formik.errors.barcode
//                         }
//                         name="barcode"
//                       />
//                     </ThemeProvider>
//                   </Row>
//                   {/* </div> */}
//                   {customerFields.map((detail, index) => {
//                     return (
//                       <React.Fragment key={index}>
//                         {localStorage.setItem(
//                           "imageUrl",
//                           props.ProductDetailsData &&
//                             props.ProductDetailsData.data &&
//                             props.ProductDetailsData.data.salesproducts &&
//                             props.ProductDetailsData.data.salesproducts.image !==
//                             null
//                             ? props.ProductDetailsData.data.salesproducts.image
//                               .primary
//                             : null
//                         )}
//                         {localStorage.setItem(
//                           "multiple",
//                           props.ProductDetailsData &&
//                             props.ProductDetailsData.data &&
//                             props.ProductDetailsData.data.salesproducts &&
//                             props.ProductDetailsData.data.salesproducts.image &&
//                             props.ProductDetailsData.data.salesproducts.image.secondary !== null &&
//                             props.ProductDetailsData.data.salesproducts.image.secondary.length !== 0
//                             ? props.ProductDetailsData.data.salesproducts.image
//                               .secondary
//                             : null
//                         )}

//                         <Col md={6} style={{ paddingTop: 30 }}>
//                           <ThemeProvider theme={theme}>
//                             <TextField
//                               key={`${detail.label}`}
//                               style={{ marginLeft: "12px" }}
//                               InputProps={{ style: { height: 35 } }}
//                               label={detail.label}
//                               fullWidth
//                               name={detail.value && detail.value}
//                               value={(formik.values[detail.value]) || ""}
//                               onChange={formik.handleChange}
//                               error={
//                                 formik.touched[detail.value] &&
//                                 Boolean(formik.errors[detail.value])
//                               }
//                               helperText={
//                                 formik.touched[detail.value] &&
//                                 formik.errors[detail.value]
//                               }
//                               variant="outlined"
//                               InputLabelProps={{
//                                 shrink: true,
//                               }}
//                             />
//                           </ThemeProvider>
//                         </Col>
//                       </React.Fragment>
//                     );
//                   })}
//                 </>
//               )}
//             </Row>

//             <Row style={{ paddingTop: 30, width: "100%" }}>
//               <Col md={6} style={{ paddingTop: 30 }}>
//                 <ThemeProvider theme={theme}>
//                   <TextField
//                     style={{
//                       marginBottom: "15px",
//                       marginLeft: 12,
//                       width: "100%",
//                     }}
//                     InputProps={{ style: { height: 35, width: "100%" } }}
//                     fullWidth
//                     variant="outlined"
//                     select
//                     label="Unit of measurement"
//                     name="uom"
//                     SelectProps={{
//                       multiple: false,
//                       value: formik.values.uom ? formik.values.uom && formik.values.uom
//                         : (props.location.state.uom === null ? '' : props.location.state.uom)
//                     }}

//                     onChange={formik.handleChange}
//                     error={formik.touched.uom && Boolean(formik.errors.uom)}
//                     helperText={formik.touched.uom && formik.errors.uom}
//                     InputLabelProps={{
//                       shrink: true,
//                     }}
//                   >
//                     <MenuItem value={"No.s"} style={{ cursor: "pointer" }}>
//                       No.s
//                     </MenuItem>
//                     <MenuItem value={"kg"} style={{ cursor: "pointer" }}>
//                       Kilogram (kg)
//                     </MenuItem>
//                     <MenuItem value={"g"} style={{ cursor: "pointer" }}>
//                       gram (g)
//                     </MenuItem>
//                     <MenuItem value={"gal"} style={{ cursor: "pointer" }}>
//                       gallon (gal)
//                     </MenuItem>
//                     <MenuItem value={"l"} style={{ cursor: "pointer" }}>
//                       liter (l)
//                     </MenuItem>
//                     <MenuItem value={"ml"} style={{ cursor: "pointer" }}>
//                       milliliter (ml)
//                     </MenuItem>
//                   </TextField>
//                 </ThemeProvider>
//               </Col>
//               <Col md={6} style={{ paddingTop: 30 }}>
//                 <ThemeProvider theme={theme}>
//                   <TextField
//                     SelectProps={{
//                       multiple: false,
//                       value: formik.values.status ? formik.values.status && formik.values.status
//                         : props.location.state.status,
//                       style: { width: "100%", height: 35 }
//                     }}
//                     onChange={formik.handleChange}
//                     error={formik.touched.status && Boolean(formik.errors.status)}
//                     helperText={formik.touched.status && formik.errors.status}
//                     select
//                     variant="outlined"
//                     style={{ width: "100%", marginLeft: "12px" }}
//                     InputLabelProps={{
//                       shrink: true,
//                     }}
//                     label="Status"
//                     name="status"
//                   >
//                     {statusFilters.map((statusFilter, index) => {
//                       return (
//                         <MenuItem key={index} value={statusFilter.value}>
//                           {statusFilter.option}
//                         </MenuItem>
//                       );
//                     })}
//                   </TextField>
//                 </ThemeProvider>
//               </Col>
//             </Row>
//             <Row style={{ paddingTop: 30 }}>
//               <Col style={{ marginBottom: "20px", marginLeft: "10px" }}>
//                 <h6>{"Primary image of the product "}</h6>
//                 <Uploader
//                   {...props}
//                   imge={
//                     props.ProductDetailsData &&
//                     props.ProductDetailsData.data &&
//                     props.ProductDetailsData.data.salesproducts &&
//                     props.ProductDetailsData.data.salesproducts.image &&
//                     props.ProductDetailsData.data.salesproducts.image.primary
//                   }
//                 />
//                 {/* <div Style="display: flex;min-width: 0px;overflow: hidden;">
//                    {props.ProductDetailsData&&
//                     // <>
//                     //  <img Style="display: block;width: 72px;height: 90px;"
//                     //  src={props.ProductDetailsData.data.salesproducts&&props.ProductDetailsData.data.salesproducts.image&&
//                     //  props.ProductDetailsData.data.salesproducts.image!==null?
//                     //  `${props.ProductDetailsData.data.salesproducts.image.primary}`:
//                     //  NoImage} alt="product"/>
//                     //  </>

//                     }

//                     </div> */}
//               </Col>
//             </Row>
//             <Row style={{ paddingTop: 30 }}>
//               <Col style={{ marginBottom: "20px", marginLeft: "10px" }}>
//                 <h6>{"Secondary image of the product "}</h6>
//                 <MultipleUploader {...props} product={props.ProductDetailsData} />
//               </Col>
//             </Row>

//             <Row>
//               <h6
//                 style={{
//                   marginLeft: "10px",
//                   paddingBottom: "20px",
//                   paddingTop: "20px",
//                 }}
//               >
//                 <b>
//                   {"Select the hierarchy in which the product should display"}
//                 </b>
//               </h6>
//             </Row>
//             <Row>
//               {
//                 props.DepartmentList &&
//                 props.DepartmentList.data &&
//                 props.DepartmentList.data.salesdepartments &&
//                 props.DepartmentList.data.salesdepartments.length !== 0 && (
//                   <>
//                     <Col md={6}>

//                       <ThemeProvider theme={theme}>
//                         <TextField
//                           select
//                           variant="outlined"
//                           required
//                           style={{
//                             width: "100%",
//                             marginLeft: "12px",
//                             marginBottom: "15px",
//                           }}
//                           InputProps={{
//                             style: { height: 35, width: "100%" },
//                             autoComplete: "off",
//                           }}
//                           SelectProps={{ style: { width: "auto", height: 35 }, }}
//                           label="Department"
//                           value={(state.salesdepartment && state.salesdepartment) || ""}
//                           name="salesdepartment"
//                           onChange={(e) => {
//                             setState({
//                               ...state,
//                               salesdepartment: e.target.value,
//                               salescategory: null,
//                               salescategory1: null,
//                               salescategory2: null,
//                             });
//                             getCategoryList(e.target.value);
//                           }}
//                           InputLabelProps={{
//                             shrink: true,
//                           }}
//                         >
//                           {props.DepartmentList &&
//                             props.DepartmentList.data &&
//                             props.DepartmentList.data.salesdepartments &&
//                             props.DepartmentList.data.salesdepartments.map(
//                               (s) => (
//                                 <MenuItem
//                                   style={{ width: "fit-content" }}
//                                   key={s.id}
//                                   value={s.id}
//                                 >
//                                   {s.name}
//                                 </MenuItem>
//                               )
//                             )}
//                         </TextField>
//                       </ThemeProvider>
//                     </Col>
//                     {state.salescategory !== ''
//                       ? props.CategoryList &&
//                       props.CategoryList.data &&
//                       props.CategoryList.data.salescategory &&
//                       props.CategoryList.data.salescategory.length !== 0 && (
//                         <Col md={6}>
//                           <ThemeProvider theme={theme}>
//                             <TextField
//                               required
//                               select
//                               SelectProps={{
//                                 style: { width: "auto", height: 35 },
//                                 value: state.salescategory && state.salescategory
//                               }}
//                               variant="outlined"
//                               style={{
//                                 width: "100%",
//                                 marginLeft: "12px",
//                               }}
//                               InputProps={{
//                                 style: { height: 35, width: "100%" },
//                                 autoComplete: "off",
//                               }}
//                               label="Category"
//                               name="salescategory"
//                               onChange={(e) => {
//                                 handleFormChange(e);
//                                 getCategory1List(e.target.value);
//                               }}
//                               InputLabelProps={{
//                                 shrink: true,
//                               }}
//                             >
//                               {props.CategoryList &&
//                                 props.CategoryList.data.salescategory.map(
//                                   (c) => (
//                                     <MenuItem
//                                       style={{ width: "fit-content" }}
//                                       key={c.id}
//                                       value={c.id}
//                                     >
//                                       {c.name}
//                                     </MenuItem>
//                                   )
//                                 )}
//                             </TextField>
//                           </ThemeProvider>
//                         </Col>
//                       )
//                       : props.CategoryList &&
//                       props.CategoryList.data &&
//                       props.CategoryList.data.salescategory &&
//                       props.CategoryList.data.salescategory.length !== 0 && (
//                         <Col md={6}>
//                           <ThemeProvider theme={theme}>
//                             <TextField
//                               required
//                               select
//                               variant="outlined"
//                               SelectProps={{
//                                 style: { width: "auto", height: 35 },
//                                 value: state.salescategory && state.salescategory
//                               }}
//                               style={{
//                                 width: "100%",
//                                 marginLeft: "12px",
//                                 marginBottom: "15px",
//                               }}
//                               InputProps={{
//                                 style: { height: 35, width: "100%" },
//                                 autoComplete: "off",
//                               }}
//                               label="Category"
//                               name="salescategory"
//                               onChange={(e) => {
//                                 handleFormChange(e);
//                                 getCategory1List(e.target.value);
//                               }}
//                               InputLabelProps={{
//                                 shrink: true,
//                               }}
//                             >
//                               {props.CategoryList &&
//                                 props.CategoryList.data.salescategory.map(
//                                   (c) => (
//                                     <MenuItem
//                                       style={{ width: "fit-content" }}
//                                       key={c.id}
//                                       value={c.id}
//                                     >
//                                       {c.name}
//                                     </MenuItem>
//                                   )
//                                 )}
//                             </TextField>
//                           </ThemeProvider>
//                         </Col>
//                       )}
//                   </>
//                 )}
//             </Row>

//             {/* SUB CATEGORY SELECT */}

//             <Row style={{ paddingTop: 30 }}>
//               <>
//                 {state.salescategory1 !== ''
//                   ? props.CategoryList &&
//                   props.CategoryList.data.salescategory.length !== 0 &&
//                   props.Category1List &&
//                   props.Category1List.data.salescategory.length !== 0 && (
//                     <>
//                       <Col md={6}>
//                         <ThemeProvider theme={theme}>
//                           <TextField
//                             required
//                             InputLabelProps={{
//                               shrink: true,
//                             }}
//                             select
//                             variant="outlined"
//                             style={{
//                               width: "100%",
//                               marginLeft: "12px",
//                               marginBottom: "15px",
//                             }}
//                             InputProps={{
//                               style: { height: 35, width: "100%" },
//                               autoComplete: "off",
//                             }}
//                             label="Sub-Category 1"
//                             SelectProps={{
//                               style: { width: "auto", height: 35 },
//                               value: state.salescategory1 && state.salescategory1
//                             }}
//                             name="salescategory1"
//                             onChange={(e) => {
//                               handleFormChange(e);
//                               getCategory2List(e.target.value);
//                             }}
//                           >
//                             {props.CategoryList &&
//                               props.CategoryList.data.salescategory.length !==
//                               0 &&
//                               props.Category1List &&
//                               props.Category1List.data.salescategory.map(
//                                 (c) => (
//                                   <MenuItem
//                                     style={{ width: "fit-content" }}
//                                     key={c.id}
//                                     value={c.id}
//                                   >
//                                     {c.name}
//                                   </MenuItem>
//                                 )
//                               )}
//                           </TextField>
//                         </ThemeProvider>
//                       </Col>
//                     </>
//                   )
//                   : state.salescategory !== '' &&
//                   props.CategoryList &&
//                   props.CategoryList.data.salescategory.length !== 0 &&
//                   props.Category1List &&
//                   props.Category1List.data.salescategory.length !== 0 && (
//                     <Col md={6}>
//                       <ThemeProvider theme={theme}>
//                         <TextField
//                           required
//                           SelectProps={{
//                             style: { width: "auto", height: 35 },
//                             value: state.salescategory1 && state.salescategory1
//                           }}
//                           InputLabelProps={{
//                             shrink: true,
//                           }}
//                           select
//                           variant="outlined"
//                           style={{
//                             width: "100%",
//                             marginLeft: "12px",
//                             marginBottom: "15px",
//                           }}
//                           InputProps={{
//                             style: { height: 35, width: "100%" },
//                             autoComplete: "off",
//                           }}
//                           label="Sub-Category 1"
//                           name="salescategory1"
//                           onChange={(e) => {
//                             handleFormChange(e);
//                             getCategory2List(e.target.value);
//                           }}
//                         >
//                           {props.CategoryList &&
//                             props.CategoryList.data.salescategory.length !==
//                             0 &&
//                             props.Category1List &&
//                             props.Category1List.data.salescategory.map((c) => (
//                               <MenuItem
//                                 style={{ width: "fit-content" }}
//                                 key={c.id}
//                                 value={c.id}
//                               >
//                                 {c.name}
//                               </MenuItem>
//                             ))}
//                         </TextField>
//                       </ThemeProvider>
//                     </Col>
//                   )}

//                 {(state.salescategory2 !== '' )
//                   ? props.Category2List &&
//                   props.Category2List!==null&&
//                   props.Category2List.data.salescategory.length !== 0 && (
//                     <Col md={6}>
//                       <ThemeProvider theme={theme}>
//                         <TextField
//                           required
//                           InputLabelProps={{
//                             shrink: true,
//                           }}
//                           SelectProps={{
//                             style: { width: "auto", height: 35 },
//                             value: state.salescategory2 && state.salescategory2
//                           }}
//                           select
//                           variant="outlined"
//                           style={{
//                             width: "100%",
//                             marginLeft: "12px",
//                             marginBottom: "15px",
//                           }}
//                           InputProps={{
//                             style: { height: 35, width: "100%" },
//                             autoComplete: "off",
//                           }}
//                           label="Sub-Category 1"
//                           name="salescategory2"
//                           onChange={(e) => {
//                             handleFormChange(e);
//                           }}
//                         >
//                           {props.Category2List &&
//                           props.Category2List!==null&&
//                             props.Category2List.data.salescategory.map((i) => (
//                               <MenuItem
//                                 style={{ width: "fit-content" }}
//                                 key={i.id}
//                                 value={i.id}
//                               >
//                                 {i.name}
//                               </MenuItem>
//                             ))}
//                         </TextField>
//                       </ThemeProvider>
//                     </Col>
//                   )
//                   : state.salescategory2 !== '' &&
//                   props.Category2List &&
//                   props.Category2List!==null&&
//                   props.Category2List.data.salescategory.length !== 0 && (
//                     <Col md={6}>
//                       <ThemeProvider theme={theme}>
//                         <TextField
//                           InputLabelProps={{
//                             shrink: true,
//                           }}
//                           required
//                           SelectProps={{
//                             style: { width: "auto", height: 35 },
//                             value: state.salescategory2 && state.salescategory2
//                           }}
//                           select
//                           variant="outlined"
//                           style={{
//                             marginBottom: "15px",
//                             width: "100%",
//                             marginLeft: "12px",
//                           }}
//                           InputProps={{
//                             style: { height: 35, width: "100%" },
//                             autoComplete: "off",
//                           }}
//                           label="Sub-Category 1"
//                           name="salescategory2"
//                           onChange={(e) => {
//                             handleFormChange(e);
//                           }}
//                         >
//                           {props.Category2List &&
//                           props.Category2List!==null&&
//                             props.Category2List.data.salescategory.map((i) => (
//                               <MenuItem
//                                 style={{ width: "fit-content" }}
//                                 value={i.id}
//                               >
//                                 {i.name}
//                               </MenuItem>
//                             ))}
//                         </TextField>
//                       </ThemeProvider>
//                     </Col>
//                   )}
//               </>
//             </Row>

//             <Row style={{ paddingBottom: "30px", paddingTop: 30 }}>
//               <Col>
//                 <button
//                   style={{
//                     height: "37px",
//                     width: "100%",
//                     backgroundColor: "white",
//                     border: "2px solid #75c224",
//                     color: "#75c224",
//                     fontSize: "14px",
//                     fontWeight: 600,
//                     borderRadius: 5,
//                   }}
//                   variant="outline-success"
//                   onClick={() => {
//                     props.history.push("/home/EditProducts");
//                   }}
//                 >
//                   Back
//                 </button>
//               </Col>
//               <Col>
//                 <button
//                   id="myForm"
//                   style={{
//                     justifyContent: " center",
//                     color: "rgb(255, 255, 255)",
//                     backgroundColor: 'rgb(137, 199, 74, 1)',
//                     width: "100%",
//                     float: "right",
//                     height: "37px",
//                     fontSize: "14px",
//                     fontWeight: "600",
//                     borderRadius: "5px",
//                     borderWidth: "1px",
//                     borderStyle: " solid",
//                     borderColor: "transparent",
//                     borderImage: "initial",
//                     overflow: "hidden",
//                     outline: "none",
//                     minHeight: " 14px",
//                     fontFamily: "Varela Round, sans-serif",
//                   }}
//                   type="submit"
//                 >
//                   {props.proupdating === true ? "Updating" : "Update"}
//                 </button>
//               </Col>
//             </Row>
//           </form>
//         </>
//       </Container>

//       :

//       <Container maxWidth='md'
//         style={{
//           backgroundColor: "white",
//           border: "1px solid rgba(0, 0, 0, 0.16)",
//           borderRadius: "10px",
//           Top: "40px",
//           padding: "30px",
//           width: "100%",
//         }}
//       >
//         <>
//           <div
//             style={{
//               paddingLeft: "30px",
//               paddingRight: "10px",
//               paddingTop: "15px",
//               paddingBottom: "10px",
//               textAlign: "center",
//               fontWeight: 600,
//             }}
//           >
//             <h5 style={{ fontWeight: 800, fontSize: "23px" }}>Edit product</h5>
//           </div>
//           <form
//             enablereinitialize={'true'}
//             initialvalues={formik.initialValues}
//             style={{ width: "100%" }}
//             onSubmit={formik.handleSubmit}
//             id="myForm"
//           >
//             <Row style={{ width: "100%" }}>
//               {props.ProductDetailsData && (
//                 <>
//                   <ThemeProvider theme={theme}>
//                     <TextField
//                       className="form-control"
//                       style={{ marginBottom: "12px", marginLeft: 30 }}
//                       InputProps={{ style: { height: 35, width: "100%" } }}
//                       variant="outlined"
//                       label="Product name"
//                       name="productname"
//                       value={
//                         (formik.values.productname && formik.values.productname) || ""
//                       }
//                       onChange={formik.handleChange}
//                       error={
//                         formik.touched.productname &&
//                         Boolean(formik.errors.productname)
//                       }
//                       helperText={
//                         formik.touched.productname && formik.errors.productname
//                       }
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                     />
//                   </ThemeProvider>
//                   <ThemeProvider theme={theme}>
//                     <TextField
//                       className="form-control"
//                       style={{ marginBottom: "12px", marginLeft: 30 }}
//                       InputProps={{ style: { height: 50, width: "100%" } }}
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                       variant="outlined"
//                       value={(formik.values.desc && formik.values.desc) || ""}
//                       onChange={formik.handleChange}
//                       error={formik.touched.desc && Boolean(formik.errors.desc)}
//                       helperText={formik.touched.desc && formik.errors.desc}
//                       label="Short description about the product"
//                       name="desc"
//                     />
//                   </ThemeProvider>
//                   <Row
//                     style={{ paddingTop: "25px", width: "100%", marginLeft: 10 }}
//                   >
//                     <ThemeProvider theme={theme}>
//                       <TextField
//                         className="form-control"
//                         style={{ marginBottom: "12px", marginLeft: 20 }}
//                         InputProps={{ style: { height: 35, width: "99%" } }}
//                         variant="outlined"
//                         label="Barcode number"
//                         InputLabelProps={{
//                           shrink: true,
//                         }}
//                         value={(formik.values.barcode && formik.values.barcode) || ""}
//                         onChange={formik.handleChange}
//                         error={
//                           formik.touched.barcode && Boolean(formik.errors.barcode)
//                         }
//                         helperText={
//                           formik.touched.barcode && formik.errors.barcode
//                         }
//                         name="barcode"
//                       />
//                     </ThemeProvider>
//                   </Row>
//                   {customerFields.map((detail, index) => {
//                     return (
//                       <React.Fragment key={index}>
//                         {localStorage.setItem(
//                           "imageUrl",
//                           props.ProductDetailsData &&
//                             props.ProductDetailsData.data &&
//                             props.ProductDetailsData.data.salesproducts &&
//                             props.ProductDetailsData.data.salesproducts.image !==
//                             null
//                             ? props.ProductDetailsData.data.salesproducts.image
//                               .primary
//                             : null
//                         )}
//                         {localStorage.setItem(
//                           "multiple",
//                           props.ProductDetailsData &&
//                             props.ProductDetailsData.data &&
//                             props.ProductDetailsData.data.salesproducts &&
//                             props.ProductDetailsData.data.salesproducts.image &&
//                             props.ProductDetailsData.data.salesproducts.image.secondary !== null &&
//                             props.ProductDetailsData.data.salesproducts.image.secondary.length !== 0
//                             ? props.ProductDetailsData.data.salesproducts.image
//                               .secondary
//                             : null
//                         )}

//                         <Col md={6} style={{ paddingTop: 30 }}>
//                           <ThemeProvider theme={theme}>
//                             <TextField
//                               key={`${detail.label}`}
//                               style={{ marginLeft: "12px" }}
//                               InputProps={{ style: { height: 35 } }}
//                               label={detail.label}
//                               fullWidth
//                               name={detail.value && detail.value}
//                               value={(formik.values[detail.value]) || ""}
//                               onChange={formik.handleChange}
//                               error={
//                                 formik.touched[detail.value] &&
//                                 Boolean(formik.errors[detail.value])
//                               }
//                               helperText={
//                                 formik.touched[detail.value] &&
//                                 formik.errors[detail.value]
//                               }
//                               variant="outlined"
//                               InputLabelProps={{
//                                 shrink: true,
//                               }}
//                             />
//                           </ThemeProvider>
//                         </Col>
//                       </React.Fragment>
//                     );
//                   })}
//                 </>
//               )}
//             </Row>

//             <Row style={{ paddingTop: 30, width: "100%" }}>
//               <Col md={6} style={{ paddingTop: 30 }}>
//                 <ThemeProvider theme={theme}>
//                   <TextField
//                     style={{
//                       marginBottom: "15px",
//                       marginLeft: 12,
//                       width: "100%",
//                     }}
//                     InputProps={{ style: { height: 35, width: "100%" } }}
//                     fullWidth
//                     variant="outlined"
//                     select
//                     label="Unit of measurement"
//                     name="uom"
//                     SelectProps={{
//                       multiple: false,
//                       value: formik.values.uom ? formik.values.uom && formik.values.uom
//                         : (props.location.state.uom === null ? '' : props.location.state.uom)
//                     }}

//                     onChange={formik.handleChange}
//                     error={formik.touched.uom && Boolean(formik.errors.uom)}
//                     helperText={formik.touched.uom && formik.errors.uom}
//                     InputLabelProps={{
//                       shrink: true,
//                     }}
//                   >
//                     <MenuItem value={"No.s"} style={{ cursor: "pointer" }}>
//                       No.s
//                     </MenuItem>
//                     <MenuItem value={"kg"} style={{ cursor: "pointer" }}>
//                       Kilogram (kg)
//                     </MenuItem>
//                     <MenuItem value={"g"} style={{ cursor: "pointer" }}>
//                       gram (g)
//                     </MenuItem>
//                     <MenuItem value={"gal"} style={{ cursor: "pointer" }}>
//                       gallon (gal)
//                     </MenuItem>
//                     <MenuItem value={"l"} style={{ cursor: "pointer" }}>
//                       liter (l)
//                     </MenuItem>
//                     <MenuItem value={"ml"} style={{ cursor: "pointer" }}>
//                       milliliter (ml)
//                     </MenuItem>
//                   </TextField>
//                 </ThemeProvider>
//               </Col>
//               <Col md={6} style={{ paddingTop: 30 }}>
//                 <ThemeProvider theme={theme}>
//                   <TextField
//                     SelectProps={{
//                       multiple: false,
//                       value: formik.values.status ? formik.values.status && formik.values.status
//                         : props.location.state.status,
//                       style: { width: "100%", height: 35 }
//                     }}
//                     onChange={formik.handleChange}
//                     error={formik.touched.status && Boolean(formik.errors.status)}
//                     helperText={formik.touched.status && formik.errors.status}
//                     select
//                     variant="outlined"
//                     style={{ width: "100%", marginLeft: "12px" }}
//                     InputLabelProps={{
//                       shrink: true,
//                     }}
//                     label="Status"
//                     name="status"
//                   >
//                     {statusFilters.map((statusFilter, index) => {
//                       return (
//                         <MenuItem key={index} value={statusFilter.value}>
//                           {statusFilter.option}
//                         </MenuItem>
//                       );
//                     })}
//                   </TextField>
//                 </ThemeProvider>
//               </Col>
//             </Row>
//             <Row style={{ paddingTop: 30 }}>
//               <Col style={{ marginBottom: "20px", marginLeft: "10px" }}>
//                 <h6>{"Primary image of the product "}</h6>
//                 <Uploader
//                   {...props}
//                   imge={
//                     props.ProductDetailsData &&
//                     props.ProductDetailsData.data &&
//                     props.ProductDetailsData.data.salesproducts &&
//                     props.ProductDetailsData.data.salesproducts.image &&
//                     props.ProductDetailsData.data.salesproducts.image.primary
//                   }
//                 />
//               </Col>
//             </Row>
//             <Row style={{ paddingTop: 30 }}>
//               <Col style={{ marginBottom: "20px", marginLeft: "10px" }}>
//                 <h6>{"Secondary image of the product "}</h6>
//                 <MultipleUploader {...props} product={props.ProductDetailsData} />
//               </Col>
//             </Row>

//             <Row>
//               <h6
//                 style={{
//                   marginLeft: "10px",
//                   paddingBottom: "20px",
//                   paddingTop: "20px",
//                 }}
//               >
//                 <b>
//                   {"Select the hierarchy in which the product should display"}
//                 </b>
//               </h6>
//             </Row>
//             <Row>
//               {
//                 props.DepartmentList &&
//                 props.DepartmentList.data &&
//                 props.DepartmentList.data.salesdepartments &&
//                 props.DepartmentList.data.salesdepartments.length !== 0 && (
//                   <>
//                     <Col md={6}>

//                       <ThemeProvider theme={theme}>
//                         <TextField
//                           select
//                           variant="outlined"
//                           required
//                           style={{
//                             width: "100%",
//                             marginLeft: "12px",
//                           }}
//                           InputProps={{
//                             style: { height: 35, width: "100%" },
//                             autoComplete: "off",
//                           }}
//                           SelectProps={{ style: { width: "auto", height: 35 }, }}
//                           label="Department"
//                           value={(state.salesdepartment && state.salesdepartment) || ""}
//                           name="salesdepartment"
//                           onChange={(e) => {
//                             setState({
//                               ...state,
//                               salesdepartment: e.target.value,
//                               salescategory: null,
//                               salescategory1: null,
//                               salescategory2: null,
//                             });
//                             getCategoryList(e.target.value);
//                           }}
//                           InputLabelProps={{
//                             shrink: true,
//                           }}
//                         >
//                           {props.DepartmentList &&
//                             props.DepartmentList.data &&
//                             props.DepartmentList.data.salesdepartments &&
//                             props.DepartmentList.data.salesdepartments.map(
//                               (s) => (
//                                 <MenuItem

//                                   key={s.id}
//                                   value={s.id}
//                                 >
//                                   {s.name}
//                                 </MenuItem>
//                               )
//                             )}
//                         </TextField>
//                       </ThemeProvider>
//                     </Col>
//                     {(state.salescategory !== ''&& state.salescategory !== null) ? props.CategoryList &&
//                       props.CategoryList.data &&
//                       props.CategoryList.data.salescategory &&
//                       props.CategoryList.data.salescategory.length !== 0 && (
//                         <Col md={6}>
//                           {console.log("S1")}
//                           <ThemeProvider theme={theme}>
//                             <TextField
//                               required
//                               select
//                               SelectProps={{
//                                 style: { width: "auto", height: 35 },
//                                 value: state.salescategory && state.salescategory
//                               }}
//                               variant="outlined"
//                               style={{
//                                 width: "100%",
//                                 marginLeft: "12px",
//                               }}
//                               InputProps={{
//                                 style: { height: 35, width: "100%" },
//                                 autoComplete: "off",
//                               }}
//                               label="Category"
//                               name="salescategory"
//                               onChange={(e) => {
//                                 handleFormChange(e);
//                                 getCategory1List(e.target.value);
//                               }}
//                               InputLabelProps={{
//                                 shrink: true,
//                               }}
//                             >
//                               {props.CategoryList &&
//                                 props.CategoryList.data.salescategory.map(
//                                   (c) => (
//                                     <MenuItem
//                                       key={c.id}
//                                       value={c.id}
//                                     >
//                                       {c.name}
//                                     </MenuItem>
//                                   )
//                                 )}
//                             </TextField>
//                           </ThemeProvider>
//                         </Col>
//                       )
//                       : props.CategoryList &&
//                       props.CategoryList.data &&
//                       props.CategoryList.data.salescategory &&
//                       props.CategoryList.data.salescategory.length !== 0 && (
//                         <Col md={6}>
//                           <ThemeProvider theme={theme}>
//                             <TextField
//                               required
//                               select
//                               variant="outlined"
//                               SelectProps={{
//                                 style: { width: "auto", height: 35 },
//                                 value: state.salescategory && state.salescategory
//                               }}
//                               style={{
//                                 width: "100%",
//                                 marginLeft: "12px",
//                               }}
//                               InputProps={{
//                                 style: { height: 35, width: "100%" },
//                                 autoComplete: "off",
//                               }}
//                               label="Category"
//                               name="salescategory"
//                               onChange={(e) => {
//                                 handleFormChange(e);
//                                 getCategory1List(e.target.value);
//                               }}
//                               InputLabelProps={{
//                                 shrink: true,
//                               }}
//                             >
//                               {props.CategoryList &&
//                                 props.CategoryList.data.salescategory.map(
//                                   (c) => (
//                                     <MenuItem
//                                       key={c.id}
//                                       value={c.id}
//                                     >
//                                       {c.name}
//                                     </MenuItem>
//                                   )
//                                 )}
//                             </TextField>
//                           </ThemeProvider>
//                         </Col>
//                       )}
//                   </>
//                 )}
//             </Row>

//             {/* SUB CATEGORY SELECT */}

//             <Row style={{ paddingTop: 30 }}>
//               <>
//                 {state.salescategory1 !== ''
//                   ? props.CategoryList &&
//                   props.CategoryList.data.salescategory.length !== 0 &&
//                   props.Category1List &&
//                   props.Category1List.data.salescategory.length !== 0 && (
//                     <>
//                       <Col md={6}>
//                         <ThemeProvider theme={theme}>
//                           <TextField
//                             required
//                             InputLabelProps={{
//                               shrink: true,
//                             }}
//                             select
//                             variant="outlined"
//                             style={{
//                               width: "100%",
//                               marginLeft: "12px",
//                             }}
//                             InputProps={{
//                               style: { height: 35, width: "100%" },
//                               autoComplete: "off",
//                             }}
//                             label="Sub-Category 1"
//                             SelectProps={{
//                               style: { width: "auto", height: 35 },
//                               value: state.salescategory1 && state.salescategory1
//                             }}
//                             name="salescategory1"
//                             onChange={(e) => {
//                               handleFormChange(e);
//                               getCategory2List(e.target.value);
//                             }}
//                           >
//                             {props.CategoryList &&
//                               props.CategoryList.data.salescategory.length !==
//                               0 &&
//                               props.Category1List &&
//                               props.Category1List.data.salescategory.map(
//                                 (c) => (
//                                   <MenuItem
//                                     key={c.id}
//                                     value={c.id}
//                                   >
//                                     {c.name}
//                                   </MenuItem>
//                                 )
//                               )}
//                           </TextField>
//                         </ThemeProvider>
//                       </Col>
//                     </>
//                   )
//                   : state.salescategory !== '' &&
//                   props.CategoryList &&
//                   props.CategoryList.data.salescategory.length !== 0 &&
//                   props.Category1List &&
//                   props.Category1List.data.salescategory.length !== 0 && (
//                     <Col md={6}>
//                       <ThemeProvider theme={theme}>
//                         <TextField
//                           required
//                           SelectProps={{
//                             style: { width: "auto", height: 35 },
//                             value: state.salescategory1 && state.salescategory1
//                           }}
//                           InputLabelProps={{
//                             shrink: true,
//                           }}
//                           select
//                           variant="outlined"
//                           style={{
//                             width: "100%",
//                             marginLeft: "12px",
//                           }}
//                           InputProps={{
//                             style: { height: 35, width: "100%" },
//                             autoComplete: "off",
//                           }}
//                           label="Sub-Category 1"
//                           name="salescategory1"
//                           onChange={(e) => {
//                             handleFormChange(e);
//                             getCategory2List(e.target.value);
//                           }}
//                         >
//                           {props.CategoryList &&
//                             props.CategoryList.data.salescategory.length !==
//                             0 &&
//                             props.Category1List &&
//                             props.Category1List.data.salescategory.map((c) => (
//                               <MenuItem
//                                 key={c.id}
//                                 value={c.id}
//                               >
//                                 {c.name}
//                               </MenuItem>
//                             ))}
//                         </TextField>
//                       </ThemeProvider>
//                     </Col>
//                   )}

//                 {(state.salescategory2 !== '')
//                   ? props.Category2List &&
//                   props.Category2List!==null&&
//                   props.Category2List.data.salescategory.length !== 0 && (
//                     <Col md={6}>
//                       <ThemeProvider theme={theme}>
//                         <TextField
//                           required
//                           InputLabelProps={{
//                             shrink: true,
//                           }}
//                           SelectProps={{
//                             style: { width: "auto", height: 35 },
//                             value: state.salescategory2 && state.salescategory2
//                           }}
//                           select
//                           variant="outlined"
//                           style={{
//                             width: "100%",
//                             marginLeft: "12px",
//                           }}
//                           InputProps={{
//                             style: { height: 35, width: "100%" },
//                             autoComplete: "off",
//                           }}
//                           label="Sub-Category 2"
//                           name="salescategory2"
//                           onChange={(e) => {
//                             handleFormChange(e);
//                           }}
//                         >
//                           {props.Category2List &&
//                           props.Category2List!==null&&
//                             props.Category2List.data.salescategory.map((i) => (
//                               <MenuItem
//                                 key={i.id}
//                                 value={i.id}
//                               >
//                                 {i.name}
//                               </MenuItem>
//                             ))}
//                         </TextField>
//                       </ThemeProvider>
//                     </Col>
//                   )
//                   : (state.salescategory1 !== '' )&&
//                   props.Category2List &&
//                   props.Category2List!==null&&
//                   props.Category2List.data.salescategory.length !== 0 && (
//                     <Col md={6}>
//                       <ThemeProvider theme={theme}>
//                         <TextField
//                           InputLabelProps={{
//                             shrink: true,
//                           }}
//                           required
//                           SelectProps={{
//                             style: { width: "auto", height: 35 },
//                             value: state.salescategory2 && state.salescategory2
//                           }}
//                           select
//                           variant="outlined"
//                           style={{
//                             width: "100%",
//                             marginLeft: "12px",
//                           }}
//                           InputProps={{
//                             style: { height: 35, width: "100%" },
//                             autoComplete: "off",
//                           }}
//                           label="Sub-Category 2"
//                           name="salescategory2"
//                           onChange={(e) => {
//                             handleFormChange(e);
//                           }}
//                         >
//                           {props.Category2List &&
//                           props.Category2List!==null&&
//                             props.Category2List.data.salescategory.map((i) => (
//                               <MenuItem
//                                 value={i.id}
//                               >
//                                 {i.name}
//                               </MenuItem>
//                             ))}
//                         </TextField>
//                       </ThemeProvider>
//                     </Col>
//                   )}
//               </>
//             </Row>

//             <Row style={{ paddingBottom: "30px", paddingTop: 30 }}>
//               <Col sm={12} md={6}>
//                 <button
//                   style={{
//                     height: "37px",
//                     width: "100%",
//                     backgroundColor: "white",
//                     border: "2px solid #75c224",
//                     color: "#75c224",
//                     fontSize: "14px",
//                     fontWeight: 600,
//                     borderRadius: 5,
//                     marginLeft: 12,
//                   }}
//                   variant="outline-success"
//                   onClick={() => {
//                     props.history.push("/home/EditProducts");
//                   }}
//                 >
//                   Back
//                 </button>
//               </Col>
//               <Col sm={12} md={6}>
//                 <button
//                   style={{
//                     justifyContent: " center",
//                     color: "rgb(255, 255, 255)",
//                     backgroundColor: 'rgb(137, 199, 74, 1)',
//                     width: "100%",
//                     height: "37px",
//                     fontSize: "14px",
//                     fontWeight: "600",
//                     borderRadius: "5px",
//                     borderWidth: "1px",
//                     borderStyle: " solid",
//                     borderColor: "transparent",
//                     borderImage: "initial",
//                     overflow: "hidden",
//                     outline: "none",
//                     minHeight: "14px",
//                     fontFamily: "Varela Round, sans-serif",
//                     marginLeft: 12,
//                   }}
//                   type="submit"
//                 >
//                   {props.proupdating === true ? "Updating" : "Update"}
//                 </button>
//               </Col>
//             </Row>
//           </form>
//         </>
//       </Container>
//   );
// };
// export default ProductDetailsCard;

import { React, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import CustomTextField from "../Commoncomponents/input";
import CustomSelect from "../Commoncomponents/selectButton";
import CustomButton from "../Commoncomponents/button";
import { validationSchema } from "./Validation";
import { useFormik } from "formik";
import Uploader from "./Uploader";
import { message, Modal } from "antd";
import MultipleUploader from "./MultipleUploader";
import {
  Box,
  Grid,
  Typography,
  InputAdornment,
  useMediaQuery,
  useTheme,
  Select,
  MenuItem,
  TextField,
  FormControl,
  FormHelperText,
} from "../MUIComponents/index";
import {
  contentFontSize_16,
  ButtonFontSize_18,
  HeaderNavigationFontSize_20,
} from "../Typography/index";
import "./ProductDetails.css";
import { useLocation, useNavigate } from "react-router-dom";
import { from } from "@apollo/client";
import { useDispatch } from "react-redux";

function ProductDetailsCard(props) {
  const [storeid, setStoreId] = useState(localStorage.getItem("storeid"));
  const nav = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const details = location.state.details || {};
  const [selectedOption, setSelectedOption] = useState("");
  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    props.getDepartmentList(localStorage.getItem("storeid"));
  }, [props.getDepartmentList]);

  useEffect(() => {
    const id = details.Productid;
    props.getProductDetails(localStorage.getItem("Productid"));
  }, []);

  useEffect(() => {}, [props.ProductDetailsData]);

  useEffect(() => {
    setState({
      salesdepartment: details.salesdepartment,
    });
  }, []);
  useEffect(() => {
    setState({
      salescategory: details.salescategory,
    });
  }, []);

  useEffect(() => {
    if (props.productUpdateFailed) {
      message.error({
        content: `${props.ProductUpadteError}`,
        className: "custom-class",
        style: {
          marginTop: "10vh",
          marginLeft: "15vw",
        },
        duration: 3.5,
      });
      setTimeout(() => {
        formik.resetForm();
      }, 3500);
    } else if (props.productUpdate) {
      message.success({
        content: "Product Updated successfully",
        className: "custom-class",
        style: {
          marginTop: "10vh",
          marginLeft: "15vw",
        },
        duration: 3.5,
      });
      setTimeout(() => {
        nav("/home/AddProducts");
      }, 4000);
    }
  }, [props.productUpdateFailed, props.productUpdate]);

  useEffect(() => {
    dispatch({ type: "CLEAR_UPDATE_PRODUCT" });
  }, []);

  useEffect(
    () => {
      props.getProductDetails(details.Productid);
      props.getDepartmentList();
      formik.setFieldValue("uom", details.uom);
      setState({
        productid: details.Productid,
        displayrank:
          props.ProductDetailsData &&
          props.ProductDetailsData.data &&
          props.ProductDetailsData.data.salesproducts &&
          props.ProductDetailsData.data.salesproducts.displayrank,
        salesdepartment: details.salesdepartment,
        salescategory: details.salescategory,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.getProductDetails, props.getDepartmentList]
  );

  //
  useEffect(
    () => {
      props.getDepartmentList();
      formik.setFieldValue("uom", details.uom);
      formik.setFieldValue(
        "uom",
        props.ProductDetailsData &&
          props.ProductDetailsData.data &&
          props.ProductDetailsData.data.salesproducts &&
          props.ProductDetailsData.data.salesproducts.uom
      );
      if (
        props.ProductDetailsData &&
        props.ProductDetailsData.data &&
        props.ProductDetailsData.data.salesproducts &&
        props.ProductDetailsData.data.salesproducts.salesdepartment &&
        props.ProductDetailsData.data.salesproducts.salesdepartment.id &&
        props.ProductDetailsData.data.salesproducts.salesdepartment.id
      ) {
        setState({
          salesdepartment: details.salesdepartment,
        });
        props.getCategoryList(
          props.ProductDetailsData &&
            props.ProductDetailsData.data &&
            props.ProductDetailsData.data.salesproducts &&
            props.ProductDetailsData.data.salesproducts.salesdepartment &&
            props.ProductDetailsData.data.salesproducts.salesdepartment.id &&
            props.ProductDetailsData.data.salesproducts.salesdepartment.id
        );
      }
      setState({
        productid: details.Productid,
        displayrank:
          props.ProductDetailsData &&
          props.ProductDetailsData.data &&
          props.ProductDetailsData.data.salesproducts &&
          props.ProductDetailsData.data.salesproducts.displayrank,
        salesdepartment:
          props.ProductDetailsData &&
          props.ProductDetailsData.data &&
          props.ProductDetailsData.data.salesproducts &&
          props.ProductDetailsData.data.salesproducts.salesdepartment &&
          props.ProductDetailsData.data.salesproducts.salesdepartment.id &&
          props.ProductDetailsData.data.salesproducts.salesdepartment.id,
        salescategory:
          props.ProductDetailsData &&
          props.ProductDetailsData.data &&
          props.ProductDetailsData.data.salesproducts &&
          props.ProductDetailsData.data.salesproducts.salescategory &&
          props.ProductDetailsData.data.salesproducts.salescategory.id &&
          props.ProductDetailsData.data.salesproducts.salescategory.id === null
            ? ""
            : props.ProductDetailsData &&
              props.ProductDetailsData.data &&
              props.ProductDetailsData.data.salesproducts &&
              props.ProductDetailsData.data.salesproducts.salescategory &&
              props.ProductDetailsData.data.salesproducts.salescategory.id &&
              props.ProductDetailsData.data.salesproducts.salescategory.id,
        salescategory1:
          props.ProductDetailsData &&
          props.ProductDetailsData.data &&
          props.ProductDetailsData.data.salesproducts &&
          props.ProductDetailsData.data.salesproducts.salescategory1 &&
          props.ProductDetailsData.data.salesproducts.salescategory1.id &&
          props.ProductDetailsData.data.salesproducts.salescategory1.id === null
            ? ""
            : props.ProductDetailsData &&
              props.ProductDetailsData.data &&
              props.ProductDetailsData.data.salesproducts &&
              props.ProductDetailsData.data.salesproducts.salescategory1 &&
              props.ProductDetailsData.data.salesproducts.salescategory1.id &&
              props.ProductDetailsData.data.salesproducts.salescategory1.id,
        salescategory2:
          props.ProductDetailsData &&
          props.ProductDetailsData.data &&
          props.ProductDetailsData.data.salesproducts &&
          props.ProductDetailsData.data.salesproducts.salescategory2 &&
          props.ProductDetailsData.data.salesproducts.salescategory2.id &&
          props.ProductDetailsData.data.salesproducts.salescategory2.id === null
            ? ""
            : props.ProductDetailsData &&
              props.ProductDetailsData.data &&
              props.ProductDetailsData.data.salesproducts &&
              props.ProductDetailsData.data.salesproducts.salescategory2 &&
              props.ProductDetailsData.data.salesproducts.salescategory2.id &&
              props.ProductDetailsData.data.salesproducts.salescategory2.id,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.ProductDetailsData]
  );

  const [state, setState] = useState({
    productid: localStorage.getItem("Productid"),
    displayrank:
      props.ProductDetailsData &&
      props.ProductDetailsData.data &&
      props.ProductDetailsData.data.salesproducts &&
      props.ProductDetailsData.data.salesproducts.displayrank,
    salesdepartment: details.salesdepartment,
    salescategory: details.salescategory,
  });
  const handleFormChange = (e, name) => {
    if (e.target.name === "department") {
      setState({
        ...state,
        [e.target.name]: e.target.value,
        salescategory: null,
      });
    } else if (e.target.name === "salescategory") {
      setState({
        ...state,
        [e.target.name]: e.target.value,
      });
    } else {
      setState({
        ...state,
        [e.target.name]: e.target.value,
      });
    }
  };

  const theme = useTheme();
  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [isUnitOpen, setIsUnitOpen] = useState(false);
  const [isFoodtypeOpen, setIsFoodtypeOpen] = useState(false);
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);

  const handleScroll = () => {
    setIsUnitOpen(false);
    setIsFoodtypeOpen(false);
    setIsCategoryOpen(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      productname:
        props.ProductDetailsData &&
        props.ProductDetailsData.data &&
        props.ProductDetailsData.data.salesproducts &&
        props.ProductDetailsData.data.salesproducts.productname === null
          ? ""
          : props.ProductDetailsData &&
            props.ProductDetailsData.data &&
            props.ProductDetailsData.data.salesproducts &&
            props.ProductDetailsData.data.salesproducts.productname,

      desc:
        props.ProductDetailsData &&
        props.ProductDetailsData.data &&
        props.ProductDetailsData.data.salesproducts &&
        props.ProductDetailsData.data.salesproducts.desc === null
          ? ""
          : props.ProductDetailsData &&
            props.ProductDetailsData.data &&
            props.ProductDetailsData.data.salesproducts &&
            props.ProductDetailsData.data.salesproducts.desc,

      mfgname:
        props.ProductDetailsData &&
        props.ProductDetailsData.data &&
        props.ProductDetailsData.data.salesproducts &&
        props.ProductDetailsData.data.salesproducts.mfgname === null
          ? ""
          : props.ProductDetailsData &&
            props.ProductDetailsData.data &&
            props.ProductDetailsData.data.salesproducts &&
            props.ProductDetailsData.data.salesproducts.mfgname,

      brand:
        (props.ProductDetailsData &&
          props.ProductDetailsData.data &&
          props.ProductDetailsData.data.salesproducts &&
          props.ProductDetailsData.data.salesproducts.brand) === null
          ? ""
          : props.ProductDetailsData &&
            props.ProductDetailsData.data &&
            props.ProductDetailsData.data.salesproducts &&
            props.ProductDetailsData.data.salesproducts.brand,

      price:
        props.ProductDetailsData &&
        props.ProductDetailsData.data &&
        props.ProductDetailsData.data.salesproducts &&
        props.ProductDetailsData.data.salesproducts.price === null
          ? ""
          : props.ProductDetailsData &&
            props.ProductDetailsData.data &&
            props.ProductDetailsData.data.salesproducts &&
            props.ProductDetailsData.data.salesproducts.price,

      barcode:
        (props.ProductDetailsData &&
          props.ProductDetailsData.data &&
          props.ProductDetailsData.data.salesproducts &&
          props.ProductDetailsData.data.salesproducts.barcode) === null
          ? ""
          : props.ProductDetailsData &&
            props.ProductDetailsData.data &&
            props.ProductDetailsData.data.salesproducts &&
            props.ProductDetailsData.data.salesproducts.barcode,

      promoprice:
        (props.ProductDetailsData &&
          props.ProductDetailsData.data &&
          props.ProductDetailsData.data.salesproducts &&
          props.ProductDetailsData.data.salesproducts.promoprice) === null
          ? ""
          : props.ProductDetailsData &&
            props.ProductDetailsData.data &&
            props.ProductDetailsData.data.salesproducts &&
            props.ProductDetailsData.data.salesproducts.promoprice,

      uom:
        (props.ProductDetailsData &&
          props.ProductDetailsData.data &&
          props.ProductDetailsData.data.salesproducts &&
          props.ProductDetailsData.data.salesproducts.uom) === null
          ? ""
          : props.ProductDetailsData &&
            props.ProductDetailsData.data &&
            props.ProductDetailsData.data.salesproducts &&
            props.ProductDetailsData.data.salesproducts.uom,

      status:
        props.ProductDetailsData &&
        props.ProductDetailsData.data &&
        props.ProductDetailsData.data.salesproducts &&
        props.ProductDetailsData.data.salesproducts.status,

      quantity:
        (props.ProductDetailsData &&
          props.ProductDetailsData.data &&
          props.ProductDetailsData.data.salesproducts &&
          props.ProductDetailsData.data.salesproducts.quantity) === null
          ? ""
          : props.ProductDetailsData &&
            props.ProductDetailsData.data &&
            props.ProductDetailsData.data.salesproducts &&
            props.ProductDetailsData.data.salesproducts.quantity,

      displayrank:
        props.ProductDetailsData &&
        props.ProductDetailsData.data &&
        props.ProductDetailsData.data.salesproducts &&
        props.ProductDetailsData.data.salesproducts.displayrank === null
          ? ""
          : props.ProductDetailsData &&
            props.ProductDetailsData.data &&
            props.ProductDetailsData.data.salesproducts &&
            props.ProductDetailsData.data.salesproducts.displayrank,
            foodType: {
              id: (props.ProductDetailsData &&
                props.ProductDetailsData.data &&
                props.ProductDetailsData.data.salesproducts &&
                props.ProductDetailsData.data.salesproducts.foodType &&
                props.ProductDetailsData.data.salesproducts.foodType.id) || 0,
              value: (props.ProductDetailsData &&
                props.ProductDetailsData.data &&
                props.ProductDetailsData.data.salesproducts &&
                props.ProductDetailsData.data.salesproducts.foodType &&
                props.ProductDetailsData.data.salesproducts.foodType.value) || "Veg",
            },
    },
    validationSchema: validationSchema,

    onReset: (values,initialValues) => {
      formik.setValues({
        productname: "",
        desc: "",
        barcode: "",
        price: "",
        promoPrice: "",
        brand: "",
        mfgname: "",
        quantity: "",
        unit: "",
        displayrank: "",
        status: "",
        foodType: { id: 0, value: "Veg" }
      });
    },

    onSubmit: (values,initialValues) => {
      console.log("initial..",initialValues)
      if (props.fileListLogo === true && props.proImage === null) {
        Modal.confirm({
          title: "Confirm",
          // icon: <ExclamationCircleOutlined />,
          content:
            "Please upload the image or delete the image before Submiting.",
          okText: "Ok",
        });
      } else if (props.newMultiFile) {
        let multi = [],
          i = 0;
        let department = state.salesdepartment;
        let category = state.salescategory;
        let subcategory = state.salescategory1;
        let category2 = state.salescategory2;
        let productid = state.productid;
        let thumbnail = props.newthumbnailUrl
          ? props.newthumbnailUrl.imageEditAndUpload.Location
          : props.ProductDetailsData &&
            props.ProductDetailsData.data &&
            props.ProductDetailsData.data.salesproducts &&
            props.ProductDetailsData.data.salesproducts.image &&
            props.ProductDetailsData.data.salesproducts &&
            props.ProductDetailsData.data.salesproducts.image.thumbnail !== null
          ? props.ProductDetailsData &&
            props.ProductDetailsData.data &&
            props.ProductDetailsData.data.salesproducts &&
            props.ProductDetailsData.data.salesproducts.image &&
            props.ProductDetailsData.data.salesproducts &&
            props.ProductDetailsData.data.salesproducts.image.thumbnail
          : null;

        let primary = localStorage.getItem("updateUrl")
          ? localStorage.getItem("updateUrl")
          : props.ProductDetailsData &&
            props.ProductDetailsData.data &&
            props.ProductDetailsData.data.salesproducts &&
            props.ProductDetailsData.data.salesproducts.image &&
            props.ProductDetailsData.data.salesproducts &&
            props.ProductDetailsData.data.salesproducts.image.primary;
        props.newMultiFile &&
          props.newMultiFile.params.map((s) => {
            if (i <= 4) {
              if (s.url) {
                multi[i] = s.url;
                i++;
              }
            }
            return multi;
          });
        if (values.promoprice === "") {
          props.UpdateProduct(
            values,
            department,
            category,
            subcategory,
            category2,
            productid,
            thumbnail,
            multi,
            primary
          );
        } else {
          //
          if (Number(values.promoprice) >= Number(values.price)) {
            document.body.scrollTop = 0;
            formik.setFieldError(
              "promoprice",
              "Promoprice must be lesser than price."
            );
            message.error({
              content: "Promoprice must be lesser than price",
              className: "custom-class",
              style: {
                marginTop: "20vh",
              },
            });
          } else {
            props.UpdateProduct(
              values,
              department,
              category,
              subcategory,
              category2,
              productid,
              thumbnail,
              multi,
              primary
            );
          }
        }
      } else if (localStorage.getItem("updateUrl")) {
        let thumbnail = props.newthumbnailUrl
          ? props.newthumbnailUrl.imageEditAndUpload.Location
          : props.ProductDetailsData &&
            props.ProductDetailsData.data &&
            props.ProductDetailsData.data.salesproducts &&
            props.ProductDetailsData.data.salesproducts.image &&
            props.ProductDetailsData.data.salesproducts &&
            props.ProductDetailsData.data.salesproducts.image.thumbnail !== null
          ? props.ProductDetailsData &&
            props.ProductDetailsData.data &&
            props.ProductDetailsData.data.salesproducts &&
            props.ProductDetailsData.data.salesproducts.image &&
            props.ProductDetailsData.data.salesproducts &&
            props.ProductDetailsData.data.salesproducts.image.thumbnail
          : null;

        let primary = localStorage.getItem("updateUrl")
          ? localStorage.getItem("updateUrl")
          : props.ProductDetailsData &&
            props.ProductDetailsData.data &&
            props.ProductDetailsData.data.salesproducts &&
            props.ProductDetailsData.data.salesproducts.image &&
            props.ProductDetailsData.data.salesproducts &&
            props.ProductDetailsData.data.salesproducts.image.primary;
        let multi = [],
          i = 0;
        if (props.newMultiFile) {
          props.newMultiFile.params.map((m) => {
            multi[i] = m.url;
            i++;
            return multi;
          });
          props.newMultiFile &&
            props.newMultiFile.params.map((s) => {
              if (i <= 4) {
                if (s.url) {
                  multi[i] = s.url;
                  i++;
                }
              }
              return multi;
            });
        } else {
          props.ProductDetailsData &&
            props.ProductDetailsData.data &&
            props.ProductDetailsData.data.salesproducts &&
            props.ProductDetailsData.data.salesproducts.image &&
            props.ProductDetailsData.data.salesproducts.image.secondary !==
              null &&
            props.ProductDetailsData.data.salesproducts.image.secondary.map(
              (s) => {
                multi[i] = s;
                i++;
                return multi;
              }
            );
        }

        let department = state.salesdepartment;
        let category = state.salescategory;
        let subcategory = state.salescategory1;
        let category2 = state.salescategory2;
        let productid = state.productid;
        if (values.promoprice === "") {
          props.UpdateProduct(
            values,
            department,
            category,
            subcategory,
            category2,
            productid,
            thumbnail,
            multi,
            primary
          );
        } else {
          if (Number(values.promoprice) >= Number(values.price)) {
            document.body.scrollTop = 0;
            formik.setFieldError(
              "promoprice",
              "Promoprice must be lesser than price."
            );
            message.error({
              content: "Promoprice must be lesser than price",
              className: "custom-class",
              style: {
                marginTop: "20vh",
              },
            });
          } else {
            props.UpdateProduct(
              values,
              department,
              category,
              subcategory,
              category2,
              productid,
              thumbnail,
              multi,
              primary
            );
          }
        }
      } else {
        if (props.multiFileEdited === true) {
          let thumbnail = props.newthumbnailUrl
            ? props.newthumbnailUrl.imageEditAndUpload.Location
            : props.ProductDetailsData &&
              props.ProductDetailsData.data &&
              props.ProductDetailsData.data.salesproducts &&
              props.ProductDetailsData.data.salesproducts.image &&
              props.ProductDetailsData.data.salesproducts &&
              props.ProductDetailsData.data.salesproducts.image.thumbnail !==
                null
            ? props.ProductDetailsData &&
              props.ProductDetailsData.data &&
              props.ProductDetailsData.data.salesproducts &&
              props.ProductDetailsData.data.salesproducts.image &&
              props.ProductDetailsData.data.salesproducts &&
              props.ProductDetailsData.data.salesproducts.image.thumbnail
            : null;

          let primary = localStorage.getItem("updateUrl")
            ? localStorage.getItem("updateUrl")
            : props.ProductDetailsData &&
              props.ProductDetailsData.data &&
              props.ProductDetailsData.data.salesproducts &&
              props.ProductDetailsData.data.salesproducts.image &&
              props.ProductDetailsData.data.salesproducts &&
              props.ProductDetailsData.data.salesproducts.image.primary;

          let multi = [],
            i = 0;
          if (props.multiEditImage) {
            if (props.multiEditImage.uploadMultipleFiles.length < 4) {
              props.multiEditImage.uploadMultipleFiles.map((m) => {
                multi[i] = m.Location;

                i++;

                return multi;
              });

              props.newMultiFile &&
                props.newMultiFile.params.map((s) => {
                  if (i <= 4) {
                    if (s.url) {
                      multi[i] = s.url;

                      i++;
                    }
                  }

                  return multi;
                });
            } else {
              props.multiEditImage.uploadMultipleFiles.map((m) => {
                multi[i] = m.Location;

                i++;

                return multi;
              });
            }
          } else {
            props.ProductDetailsData &&
              props.ProductDetailsData.data &&
              props.ProductDetailsData.data.salesproducts &&
              props.ProductDetailsData.data.salesproducts.image &&
              props.ProductDetailsData.data.salesproducts.image.secondary !==
                null &&
              props.ProductDetailsData.data.salesproducts.image.secondary.map(
                (s) => {
                  multi[i] = s;

                  i++;

                  return multi;
                }
              );
          }

          let department = state.salesdepartment;

          let category = state.salescategory;

          let subcategory = state.salescategory1;

          let category2 = state.salescategory2;

          let productid = state.productid;
          if (values.promoprice === "") {
            props.UpdateProduct(
              values,
              department,
              category,
              subcategory,
              category2,
              productid,
              thumbnail,
              multi,
              primary
            );
          } else {
            if (Number(values.promoprice) >= Number(values.price)) {
              document.body.scrollTop = 0;
              formik.setFieldError(
                "promoprice",
                "Promoprice must be lesser than price."
              );
              message.error({
                content: "Promoprice must be lesser than price",
                className: "custom-class",
                style: {
                  marginTop: "20vh",
                },
              });
            } else {
              props.UpdateProduct(
                values,
                department,
                category,
                subcategory,
                category2,
                productid,
                thumbnail,
                multi,
                primary
              );
            }
          }
        } else {
          let department = state.salesdepartment;
          let category = state.salescategory;
          let subcategory = state.salescategory1;
          let category2 = state.salescategory2;
          let productid = state.productid;
          if (values.promoprice === "") {
            props.ProductUpload(
              values,
              department,
              category,
              subcategory,
              category2,
              productid
            );
          } else {
            if (Number(values.promoprice) >= Number(values.price)) {
              document.body.scrollTop = 0;
              formik.setFieldError(
                "promoprice",
                "Promoprice must be lesser than price."
              );
              message.error({
                content: "Promoprice must be lesser than price",
                className: "custom-class",
                style: {
                  marginTop: "20vh",
                },
              });
            } else {
              props.ProductUpload(
                values,
                department,
                category,
                subcategory,
                category2,
                productid
              );
            }
          }
        }
      }
      setTimeout(() => {
        dispatch({ type: "CLEAR_UPDATE_PRODUCT" });
      }, 3000);
    },
  });

  useEffect(() => {
    if (!formik.isSubmitting) return;
    if (Object.keys(formik.errors).length > 0) {
      document.getElementsByName(Object.keys(formik.errors)[0])[0].focus();
    }
  }, [formik]);

  const foodTypes = [
    { id: 1, value: "Veg" },
    { id: 2, value: "Non Veg" },
    { id: 3, value: "Egg" },
    { id: 4, value: "Gluten-free" },
    { id: 5, value: "Dairy-free" },
    { id: 6, value: "Halal" },
    { id: 7, value: "Kosher" },
    { id: 8, value: "Organic" },
    { id: 9, value: "Low-carb" },
    { id: 10, value: "Low-fat" },
    { id: 11, value: "Sugar-free" },
    { id: 12, value: "Paleo" },
  ];

  const getCategoryList = (id) => {
    props.getCategoryList(id);
    props.clearCategories();
  };
  const getCategory1List = (id) => {
    props.getCategory1List(id);
  };

  const optionsFoodType = [];

  const optionsmeasurent = [
    { value: "No.s", label: "No.s" },
    { value: "kg", label: "Kilogram (kg)" },
    { value: "g", label: "gram (g)" },
    { value: "gal", label: "gallon (gal)" },
    { value: "l", label: "liter (l)" },
    { value: "ml", label: "milliliter (ml)" },
  ];

  const handleClose = () => {
    nav("/home/AddProducts/");
  };

  return (
    <>
      <div className="product">
        <div style={{ display: "flex" }}>
          <Typography
            style={{ cursor: "pointer" }}
            onClick={handleClose}
            className="header-font"
            fontSize={HeaderNavigationFontSize_20}
          >
            Product
          </Typography>
          <Typography
            className="header-font"
            fontSize={HeaderNavigationFontSize_20}
          >
            /
          </Typography>
          <Typography
            className="header-font"
            fontSize={HeaderNavigationFontSize_20}
            style={{ color: "#85BE49" }}
          >
            Edit Product
          </Typography>
        </div>
      </div>
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <div style={{ paddingTop: "30px", boxSizing: "border-box" }}>
          <div className="product-details">
            <div style={{ display: "flex", gap: "3px" }}>
              <Typography className="text-style" fontSize={contentFontSize_16}>
                Product Name
              </Typography>
              <span className="red-start">*</span>
            </div>
            <TextField
              sx={{
                width: "100%",

                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#85BE49",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#85BE49",
                  },
                },
                "& .MuiInputBase-input": {
                  backgroundColor: "white",
                  borderRadius: "10px",
                },
              }}
              // sx={{background:"white"}}
              size={matches ? "small" : "normal"}
              name="productname"
              match={matches ? true : false}
              value={
                (formik.values.productname && formik.values.productname) || ""
              }
              onChange={formik.handleChange}
              error={
                formik.touched.productname && Boolean(formik.errors.productname)
              }
              helperText={
                formik.touched.productname && formik.errors.productname
              }
            />
            <div style={{ display: "flex", gap: "3px" }}>
              <Typography className="text-style" fontSize={contentFontSize_16}>
                Product Description
                <span className="red-start">*</span>
              </Typography>
            </div>
            <TextField
              InputProps={{
                sx: {
                  fontSize: matches && "13px",
                  background: "white",
                },
              }}
              sx={{
                width: "100%",

                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#85BE49",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#85BE49",
                  },
                },
                "& .MuiInputBase-input": {
                  backgroundColor: "white",
                  borderRadius: "10px",
                },
              }}
              name="desc"
              value={(formik.values.desc && formik.values.desc) || ""}
              onChange={formik.handleChange}
              error={formik.touched.desc && Boolean(formik.errors.desc)}
              helperText={formik.touched.desc && formik.errors.desc}
              multiline={true}
              rows="2"
              background="white"
              size={matches ? "small" : "normal"}
              match={matches ? true : false}
            />
            <Typography className="text-style" fontSize={contentFontSize_16}>
              Barcode Number
            </Typography>
            <TextField
              sx={{
                width: "100%",

                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#85BE49",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#85BE49",
                  },
                },
                "& .MuiInputBase-input": {
                  backgroundColor: "white",
                  borderRadius: "10px",
                },
              }}
              name="barcode"
              helperText={formik.touched.barcode && formik.errors.barcode}
              error={formik.touched.barcode && Boolean(formik.errors.barcode)}
              value={(formik.values.barcode && formik.values.barcode) || ""}
              onChange={formik.handleChange}
              background="white"
              size={matches ? "small" : "normal"}
              match={matches ? true : false}
            />
          </div>
          <Grid>
            <Box className="text-style">
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={6} sx={{ paddingTop: 0 }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                      paddingTop: "0px",
                    }}
                  >
                    <div
                      style={{ marginTop: "30px", display: "flex", gap: "3px" }}
                      className="text-style"
                    >
                      <Typography
                        className="text-style"
                        fontSize={contentFontSize_16}
                      >
                        Product Price
                        <span className="red-start">*</span>
                      </Typography>
                    </div>
                    <div>
                      <TextField
                        sx={{
                          width: "100%",

                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#85BE49",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#85BE49",
                            },
                          },
                          "& .MuiInputBase-input": {
                            backgroundColor: "white",
                            borderRadius: "10px",
                          },
                        }}
                        // sx={{background:"white",width:"100%"}}
                        error={
                          formik.touched.price && Boolean(formik.errors.price)
                        }
                        name="price"
                        onChange={formik.handleChange}
                        helperText={formik.touched.price && formik.errors.price}
                        value={formik.values.price || " "}
                        width="100%"
                        background="white"
                        size={matches ? "small" : "normal"}
                        match={matches ? true : false}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                    }}
                  >
                    <div
                      style={{ marginTop: "30px", display: "flex", gap: "3px" }}
                    >
                      <Typography
                        className="text-style"
                        fontSize={contentFontSize_16}
                      >
                        Food type
                        {/* <span className="red-start">*</span> */}
                      </Typography>
                    </div>
                    <div>
                      <Select
                        name="foodType"
                        className="custom-select_1"
                        open={isFoodtypeOpen}
                        onClose={() => setIsFoodtypeOpen(false)}
                        onOpen={() => setIsFoodtypeOpen(true)}
                        value={formik.values.foodType.value}
                        onChange={(e) => {
                          const selectedValue = e.target.value;
                          const selectedFoodType = foodTypes.find(
                            (item) => item.value === selectedValue
                          );
                          formik.setFieldValue("foodType", selectedFoodType);
                        }}
                        sx={{
                          width: "100%",
                          buttonColor: "success",
                          variant: "outlined",
                          background: "white",
                          borderRadius: "10px",
                        }}
                      >
                        {foodTypes.map((item) => (
                          <MenuItem key={item.id} value={item.value}>
                            {item.value}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                    }}
                  >
                    <div
                      style={{ marginTop: "30px", display: "flex", gap: "3px" }}
                      className="text-style"
                    >
                      <Typography
                        className="text-style"
                        fontSize={contentFontSize_16}
                      >
                        Brand Name
                        {/* <span className="red-start">*</span> */}
                      </Typography>
                    </div>
                    <div>
                      <TextField
                        sx={{
                          width: "100%",

                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#85BE49",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#85BE49",
                            },
                          },
                          "& .MuiInputBase-input": {
                            backgroundColor: "white",
                            borderRadius: "10px",
                          },
                        }}
                        // sx={{background:"white",width:"100%"}}
                        name="brand"
                        value={formik.values.brand || " "}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.brand && Boolean(formik.errors.brand)
                        }
                        helperText={formik.touched.brand && formik.errors.brand}
                        width="100%"
                        background="white"
                        size={matches ? "small" : "normal"}
                        match={matches ? true : false}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                    }}
                  >
                    <div
                      style={{ marginTop: "30px", display: "flex", gap: "3px" }}
                      className="text-style"
                    >
                      <Typography
                        className="text-style"
                        fontSize={contentFontSize_16}
                      >
                        Add Category
                        <span className="red-start">*</span>
                      </Typography>
                    </div>
                    {props.DepartmentList &&
                      props.DepartmentList.data &&
                      props.DepartmentList.data.salesdepartments &&
                      props.DepartmentList.data.salesdepartments.length !==
                        0 && (
                        <div>
                          <Select
                            className="custom-select_1"
                            open={isCategoryOpen}
                            onClose={() => setIsCategoryOpen(false)}
                            onOpen={() => setIsCategoryOpen(true)}
                            width="100%"
                            name="salesdepartment"
                            buttonColor="success"
                            variant="outlined"
                            background="white"
                            borderRadius="10px"
                            match={matches ? true : false}
                            size={matches ? "small" : "normal"}
                            value={
                              (state.salesdepartment &&
                                state.salesdepartment) ||
                              ""
                            }
                            onChange={(e) => {
                              setState({
                                ...state,
                                salesdepartment: e.target.value,
                                salescategory: null,
                                salescategory1: null,
                                salescategory2: null,
                              });
                              getCategoryList(e.target.value);
                            }}
                            sx={{
                              width: "100%",
                              buttonColor: "success",
                              variant: "outlined",
                              background: "white",
                              borderRadius: "10px",
                            }}
                          >
                            {props.DepartmentList &&
                              props.DepartmentList.data &&
                              props.DepartmentList.data.salesdepartments &&
                              props.DepartmentList.data.salesdepartments.map(
                                (s) => (
                                  <MenuItem
                                    style={{ width: "fit-content" }}
                                    key={s.id}
                                    value={s.id}
                                  >
                                    {s.name}
                                  </MenuItem>
                                )
                              )}
                          </Select>
                        </div>
                      )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                    }}
                  >
                    <div
                      style={{ marginTop: "30px", display: "flex", gap: "3px" }}
                      className="text-style"
                    >
                      <Typography
                        className="text-style"
                        fontSize={contentFontSize_16}
                      >
                        Promo Price
                      </Typography>
                    </div>
                    <div>
                      <TextField
                        sx={{
                          width: "100%",

                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#85BE49",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#85BE49",
                            },
                          },
                          "& .MuiInputBase-input": {
                            backgroundColor: "white",
                            borderRadius: "10px",
                          },
                        }}
                        // sx={{background:"white",width:"100%"}}
                        name="promoprice"
                        width="100%"
                        background="white"
                        size={matches ? "small" : "normal"}
                        match={matches ? true : false}
                        value={formik.values.promoprice || " "}
                        error={
                          formik.touched.promoprice &&
                          Boolean(formik.errors.promoprice)
                        }
                        helperText={
                          formik.touched.promoprice && formik.errors.promoprice
                        }
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                    }}
                  >
                    <div
                      style={{ marginTop: "30px", display: "flex", gap: "3px" }}
                      className="text-style"
                    >
                      <Typography
                        className="text-style"
                        fontSize={contentFontSize_16}
                      >
                        Unit of Measurement
                        <span className="red-start">*</span>
                      </Typography>
                    </div>

                    <div style={{ display: "flex", gap: "10px" }}>
                      <TextField
                        sx={{
                          width: "100%",

                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#85BE49",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#85BE49",
                            },
                          },
                          "& .MuiInputBase-input": {
                            backgroundColor: "white",
                            borderRadius: "10px",
                          },
                        }}
                        // sx={{background:"white",width:"100%"}}
                        width="100%"
                        label="quantity"
                        background="white"
                        placeholder="10"
                        size={matches ? "small" : "normal"}
                        match={matches ? true : false}
                        name="quantity"
                        value={formik.values.quantity || " "}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.quantity &&
                          Boolean(formik.errors.quantity)
                        }
                        helperText={
                          formik.touched.quantity && formik.errors.quantity
                        }
                      />
                      <FormControl sx={{ width: "100%" }}>
                        <Select
                          className="custom-select_1"
                          open={isUnitOpen}
                          onClose={() => setIsUnitOpen(false)}
                          onOpen={() => setIsUnitOpen(true)}
                          width="100%"
                          buttonColor="success"
                          variant="outlined"
                          background="white"
                          borderRadius="10px"
                          match={matches ? true : false}
                          size={matches ? "small" : "normal"}
                          name="uom"
                          value={formik.values.uom || ""}
                          SelectProps={{
                            multiple: false,
                            value: formik.values.uom
                              ? formik.values.uom && formik.values.uom
                              : details.uom === null
                              ? ""
                              : details.uom,
                          }}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.unit && Boolean(formik.errors.unit)
                          }
                          helperText={formik.touched.unit && formik.errors.unit}
                          sx={{
                            width: "100%",
                            buttonColor: "success",
                            variant: "outlined",
                            background: "white",
                            borderRadius: "10px",
                          }}
                        >
                          <MenuItem value="" disabled>
                            Select Unit
                          </MenuItem>
                          {optionsmeasurent.map((unit) => (
                            <MenuItem key={unit.value} value={unit.value}>
                              {unit.label}
                            </MenuItem>
                          ))}
                        </Select>
                        {formik.touched.unit && formik.errors.unit ? (
                          <FormHelperText
                            sx={{ color: "red", marginLeft: "10px !important" }}
                          >
                            {formik.touched.unit && formik.errors.unit}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                    }}
                  >
                    <div
                      style={{ marginTop: "30px", display: "flex", gap: "3px" }}
                      className="text-style"
                    >
                      <Typography
                        className="text-style"
                        fontSize={contentFontSize_16}
                      >
                        Manufacture Name
                        {/* <span className="red-start">*</span> */}
                      </Typography>
                    </div>
                    <div>
                      <TextField
                        sx={{
                          width: "100%",

                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#85BE49",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#85BE49",
                            },
                          },
                          "& .MuiInputBase-input": {
                            backgroundColor: "white",
                            borderRadius: "10px",
                          },
                        }}
                        // sx={{background:"white",width:"100%"}}
                        name="mfgname"
                        value={formik.values.mfgname || " "}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.mfgname &&
                          Boolean(formik.errors.mfgname)
                        }
                        helperText={
                          formik.touched.mfgname && formik.errors.mfgname
                        }
                        width="100%"
                        background="white"
                        size={matches ? "small" : "normal"}
                        match={matches ? true : false}
                      />
                    </div>
                  </div>
                  {state.salescategory !== "" &&
                    props.CategoryList &&
                    props.CategoryList.data &&
                    props.CategoryList.data.salescategory &&
                    props.CategoryList.data.salescategory.length !== 0 && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "15px",
                        }}
                      >
                        <div
                          style={{
                            marginTop: "30px",
                            display: "flex",
                            gap: "3px",
                          }}
                          className="text-style"
                        >
                          <Typography
                            className="text-style"
                            fontSize={contentFontSize_16}
                          >
                            Choose Sub Category
                            {/* <span className="red-start">*</span> */}
                          </Typography>
                        </div>

                        <div>
                          <Select
                            className="custom-select_1"
                            name="salescategory"
                            value={
                              (state.salescategory && state.salescategory) || ""
                            }
                            onChange={(e) => {
                              // setState({
                              //   ...state,
                              //   salescategory: e.target.value,
                              //   salescategory1: null,
                              //   salescategory2: null,
                              // });
                              handleFormChange(e);
                            }}
                            // SelectProps={{
                            //   value: state.salescategory && state.salescategory || ''
                            // }}
                            width="100%"
                            buttonColor="success"
                            variant="outlined"
                            background="white"
                            borderRadius="10px"
                            match={matches ? true : false}
                            size={matches ? "small" : "normal"}
                            sx={{
                              width: "100%",
                              variant: "outlined",
                              background: "white",
                              borderRadius: "10px",
                            }}
                          >
                            {props.CategoryList &&
                              props.CategoryList.data.salescategory.map((c) => (
                                <MenuItem
                                  style={{ width: "fit-content" }}
                                  key={c.id}
                                  value={c.id}
                                >
                                  {c.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </div>
                      </div>
                    )}
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <div className="product-details" style={{ marginTop: "20px" }}>
            <div style={{ display: "flex" }}>
              <Typography className="text-style" fontSize={contentFontSize_16}>
                Upload Product Images
              </Typography>
            </div>
            <div>
              <Row>
                <Col style={{ marginBottom: "20px", marginLeft: "10px" }}>
                  <h6>{"Primary image of the product "}</h6>
                  <Uploader
                    {...props}
                    imge={
                      props.ProductDetailsData &&
                      props.ProductDetailsData.data &&
                      props.ProductDetailsData.data.salesproducts &&
                      props.ProductDetailsData.data.salesproducts.image &&
                      props.ProductDetailsData.data.salesproducts.image.primary
                    }
                  />
                </Col>
              </Row>
              <Row style={{ paddingTop: 30 }}>
                <Col style={{ marginBottom: "20px", marginLeft: "10px" }}>
                  <h6>{"Secondary image of the product "}</h6>
                  <MultipleUploader
                    {...props}
                    product={props.ProductDetailsData}
                  />
                </Col>
              </Row>
            </div>
          </div>
          <div style={{ marginTop: "40px", textAlign: "center" }}>
            <CustomButton
              width={
                upXlmatch
                  ? "358px"
                  : xlmatch
                  ? lgmatch
                    ? mdmatch
                      ? smmatch
                        ? xsmatch
                          ? "250px"
                          : "250px"
                        : "310px"
                      : "330px"
                    : "340px"
                  : "358px"
              }
              type="submit"
              borderRadius="20px"
            >
              <Typography
                fontSize={ButtonFontSize_18}
                className="Add-product-btn"
              >
                {" "}
                {props.proupdating === true ? "Updating" : "Update"}
              </Typography>
            </CustomButton>
          </div>
        </div>
      </form>
    </>
  );
}

export default ProductDetailsCard;
