import React, { useEffect, useRef, useState } from "react";
import {
  AdapterDateFns,
  AdapterDayjs,
  Box,
  Breadcrumbs,
  CloseIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  LocalizationProvider,
  Paper,
  Stack,
  TextField,
  TimePicker,
  Typography,
  useMediaQuery,
  useTheme,
} from "../../MUIComponents/index";
import { Link } from "../../MUIComponents/index";
import Direction from "../../../utils/Icon/Direction.svg";
import Pointer from "../../../utils/Icon/Pointer.svg";
import Phone from "../../../utils/Icon/Phone.svg";
import Triangle from "../../../utils/Icon/Triangle.svg";
import Lines from "../../../utils/Icon/Lines.jpg";
import Line2 from "../../../utils/Icon/Line2.svg";
import "./NewOrder.css";
import CustomButton from "../../Commoncomponents/button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useLocation, useNavigate } from "react-router-dom";
import {
  contentFontSize_16,
  contentFontSize_20,
  contentFontsize_14,
  HeaderNavigationFontSize_20_xs_13,
  contentFontSize_18_xs_13,
  contentFontSize_20_xs_18,
  ButtonFontSize_16,
  ButtonFontSize_18,
  contentFontsize_36,
  contentFontsize_24,
  contentFontsize_28,
  contentFontsize_17,
} from "../../Typography";
import { contentFontSize_18 } from "../../Typography";
import CustomCard from "../../Commoncomponents/card";
import CustomDialog from "../../Commoncomponents/dialog";
import { Flex, message } from "antd";
import Loaders from "../Loader/index";
import { useFormik } from "formik";
import * as Yup from "yup";
import { validationSchema } from "./Validation";
function NewOrder(props) {
  const nav = useNavigate();
  const location = useLocation();
  const orderId = location.state?.orderId;
  const orderid = orderId
    ? orderId
    : JSON.parse(localStorage.getItem("id")) || [];
  useEffect(() => {
    props.getSearchDetails(orderid);
  }, []);
  const newOrderDetails = ("props", props && props?.searchDetails);
  const [activeButton, setActiveButton] = useState(false);
  const [active, setActive] = useState("");
  const [state, setState] = useState([]);

  const [loading, setLoading] = useState(false);
  const { ChangetoActiveStatusSuccess, ChangeToActiveLoading } = props;

  const [preparationTime, setpreparationTime] = useState(null);

  const [openTimePicker, setOpenTimePicker] = useState(false);
  const [selectedTime, setSelectedTime] = useState(null);
  const [error, setError] = useState(null);

  function handleButtonOne() {
    setActiveButton("15 min");
    setpreparationTime("15");
    setPick(false);
  }
  function handleButtonTwo() {
    setActiveButton("30 min");
    setpreparationTime("30");
    setPick(false);
  }
  function handleButtonThree() {
    setActiveButton("45 min");
    setpreparationTime("45");
    setPick(false);
  }
  function handleButtonFour() {
    setActiveButton("55 min");
    setpreparationTime("55");
    setPick(false);
  }

  const validationSchema = Yup.object({
    hour: Yup.number()
      .typeError("The hour should be a number")
      .min(0, "Hour must be at least 0")
      .max(23, "Hour must be at most 23")
      .required("Hour is required"),
    minute: Yup.number()
      .typeError("The minute should be a number")
      .min(0, "Minute must be at least 0")
      .max(59, "Minute must be at most 59")
      .required("Minute is required"),
  });

  const [show, setShow] = useState(false);

  const [pick, setPick] = useState(false);

  const formik = useFormik({
    initialValues: {
      hour: "",
      minute: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const totals =
        parseInt(values.hour, 10) * 60 + parseInt(values.minute, 10);
      setpreparationTime(totals);
      if (isNaN(totals)) {
        message.error({
          content: "Please choose the preparation time",
          className: "custom-class",
          style: {
            marginTop: "40vh",
            marginLeft: "40vh",
          },
        });
      } else {
        setShow(false);
        setPick(`${values.hour}hr ${values.minute}min`);
        formik.resetForm();
      }
    },
  });
  useEffect(() => {
    if (!show) {
      formik.resetForm();
    }
  }, [show]);
  const handleButtonFive = () => {
    if (activeButton !== "Pick a time") {
      setPick(false); // Reset the pick state when a different button is selected
    }
    setActiveButton("Pick a time");
    setShow(true);
  };

  function handleClosed() {
    setShow(false);
    formik.resetForm();
  }

  function handleButtonAccept() {
    // Checking if preparationTime is null
    if (preparationTime === null || preparationTime === NaN) {
      // Showing an error message if preparationTime is null
      // setLoading(false)
      message.error({
        content: "Please provide the preparation time",
        className: "custom-class",
        style: {
          marginTop: "20vh",
          marginLeft: "40vh",
        },
      });
      // Exiting the function if preparationTime is null
    } else {
      setLoading(true); // Setting loading state to true
      //props.getActiveOrdertlist(storeid)
      setTimeout(() => {
        setLoading(false); // Setting loading state to false after 2 seconds

        // Checking if preparationTime is truthy
        if (preparationTime) {
          // Navigating to the specified path if preparationTime is truthy
          props.ChangetoActiveStatus(orderid, preparationTime);
          nav("/home/MyOrders/ActiveOrders", {
            state: { orderid: orderid },
          });
          // props.getActiveOrdertlist(storeid)
        } else {
          // Log an error if preparationTime is still not provided after 2 seconds
          console.error("Preparation time is not provided");
        }

        // Calling the props function ChangetoActiveStatus with orderid and preparationTime

        //props.getActiveOrdertlist(storeid)
      }, 5000);
    }
  }

  useEffect((storeid) => {
    props.getActiveOrdertlist(storeid);
  }, []);

  // const detailsActive = ("props",props && props.ChangetoActiveStatus && props.ChangetoActiveStatus.changeOrderStatusToShopping)
  //  console.log("actve",detailsActive)
  const [open, setOpen] = useState(false);
  const [commentsSeller, setCommentsSeller] = useState(null);
  const [give, setGive] = useState(false);
  function handleReject() {
    // props.rejectedStatusHandler(orderid,value,newOrderDetails.lastStatus)
    setOpen(true);
  }
  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setReason(selectedValue);
    setCommentsSeller(selectedValue);
    if (selectedValue === "Other") {
      setGive(true);
    }
  };
  function handleClose() {
    setActive("cancel");
    setOpen(false);
  }
  const status = "Order-Rejected";
  const situation = 0;
  const [reason, setReason] = useState("");
  const [rejected, setReject] = useState(false);
  function handleApply() {
    setActive("apply");
    setOpen(false);
    props.rejectedStatusHandler({
      commentsSeller: commentsSeller,
      orderid: orderid,
      stats: { status: status },
    });
    setReject(true);
    if (reason === "") {
      message.error({
        content: "Please provide the reason for rejecting the order",
        className: "custom-class",
        style: {
          marginTop: "20vh",
          marginLeft: "60vh",
        },
      });
      return;
    }

    if (props.rejectedData?.updateOrderStatus.lastStatus === "Order-Rejected") {
      message.success({
        content: "Order Rejected Successfully",
        className: "custom-class",
        style: {
          marginTop: "25vh",
          marginLeft: "40vh",
        },
      });
      nav("/home/MyOrders");
    }
  }
  const storeid = localStorage.getItem("storeid");
  const deliveryDate = newOrderDetails?.deliveryDate;
  const deliveryTime = newOrderDetails?.deliveryTime;
  const orderNumber = newOrderDetails?.orderNumber;
  const shopAssistantId = newOrderDetails?.shopAssistantId;
  {
    props.rejected &&
      props.scheduleAlert({
        storeid: storeid,
        orderid: orderid,
        status: status,
        deliveryDate: deliveryDate,
        deliveryTime: deliveryTime,
        orderNumber: orderNumber,
        shopAssistantId: shopAssistantId,
      });
  }
  const instructions = ["add more Salt"];
  let totalPrice = 0;

  if (newOrderDetails && newOrderDetails.products) {
    newOrderDetails.products.forEach((item) => {
      totalPrice += (item?.quantity || 0) * (item?.price || 0);
    });
  }

  function handleClickOne() {
    nav("/home/MyOrders");
  }
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const matchessm = useMediaQuery(theme.breakpoints.down("sm"));
  const matcheslg = useMediaQuery(theme.breakpoints.down("lg"));

  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));

  const [showScanner, setShowScanner] = useState(false);
  const [showCollected, setShowCollected] = useState(false);

  const getTimeString = (time) => {
    return time
      ? time.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
      : "";
  };

  return (
    <Grid
      className="NewOrderPage"
      sx={{ marginLeft: "0px", marginRight: "0px" }}
    >
      {!loading && (
        <>
          <div style={{ display: "flex", gap: "3px" }}>
            <div
              style={{ cursor: "pointer" }}
              onClick={handleClickOne}
              className="MainActiveOrder"
            >
              <Typography
                className="MainActive"
                fontSize={HeaderNavigationFontSize_20_xs_13}
                sx={{
                  textDecoration: "underline",
                }}
              >
                New Orders
              </Typography>
            </div>
            <div>
              <Typography
                className="MainActive"
                fontSize={HeaderNavigationFontSize_20_xs_13}
              >
                /
              </Typography>
            </div>
            <div style={{ cursor: "pointer" }}>
              <Typography
                className="MainActives"
                fontSize={HeaderNavigationFontSize_20_xs_13}
              >
                New Order Details
              </Typography>
            </div>
          </div>

          {/* <Grid container direction="row" justifyContent="space-between">
          <Typography>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={Direction}
                style={{ width: "20px", height: "20px", marginTop: "30px" }}
                alt="Direction Icon"
              />
              <span
                style={{
                  marginLeft: "8px",
                  marginTop: "30px",
                  fontWeight: "bold",
                }}
              >
                
                   {newOrderDetails&&newOrderDetails.customerName}
                 
              </span>
            </div>
            <p>     
               {newOrderDetails&&newOrderDetails.deliveryAddress} 
            </p>
          </Typography>

          <Typography>
            <img
              src={Phone}
              style={{ width: "20px", height: "20px" }}
              alt="Phone icon"
            />
            <span
              style={{
                marginRight: "20px",
                fontWeight: "bold",
                marginTop: "50px",
              }}
            >
              {newOrderDetails&&newOrderDetails.mobileNumber}
            </span>
          </Typography>
        </Grid>
        <div className="neworderBox">
          <span style={{ fontWeight: "bold" }}>
            #{newOrderDetails&&newOrderDetails.orderNumber} - Scheduled on {newOrderDetails&&newOrderDetails.deliveryDate}, {newOrderDetails&&newOrderDetails.deliveryTime}
          </span>
        </div> */}
          <div style={{ marginTop: "30px" }}>
            <Grid container spacing={2} sx={{ paddingLeft: "0px" }}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ display: "flex", gap: "5px" }}>
                    <img
                      src={Direction}
                      style={{ width: "20px", height: "20px" }}
                      alt="Direction Icon"
                    />

                    <div>
                      <Typography
                        className="nameStyle"
                        sx={{ fontSize: { contentFontsize_14 } }}
                      >
                        {" "}
                        {newOrderDetails &&
                          newOrderDetails?.userid?.firstName}{" "}
                        {newOrderDetails && newOrderDetails?.userid?.lastName}
                      </Typography>
                    </div>
                  </div>

                  {/* <div>
                    <Typography
                      className="address"
                      sx={{ fontSize: { contentFontsize_14 } }}
                    >
                      {newOrderDetails && newOrderDetails?.deliveryAddress} ,
                      <br />
                      {newOrderDetails && newOrderDetails?.deliveryDate},
                      {newOrderDetails?.deliveryTime}
                    </Typography>
                  </div> */}
                  <div style={{display:"flex",flexDirection:"column"}}>
                    {newOrderDetails?.stats?.map((item, index) => (
                      <Typography
                        key={index}
                        className="address"
                        sx={{ fontSize: contentFontsize_14 }}
                      >
                        {/* {newOrderDetails && newOrderDetails.deliverytime}{" "} */}
                        {newOrderDetails?.deliveryAddress} , <br/>{item.created},
                        {item.createdTime}
                      </Typography>
                    ))}
                  </div>
                  
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    justifyContent: smmatch ? "flex-start" : "flex-end",
                    height: "100%",
                  }}
                >
                  <img
                    src={Phone}
                    style={{ width: "20px", height: "20px" }}
                    alt="Phone icon"
                  />
                  <span>
                    <Typography
                      className="numberStyle1"
                      sx={{ fontSize: { contentFontSize_18 } }}
                    >
                      +91 {newOrderDetails && newOrderDetails.mobileNumber}
                    </Typography>
                  </span>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="neworderBox">
            <span>
              <Typography
                fontSize={contentFontsize_17}
                className="textStyleNew"
              >
                #{newOrderDetails && newOrderDetails.orderNumber} - Scheduled on{" "}
                {newOrderDetails && newOrderDetails.deliveryDate},{" "}
                {newOrderDetails && newOrderDetails.deliveryTime}
              </Typography>
            </span>
          </div>

          {/* <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "30px",
              padding: smmatch ? "10px 10px" : "10px 70px",
            }}
          >
            {newOrderDetails &&
              newOrderDetails?.products.map((item) => {
                <>
                  <div style={{ display: "flex" }}>
                    <div style={{ flex: "1" }}>
                      <div style={{ display: "flex", gap: "60px" }}>
                        <div className="productDetails">
                          <img
                            style={{
                              width: matchessm ? "15px" : "20px",
                              height: matchessm ? "15px" : "20px",
                            }}
                            src={Pointer}
                            alt={item?.name}
                          />
                        </div>
                        <div>
                          <Typography
                            fontSize={contentFontSize_18}
                            className="productDetails"
                          >
                            {item.quantity} X {item.productid.productname}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ flex: "1" }}>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }} 
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "1px",
                        }}
                      >
                        <Typography
                          className="productDetails"
                          fontSize={contentFontSize_18}
                          sx={{ marginRight: "20px" }}
                        >
                          ₹{(item?.quantity * item?.productPrice)?.toFixed(2)}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </>;
              })}
          </div> */}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              marginTop: "20px",
              padding: smmatch ? "10px 10px" : "10px 70px",
            }}
          >
            {newOrderDetails &&
              newOrderDetails?.products?.map((item) => (
                <>
                  <div style={{ display: "flex" }}>
                    <div style={{ flex: "1" }}>
                      <div style={{ display: "flex", gap: "60px" }}>
                        <div className="productDetails">
                          <img
                            style={{
                              width: matchessm ? "15px" : "20px",
                              height: matchessm ? "15px" : "20px",
                            }}
                            src={Pointer}
                            alt={item?.name}
                          />
                        </div>

                        <div>
                          <Typography
                            fontSize={contentFontsize_17}
                            className="productDetails"
                          >
                            {item?.quantity} X {item?.productid?.productname}
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <div style={{ flex: "1" }}>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "1px",
                          }}
                        >
                          <Typography
                            className="productDetails"
                            fontSize={contentFontsize_17}
                          >
                            ₹{(item?.quantity * item?.productPrice)?.toFixed(2)}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
          </div>

          <div>
            <img src={Line2} className="image_container" />
          </div>

          <div style={{ padding: smmatch ? "10px 10px" : "10px 70px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <Typography
                fontSize={contentFontSize_20_xs_18}
                sx={{ fontWeight: "700", fontFamily: "Roboto" }}
              >
                Total
              </Typography>

              <Typography
                sx={{ fontWeight: "700", fontFamily: "Roboto" }}
                fontSize={contentFontSize_18}
              >
                ₹
                {(newOrderDetails && newOrderDetails?.totalPayable)?.toFixed(2)}
              </Typography>
            </div>
          </div>

          <div>
            <img src={Line2} className="image_container2" />
          </div>
          <div>
            <Typography
              sx={{
                fontWeight: "700",
                marginTop: "30px",
                marginLeft: "0px",
                color: "black",
              }}
              fontSize={contentFontSize_20}
            >
              Delivery Instructions
              <br />
              <span>
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontWeight: "400",
                    color: "#8B8989",
                    marginTop: "10px",
                  }}
                  fontSize={contentFontSize_16}
                >
                  {newOrderDetails?.deliveryInstructions
                    ? newOrderDetails?.deliveryInstructions
                    : "no instructions are provided"}
                </Typography>
              </span>
            </Typography>
          </div>
          <div>
            <Typography
              sx={{
                fontWeight: "700",
                marginTop: "30px",
                marginLeft: "0px",
                color: "black",
              }}
              fontSize={contentFontSize_20}
            >
              Cooking Instructions
              <br />
              <span>
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontWeight: "400",
                    color: "#8B8989",
                    marginTop: "10px",
                  }}
                  fontSize={contentFontSize_16}
                >
                  {newOrderDetails?.specialInstructions
                    ? newOrderDetails?.specialInstructions
                    : "no instructions are provided"}
                </Typography>
              </span>
            </Typography>
          </div>
          <div style={{ marginTop: "20px" }}>
            <Typography
              style={{
                fontWeight: "700",
                fontFamily: "Roboto",
                marginLeft: "0px",
                color: "black",
              }}
              fontSize={contentFontSize_20}
            >
              Choose Preparation time
            </Typography>
          </div>
          <div className="gridStyle3">
            <div>
              {" "}
              <CustomButton
                color={activeButton === "15 min" ? "white" : "black"}
                variant={activeButton === "15 min" ? "contained" : "outlined"}
                background={
                  activeButton === "15 min" ? "#659B1B" : "transparent"
                }
                borderColor="#659B1B"
                onClick={handleButtonOne}
                width="100%"
                borderRadius="10px"
                height="45px"
                matchPadding={true}
                padding="0px 25px 0px 25px"
              >
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontFamily: "Roboto",
                    textTransform: "lowercase",
                  }}
                  fontSize={ButtonFontSize_16}
                >
                  15 min
                </Typography>
              </CustomButton>
            </div>
            <div>
              <CustomButton
                color={activeButton === "30 min" ? "white" : "black"}
                variant={activeButton === "30 min" ? "contained" : "outlined"}
                textColor="white"
                background={
                  activeButton === "30 min" ? "#659B1B" : "transparent"
                }
                borderColor="#659B1B"
                onClick={handleButtonTwo}
                width="100%"
                borderRadius="10px"
                height="45px"
                matchPadding={true}
                padding="0px 25px 0px 25px"
              >
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontFamily: "Roboto",
                    textTransform: "capitalize",
                  }}
                  fontSize={ButtonFontSize_16}
                >
                  30 min
                </Typography>
              </CustomButton>
            </div>
            <div>
              <CustomButton
                color={activeButton === "45 min" ? "white" : "black"}
                variant={activeButton === "45 min" ? "contained" : "outlined"}
                textColor="white"
                background={
                  activeButton === "45 min" ? "#659B1B" : "transparent"
                }
                borderColor="#659B1B"
                onClick={handleButtonThree}
                width="100%"
                borderRadius="10px"
                height="45px"
                matchPadding={true}
                padding="0px 25px 0px 25px"
              >
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontFamily: "Roboto",
                    textTransform: "capitalize",
                  }}
                  fontSize={ButtonFontSize_16}
                >
                  45 min
                </Typography>
              </CustomButton>
            </div>
            <div>
              <CustomButton
                color={activeButton === "55 min" ? "white" : "black"}
                variant={activeButton === "55 min" ? "contained" : "outlined"}
                textColor="white"
                background={
                  activeButton === "55 min" ? "#659B1B" : "transparent"
                }
                borderColor="#659B1B"
                onClick={handleButtonFour}
                width="100%"
                borderRadius="10px"
                height="45px"
                matchPadding={true}
                padding="0px 25px 0px 25px"
              >
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontFamily: "Roboto",
                    textTransform: "capitalize",
                  }}
                  fontSize={ButtonFontSize_16}
                >
                  55 min
                </Typography>
              </CustomButton>
            </div>
            <div>
              <CustomButton
                color={activeButton === "Pick a time" ? "white" : "black"}
                width="100%"
                variant={
                  activeButton === "Pick a time" ? "contained" : "outlined"
                }
                textColor="white"
                background={
                  activeButton === "Pick a time" ? "#659B1B" : "transparent"
                }
                borderColor="#659B1B"
                onClick={handleButtonFive}
                borderRadius="10px"
                height="100%"
                matchPadding={true}
                padding="0px 25px 0px 25px"
              >
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontFamily: "Roboto",
                    textTransform: "capitalize",
                  }}
                  fontSize={ButtonFontSize_16}
                >
                  {pick || "Pick a time"}
                </Typography>
              </CustomButton>
              {show && (
                <CustomDialog
                  open={show}
                  showCloseButton={true}
                  onClose={handleClosed}
                  top="10px"
                  right="10px"
                >
                  <DialogTitle
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Enter Time:
                  </DialogTitle>
                  <DialogContent>
                    <Box>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: "20px",
                        }}
                      >
                        <div>
                          <TextField
                            id="hour"
                            placeholder="Hour"
                            variant="standard"
                            name="hour"
                            value={formik.values.hour}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                              formik.touched.hour && Boolean(formik.errors.hour)
                            }
                            helperText={
                              formik.touched.hour && formik.errors.hour
                            }
                          />
                        </div>
                        <div style={{ marginTop: "20px" }}>:</div>
                        <div>
                          <TextField
                            id="minute"
                            placeholder="Minute"
                            variant="standard"
                            name="minute"
                            value={formik.values.minute}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                              formik.touched.minute &&
                              Boolean(formik.errors.minute)
                            }
                            helperText={
                              formik.touched.minute && formik.errors.minute
                            }
                          />
                        </div>
                      </div>
                    </Box>
                  </DialogContent>
                  <DialogActions
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <CustomButton
                      width="200px"
                      height="40px"
                      borderRadius="10px"
                      onClick={formik.handleSubmit}
                    >
                      Submit
                    </CustomButton>
                  </DialogActions>
                </CustomDialog>
              )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "0px",
              justifyContent: "center",
              height: "13vh",
            }}
          >
            <div className="helpTag">
              <Typography
                fontSize={contentFontSize_18}
                sx={{
                  color: "#85BE49",
                  textDecoration: "none",
                  fontFamily: "Nunito",
                  fontWeight: "700",
                }}
              >
                This order will be delivered by the store. Need help ?
              </Typography>
            </div>
          </div>

          <div id="buttonContainer">
            <CustomButton
              width="32%"
              height="50px"
              onClick={handleButtonAccept}
              borderRadius="20px"
            >
              <Typography
                sx={{ fontFamily: "Roboto", fontWeight: "700" }}
                fontSize={ButtonFontSize_18}
              >
                {" "}
                Accept
              </Typography>
            </CustomButton>
            <CustomButton
              width="32%"
              height="50px"
              borderRadius="20px"
              onClick={handleReject}
              color="black"
              background="white"
              borderColor="rgba(114, 176, 29, 1)"
            >
              {" "}
              <Typography
                sx={{ fontFamily: "Roboto", fontWeight: "700" }}
                fontSize={ButtonFontSize_18}
              >
                Reject
              </Typography>
            </CustomButton>
            {open && (
              <Dialog
                width="100%"
                height="600px"
                borderRadius="20px"
                open={open}
                onClose={handleClose}
                showCloseButton={true}
                right="20px"
                top="20px"
                bottom="20px"
                sx={{
                  "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      width: "100%",
                      maxWidth: "700px",
                      // Set your width here
                      padding: "10px 10px 30px 10px",
                      borderRadius: "20px",
                    },
                  },
                }}
              >
                <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>
                  <div style={{ textAlign: "center", marginTop: "20px" }}>
                    <Typography
                      fontSize={contentFontsize_24}
                      sx={{
                        fontFamily: "Roboto",
                        fontWeight: "700",
                        color: "black",
                      }}
                    >
                      Are you sure
                    </Typography>
                    <Typography
                      fontSize={contentFontsize_24}
                      sx={{
                        fontFamily: "Roboto",
                        fontWeight: "700",
                        color: "black",
                      }}
                    >
                      you want to reject this order?
                    </Typography>
                  </div>
                  <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: "black",
                    }}
                  >
                    <div
                      style={{
                        height: "27px",
                        width: "27px",
                        border: "2px solid #8B8989",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CloseIcon style={{ color: "#8B8989" }} />
                    </div>
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  <div>
                    <Typography
                      fontSize={contentFontSize_20}
                      sx={{
                        fontFamily: "Roboto",
                        fontWeight: "500",
                        color: "black",
                      }}
                    >
                      Please specify the reason for rejecting this order:
                    </Typography>
                  </div>
                  <div>
                    <FormControl>
                      <FormLabel id="demo-radio-buttons-group-label"></FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={commentsSeller}
                        onChange={handleChange}
                        name="radio-buttons-group"
                      >
                        <FormControlLabel
                          value="Product Unavailable"
                          control={<Radio />}
                          label="Product Unavailable"
                        />
                        <FormControlLabel
                          value="Longer Preparation Time"
                          control={<Radio />}
                          label="Longer Preparation Time"
                        />
                        <FormControlLabel
                          value="Other"
                          control={<Radio />}
                          label="Other"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  {give && (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <Typography
                            fontSize={contentFontSize_20}
                            sx={{
                              fontFamily: " Roboto",
                              fontWeight: "500",
                              color: "black",
                            }}
                          >
                            Please specify the reason for rejecting this order:
                          </Typography>
                        </div>
                        <div>
                          <Typography sx={{ marginTop: "3px" }}>
                            0/300
                          </Typography>
                        </div>
                      </div>
                      <div style={{ marginTop: "15px" }}>
                        <Box
                          component="form"
                          sx={{
                            width: "650px",
                            maxWidth: "100%",
                            // corrected syntax
                          }}
                          noValidate
                          autoComplete="off"
                        >
                          <TextField
                            fullWidth
                            id="outlined-basic"
                            onChange={handleChange}
                            multiline={true}
                            rows="2"
                          />
                        </Box>
                      </div>
                    </div>
                  )}
                </DialogContent>
                <DialogActions>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "30px",
                      marginRight: "50px",
                    }}
                  >
                    <CustomButton
                      onClick={handleClose}
                      width="270px"
                      height="43px"
                      // variant={active === "cancel" ? "contained" : "outlined"}
                      // background={active === "cancel" ? "#659B1B" : "transparent"}
                      // color={active === "cancel" ? "white" : "#659B1B"}
                      borderRadius="25px"
                      borderColor="#659B1B"
                      color="black"
                      background="white"
                      variant="outlined"
                    >
                      Cancel
                    </CustomButton>
                    <CustomButton
                      // onClick={() => handleChange(selectedItem)}
                      width="270px"
                      height="43px"
                      // variant={active === "change" ? "contained" : "outlined"}
                      // background={active === "change" ? "#659B1B" : "transparent"}
                      // color={active === "change" ? "white" : "#659B1B"}
                      borderRadius="25px"
                      borderColor="#659B1B"
                      onClick={handleApply}
                      color="white"
                      background="#659B1B"
                      variant="contained"
                    >
                      Reject
                    </CustomButton>
                  </div>
                </DialogActions>
              </Dialog>
            )}
          </div>
        </>
      )}
      {loading && preparationTime && <Loaders {...props} />}
    </Grid>
  );
}

export default NewOrder;
