import client from "../../../Configurations/apollo";
import { gql } from "@apollo/client";


import {
	EDIT_RETAILER_MUTATION,
	EDIT_STORE_MUTATION,
	RESET_PASSWORD,
	LOGOUT,
	ADD_PRODUCT, 
	ADD_PRDT,
	CREATE_HEIRARCHY,
	UPDATE_HEIRARCHY,
	DELETE_S3,
	SHOPASSISTANT_REGISTRATION,
	//OrderNotificationSeen,
	EDIT_SHOPASSISTANT_REGISTRATION,
	DELETE_SHOPASSISTANT,
	SHOPASSISTANT_LIST,
	SHOPASSISTANT_ORDERS,
	ORDER_DETAILS,
	REASSIGN_ORDERS,
	STORE_WORKING,
	Multiple_Upload,
	AlertOrders,
	CRITICAL_ORDERS,
	SHOPASSISTANT_COMPLETEDORDERS,
	ASSIGN_DELIVERY_PARTNER,
	NEW_UPLOAD,
	AADHAR_UPLOAD,
	OrderNotification,
	Dashboard,
	newDashboard,
	GST_FILEUPLOAD,
	STORE_WORKING_SUBSCRIPTION,
	GET_MASTER_DEPARTMENTS,
	GET_MASTER_CATEGORY,
	GET_MASTER_PRODUCTS,
	ADD_CATALOG_PRODUCTS,
	createPercentageCoupon,
	LAST_SEEN_CHANGE,
	createFlatCoupon,
	createDeliveryFreeCoupon,
	LISTUSERCOUPONS,
	LISTALLCOUPONS,
	LISTALLUPCOMINGCOUPONS,
	LISTALLONGOINGCOUPONS,
	ASSIGN_COUPON,
	EDIT_COUPON,
	createBXGYCoupon,
	BXGY_PRODUCTS,
	CATALOG_DEPARTMENTS,
	GET_BUSINESSTYPES,
	GETLINK,
	NEWORDERS_LIST,
	ACTIVEORDERS_LIST,
	COMPLETEDORDERS_LIST,
	READYORDERS_LIST,
	ORDER_NEW_UPLOAD,
	OUTFORDELIVERY_LIST,
	ORDER_COUNT,
	ALL_ORDERS_COUNT
} from "./gqlQuery";







// const UPLOAD_FILE = gql`
// mutation($file: Upload!,$type:Int!,$storeid:String!) {
//   signS3(file: $file,type:$type,storeid:$storeid) {
//       ETag
//       Location
//       Bucket
//   }
// }
// `;


const GET_SELLER_DETAIL = gql`
	query($retailerid: ID!) {
		retailerdetails(retailerid: $retailerid) {
			id
			firstName
			lastName
			mobileNumber
			secondaryNumber
			emailAddress
			password
			PANnumber
			PANurl
			GSTnumber
			profileImageUrl
			GSTurl
			aadharUrl
			aadharNumber
			formNumber
			stores {
				id
				businessTypeId
				storeLocation
				storeWorking
				storeName
				openingTime
                closingTime
				storeDescription
				storeManagerFirstName
				storeManagerLastName
				fssaiNo
				fssaiUrl
				packingCharge
				licenseUrl
				storeStatus
				sameDayDelivery
    			deliveryAfter
				bankDetails{
					bankAccountNumber
					accountHolderName
					IFSCcode
				  }
				storeCoordinates {
					coordinates
				}
				subscription{
					type
					amount
					startdate
				}
				storeLogoUrl
				deliveryType
				deliveryMaxDistance
				deliveryCharge {
					kmRange
					charge
				}
				paymentSupported
				holidays
				minAmount
				deliveryDays {
					deliveryDay
					T9amTO12pm
					T12pmTO3pm
					T3pmTO6pm
					T6pmTO9pm
				}
				storeManagerMobile
				storeManagerPhone
			}
		}
	}
`;

const GET_SELLER_WORKING = gql`
	query($retailerid: ID!) {
		retailerdetails(retailerid: $retailerid) {
			
			stores {
				
				storeWorking
			}
		}
	}
`;

export const GET_IMAGE_SIGN = gql`
	mutation($file: Upload!,$type:Int!,$storeid:String!) {
		signS3(file: $file,type:$type,storeid:$storeid) {
			ETag
			Location
			Bucket
		}
	}
`;

export const resetProducts = () => {
	return {
		type: "RESET_PRODUCTS",
		}
	};
export const secondaryImage = (params) => {
	let Imgdata=[]
	let fileName=[]
	Imgdata=params.files.map((m)=>
	( m.originFileObj))
	fileName=params.files.map((m)=>
	( `${m.originFileObj.name}${Math.floor(Math.random()*(999-100+1)+100)}.jpg`)
 )
	return (dispatch) => {
		dispatch({
			type: "LOADING_MULTIPLE_UPLOAD",
		});
		client
			.mutate({
				mutation: Multiple_Upload,
				variables: {
					files: Imgdata,
					type:2,
					storeid:`${localStorage.getItem('storeid')}`,
					fileName:fileName
				},
				
			})
			.then((res) => {
				if (res.data) {
					// localStorage.setItem("MultipleImg",res.data.uploadMultipleFiles)
					dispatch({
						type: "MULTIPLE_UPLOAD_SUCCESS",
						payload: res.data,
						
					});
				} else {
					dispatch({
						type: "MULTIPLE_UPLOAD_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "MULTIPLE_UPLOAD_FAILURE",
					payload: err.message,
				});
			});
	};
};
export const secondaryImageOrder = (params) => {
	let Imgdata=[]
	let fileName=[]
	Imgdata=params.files.map((m)=>
	( m.originFileObj))
	fileName=params.files.map((m)=>
	( `${m.originFileObj.name}${Math.floor(Math.random()*(999-100+1)+100)}.jpg`)
 )
	return (dispatch) => {
		dispatch({
			type: "LOADING_MULTIPLE_UPLOAD",
		});
		client
			.mutate({
				mutation: Multiple_Upload,
				variables: {
					files: Imgdata,
					type:3,
					storeid:`${localStorage.getItem('storeid')}`,
					fileName:fileName
				},
				
			})
			.then((res) => {
				if (res.data) {
					// localStorage.setItem("MultipleImg",res.data.uploadMultipleFiles)
					dispatch({
						type: "MULTIPLE_UPLOAD_SUCCESS",
						payload: res.data,
						
					});
				} else {
					dispatch({
						type: "MULTIPLE_UPLOAD_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "MULTIPLE_UPLOAD_FAILURE",
					payload: err.message,
				});
			});
	};
};

export const secondaryImageUpdate = (params) => {
	let Imgdata=[],i=0
	let fileName=[]
	params.files.map((m,)=>
	( m.originFileObj?
		(Imgdata[i]=m.originFileObj,

			i++):(null)
		)
 )
 fileName=params.files.map((m)=>
	(
		 `${m.originFileObj.name}${Math.floor(Math.random()*(999-100+1)+100)}.jpg`)
 )
	
	return (dispatch) => {
		dispatch({
			type: "LOADING_MULTIPLEEDIT_UPLOAD",
		});
		client
			.mutate({
				mutation: Multiple_Upload,
				variables: {
					files: Imgdata,
					type:2,
					storeid:`${localStorage.getItem('storeid')}`,
					fileName:fileName
				},
				
			})
			.then((res) => {
				if (res.data) {
					// localStorage.setItem("MultipleImg",res.data.uploadMultipleFiles)
					dispatch({
						type: "MULTIPLE_UPLOADEDIT_SUCCESS",
						payload: res.data,
						
					});
				} else {
					dispatch({
						type: "MULTIPLE_UPLOADEDIT_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "MULTIPLE_UPLOADEDIT_FAILURE",
					payload: err.message,
				});
			});
	};
};

const token = localStorage.getItem('token');
export const getSellerDetail = (params) => {
    return async (dispatch) => {
        function onSuccess(success) {
            dispatch({ type: "SELLER_VIEW_SUCCESS", payload: success });
            return success;
        }
        function onError(error) {
            dispatch({ type: "SELLER_VIEW_FAILED", payload: error });
            return error;
        }
       function onNetworkError(error) {
            dispatch({ type: "SELLER_VIEW_NETWORK", payload: error });
            return error;
        }
        const success = await client.query({
            query: GET_SELLER_DETAIL,
            variables: {
                retailerid: params,
            },
        });
        if (success) {
            return onSuccess(success);
        } else if (success.errors) {
            return onError(success.errors[0]);
		}
		 else return onNetworkError();
		
    };
};
export const alertOrders = () => {
    return async (dispatch) => {
        function onSuccess(success) {
            dispatch({ type: "ORDER_VIEW_SUCCESS", payload: success.data });
            return success;
        }
        function onError(error) {
            dispatch({ type: "ORDER_VIEW_FAILED", payload: error });
            return error;
        }
       function onNetworkError(error) {
            dispatch({ type: "ORDER_VIEW_NETWORK", payload: error });
            return error;
        }
        const success = await client.query({
            query: AlertOrders,
            variables: {
                storeid: localStorage.getItem("storeid"),
            },
        });
        if (success) {
            return onSuccess(success);
        } else if (success.errors) {
            return onError(success.errors[0]);
		}
		 else return onNetworkError();
		
    };
};


export const criticalOrders = () => {

    return async (dispatch) => {
		dispatch({
			type: "CRITICALORDER_VIEW_LOADING",
		});
        function onSuccess(success) {
            dispatch({ type: "CRITICALORDER_VIEW_SUCCESS", payload: success });
            return success;
        }
        function onError(error) {
            dispatch({ type: "CRITICALORDER_VIEW_FAILED", payload: error });
            return error;
        }
       function onNetworkError(error) {
            dispatch({ type: "CRITICALORDER_VIEW_NETWORK", payload: error });
            return error;
        }
        const success = await client.query({
            query: CRITICAL_ORDERS,
            variables: {
                storeid: localStorage.getItem("storeid"),
            },
        });
        if (success) {
            return onSuccess(success);
        } else if (success.errors) {
            return onError(success.errors[0]);
		}
		 else return onNetworkError();
		
    };
};

export const OrderNotifications = () => {
    return async (dispatch) => {
        function onSuccess(success) {
            dispatch({ type: "ORDER_NOTIFICATION_SUCCESS", payload: success });
            return success;
        }
        function onError(error) {
            dispatch({ type: "ORDER_NOTIFICATION_FAILED", payload: error });
            return error;
        }
       function onNetworkError(error) {
            dispatch({ type: "ORDER_NOTIFICATION_NETWORK", payload: error });
            return error;
        }
        const success = await client.query({
            query: OrderNotification,
            variables: {
                storeid: localStorage.getItem("storeid"),
            },
        });
        if (success) {
            return onSuccess(success);
        } else if (success.errors) {
            return onError(success.errors[0]);
		}
		 else return onNetworkError();
		
    };
};


// export const OrderNotificationSeens = () => {
//     return async (dispatch) => {
//         function onSuccess(success) {
//             dispatch({ type: "ORDER_NOTIFICATION_SEEN_SUCCESS", payload: success });
//             return success;
//         }
//         function onError(error) {
//             dispatch({ type: "ORDER_NOTIFICATION_SEEN_FAILED", payload: error });
//             return error;
//         }
//        function onNetworkError(error) {
//             dispatch({ type: "ORDER_NOTIFICATION_SEEN_NETWORK", payload: error });
//             return error;
//         }
//         const success = await client.query({
//             query: OrderNotificationSeen,
//             variables: {
//                 userid: localStorage.getItem("storeid"),
//             },
//         });
//         if (success) {
//             return onSuccess(success);
//         } else if (success.errors) {
//             return onError(success.errors[0]);
// 		}
// 		 else return onNetworkError();
		
//     };
// };

export const getSellerWorking = (params) => {
    return async (dispatch) => {
        function onSuccess(success) {
            dispatch({ type: "SELLER_WORKING_SUCCESS", payload: success });
            return success;
        }
        function onError(error) {
            dispatch({ type: "SELLER_WORKING_FAILED", payload: error });
            return error;
        }
       function onNetworkError(error) {
            dispatch({ type: "SELLER_WORKING_NETWORK", payload: error });
            return error;
        }
        const success = await client.query({
            query: GET_SELLER_WORKING,
            variables: {
                retailerid: params,
            },
        });
        if (success) {
            return onSuccess(success);
        } else if (success.errors) {
            return onError(success.errors[0]);
		}
		 else return onNetworkError();
		
    };
};
export const textEditChanges = (params) => {
	return {
		type: "TEXT_EDIT_CHANGE",
		payload: {
			data: {
				retailerdetails: {
					...params.params.data.retailerdetails,
					[params.e.target.name]: params.e.target.value,
				},
			},
		},
	};
};


export const setUrl = (params) => {
	return {
		type: "SET_URL",
		payload: {
			data: {
				url :params
			},
		},
	};
};


export const textStoreChanges = (params) => {
	let newDetail = { ...params.params.data.retailerdetails };
	newDetail.stores[0].minAmount= params.e;
	newDetail.stores[0].packingCharge=params.e1;
	return {
		type: "TEXT_STORE_CHANGE",
		payload: {
			data: {
				retailerdetails: {
					...newDetail,
				},
			},
		},
	};
};


export const setProducts = (params) => {
	
	
	let newProducts = { params };
	
	return {
		type: "Product_Search",
		payload: {
			
					newProducts,
		},
	};
};

export const setCatalogProducts = (params) => {
	let newProducts = params;
	
	return {
		type: "Catalog_Product_Search",
		payload: {
			
			params,
		},
	};
};

export const setMultipleImage = (params) => {
	return {
		type : "NEW_MULTI_FILE",
		payload:{
			params
		}
	}
}


export const setOrders = (params) => {
	let newOrders = { params };
	
	return {
		type: "Order_Search",
		payload: {
			
					newOrders,
		},
	};
};


export const checkBoxEdit = (e, params) => {
	let editedValue = { ...params.stores[0] };
	if (e.target.name in editedValue) {
		if (e.target.checked) {
			editedValue[e.target.name].push(e.target.value);
		} else {
			let indexValue = editedValue[e.target.name].indexOf(e.target.value);
			editedValue[e.target.name].splice(indexValue, 1);
		}
	} else {
		let newFieldAdd = [];
		newFieldAdd.push(e.target.value);
		return { ...editedValue, newFieldAdd };
	}
	return {
		type: "CHECKBOX_EDIT",
		payload: {
			data: {
				retailerdetails: {
					...params,
					stores: [editedValue],
				},
			},
		},
	};
};

export const MastercheckBox = (params) => {
	return {
		type: "MASTER_CHECK",
		payload: {
				params,
		},
	};
};

export const BxgycheckBox = (params) => {
	return {
		type: "BXGY_CHECK",
		payload: {
				params,
		},
	};
};



export const setHolidaySlot = (key,params) => {
	let { deliveryDays } = params.stores[0];
	
	let newAddedSchedule = { ...params };
	let { holidays } = params.stores[0];
	if (holidays.includes(key)) {
		// Element is already in the array, so we remove it
		holidays.splice(holidays.indexOf(key), 1);
	  } else {
		// Element is not in the array, so we add it
		holidays.push(key);
	  }
	  newAddedSchedule.stores[0].holidays = holidays;
	  let schedule = [...deliveryDays];
		schedule[key]["T9amTO12pm"] = "No";
		schedule[key]["T12pmTO3pm"] = "No";
		schedule[key]["T3pmTO6pm"] = "No";
		schedule[key]["T6pmTO9pm"] = "No";
	// } else {
	// 	schedule[params.key][params.timing] = "Yes";
	// }
	newAddedSchedule.stores[0].deliveryDays = schedule;
	return {
		type: "HOLIDAY_SLOT_EDIT",
		payload: {
			data: {
				retailerdetails: {
					...newAddedSchedule,
				},
			},
		},
	};
};

export const scheduleEdit = (params) => {
	let { deliveryDays } = params.scheduleDetail.stores[0];
	let schedule = [...deliveryDays];
	let newAddedSchedule = { ...params.scheduleDetail };
	if (schedule[params.key][params.timing] === "Yes") {
		schedule[params.key][params.timing] = "No";
	} else {
		schedule[params.key][params.timing] = "Yes";
	}
	newAddedSchedule.stores[0].deliveryDays = schedule;
	return {
		type: "SCHEDULE_EDIT",
		payload: {
			data: {
				retailerdetails: {
					...newAddedSchedule,
				},
			},
		},
	};
};

export const Delete_Image = () => {
	return (dispatch) => {
		dispatch({
			type: "LOADING_NEWIMAGE_DELETE",
		});
		client
			.mutate({
				mutation: DELETE_S3,
				variables: {
					fileName:localStorage.getItem('imageUrl')
				},
				
			})
			.then((res) => {
				if (res.data) {
					localStorage.setItem('imageUrl',localStorage.getItem('updateUrl'))
					dispatch({
						type: "IMAGE_DELETE_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "IMAGE_DELETE_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "IMAGE_DELETE_NFAILURE",
					payload: err.message,
				});
			});
	};

}

export const Delete_Logo = (params) => {
	return (dispatch) => {
		dispatch({
			type: "LOADING_LOGO_DELETE",
		});
		client
			.mutate({
				mutation: DELETE_S3,
				variables: {
					fileName:params
				},
				
			})
			.then((res) => {
				if (res.data) {
					dispatch({
						type: "LOGO_DELETE_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "LOGO_DELETE_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "LOGO_DELETE_NFAILURE",
					payload: err.message,
				});
			});
	};

}

export const setImage = (params) => {
	localStorage.setItem('imageUrl',params)
	return {
		type: "SET_IMG",
		payload: {
			params,
		},
	};
};

export const uploadLogoImage = (params) => {
	
	let Imgdata = params.files;
	return (dispatch) => {
		dispatch({
			type: "LOADING_LOGOIMAGE_UPLOAD",
		});
		client
			.mutate({
				mutation: AADHAR_UPLOAD,
				variables: {
					file: Imgdata,
					type:1,
					size:180,
					filename:`${'logo'}-${Math.floor(Math.random()*(9999-1000+1)+1000)};.jpg`
				},
			})
			.then((res) => { 
				if (res.data) {
					localStorage.setItem('imageUrl',res.data.imageEditAndUpload.Location)
					
					dispatch({
						type: "LOGOIMAGE_UPLOAD_SUCCESS",
						payload: res.data,
						
					});
				} else {
					dispatch({
						type: "LOGOIMAGE_UPLOAD_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "LOGOIMAGE_UPLOAD_FAILURE",
					payload: err.message,
				});
			});
	};
};

export const uploadNewImage = (params) => {
	let Imgdata = params.files;
	return (dispatch) => {
		dispatch({
			type: "LOADING_NEWIMAGE_UPLOAD",
		});
		client
			.mutate({
				mutation: GET_IMAGE_SIGN,
				variables: {
					file: Imgdata,
					type:2,
					storeid:`${localStorage.getItem('storeid')}`
				},
				
			})
			.then((res) => {
				if (res.data) {
					dispatch({
						type: "NEWIMAGE_UPLOAD_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "NEWIMAGE_UPLOAD_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "NEWIMAGE_UPLOAD_FAILURE",
					payload: err.message,
				});
			});
	};
};

export const postNewUrl = (params) => {
	let storeurl = { ...params.details.stores[0].storeLogoUrl };
	storeurl = params.url;
	let newUrlDetail = { ...params.details };
	newUrlDetail.stores[0].storeLogoUrl = storeurl;
	return {
		type: "NEW_IMAGE_URL",
		payload: {
			data: {
				retailerdetails: {
					...newUrlDetail,
				},
			},
		},
	};
};

export const getNewLocation = (params) => {
	let storePlace = {
		...params.details.retailerdetails.stores[0].storeLocation,
	};
	storePlace = params.address;
	let newDetail = { ...params.details.retailerdetails };
	newDetail.stores[0].storeLocation = storePlace;
	let coordinate = [
		...params.details.retailerdetails.stores[0].storeCoordinates.coordinates,
	];
	coordinate[0] = Number(params.coordinates.lng);
	coordinate[1] = Number(params.coordinates.lat);
	newDetail.stores[0].storeCoordinates.coordinates = coordinate;

	return {
		type: "EDIT_LOCATION",
		payload: {
			data: {
				retailerdetails: {
					...newDetail,
				},
			},
		},
	};
};

export const getNewCoordinates = (params) => {
	console.log("orginal",params)
	let detailsNewCoordinate = { ...params.details };
	let coordinate = [...params.details.stores[0].storeCoordinates.coordinates];
	coordinate[0] = Number(params.geocode.lng);
	coordinate[1] = Number(params.geocode.lat);
	detailsNewCoordinate.stores[0].storeCoordinates.coordinates = coordinate;
	console.log("new",coordinate[0],coordinate[1])
	return {
		type: "NEW_COORDINATES",
		payload: {
			data: {
				retailerdetails: {
					...detailsNewCoordinate,
				},
			},
		},
	};
};

export const doorDeliveryEdit = (params) => {
	let storeDeliveryPerson = { ...params.details.stores[0].storeDeliveryPerson };
	storeDeliveryPerson = params.value;
	let newDetail = { ...params.details };
	newDetail.stores[0].storeDeliveryPerson = storeDeliveryPerson;
	return {
		type: "NEW_DELIVERY_DETAIL",
		payload: {
			data: {
				retailerdetails: {
					...newDetail,
				},
			},
		},
	};
};

export const changeMaxDistance = (params) => {
	let deliveryMaxDistance = { ...params.details.stores[0].deliveryMaxDistance };
	deliveryMaxDistance = params.value;
	let newDetail = { ...params.details };
	newDetail.stores[0].deliveryMaxDistance = deliveryMaxDistance;
	return {
		type: "NEW_MAX_DISTANCE",
		payload: {
			data: {
				retailerdetails: {
					...newDetail,
				},
			},
		},
	};
};

export const editDeliveryCharge = (params) => {
	let { deliveryCharge } = params.details.stores[0];
	let newCharges = [...deliveryCharge];
	let newAddedDetails = { ...params.details };
	newCharges[params.index].charge = params.value;
	newAddedDetails.stores[0].deliveryCharge = newCharges;
	return {
		type: "NEW_EDIT_CHARGES",
		payload: {
			data: {
				retailerdetails: {
					...newAddedDetails,
				},
			},
		},
	};
};
export const resetDetails = (params) => {
	return {
		type: "RESET_DETAILS",
		payload: {
			data: {
				...params.data,
			},
		},
	};
};

export const editRetailer = (id,params) => {
	return (dispatch) => {
		dispatch({
			type: "EDIT_RETAILER_WAITING",
		});
		client
			.mutate({
				mutation: EDIT_RETAILER_MUTATION,
				variables: {
					retailerid: id,
					firstName: params.firstName,
					lastName: params.lastName,
					mobileNumber: params.mobileNumber,
					secondaryNumber: params.secondaryNumber===""?" ":params.secondaryNumber,
					emailAddress: params.emailAddress,
					VATnumber: params.VATnumber,
					PANnumber: params.PANnumber,
					GSTnumber: params.GSTnumber,
					GSTurl:params.GSTurl,
					profileImageUrl:params.profileImageUrl
				},
				context: {
					headers: {
					Authorization:'Bearer ' + token
					}
				},
			})
			.then((res) => {
				if (res.data) {
					dispatch({
						type: "EDIT_RETAILER_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "EDIT_RETAILER_FAILED",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "EDIT_RETAILER_FAILED",
					payload: err.message,
				});
			});
	};
};

export const editStore = (params1,params2) => {
	let { deliveryDays, deliveryCharge,storeCoordinates,holidays} = params1;
    let Holidays=holidays.map((h)=>(
        Number(h)
    ))
	delete storeCoordinates.__typename;
	let deliveryRate = deliveryCharge.map((rate) => ({
		kmRange: rate.kmRange,
		charge: rate.charge,
	}));
	let deliverDay = deliveryDays.map((day) => ({
		
		T9amTO12pm:`${day.T9amTO12pm}`,
		T12pmTO3pm:`${day.T12pmTO3pm}`,
		T3pmTO6pm:`${day.T3pmTO6pm}`,
		T6pmTO9pm:`${day.T6pmTO9pm}`,
		deliveryDay:`${day.deliveryDay}`,
	}));
	return (dispatch) => {
		dispatch({
			type: "EDIT_STORE_WAITING",
		});
		client
			.mutate({
				mutation: EDIT_STORE_MUTATION,
				variables: {
					storeid: params1.id,
					storeName: params2.storeName,
					storeLocation: params1.storeLocation,
					storeDescription: params2.storeDescription,
					storeLogoUrl: params1.storeLogoUrl,
					storeCoordinates:{
						coordinates:[Number(storeCoordinates.coordinates[0]),
						Number(storeCoordinates.coordinates[1])]
						},
					storeManagerFirstName: params2.storeManagerFirstName,
					storeManagerLastName: params2.storeManagerLastName,
					storeManagerMobile: params2.storeManagerMobile,
					storeManagerPhone: params2.storeManagerPhone===""?" ":params2.storeManagerPhone,
					deliveryType: params1.deliveryType,
					deliveryMaxDistance:Number(params1.deliveryMaxDistance),
					deliveryCharge: deliveryRate,
					paymentSupported: params1.paymentSupported,
					deliveryDays: deliverDay,
                    holidays:Holidays,
					minAmount:Number(params1.minAmount),
					packingCharge:Number(params1.packingCharge),
					bankDetails:{bankAccountNumber:params1.bankAccountNumber,accountHolderName:params1.accountHolderName,IFSCcode:params1.IFSCcode}
				},
				context: {
					headers: {
					Authorization:'Bearer ' + token
					}
				},
			})
			.then((res) => {
				if (res.data) {
					localStorage.removeItem('imageUrl')
					dispatch({
						type: "EDIT_STORE_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "EDIT_STORE_FAILED",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "EDIT_STORE_FAILED",
					payload: err.message,
				});
			});
	};
};

export const StoreEdit = (params1,params2,params3,params4,params5) => {
	let { deliveryDays, deliveryCharge, storeCoordinates,holidays } = params1;
	let OpeningTime=('0' + params2.getHours()).slice(-2)
	+ ':' + ('0' + params2.getMinutes()).slice(-2)
	let ClosingTime=('0' + params3.getHours()).slice(-2)
	+ ':' + ('0' + params3.getMinutes()).slice(-2)
	delete storeCoordinates.__typename;
    let Holidays = holidays.map((h)=>(
        Number(h)
    ))
	let deliveryRate = deliveryCharge.map((rate) => ({
		kmRange: rate.kmRange,
		charge: rate.charge===""?null:rate.charge,
	}));
	let deliverDay = deliveryDays.map((day) => ({
		T9amTO12pm:`${day.T9amTO12pm}`,
		T12pmTO3pm:`${day.T12pmTO3pm}`,
		T3pmTO6pm:`${day.T3pmTO6pm}`,
		T6pmTO9pm:`${day.T6pmTO9pm}`,
		deliveryDay:`${day.deliveryDay}`,
	}));
	return (dispatch) => {
		dispatch({
			type: "EDIT_STORE_WAITING",
		});
		client
			.mutate({
				mutation: EDIT_STORE_MUTATION,
				variables: {
					storeid: params1.id,
					storeName: params1.storeName,
					storeLocation: params1.storeLocation,
					storeDescription: params1.storeDescription,
					storeLogoUrl: params1.storeLogoUrl,
				    storeCoordinates:{
						coordinates:[Number(storeCoordinates.coordinates[0]),
						Number(storeCoordinates.coordinates[1])]
						},
					storeManagerFirstName: params1.storeManagerFirstName,
					storeManagerLastName: params1.storeManagerLastName,
					storeManagerMobile: params1.storeManagerMobile,
					storeManagerPhone: params1.storeManagerPhone===""?" ":params1.storeManagerPhone,
					deliveryType: params1.deliveryType,
					deliveryMaxDistance:Number(params1.deliveryMaxDistance),
					deliveryCharge: deliveryRate,
					paymentSupported: params1.paymentSupported,
					deliveryDays: deliverDay,
					openingTime:OpeningTime,
					closingTime:ClosingTime,
                    holidays:Holidays,
					minAmount:Number(params1.minAmount),
					packingCharge:Number(params1.packingCharge),
					bankDetails:{bankAccountNumber:params1.bankDetails.bankAccountNumber,accountHolderName:params1.bankDetails.accountHolderName,IFSCcode:params1.bankDetails.IFSCcode},
					sameDayDelivery: (params4==='true')?true:(params4===true)?true:false,
      				deliveryAfter:params5
					
				},
				context: {
					headers: {
					Authorization:'Bearer ' + token
					}
				},
			})
			.then((res) => {
				if (res.data) {
					getSellerDetail(`${localStorage.getItem('retailerid')}`);
					localStorage.removeItem('imageUrl')
					dispatch({
						type: "EDIT_STORE_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "EDIT_STORE_FAILED",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "EDIT_STORE_FAILED",
					payload: err.message,
				});
			});
	};
};


export const closeSnackBars = () => {
	return {
		type: "CLOSE_SNACKBARS",
	};
};
export const RemoveLogo = () => {
	return {
		type: "REMOVE_LOGO",
	};
};
export const MultipleImage = () => {
	return {
		type: "MULTIPLE_FILELIST",
	};
};
export const MultipleImageReset = () => {
	return {
		type: "MULTIPLE_FILELIST_RESET",
	};
};
export const gstChange= (params) => {
	return {
		type: "GSTChange",
		payload: params
	};
};
export const setMaster=()=>{
	return {
		type: "SET_MASTER"
	}
}
export const SingleDelete = () => {
	localStorage.removeItem('imageUrl')
	return {
		type: "SINGLE_DELETE",
	};
};
export const DeleteImg = () => {
	return {
		type:"DELETE_IMG"
	};
};
export const multipleDelete = () =>{
	return {
		type : "MULTIPLE_DELETE"
	}
}
export const setRejectClick = (params) =>{
	return {
		type : "SET_REJECT",
		payload:params
	}
}
export const setCriteria = (params) => {
	return{
		type:"SET_CRITERIA",
		payload:params
	}
}
export const MultiDelete = () => {
	return{
		type:"MULTI_EDIT_DELETE"
	}
}
export const resetPassword = (params) => {
	return (dispatch) => {
		dispatch({
			type: "PASSWORD_RESET_WAITING",
		});
		client
			.mutate({
				mutation: RESET_PASSWORD,
				variables: {
					password: params.password,
					retailerid: params.retailerid,
					newPassword: params.newPassword,
				},
				context: {
					headers: {
					Authorization:'Bearer ' + token
					}
				},
			})
			.then((res) => {
				if (res.data) {
					dispatch({
						type: "PASSWORD_RESET_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "PASSWORD_FAILED",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "PASSWORD_RESET_FAILED",
					payload: err.message,
				});
			});
	};
};

export const logoutHandler = (params1,params2) => {
	return (dispatch) => {
		dispatch({
			type: "LOGOUT_WAITING",
		});
		client
			.mutate({
				mutation: LOGOUT,
				variables: {					
					retailerid: params1,
					token:params2,			
				},
				context: {
					headers: {
					Authorization:'Bearer ' + token
					}
				},
			})
			.then((res) => {
				if (res.data) {
					localStorage.removeItem('token')
					localStorage.removeItem('isLoggedin')
					localStorage.removeItem('retailerid')
					dispatch({
						type: "LOGOUT_SUCCESS",
						payload: res.data,
						
					});
				} else {
					dispatch({
						type: "LOGOUT_FAILED",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "LOGOUT_NETWORK_ERROR",
					payload: err.message,
				});
			});
	};
};

export const AddProduct = (params,salesdepartment,salescategory,salescategory1,salescategory2,multipleImg,thumbnail) => {
	console.log("params",params)
	let secondaryImages=[]
	if(multipleImg!==null)
	{secondaryImages=multipleImg.map((m)=>
	( m.Location)
 )}
 else{
	secondaryImages=null
 }
 
	return (dispatch) => {
	
		dispatch({
			type: "ADDING_PRODUCTS",
		});
		client
			.mutate({
				mutation:  ADD_PRODUCT ,
				variables: {
					productname:params.Productname,
					desc:params.Description,
					mfgname:params.MfgName===""?null:params.MfgName,
					brand:params.brand===""?null:params.brand,
					price:params.Price,
					barcode:params.barcode,
					promoprice:params.PromoPrice===""?"":`${params.PromoPrice}`,
					quantity:params.Quantity===""?null:`${params.Quantity}`,
					foodType: params.foodType ? { id: params.foodType.id, value: params.foodType.value } : null,
					uom:params.unit===""?null:params.unit,
					image:{primary:localStorage.getItem('imageUrl')===null||
					localStorage.getItem('imageUrl')==="undefined"?
					null:
					localStorage.getItem('imageUrl')
					,
				       secondary:secondaryImages,
					thumbnail:thumbnail!==null?thumbnail.imageEditAndUpload.Location
					:null},
					status:params.status,
					displayrank:Number(params.displayRank),
					storeid:localStorage.getItem('storeid'),
					salesdepartment:salesdepartment===""?null:salesdepartment,
					salescategory:salescategory===""?null:salescategory,
					salescategory1:salescategory1===""?null:salescategory1,
					salescategory2:salescategory2===""?null:salescategory2
				},
				context: {
					headers: {
					Authorization:'Bearer ' + token
					}
				},
			})
			.then((res) => {
				if (res.data) {
					localStorage.removeItem('imageUrl')
					localStorage.removeItem("MultipleImg",res.data)
					dispatch({
						type: "ADD_PRODUCT_SUCCESS",
						payload:  res.data,
						
					});
				} else {
					dispatch({
						type: "ADD_PRODUCT_FAILED",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "ADD_PRODUCT_FAILED",
					payload: err.message,
				});
			});
	};
};

export const AddProduct1 = (params,salesdepartment,salescategory,salescategory1,salescategory2,multipleImg,thumbnail) => {
	console.log("params",params)
	let secondaryImages=[]
	if(multipleImg!==null)
	{secondaryImages=multipleImg.map((m)=>
	( m.Location)
 )}
 else{
	secondaryImages=null
 }
 
	return (dispatch) => {
	
		dispatch({
			type: "ADDING_PRODUCTS",
		});
		client
			.mutate({
				mutation:  ADD_PRODUCT ,
				variables: {
					productname:params.Productname,
					desc:params.Description,
					mfgname:params.MfgName===""?null:params.MfgName,
					brand:params.brand===""?null:params.brand,
					price:params.Price,
					barcode:params.barcode,
					promoprice:params.PromoPrice===""?"":`${params.PromoPrice}`,
					packingCharge:Number(params.packingCharge),
					quantity:params.Quantity===""?null:`${params.Quantity}`,
					uom:params.unit===""?null:params.unit,
					foodType: params.foodType ? { id: params.foodType.id, value: params.foodType.value } : null,
					image:{primary:localStorage.getItem('imageUrl')===null||
					localStorage.getItem('imageUrl')==="undefined"?
					null:
					localStorage.getItem('imageUrl')
					,
				       secondary:secondaryImages,
					thumbnail:thumbnail!==null?thumbnail.imageEditAndUpload.Location
					:null},
					status:params.status,
					displayrank:Number(params.displayRank),
					storeid:localStorage.getItem('storeid'),
					salesdepartment:salesdepartment===""?null:salesdepartment,
					salescategory:salescategory===""?null:salescategory,
					salescategory1:salescategory1===""?null:salescategory1,
					salescategory2:salescategory2===""?null:salescategory2
				},
				context: {
					headers: {
					Authorization:'Bearer ' + token
					}
				},
			})
			.then((res) => {
				if (res.data) {
					localStorage.removeItem('imageUrl')
					localStorage.removeItem("MultipleImg",res.data)
					dispatch({
						type: "ADD_PRODUCT_SUCCESS",
						payload:  res.data,
						
					});
				} else {
					dispatch({
						type: "ADD_PRODUCT_FAILED",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "ADD_PRODUCT_FAILED",
					payload: err.message,
				});
			});
	};
};

export const AddPrdt = (params,salesdepartment,salescategory,salescategory1,salescategory2) => {
	return (dispatch) => {
		console.log("params",params)
		
		dispatch({
			type: "ADDING_PRODUCTS",
			
		});
		client
			.mutate({
				mutation:  ADD_PRDT ,
				variables: {
					productname:params.Productname,
					desc:params.Description,
					mfgname:params.MfgName===""?null:params.MfgName,
					brand:params.brand===""?null:params.brand,
					price:params.Price,
					barcode:params.barcode,
					promoprice:params.PromoPrice===""?"":`${params.PromoPrice}`,
					packingCharge:Number(params.packingCharge),
					quantity:params.Quantity===""?null:`${params.Quantity}`,
					uom:params.unit===""?null:params.unit,
					status:params.status,
					displayrank:Number(params.displayRank),
					foodType: params.foodType ? { id: params.foodType.id, value: params.foodType.value } : null,
					storeid:localStorage.getItem('storeid'),
					salesdepartment:salesdepartment===""?null:salesdepartment,
					salescategory:salescategory===""?null:salescategory,
					salescategory1:salescategory1===""?null:salescategory1,
					salescategory2:salescategory2===""?null:salescategory2
				},
				context: {
					headers: {
					Authorization:'Bearer ' + token
					}
				},
			})
			.then((res) => {
				if (res.data) {
					dispatch({
						type: "ADD_PRODUCT_SUCCESS",
						payload:  res.data,
					});
				} else {
					dispatch({
						type: "ADD_PRODUCT_FAILED",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "ADD_PRODUCT_FAILED",
					payload: err.message,
				});
			});
	};
};


export const updateImage = (params) => {
	let Imgdata = params.files;
	return (dispatch) => {
		dispatch({
			type: "LOADING_NEWIMAGE_UPLOAD",
		});
		client
			.mutate({
				mutation: NEW_UPLOAD,
				variables: {
					file: Imgdata,
					type:2,
					size:512,
					filename: params.filename,
					storeid:localStorage.getItem('storeid')
				},
			})
			.then((res) => { 
				if (res.data) {
					localStorage.setItem('updateUrl',res.data&&res.data.imageEditAndUpload
					&&res.data.imageEditAndUpload.Location)
					
					dispatch({
						type: "PROIMAGE_UPLOAD_SUCCESS",
						payload: res.data,
						
					});
				} else {
					dispatch({
						type: "PROIMAGE_UPLOAD_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "NEWIMAGE_UPLOAD_FAILURE",
					payload: err.message,
				});
			});
	};
};

export const updateThumbnail = (params) => {
	let Imgdata = params.files;
	return (dispatch) => {
		dispatch({
			type: "LOADING_NEWTHUMBNAIL_UPLOAD",
		});
		client
			.mutate({
				mutation: NEW_UPLOAD,
				variables: {
					file: Imgdata,
					type:2,
					size:165,
					filename:`${params.fileName}${'thumbnail'}${Math.floor(Math.random()*(999-100+1)+100)}.jpg`,
					storeid:`${localStorage.getItem('storeid')}`
				},
			})
			.then((res) => { 
				if (res.data) {
					// localStorage.clear('imageUrl')
					// localStorage.setItem('updateUrl',res.data&&res.data.imageEditAndUpload
					// &&res.data.imageEditAndUpload.Location)
					
					dispatch({
						type: "NEWTHUMBNAIL_UPLOAD_SUCCESS",
						payload: res.data,
						
					});
				} else {
					dispatch({
						type: "NEWTHUMBNAIL_UPLOAD_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "NEWTHUMBNAIL_UPLOAD_FAILURE",
					payload: err.message,
				});
			});
	};
};
export const AddDepartment = (params) => {
	return (dispatch) => {
		dispatch({
			type: "ADDING_DEPARTMENT",
		});
		client
			.mutate({
				mutation: CREATE_HEIRARCHY ,
				variables: {
					storeid:localStorage.getItem('storeid'),
					name:params.name,
					displayrank:Number(params.displayRank),
					parentid:params.depParentid,
					status:params.status,
					childCategoryPresent:params.childCategoryPresent
				},
				context: {
					headers: {
					Authorization:'Bearer ' + token
					}
				},
			})
			.then((res) => {
				if (res.data&&res.data.createSalesHierarchy&&res.data.createSalesHierarchy!==null) {
					dispatch({
						type: "ADD_DEPARTMENT_SUCCESS",
						payload: res.data,
					});
				} else if(res.errors){
					dispatch({
						type: "ADD_DEPARTMENT_FAILED",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "ADD_DEPARTMENT_FAILED",
					payload: err.message,
				});
			});
	};
};


export const UpdateDepartment = (params) => {
	return (dispatch) => {
		dispatch({
			type: "UPDATING_DEPARTMENT",
		});
		client
			.mutate({
				mutation: UPDATE_HEIRARCHY ,
				variables: {
					hierarchyid:params.hid,
					name:params.name,
					parentid:params.depParentid,
					status:params.status,
					displayrank:Number(params.displayRank),
					childCategoryPresent:Boolean(params.childCategoryPresent)
				},
				context: {
					headers: {
					Authorization:'Bearer ' + token
					}
				},
			})
			.then((res) => {
				if (res.data) {
					dispatch({
						type: "UPDATE_DEPARTMENT_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "UPDATE_DEPARTMENT_FAILED",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "UPDATE_DEPARTMENT_FAILED",
					payload: err.message,
				});
			});
	};
};


export const shopassistantregister = (params1,params2,params3,params4,params5) => {
	let firstname=params1;
	let lastname=params2;
	let phonenumber=params3;
	let storeid=params4;
	let profilePicUrl=params5;
	return (dispatch) => {
		dispatch({
			type: "SHOPASSISTANT_REGISTERING",
		});
		client
			.mutate({
				mutation: SHOPASSISTANT_REGISTRATION,
				variables: {
					firstName:`${firstname}`,
					lastName:`${lastname}`,
					phoneNumber:`${phonenumber}`,
					storeid:`${storeid}`,
					profilePicUrl:`${profilePicUrl}`,
				},
				
			})
			.then((res) => {
				if (res.data) {
					dispatch({
						type: "SHOPASSISTANT_REGISTRATION_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "SHOPASSISTANT_REGISTRATION_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "SHOPASSISTANT_REGISTRATION_FAILURE",
					payload: err.message,
				});
			});
	};
};


export const editShopAssistant = (params1,params2,params3,params4) => {
	let shopAssistantId=params1;
	let firstname=params2;
	let lastname=params3;
	let profilePicUrl=params4;
	
	return (dispatch) => {
		dispatch({
			type: "EDIT_SHOPASSISTANT",
		});
		client
			.mutate({
				mutation: EDIT_SHOPASSISTANT_REGISTRATION,
				variables: {
					shopAssistantId:`${shopAssistantId}`,
					firstName:`${firstname}`,
					lastName:`${lastname}`,
					profilePicUrl:`${profilePicUrl}`
				},
				
			})
			.then((res) => {
				if (res.data) {
					dispatch({
						type: "EDIT_SHOPASSISTANT_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "EDIT_SHOPASSISTANT_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "EDIT_SHOPASSISTANT_FAILURE",
					payload: err.message,
				});
			});
	};
};


export const deleteShopAssistant = (params) => {

	let ShopAssistantId = params;

	return (dispatch) => {
		dispatch({
			type: "DELETE_SHOPASSISTANT",
		});
		client
			.mutate({
				mutation: DELETE_SHOPASSISTANT,
				variables: {
					shopAssistantId: `${ShopAssistantId}`,
				},
				refetchQueries: [{ query: SHOPASSISTANT_LIST,  variables: {
					storeid: localStorage.getItem("storeid"),
				  } }],
			})
			.then((res) => {
				if (res.data) {
					dispatch({
						type: "DELETE_SHOPASSISTANT_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "DELETE_SHOPASSISTANT_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "DELETE_SHOPASSISTANT_FAILURE",
					payload: err.message,
				})
			})
	}

}





// export const getShopassistantlist = (params) => {
//     return async (dispatch) => {
//         function onSuccess(success) {
//             dispatch({ type: "SHOPASSISTANT_LIST_SUCCESS", payload: success });
//             return success;
//         }
//         function onError(error) {
//             dispatch({ type: "SHOPASSISTANT_LIST_FAILED", payload: error });
//             return error;
//         }
      
//         const success = await client.query({
//             query: SHOPASSISTANT_LIST,
//             variables: {
//                 storeid: params,
//             },
//         });
//         if (success) {
//             return onSuccess(success);
//         } else if (success.errors) {
//             return onError(success.errors[0]);
// 		}		
//     };
// };

export const getShopassistantlist = (params) => {
	return async (dispatch) => {
	  function onSuccess(success) {
		dispatch({ type: "SHOPASSISTANT_LIST_SUCCESS", payload: success });
		return success;
	  }
	  function onError(error) {
		dispatch({ type: "SHOPASSISTANT_LIST_FAILED", payload: error });
		return error;
	  }
  
	  try {
		// Start loading (optional, depending on your use case)
		dispatch({ type: "SHOPASSISTANT_LIST_LOADING" });
  
		const success = await client.query({
		  query: SHOPASSISTANT_LIST,
		  variables: {
			storeid: params,
		  },
		});
  
		if (success) {
		  // Request succeeded
		  return onSuccess(success);
		} else if (success.errors) {
		  // Request failed
		  return onError(success.errors[0]);
		}
	  } catch (error) {
		// Network error or other exceptions
		return onError(error.message);
	  }
	};
  };
  

// export const getShopassistantOrders = (params1,params2) => {
//     return async (dispatch) => {
//         function onSuccess(success) {
//             dispatch({ type: "SHOPASSISTANT_ORDERS_SUCCESS", payload: success });
//             return success;
//         }
//         function onError(error) {
//             dispatch({ type: "SHOPASSISTANT_ORDERS_FAILED", payload: error });
//             return error;
//         }
      
//         const success = await client.query({
//             query: SHOPASSISTANT_ORDERS,
//             variables: {
//                 shopAssistantId: params1,
// 				status:`${params2}`
//             },
//         });
//         if (success) {
//             return onSuccess(success);
//         } else if (success.errors) {
//             return onError(success.errors[0]);
// 		}		
//     };
// };


export const getShopassistantOrders = (params1, params2) => {

	
    return async (dispatch) => {
        function onSuccess(assignedOrders, readyOrders,outforDelivery) {
            dispatch({ type: "SHOPASSISTANT_ORDERS_SUCCESS", payload: { assigned: assignedOrders, ready: readyOrders,outforDelivery:outforDelivery } });
            return { assigned: assignedOrders, ready: readyOrders,outforDelivery:outforDelivery};
        }

        function onError(error) {
            dispatch({ type: "SHOPASSISTANT_ORDERS_FAILED", payload: error });
            return error;
        }
			dispatch({type:"SHOPASSISTANT_ORDERS_LOADING"})
        const success = await client.query({
            query: SHOPASSISTANT_ORDERS,
            variables: {
                shopAssistantId: params1,
                status: `${params2}`
            },
        });

        if (success) {
            const assignedOrders = [];
            const readyOrders = [];
			const outForDelivery=[];
			
			
           
			
            
            if (Array.isArray(success.data.shopAssistantActiveOrdersList)) {
				
				
               
                success.data.shopAssistantActiveOrdersList.forEach(order => {
					
					
					
                    if (order.lastStatus === "Order-Accepted") {
				
                        assignedOrders.push(order);
						
                    } else if(order.lastStatus === "Order-Ready"){
						readyOrders.push(order);
					}else if(order.lastStatus==="Out-For-Delivery"){
						outForDelivery.push(order);
					}
                });
            } else {
            }

            return onSuccess(assignedOrders, readyOrders,outForDelivery);
        } else if (success.errors) {
            return onError(success.errors[0]);
        }
    };
};





export const getShopassistantCompletedOrders = (params1,params2) => {
    return async (dispatch) => {
        function onSuccess(success) {
            dispatch({ type: "SHOPASSISTANT_COMPLETED_ORDERS_SUCCESS", payload: success });
            return success;
        }
        function onError(error) {
            dispatch({ type: "SHOPASSISTANT_COMPLETED_ORDERS_FAILED", payload: error });
            return error;
        }
        dispatch({type:"SHOPASSISTANT_COMPLETED_ORDER_LOADING"})
        const success = await client.query({
            query: SHOPASSISTANT_COMPLETEDORDERS,
            variables: {
                shopAssistantId: params1,
				status:`${params2}`
            },
        });
        if (success) {
            return onSuccess(success);
        } else if (success.errors) {
            return onError(success.errors[0]);
		}		
    };
};



export const getOrderDetails = (params) => {
	return async (dispatch) => {
		function onSuccess(success) {
			dispatch({ type: "ORDERLIST_SUCCESS", payload: success });
			return success;
		}
		function onError(error) {
			dispatch({ type: "ORDERLIST_FAILED", payload: error });
			return error;
		}
		function onNetworkError() {
			dispatch({ type: "ORDERDETAILS_ERROR" });
		}
		const success = await client.query({
			query: ORDER_DETAILS,
			variables: {
				orderid: params,
			},
		});
		if (success) {
			return onSuccess(success);
		} else if (success.errors) {
			return onError(success.errors[0]);
		} else return onNetworkError();
	};
};


export const AssignOrder = (params1 , params2) => {
	return (dispatch) => {
		dispatch({
			type: "ASSIGNING_ORDER",
		});
		client
			.mutate({
				mutation: REASSIGN_ORDERS,
				variables: {
					shopAssistantId:`${params1}`,
					orderId:`${params2}`
				},
			})
			.then((res) => {
				if (res.data) {	
					dispatch({
						type: "ASSIGN_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "ASSIGN_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "ASSIGN_FAILURE",
					payload: err.message,
				});
			});
	};

}


export const AssignPartnerOrder = (params1 , params2,params3) => {
	return (dispatch) => {
		dispatch({
			type: "ASSIGNING_PARTNER_ORDER",
		});
		client
			.mutate({
				mutation: ASSIGN_DELIVERY_PARTNER,
				variables: {
					deliveryPartnerId:`${params1}`,
					orderId:`${params2}`,
					estimatedDeliveryTime:`${params3}`
				},
			})
			.then((res) => {
				if (res.data) {	
					dispatch({
						type: "ASSIGN_PARTNER_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "ASSIGN_PARTNER_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "ASSIGN_PARTNER_FAILURE",
					payload: err.message,
				});
			});
	};

}

export const storeWorkingSubscription = (params1) => {
	const current = new Date();
  const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
	return (dispatch) => {
		dispatch({
			type: "STORE_WORKING_PENDING",
		});
		client
			.mutate({
				mutation:  STORE_WORKING_SUBSCRIPTION,
				variables: {
					storeWorking:Boolean(params1),
					storeid:localStorage.getItem('storeid'),
					subscription:{
						type:1,
						amount:1000,
						startdate:date
					}
				},	
			})
			.then((res) => {
				if (res.data) {	
					dispatch({
						type: "STORE_WORKING_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "STORE_WORKING_ERROR",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "STORE_WORKING_ERROR",
					payload: err.message,
				});
			});
	};

}



export const storeWorking = (params1) => {
	return (dispatch) => {
		dispatch({
			type: "STORE_WORKING_PENDING",
		});
		client
			.mutate({
				mutation:  STORE_WORKING,
				variables: {
					storeWorking:Boolean(params1),
					storeid:localStorage.getItem('storeid')
				},	
			})
			.then((res) => {
				if (res.data) {	
					dispatch({
						type: "STORE_WORKING_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "STORE_WORKING_ERROR",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "STORE_WORKING_ERROR",
					payload: err.message,
				});
			});
	};

}


export const UploadAadhar = (params) => {
	debugger
	let Imgdata = params.files;
	return (dispatch) => {
		dispatch({
			type: "LOADING_AADHAR_UPLOAD",
		});
		client
			.mutate({
				mutation: AADHAR_UPLOAD,
				variables: {
					file: Imgdata,
					type:4,
					size:512,
					filename:`${params.fileName}-aadhar.jpg`
				},
			})
			.then((res) => { 
				if (res.data) {
					dispatch({
						type: "AADHAR_UPLOAD_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "AADHAR_UPLOAD_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "AADHAR_UPLOAD_FAILURE",
					payload: err.message,
				});
			});
	};
};

export const UploadLicense = (params) => {
	let Imgdata = params.files;
	return (dispatch) => {
		dispatch({
			type: "LOADING_LICENSE_UPLOAD",
		});
		client
			.mutate({
				mutation: AADHAR_UPLOAD,
				variables: {
					file: Imgdata,
					type:4,
					size:512,
					filename:`${params.fileName}-license.jpg`
				},
			})
			.then((res) => { 
				if (res.data) {
					dispatch({
						type: "LICENSE_UPLOAD_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "LICENSE_UPLOAD_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "LICENSE_UPLOAD_FAILURE",
					payload: err.message,
				});
			});
	};
};

export const uploadImage = (params) => {
	let Imgdata = params.files;
	return (dispatch) => {
		dispatch({
			type: "LOADING_PRODUCTIMAGE_UPLOAD",
		});
		client
			.mutate({
				mutation: NEW_UPLOAD,
				variables: {
					file: Imgdata,
					type:2,
					size:512,
					filename:params.fileName,
					storeid:`${localStorage.getItem('storeid')}`
				},
			})
			.then((res) => { 
				if (res.data) {
					// localStorage.clear('imageUrl')
					localStorage.setItem('imageUrl',res.data&&res.data.imageEditAndUpload
					&&res.data.imageEditAndUpload.Location)
					dispatch({
						type: "PRODUCTIMAGE_UPLOAD_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "PRODUCTIMAGE_UPLOAD_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "PRODUCTIMAGE_UPLOAD_FAILURE",
					payload: err.message,
				});
			});
	};
};
export const uploadProductImage = (params) => {
	let Imgdata = params.files;
	return (dispatch) => {
		dispatch({
			type: "LOADING_ORDERIMAGE_UPLOAD",
		});
		client
			.mutate({
				mutation: NEW_UPLOAD,
				variables: {
					file: Imgdata,
					type:2,
					size:params.size,
					filename:params.filename,
				    storeid:localStorage.getItem('storeid')
				},
			})
			.then((res) => { 
				if (res.data) {
					// localStorage.clear('imageUrl')
					localStorage.setItem('imageUrl',res.data&&res.data.imageEditAndUpload
					&&res.data.imageEditAndUpload.Location)
					dispatch({
						type: "ORDERIMAGE_UPLOAD_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "ORDERIMAGE_UPLOAD_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "ORDERIMAGE_UPLOAD_FAILURE",
					payload: err.message,
				});
			});
	};
};

export const uploadOrderImage = (params) => {
	let Imgdata = params.files;
	return (dispatch) => {
		dispatch({
			type: "LOADING_ORDERIMAGE_UPLOAD",
		});
		client
			.mutate({
				mutation: ORDER_NEW_UPLOAD,
				variables: {
					file: Imgdata,
					type:3,
					size:512,
					filename:params.filename,
					orderid:localStorage.getItem('Rid')
				},
			})
			.then((res) => { 
				if (res.data) {
					// localStorage.clear('imageUrl')
					localStorage.setItem('imageUrl',res.data&&res.data.imageEditAndUpload
					&&res.data.imageEditAndUpload.Location)
					dispatch({
						type: "ORDERIMAGE_UPLOAD_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "ORDERIMAGE_UPLOAD_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "ORDERIMAGE_UPLOAD_FAILURE",
					payload: err.message,
				});
			});
	};
};


export const uploadThumbnail = (params) => {
	let Imgdata = params.files;
	return (dispatch) => {
		dispatch({
			type: "LOADING_THUMBNAIL_UPLOAD",
		});
		client
			.mutate({
				mutation: NEW_UPLOAD,
				variables: {
					file: Imgdata,
					type:2,
					size:165,
					filename:`${params.fileName}-${'thumbnail'}${Math.floor(Math.random()*(999-100+1)+100)}.jpg`,
					storeid:`${localStorage.getItem('storeid')}`
				},
			})
			.then((res) => { 
				if (res.data) {
					// localStorage.clear('imageUrl')
					// localStorage.setItem('imageUrl',res.data&&res.data.imageEditAndUpload
					// &&res.data.imageEditAndUpload.Location)
					dispatch({
						type: "THUMBNAIL_UPLOAD_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "THUMBNAIL_UPLOAD_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "THUMBNAIL_UPLOAD_FAILURE",
					payload: err.message,
				});
			});
	};
};

export const UploadFSSAI = (params) => {
	let Imgdata = params.files;
	return (dispatch) => {
		dispatch({
			type: "LOADING_FSSAI_UPLOAD",
		});
		client
			.mutate({
				mutation: AADHAR_UPLOAD,
				variables: {
					file: Imgdata,
					type:4,
					size:512,
					filename:`${params.fileName}-fssai.jpg`
				},
			})
			.then((res) => { 
				if (res.data) {
					dispatch({
						type: "FSSAI_UPLOAD_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "FSSAI_UPLOAD_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "FSSAI_UPLOAD_FAILURE",
					payload: err.message,
				});
			});
	};
};

export const UploadPan = (params) => {
	let Imgdata = params.files;
	return (dispatch) => {
		dispatch({
			type: "LOADING_PAN_UPLOAD",
		});
		client
			.mutate({
				mutation: AADHAR_UPLOAD,
				variables: {
					file: Imgdata,
					type:4,
					size:512,
					filename:`${params.fileName}-pan.jpg`
				},
			})
			.then((res) => { 
				if (res.data) {
					dispatch({
						type: "PAN_UPLOAD_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "PAN_UPLOAD_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "PAN_UPLOAD_FAILURE",
					payload: err.message,
				});
			});
	};
};



export const getDashboard = () => {
    return async (dispatch) => {
        function onSuccess(success) {
            dispatch({ type: "Dashboard_SUCCESS", payload: success });
            return success;
        }
        function onError(error) {
            dispatch({ type: "Dashboard_FAILED", payload: error });
            return error;
        }
       function onNetworkError(error) {
            dispatch({ type: "Dashboard_NETWORK", payload: error });
            return error;
        }
        const success = await client.query({
            query: Dashboard,
            variables: {
                storeid: localStorage.getItem('storeid'),
            },
        });
        if (success) {
            return onSuccess(success);
			
        } else if (success.errors) {
            return onError(success.errors[0]);
		}
		 else return onNetworkError();
		
    };
};



// export const getNewDashboard = (params) => {
// 	console.log("this is the new dashboard parmas",params)
//     return async (dispatch) => {
		
//         function onSuccess(success) {
//             dispatch({ type: "NEW_Dashboard_SUCCESS", payload: success });
//             return success;
//         }
//         function onError(error) {
//             dispatch({ type: "NEW_Dashboard_FAILED", payload: error });
//             return error;
//         }
//        function onNetworkError(error) {
//             dispatch({ type: "NEW_Dashboard_NETWORK", payload: error });
//             return error;
//         }

// 		dispatch({type:"NEW_Dashboard_loader"})
//         const success = await client.mutate({
//             mutation: newDashboard,
//             variables: {
//                 storeid: params,
//             },
//         }).then((res) => { 
//         if (res.data) {
// 			console.log("ok Success",success)
//             return onSuccess(res.data);
			
//         } else if (res.errors) {
// 			console.log("no Success",success)
//             return onError(res.errors);
// 		}
// 		 else return onNetworkError();
		
//     })}
// };

export const getNewDashboard = (params) => {
    return async (dispatch) => {
        dispatch({ type: "NEW_Dashboard_loader" });

        try {
            const response = await client.mutate({
                mutation: newDashboard,
                variables: {
                    storeId: params,
                },
            });

            if (response.data) {
                return dispatch({
                    type: "NEW_Dashboard_SUCCESS",
                    payload: response.data,
                });
            } else if (response.errors) {
                return dispatch({
                    type: "NEW_Dashboard_FAILED",
                    payload: response.errors,
                });
            }
        } catch (error) {
            return dispatch({
                type: "NEW_Dashboard_NETWORK",
                payload: error,
            });
        }
    };
};



// export const getNewDashboard = () => {
//     return async (dispatch) => {
//         function onSuccess(success) {
//             dispatch({ type: "NEW_Dashboard_SUCCESS", payload: success });
//             return success;
//         }
//         function onError(error) {
//             dispatch({ type: "NEW_Dashboard_FAILED", payload: error });
//             return error;
//         }
//         function onNetworkError(error) {
//             dispatch({ type: "NEW_Dashboard_NETWORK", payload: error });
//             return error;
//         }

//         try {
//             dispatch({ type: "NEW_Dashboard_loader" });
//             const success = await client.query({
//                 query: newDashboard,
//                 variables: {
//                     storeid: localStorage.getItem('storeid'),
//                 },
//             });
//             if (success && success.data) {
//                 console.log("ok Success", success);
//                 return onSuccess(success.data);
//             } else if (success.errors) {
//                 console.log("no Success", success);
//                 return onError(success.errors[0]);
//             } else {
//                 return onNetworkError(new Error('Network error'));
//             }
//         } catch (error) {
//             return onNetworkError(error);
//         }
//     };
// };




export const EditGst = (params) => {
	let Imgdata = params.files;
	return (dispatch) => {
		dispatch({
			type: "LOADING_GSTEDIT_UPLOAD",
		});
		client
			.mutate({
				mutation: GST_FILEUPLOAD,
				variables: {
					file: Imgdata,
					type:4,
					size:512,
					filename:`${params.fileName}-gst.jpg`
				},
			})
			.then((res) => { 
				if (res.data) {
					dispatch({
						type: "GSTEDIT_UPLOAD_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "GSTEDIT_UPLOAD_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "GSTEDIT_UPLOAD_FAILURE",
					payload: err.message,
				});
			});
	};
};

export const getMasterDepartment = (params) => {
    return async (dispatch) => {
        function onSuccess(success) {
            dispatch({ type: "MASTER_DEPARTMENTS_VIEW_SUCCESS", payload: success });
            return success;
        }
        function onError(error) {
            dispatch({ type: "MASTER_DEPARTMENTS_VIEW_FAILED", payload: error });
            return error;
        }
       function onNetworkError(error) {
            dispatch({ type: "MASTER_DEPARTMENTS_VIEW_NETWORK", payload: error });
            return error;
        }
        const success = await client.query({
            query: GET_MASTER_DEPARTMENTS,
			variables:{
				businessTypeId:Number(params),
				storeId:localStorage.getItem('storeid')
			}
        });
        if (success) {
            return onSuccess(success);
        } else if (success.errors) {
            return onError(success.errors[0]);
		}
		 else return onNetworkError();
		
    };
};

export const getMasterCategory = (params) => {
    return async (dispatch) => {
        function onSuccess(success) {
            dispatch({ type: "MASTER_CATEGORY_VIEW_SUCCESS", payload: success });
            return success;
        }
        function onError(error) {
            dispatch({ type: "MASTER_CATEGORY_VIEW_FAILED", payload: error });
            return error;
        }
       function onNetworkError(error) {
            dispatch({ type: "MASTER_CATEGORY_VIEW_NETWORK", payload: error });
            return error;
        }
        const success = await client.query({
            query: GET_MASTER_CATEGORY,
			variables: {
                departmentid: params,
				storeId:localStorage.getItem('storeid')
            },
        });
        if (success) {
            return onSuccess(success);
        } else if (success.errors) {
            return onError(success.errors[0]);
		}
		 else return onNetworkError();
		
    };
};

export const getMasterProducts = (params) => {
    return async (dispatch) => {
        function onSuccess(success) {
            dispatch({ type: "MASTER_PRODUCTS_VIEW_SUCCESS", payload: success });
            return success;
        }
        function onError(error) {
            dispatch({ type: "MASTER_PRODUCTS_VIEW_FAILED", payload: error });
            return error;
        }
       function onNetworkError(error) {
            dispatch({ type: "MASTER_PRODUCTS_VIEW_NETWORK", payload: error });
            return error;
        }
        const success = await client.query({
            query: GET_MASTER_PRODUCTS,
			variables: {
				storeid:localStorage.getItem('storeid'),
                categoryid: localStorage.getItem('CategoryId'),
				pagination:{offset:params.offset, first: 50}
            },
        });
        if (success) {
            return onSuccess(success);
        } else if (success.errors) {
            return onError(success.errors[0]);
		}
		 else return onNetworkError();
		
    };
};

export const AddCatalogProducts = (params) => {
	return (dispatch) => {
		dispatch({
			type: "ADDING_CATALOG_PRODUCTS",
		});
		client
			.mutate({
				mutation: ADD_CATALOG_PRODUCTS,
				variables: {
					departmentName:params.departmentName,
					categoryName:params.categoryName,
					storeid:params.storeid,
					productid:params.productid
				},
				
			})
			.then((res) => {
				// console.log("response data",res)
				if (res.data) {
					localStorage.setItem('imageUrl',localStorage.getItem('updateUrl'))
					dispatch({
						type: "ADD_CATALOG_PRODUCTS_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "ADD_CATALOG_PRODUCTS_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "ADD_CATALOG_PRODUCTS_NFAILURE",
					payload: err.message,
				});
			});
	};

}


export const setDeliveryDays = (params)=>{
	return {
		type: "Number of Delivery days",
		payload: params
	};
}

export const getSameDayDelivery = (params) => {
	return {
		type: "SameDayDeliverys",
		payload: params==='true'?true:false
	};
};

export const getAllCoupons = () => {
	return async (dispatch) => {
	  dispatch({ type: "LISTALLCOUPONS_LOADING" });
	  function onSuccess(success) {
		dispatch({ type: "LISTALLCOUPONS_SUCCESS", payload: success });
		return success;
	  }
	  function onError(error) {
		dispatch({ type: "LISTALLCOUPONS_FAILED", payload: error });
		return error;
	  }
	  function onNetworkError() {
		dispatch({ type: "LISTALLCOUPONS_ERROR" });
	  }
	  const success = await client.query({
		query: LISTALLCOUPONS,
		variables: {
			storeid: localStorage.getItem('storeid'),
		},
	   
	  });
	  if (success) {
		return onSuccess(success);
	  } else if (success.errors) {
		return onError(success.errors[0]);
	  } else return onNetworkError();
	};
  };

  export const getAllUpComingCoupons = () => {
	return async (dispatch) => {
	  dispatch({ type: "LISTALLUPCOMINGCOUPONS_LOADING" });
	  function onSuccess(success) {
		dispatch({ type: "LISTALLUPCOMINGCOUPONS_SUCCESS", payload: success });
		return success;
	  }
	  function onError(error) {
		dispatch({ type: "LISTALLUPCOMINGCOUPONS_FAILED", payload: error });
		return error;
	  }
	  function onNetworkError() {
		dispatch({ type: "LISTALLUPCOMINGCOUPONS_ERROR" });
	  }
	  const success = await client.query({
		query: LISTALLUPCOMINGCOUPONS,
		variables: {
			storeid: localStorage.getItem('storeid'),
		},
	   
	  });
	  if (success) {
		return onSuccess(success);
	  } else if (success.errors) {
		return onError(success.errors[0]);
	  } else return onNetworkError();
	};
  };

  export const getAllOnGoingCoupons = () => {
	return async (dispatch) => {
	  dispatch({ type: "LISTALLONGOINGCOUPONS_LOADING" });
	  function onSuccess(success) {
		dispatch({ type: "LISTALLONGOINGCOUPONS_SUCCESS", payload: success });
		return success;
	  }
	  function onError(error) {
		dispatch({ type: "LISTALLONGOINGCOUPONS_FAILED", payload: error });
		return error;
	  }
	  function onNetworkError() {
		dispatch({ type: "LISTALLONGOINGCOUPONS_ERROR" });
	  }
	  const success = await client.query({
		query: LISTALLONGOINGCOUPONS,
		variables: {
			storeid: localStorage.getItem('storeid'),
		},
	   
	  });
	  if (success) {
		return onSuccess(success);
	  } else if (success.errors) {
		return onError(success.errors[0]);
	  } else return onNetworkError();
	};
  };
  
export const AssignCouponTouser = (params) => {
	  return (dispatch) => {
		  dispatch({
			  type: "ASSIGNING_COUPON",
		  });
		  client
			  .mutate({
				  mutation:  ASSIGN_COUPON,
				  variables: {
			couponId:params.CList,
			userId:params.userId,
			userCouponCount:Number(params.uses)
				  },
				  
			  })
			  .then((res) => {
				  if (res.data) {
					  localStorage.removeItem('updateUrl')
					  dispatch({
						  type: "ASSIGNCOUPON_SUCCESS",
					  });
				  } else {
					  dispatch({
						  type: "ASSIGNCOUPON_FAILED",
						  payload: res.message,
					  });
				  }
			  })
			  .catch((err) => {
				  dispatch({
					  type: "ASSIGNCOUPON_FAILED",
					  payload: err.message,
				  });
			  });
	  };
  };
  
export const EditCoupon = (params) => {
	  return (dispatch) => {
		  dispatch({
			  type: "EDITING_COUPON",
		  });
		  client
			  .mutate({
				  mutation:  EDIT_COUPON,
				  variables: {
			couponId:params.id,
			status:params.status
				  },
				  
			  })
			  .then((res) => {
				  if (res.data) {
					  localStorage.removeItem('updateUrl')
					  dispatch({
						  type: "EDIT_COUPON_SUCCESS",
						  payload:res.message
					  });
				  } else {
					  dispatch({
						  type: "EDIT_COUPON_FAILED",
						  payload: res.message,
					  });
				  }
			  })
			  .catch((err) => {
				  dispatch({
					  type: "EDIT_COUPON_FAILED",
					  payload: err.message,
				  });
			  });
	  };
  };


  export const LastSeenChange=(params)=>{
	// console.log("this is the orderid for the alertorder Berlin",params)
	return(dispatch)=>{
		dispatch({
			type:"CHANGING_LAST_SEEN",
		})
		client
		.mutate({
			mutation:LAST_SEEN_CHANGE,
			variables:{
				alertIds:params.id
			},
			refetchQueries: [{ query: AlertOrders,  variables: {
				storeid: localStorage.getItem("storeid"),
			  } }],
		}).then((res) => {
			if (res.data) {
				//localStorage.removeItem('updateUrl')
				dispatch({
					type: "LAST_STATUS_CHANGED_SUCCESSFULLY",
					payload:res.message,
				});
			} else {
				dispatch({
					type: "LAST_STATUS_CHANGED_FAILED",
					payload: res.message,
				});
			}
		})
		.catch((err) => {
			dispatch({
				type: "LAST_STATUS_CHANGED_FAILED",
				payload: err.message,
			});
		});
	}
  }

  export const LastSeenChangeNew=(params)=>{
	// console.log("this is the orderid for the alertorder ashiya",params)
	return(dispatch)=>{
		dispatch({
			type:"CHANGING_LAST_SEEN_ALL",
		})
		client
		.mutate({
			mutation:LAST_SEEN_CHANGE,
			variables:{
				alertIds:params
			},
			refetchQueries: [{ query: AlertOrders,  variables: {
				storeid: localStorage.getItem("storeid"),
			  } }],
		}).then((res) => {
			if (res.data) {
				//localStorage.removeItem('updateUrl')
				dispatch({
					type: "LAST_STATUS_CHANGED_ALL_SUCCESSFULLY",
					payload:res.message,
				});
			} else {
				dispatch({
					type: "LAST_STATUS_CHANGED_ALL_FAILED",
					payload: res.message,
				});
			}
		})
		.catch((err) => {
			dispatch({
				type: "LAST_STATUS_CHANED_ALL_FAILED",
				payload: err.message,
			});
		});
	}
  }

  
export const CreatePercentage = (params) => {
	return (dispatch) => {
		dispatch({
			type: "CREATING_PERCENTAGE_COUPON",
		});
		client
			.mutate({
				mutation:  createPercentageCoupon ,
				variables: {
					type:1,
					storeid: localStorage.getItem('storeid'),
          couponCode:params.couponCode,
          custUseCount:Number(params.usesperCustomer),
          minPurAmt:Number(params.minOrder),
          startDate:new Date(params.fromtime),
          endDate:new Date(params.totime),
          
          disAmt:Number(params.disAmt),
          discountPercent:Number(params.disPercent)
				},
				
			})
			.then((res) => {
				if (res.data) {
					localStorage.removeItem('updateUrl')
					dispatch({
						type: "PERCENTAGECOUPON_CREATED",
					});
				} else {
					dispatch({
						type: "PERCENTAGECOUPON_CREATION_FAILED",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "PERCENTAGECOUPON_CREATION_FAILED",
					payload: err.message,
				});
			});
	};
};

export const CreateFlat = (params) => {
	return (dispatch) => {
		dispatch({
			type: "CREATING_FLAT_COUPON",
		});
		client
			.mutate({
				mutation:  createFlatCoupon ,
				variables: {
					type:2,
					storeid: localStorage.getItem('storeid'),
          couponCode:params.couponCode,
          custUseCount:Number(params.usesperCustomer),
          minPurAmt:Number(params.minOrder),
          disAmt:Number(params.disAmt),
          startDate:params.StartDate,
          endDate:params.ToDate,
          
				},
				
			})
			.then((res) => {
				if (res.data) {
					localStorage.removeItem('updateUrl')
					dispatch({
						type: "FLATCOUPON_CREATED",
					});
				} else {
					dispatch({
						type: "FLATCOUPON_CREATION_FAILED",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "FLATCOUPON_CREATION_FAILED",
					payload: err.message,
				});
			});
	};
};

export const CreateDeliveryFree = (params) => {
	return (dispatch) => {
		dispatch({
			type: "CREATING_DELIVERYFREE_COUPON",
		});
		client
			.mutate({
				mutation:  createDeliveryFreeCoupon ,
				variables: {
					type:4,
          couponCode:params.couponCode,
		  storeid: localStorage.getItem('storeid'),
          minPurAmt:Number(params.minOrder),
          custUseCount:Number(params.usesperCustomer),
          startDate:params.StartDate,
          endDate:params.ToDate,
          
				},
				
			})
			.then((res) => {
				if (res.data) {
					localStorage.removeItem('updateUrl')
					dispatch({
						type: "DELIVERYFREECOUPON_CREATED",
					});
				} else {
					dispatch({
						type: "DELIVERYFREECOUPON_CREATION_FAILED",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "DELIVERYFREECOUPON_CREATION_FAILED",
					payload: err.message,
				});
			});
	};
};


export const CreateBXGY = (params) => {
	return (dispatch) => {
		dispatch({
			type: "CREATING_BXGY_COUPON",
		});
		client
			.mutate({
				mutation:  createBXGYCoupon ,
				variables: {
					type:3,
					storeid: localStorage.getItem('storeid'),
          couponCode:params.couponCode,
          custUseCount:Number(params.usesperCustomer),
          startDate:new Date(params.StartDate),
          endDate:new Date(params.ToDate),
		  getItemNo:Number(params.getitem),
    	buyItemNo:Number(params.buyitem),
    	productIds:params.selectedproducts,
    	categoryId:null,
   	 	allProducts:false
				},
				
			})
			.then((res) => {
				if (res.data) {
					localStorage.removeItem('updateUrl')
					dispatch({
						type: "BXGYCOUPON_CREATED",
					});
				} else {
					dispatch({
						type: "BXGYCOUPON_CREATION_FAILED",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "BXGYCOUPON_CREATION_FAILED",
					payload: err.message,
				});
			});
	};
};

export const getUserSpecificCoupons = () => {
  return async (dispatch) => {
    dispatch({ type: "LISTUSERCOUPONS_LOADING" });
    function onSuccess(success) {
      dispatch({ type: "LISTUSERCOUPONS_SUCCESS", payload: success });
      return success;
    }
    function onError(error) {
      dispatch({ type: "LISTUSERCOUPONS_FAILED", payload: error });
      return error;
    }
    function onNetworkError() {
      dispatch({ type: "LISTUSERCOUPONS_ERROR" });
    }
    const success = await client.query({
      query: LISTUSERCOUPONS,
     
    });
    if (success) {
      return onSuccess(success);
    } else if (success.errors) {
      return onError(success.errors[0]);
    } else return onNetworkError();
  };
};


export const getBxgyProducts = (params) => {
	return async (dispatch) => {
	  dispatch({ type: "BXGY_PRODUCTS_LOADING" });
	  function onSuccess(success) {
		dispatch({ type: "BXGY_PRODUCTS_SUCCESS", payload: success });
		return success;
	  }
	  function onError(error) {
		dispatch({ type: "BXGY_PRODUCTS_FAILED", payload: error });
		return error;
	  }
	  function onNetworkError() {
		dispatch({ type: "BXGY_PRODUCTS_ERROR" });
	  }
	  const success = await client.query({
		query: BXGY_PRODUCTS,
		variables: {
			storeid: localStorage.getItem('storeid'),
			pagination:{offset:0,first:params.first}
 
		},
	   
	  });
	  if (success) {
		return onSuccess(success);
	  } else if (success.errors) {
		return onError(success.errors[0]);
	  } else return onNetworkError();
	};
  };


export const getBusinessType = () => {
	
	return async (dispatch) => {
		dispatch({ type: "GET_BUSINESSTYPES_LOADING" });
		function onSuccess(success) {
		  dispatch({ type: "GET_BUSINESSTYPES_SUCCESS", payload: success });
		  return success;
		}
		function onError(error) {
		  dispatch({ type: "GET_BUSINESSTYPES_FAILED", payload: error });
		  return error;
		}
		function onNetworkError() {
		  dispatch({ type: "GET_BUSINESSTYPES_ERROR" });
		}
		const success = await client.query({
		  query: GET_BUSINESSTYPES,
		});
		if (success) {
		  return onSuccess(success);
		} else if (success.errors) {
		  return onError(success.errors[0]);
		} else return onNetworkError();
	  };
}


export const setBusinessType = (params) => {
	return {
		type: "SET_BUSINESS_ID_TYPE",
		payload:params
	};
}
export const setBusinessError = (params) => {
	return {
		type: "SET_BUSINESS_TYPE_ID_ERROR",
		payload:params
	};
}

export const setEstimatedTime = (params)=>{
	return {
		type: "SET_ESTIMATED_TIME",
		payload: params
	};
}
export const EliminateOrder = (params) => {
	
	return {
		type:"ELIMINATE_ORDER",
		payload:params
		}
	};


export const ShareLink = () => {
	return (dispatch) => {
		dispatch({
			type: "GETTING_LINK",
		});
		client
			.mutate({
				mutation: GETLINK,
				variables: {
					storeid:localStorage.getItem('storeid')
				},
				
			})
			.then((res) => {
				if (res.data) {
					dispatch({
						type: "GET_LINK_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "GET_LINK_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "GET_LINK_NFAILURE",
					payload: err.message,
				});
			});
	};

}
export const getshopAssistantID = (id) =>{
	return {
		
			 type: "GET_SHOPASSISTANTID",
			 payload:id
		 
	}
}
export const getNewOrdertlist = (params) => {
    return async (dispatch) => {
        function onSuccess(success) {
            dispatch({ type: "NEWORDERS_LIST_SUCCESS", payload: success });
            return success;
        }
        function onError(error) {
            dispatch({ type: "NEWORDERS_LIST_FAILED", payload: error });
            return error;
        }
      
        const success = await client.query({
            query:NEWORDERS_LIST,
            variables: {
                storeid:localStorage.getItem('storeid'),
            },
			
        });
        if (success) {
            return onSuccess(success);
        } else if (success.errors) {
            return onError(success.errors[0]);
		}		
    };
};
export const getAllCount = (params) => {
    return async (dispatch) => {
        function onSuccess(success) {
            dispatch({ type: "ALL_COUNT_SUCCESS", payload: success });
            return success;
        }
        function onError(error) {
            dispatch({ type: "ALL_COUNT_FAILED", payload: error });
            return error;
        }
      
        const success = await client.query({
            query:ALL_ORDERS_COUNT,
            variables: {
                storeid:localStorage.getItem('storeid'),
            },
			
        });
        if (success) {
            return onSuccess(success);
        } else if (success.errors) {
            return onError(success.errors[0]);
		}		
    };
};
export const getActiveOrdertlist = (params) => {
    return async (dispatch) => {
        function onSuccess(success) {
            dispatch({ type: "ACTIVEORDERS_LIST_SUCCESS", payload: success });
            return success;
        }
        function onError(error) {
            dispatch({ type: "ACTIVEORDERS_LIST_FAILED", payload: error });
            return error;
        }
      
        const success = await client.query({
            query:ACTIVEORDERS_LIST,
            variables: {
                storeid:localStorage.getItem('storeid'),
            },
			
        });
        if (success) {
            return onSuccess(success);             
        } else if (success.errors) {
            return onError(success.errors[0]);
		}		
    };
};
export const getReadyOrdertlist = (params) => {
    return async (dispatch) => {
        function onSuccess(success) {
            dispatch({ type: "READYORDERS_LIST_SUCCESS", payload: success });
            return success;
        }
        function onError(error) {
            dispatch({ type: "READYORDERS_LIST_FAILED", payload: error });
            return error;
        }
      
        const success = await client.query({
            query:READYORDERS_LIST,
            variables: {
                storeid:localStorage.getItem('storeid'),
            },
			refetchQueries: [{ query: READYORDERS_LIST,  variables: {
				storeid: localStorage.getItem("storeid"),
			  } }],
        });
        if (success) {
            return onSuccess(success);
        } else if (success.errors) {
            return onError(success.errors[0]);
		}		
    };
};
export const getOutForDeliverytlist = (params) => {
    return async (dispatch) => {
        function onSuccess(success) {
            dispatch({ type: "OUTFORDELIVERY_LIST_SUCCESS", payload: success });
            return success;
        }
        function onError(error) {
            dispatch({ type: "OUTFORDELIVERY_LIST_FAILED", payload: error });
            return error;
        }
      
        const success = await client.query({
            query:OUTFORDELIVERY_LIST,
            variables: {
                storeid:localStorage.getItem('storeid'),
            },
			
        });
        if (success) {
            return onSuccess(success);
        } else if (success.errors) {
            return onError(success.errors[0]);
		}		
    };
};
export const getCompletedOrdertlist = (params) => {
    return async (dispatch) => {
        function onSuccess(success) {
            dispatch({ type: "COMPLETEDORDERS_LIST_SUCCESS", payload: success });
            return success;
        }
        function onError(error) {
            dispatch({ type: "COMPLETEDORDERS_LIST_FAILED", payload: error });
            return error;
        }
      
        const success = await client.query({
            query:COMPLETEDORDERS_LIST,
            variables: {
                storeid:localStorage.getItem('storeid'),
				 pagination: { offset: 0, first: params?.first}
            },
			
        });
        if (success) {
            return onSuccess(success);
        } else if (success.errors) {
            return onError(success.errors[0]);
		}		
    };
};
export const getOrderCount = (params) => {
    return async (dispatch) => {
        function onSuccess(success) {
            dispatch({ type: "ORDER_COUNT_SUCCESS", payload: success });
            return success;
        }
        function onError(error) {
            dispatch({ type: "ORDER_COUNT_FAILED", payload: error });
            return error;
        }
      
        const success = await client.query({
            query:ORDER_COUNT,
            variables: {
                storeid:localStorage.getItem('storeid'),
				
            },
        });
        if (success) {
            return onSuccess(success);
        } else if (success.errors) {
            return onError(success.errors[0]);
		}		
    };
};