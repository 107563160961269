import React, { useState, useEffect } from "react";
import "./Businessproduct.css";
import CustomTextField from "../Commoncomponents/input";
import {
  Grid,
  Typography,
  Checkbox,
  Box,
  InputAdornment,
  useTheme,
  useMediaQuery,
} from "../MUIComponents/index";
import CustomCard from "../Commoncomponents/card";
import Bevarages from "../../utils/Icon/Bevarages.svg";
import CustomButton from "../Commoncomponents/button";
import Search from "../../utils/Icon/search.svg";
import {
  HeaderFontSize_24,
  ButtonFontSize_16,
  ButtonFontSize_18,
  HeaderNavigationFontSize_20,
  HeaderNavigationFontSize_20_xs_13,
  contentFontSize_16,
  contentFontSize_18,
} from "../Typography";
import { useNavigate } from "react-router-dom";
import { NoImage } from "../../utils/image";
import { message } from "antd";
function Businessproduct(props) {
  const nav = useNavigate();
  const [checkProducts, setCheckProducts] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [products, setProducts] = useState([]);
  const [offset, setOffset] = useState(0);
  const [first, setFirst] = useState(50);
  const [selectedCount, setSelectedCount] = useState();
  const [pro, setPro] = useState([]);
  const [categoryCount, setCategoryCount] = useState(
    localStorage.getItem("CategoryCount")
  );
  const [wordEntered, setWordEntered] = useState("");
  const [searchWord, setSearchWord] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [storeid, setStoreId] = useState(localStorage.getItem("storeid"));
  const [key, setKey] = useState("");
  const [from, setFrom] = useState(0);
  const [filteredProducts, setFilteredProducts] = useState(
    props.CatalogProducts
  );

  const [state, setState] = useState({
    CheckProducts: [],
    checkAll: false,
    department: localStorage.getItem("CatalogDepartment"),
    storeid: localStorage.getItem("storeid"),
    category: localStorage.getItem("CatalogCategory"),
    categoryid: localStorage.getItem("CategoryId"),
    key: "",
    first: 20,
    page: null,
    products: [],
    status: null,
  });
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    props.setCatalogProducts(localStorage.getItem("CatalogId"));
  }, []);

  useEffect(() => {
    if (props.MasterProducts.length !== 0) {
      setProducts((prevProducts) => [
        ...prevProducts,
        ...(props.MasterProducts.data.masterCategoryProducts || []).map(
          (p) => p
        ),
      ]);
    }
  }, [props.MasterProducts]);

  // useEffect(() => {}, [props.productAdded]);

  const selected_prod = props.CatalogProducts;
  const local = localStorage.getItem("CatalogDepartment");

  const handleSelectAll = (products) => {
    const newSelectAll = !selectAll;
    let newArray = [];

    if (newSelectAll) {
      products.forEach((source) => {
        if (source.isAdded === null) {
          newArray.push(source.id);
        }
      });
    }

    setSelectAll(newSelectAll);
    setCheckProducts(newArray);
    setSelectedCount(newSelectAll ? newArray.length : "");
    props.MastercheckBox(newArray);
  };

  const handleClickOne = () => {
    nav("/home/CatalogBuilder");
  };
  const handleClickTwo = () => {
    nav("/home/CategoryBuilder/Departments");
  };

  const handleClickThree = () => {
    nav("/home/CategoryBuilder/Products");
  };

  useEffect(() => {
    const categoryid = localStorage.getItem("CategoryId");
    const storeid = localStorage.getItem("storeid");
    props.getMasterProducts({ storeid, categoryid });
    props.setCatalogProducts([]);
  }, []);

  const handleAddCatalogProduct = () => {
    props.AddCatalogProducts({
      departmentName: localStorage.getItem("CatalogDepartment"),
      categoryName: localStorage.getItem("CatalogCategory"),
      storeid: localStorage.getItem("storeid"),
      productid: checkProducts,
    }
  );

    setCheckProducts([]);
    setCheckAll(false);
    message.success({
      content: "Product Added successfully",
      className: "custom-class",
      style: {
        marginTop: "20vh",
      },
      duration: 2
    });
    setTimeout(() => {
      nav("/home/AddProducts")
    }, 2000);
  };

  const handleSearchInputChange = (event) => {
    const searchQuery = event.target.value.toLowerCase();
    setSearchQuery(searchQuery);
    const filteredData =
      props?.MasterProducts &&
      props?.MasterProducts.data &&
      props?.MasterProducts.data.masterCategoryProducts.filter((product) =>
        product.productname.toLowerCase().includes(searchQuery)
      );
    setFilteredProducts(filteredData);
  };

  const handleProductCheckboxChange = (productId) => {
    let newArray = [...checkProducts];
    const index = newArray.indexOf(productId);
  
    if (index !== -1) {
      newArray.splice(index, 1);
    } else {
      newArray.push(productId);
    }
    if (selectAll) {
      setSelectAll(false);
    }
    setCheckProducts(newArray);
    setSelectedCount(newArray.length);
  };

  const LoadMore = () => {
    const newOffset = offset + 10;
    setOffset(newOffset);
    props.getMasterProducts({ id: state.categoryid, offset: newOffset });
  };

  const theme = useTheme();
  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <div style={{ display: "flex", gap: "3px", width: "100%" }}>
        <Typography
          className="Main-Business"
          fontSize={HeaderNavigationFontSize_20_xs_13}
          onClick={handleClickOne}
          style={{ cursor: "pointer" }}
        >
          Business Type
        </Typography>
        <Typography
          className="Main-Business"
          fontSize={HeaderNavigationFontSize_20_xs_13}
        >
          /
        </Typography>
        <Typography
          className="Main-Business"
          fontSize={HeaderNavigationFontSize_20_xs_13}
          style={{ cursor: "pointer" }}
          onClick={handleClickTwo}
        >
          Category
        </Typography>
        <Typography
          className="Main-Business"
          fontSize={HeaderNavigationFontSize_20_xs_13}
        >
          /
        </Typography>
        <Typography
          className="Main-Business"
          fontSize={HeaderNavigationFontSize_20_xs_13}
          style={{ cursor: "pointer" }}
          onClick={handleClickThree}
        >
          Sub Category
        </Typography>
        <Typography
          className="Main-Business"
          fontSize={HeaderNavigationFontSize_20_xs_13}
        >
          /
        </Typography>
        <Typography
          className="Main-Business"
          style={{ color: "#85BE49" }}
          fontSize={HeaderNavigationFontSize_20_xs_13}
        >
          Product
        </Typography>
      </div>
      <div className="textsearch">
        <CustomTextField
          name="key"
          type="text"
          width="100%"
          borderRadius="20px"
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
          // value={state.key}
          onChange={handleSearchInputChange}
          placeholder={"Search Products"}
          startAdornment={
            <InputAdornment position="start">
              <img src={Search} alt="Search" style={{ marginRight: "8px" }} />
            </InputAdornment>
          }
        />
      </div>

      <>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <Typography
              className="select-all"
              fontSize={HeaderNavigationFontSize_20}
            >
              Choose your Product
            </Typography>
          </div>
          <div style={{ justifyContent: "flex-start", paddingRight: "15px" }}>
            <Typography
              className="select"
              fontSize={HeaderNavigationFontSize_20}
            >
              <span
                onClick={() =>
                  handleSelectAll(
                    props.MasterProducts.data.masterCategoryProducts
                  )
                }
                style={{ cursor: "pointer" }}
              >
                {selectAll ? "Unselect All" : "Select All"}
              </span>
            </Typography>
          </div>
        </div>
        <>
          {searchQuery ? (
            <>
              {filteredProducts != 0 &&
                filteredProducts.map((source) => (
                  <div
                    style={{
                      paddingTop: "30px",
                      // paddingRight: "30px",
                      boxSizing: "border-box",
                    }}
                    key={source.id} 
                  >
                    <CustomCard padding={"20px"} width={"100%"}>
                      <Box sx={{ flexGrow: 1 }}>
                        <Grid
                          container
                          spacing={2}
                          sx={{ paddingLeft: "0px", paddingTop: "0px" }}
                        >
                          <Grid item xs={3} sm={2} md={2} lg={1} xl={1}>
                            <CustomCard
                              padding="2px"
                              width={"70px"}
                              height={"70px"}
                              background="#F2F2F2"
                              boxShadow="none"
                              borderRadius="10px"
                              sx={{ alignItems: "center" }}

                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                className={source.isAdded ? 'added-image' : ''}
                                  src={source.image && source.image.primary}
                                  alt={source.productname}
                                  style={{
                                    width: matches ? "45px" : "80%",
                                  }}
                                />
                              </div>
                            </CustomCard>
                          </Grid>
                          <Grid item xs={7} sm={5} md={4} lg={5} xl={4}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                height: "100%",
                                paddingLeft: "20px",
                                boxSizing: "border-box",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "8px",
                                }}
                              >
                                <div>
                                  <Typography
                                    className="select-product"
                                    fontSize={contentFontSize_18}
                                    color={source.isAdded === true ? '#6D6D6D' :'black'}
                                  >
                                    {source.productname}
                                  </Typography>
                                </div>
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={0} sm={3} md={3} lg={3} xl={3}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "flex-end",
                                height: "100%",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "100%",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "15px",
                                  }}
                                >
                                  <div>
                                    <Typography
                                      className="price-product"
                                      fontSize={contentFontSize_16}
                                      color={source.isAdded === true ? '#6D6D6D' :'black'}
                                    >
                                       ₹ {(source.price).toFixed(2)}
                                    </Typography>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={0} sm={2} md={4} lg={3} xl={4}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "flex-end",
                                height: "100%",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "100%",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "15px",
                                  }}
                                >
                                  <div>
                                    <Checkbox
                                    disableRipple={true}
                                      defaultChecked={source.isAdded}
                                      checked={
                                        selectAll ||
                                        checkProducts.includes(source.id)
                                      }
                                      onChange={() =>
                                        handleProductCheckboxChange(source.id)
                                      }
                                      sx={{
                                        borderRadius: "4px",
                                        width: "90px",
                                        size: "medium",
                                        fontSize: "20px",
                                        border: "1%",
                                        "& .MuiSvgIcon-root": {
                                          color: source.isAdded ? "#A9A9A9" : "#85BE49",
                                        },
                                      }}
                                      style={{
                                        transform: matches
                                          ? "none"
                                          : "scale(1.6)",
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </Box>
                    </CustomCard>
                  </div>
                ))}
              {filteredProducts == 0 && (
                <>
                  <div
                    style={{
                      marginTop: "30px",
                      display: "flex",
                      alignContent: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      fontSize={HeaderNavigationFontSize_20}
                      className="Add-product-btn"
                    >
                      No Products to display
                    </Typography>
                  </div>
                </>
              )}
              {filteredProducts != 0 && (
                <>
                  <div style={{ marginTop: "40px", textAlign: "center" }}>
              <CustomButton
                width={
                  upXlmatch
                    ? "358px"
                    : xlmatch
                    ? lgmatch
                      ? mdmatch
                        ? smmatch
                          ? xsmatch
                            ? "250px"
                            : "250px"
                          : "310px"
                        : "330px"
                      : "340px"
                    : "358px"
                }
                borderRadius="20px"
              >
                <Typography
                  fontSize={ButtonFontSize_18}
                  className="Add-product-btn"
                  onClick={handleAddCatalogProduct}
                >
                  {" "}
                  Add {selectedCount} Product{selectedCount !== 1 ? "s" : ""}
                  {/* {checkProducts.length !== 1 ? "s" : ""} */}
                </Typography>
              </CustomButton>
            </div>
                </>
              )}

            </>
          ) : (
            <>
              {props?.MasterProducts &&
              props?.MasterProducts.data &&
              props?.MasterProducts.data.masterCategoryProducts.length != 0 ? (
                props?.MasterProducts &&
                props?.MasterProducts.data &&
                props?.MasterProducts.data.masterCategoryProducts
                // .sort((a, b) => (a.isAdded === b.isAdded ? 0 : a.isAdded ? 1 : -1))
                .map(
                  (source) => (
                    <div
                      style={{
                        paddingTop: "30px",
                        // paddingRight: "30px",
                        boxSizing: "border-box",
                      }}
                      key={source.id}
                    >
                      <CustomCard padding={"20px"} width={"100%"}>
                        <Box sx={{ flexGrow: 1 }}>
                          <Grid
                            container
                            spacing={2}
                            sx={{ paddingLeft: "0px", paddingTop: "0px" }}
                          >
                            <Grid item xs={3} sm={2} md={2} lg={1} xl={1}>
                              <CustomCard
                                padding="2px"
                                width={"70px"}
                                height={"70px"}
                                background="#F2F2F2"
                                boxShadow="none"
                                borderRadius="10px"
                                sx={{ alignItems: "center" }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                  className={source.isAdded ? 'added-image' : ''}
                                    src={source.image && source.image.primary}
                                    alt="Bevarages"
                                    style={{
                                      width: matches ? "45px" : "80%",
                                    }}
                                  />
                                </div>
                              </CustomCard>
                            </Grid>
                            <Grid item xs={7} sm={5} md={4} lg={5} xl={4}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  height: "100%",
                                  paddingLeft: "20px",
                                  boxSizing: "border-box",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "8px",
                                  }}
                                >
                                  <div>
                                    <Typography
                                      className="select-product"
                                      fontSize={contentFontSize_18}
                                      color={source.isAdded === true ? '#6D6D6D' :'black'}
                                    >
                                      {source.productname}
                                    </Typography>
                                  </div>
                                </div>
                              </div>
                            </Grid>
                            <Grid item xs={0} sm={3} md={3} lg={3} xl={3}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "flex-end",
                                  height: "100%",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "15px",
                                    }}
                                  >
                                    <div>
                                      <Typography
                                        className="price-product"
                                        fontSize={contentFontSize_16}
                                        color={source.isAdded === true ? '#6D6D6D' :'black'}
                                      >
                                       ₹ {parseFloat(source.price).toFixed(2)}
                                      </Typography>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Grid>
                            <Grid item xs={0} sm={2} md={4} lg={3} xl={4}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "flex-end",
                                  height: "100%",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "15px",
                                    }}
                                  >
                                    <div>
                                      <Checkbox
                                      disableRipple={true}
                                        checked={
                                          source.isAdded ||
                                          selectAll ||
                                          checkProducts.includes(source.id)
                                        }
                                        onChange={() =>
                                          handleProductCheckboxChange(source.id)
                                        }
                                        disabled={source.isAdded} 
                                        sx={{
                                          borderRadius: "4px",
                                          width: "90px",
                                          size: "medium",
                                          fontSize: "20px",
                                          border: "1%",
                                          "& .MuiSvgIcon-root": {
                                            color: source.isAdded ? "#A9A9A9" : "#85BE49",
                                          },
                                        }}
                                        style={{
                                          transform: matches
                                            ? "none"
                                            : "scale(1.6)",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </Box>
                      </CustomCard>
                    </div>
                  )
                )
              ) : (
                <>
                  <div
                    style={{
                      marginTop: "30px",
                      display: "flex",
                      alignContent: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      fontSize={HeaderNavigationFontSize_20}
                      className="Add-product-btn"
                    >
                      No Products to display
                    </Typography>
                  </div>
                </>
              )}
            </>
          )}

          {!searchQuery &&
          categoryCount >= first &&
          products.length < categoryCount ? (
            <div style={{ marginTop: "20px" }}>
              <CustomButton width={"20%"} borderRadius="10px">
                <Typography
                  fontSize={ButtonFontSize_18}
                  className="Add-product-btn"
                  onClick={LoadMore}
                >
                  Load More
                </Typography>
              </CustomButton>
            </div>
          ) : null}


          

          {!searchQuery &&
          props?.MasterProducts &&
          props?.MasterProducts.data &&
          props?.MasterProducts.data.masterCategoryProducts.length != 0 ? (
            <div style={{ marginTop: "40px", textAlign: "center" }}>
              <CustomButton
                width={
                  upXlmatch
                    ? "358px"
                    : xlmatch
                    ? lgmatch
                      ? mdmatch
                        ? smmatch
                          ? xsmatch
                            ? "250px"
                            : "250px"
                          : "310px"
                        : "330px"
                      : "340px"
                    : "358px"
                }
                borderRadius="20px"
              >
                <Typography
                  fontSize={ButtonFontSize_18}
                  className="Add-product-btn"
                  onClick={handleAddCatalogProduct}
                >
                  {" "}
                  Add {selectedCount} Product{selectedCount !== 1 ? "s" : ""}
                  {/* {checkProducts.length !== 1 ? "s" : ""} */}
                </Typography>
              </CustomButton>
            </div>
          ) : null}
        </>
      </>
    </>
  );
}

export default Businessproduct;
