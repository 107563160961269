import React, { useState,useEffect } from 'react';
import { FormControl, Select, MenuItem, Checkbox, Box, OutlinedInput, ListItemText, IconButton, Grid } from '../../MUIComponents/index';
import CustomButton from '../../Commoncomponents/button';
import Chip from '@mui/material/Chip';
import { useMediaQuery } from "../../MUIComponents/index";
import { useTheme, Typography } from "../../MUIComponents/index";
import { contentFontSize_16 } from '../../Typography/index';
import { contentFontSize_18_xs_13 } from '../../Typography/index';
import { Alert } from '../../MUIComponents/index';
import { Stack } from '../../MUIComponents/index';
import { message } from 'antd';
import CloseIcon from '@mui/icons-material/Close';

export default function Businesstype({selectedBusinessTypes,setSelectedBusinessTypes}) {
 
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [tempBusiness, setTempBusiness] = useState([]);
  
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));

  const Business = [
    { id: 1, name: "Groceries & Essentials", iconImage: "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Groceries+%26+Essentials.png" },
    { id: 2, name: "Restaurants & Bakery", iconImage: "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Restaurant+%26+bakery.jpg" },
    { id: 3, name: "Fish & Meat", iconImage: "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Meat+%26+Fish.jpg" },
    { id: 4, name: "Fruits & Vegetables", iconImage: "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Fruits+%26+Vegetables.png" },
    { id: 5, name: "Medicines", iconImage: "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Medicines.webp" },
    { id: 6, name: "Electronics & Home Appliances", iconImage: "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Electronics+%26+Home+Appliances.jpg" },
    { id: 7, name: "Lifestyle & Fashion", iconImage: "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Lifestyle+%26+Fashion.jpg" },
    { id: 8, name: "Sports & Fitness", iconImage: "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/sports+%26+fitness.jpg" },
    { id: 9, name: "Pet Care", iconImage: "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Pet+Care.jpg" },
    { id: 10, name: "Home decor & Furnishing", iconImage: "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Home+decor+%26+Furnishing.png" },
    { id: 11, name: "Home Business", iconImage: "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Home+Business.jpg" }
  ];

  const handleChange = (event) => {
    const { value } = event.target;
    setTempBusiness(value);
    if (value.includes(11)) {
      localStorage.setItem('homeBusinessSelected', true);
    } else {
      localStorage.removeItem('homeBusinessSelected');
    }
  };

  const handleConfirm = () => {
    setSelectedBusinessTypes(tempBusiness);
    message.success({
      content: "Department Added Successfully",
      className: "custom-class",
      style: {
        marginTop: "10vh",
      },
      duration: 3.5,
    });
    setTimeout(() => {
      setIsDropdownOpen(false);
    }, 4000);
  };

  
  useEffect(() => {
    const handleScroll = () => {
      if (isDropdownOpen) {
        setIsDropdownOpen(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll); 
    };
  }, [isDropdownOpen]);

  const handleRemove = (id) => {
    const updatedBusinessTypes = selectedBusinessTypes.filter(item => item !== id);
    setSelectedBusinessTypes(updatedBusinessTypes);
  };

  return (
    <>
      <FormControl
        fullWidth
        sx={{ borderRadius: "10px", background: "white" }}
        size="medium"
      >
        <Select
        required
          multiple
          value={tempBusiness}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" />}
          renderValue={() => ''}
          open={isDropdownOpen}
          // MenuProps={{
          //   // disableScrollLock: true,
          //   marginThreshold: null,
          //   open: isDropdownOpen,
          // }}
          sx={{
            color: "white",
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: '#85BE49',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#85BE49',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: '#85BE49',
            },
          }}
          size={matches ? "small" : "normal"}
          InputProps={{
            sx: {
              fontSize: matches && '13px',
            },
          }}
          onOpen={() => setIsDropdownOpen(true)}
          onClose={() => setIsDropdownOpen(false)}
          disableScrollLock
        >
          {Business.map((b) => (
            <MenuItem key={b.id} value={b.id}>
              <Checkbox checked={tempBusiness.includes(b.id)} />
              <ListItemText primary={b.name} />
            </MenuItem>
          ))}
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "20px 0px" }}>
            <CustomButton matchPadding={true} padding="10px" borderRadius="13px" onClick={handleConfirm}>
              <Typography fontSize={contentFontSize_18_xs_13} style={{ fontWeight: "700" }}>
                Confirm Department
              </Typography>
            </CustomButton>
          </div>
        </Select>
        {/* {selectedBusinessTypes?.length === 0 ? (
          <div style={{ background: "#F4F5F9" }}>
            <Stack sx={{ width: '100%', marginTop: "20px" }} spacing={2}>
              <Alert severity="warning">Please select at least one business type</Alert>
            </Stack>
          </div>
        ) : null} */}
      </FormControl>
      <div style={{ marginTop: "15px" }}>
        <Box mt={2}>
          <Grid container spacing={3}>
            {selectedBusinessTypes?.map((selectedType) => (
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3} key={selectedType}>
                <div>
                  <CustomButton
                    variant="contained"
                    color="white"
                    background="#85BE49"
                    width="100%"
                    borderRadius="12.09px"
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: smmatch ? "space-between" : "space-between",
                        alignItems: "center",
                        width: "100%",
                        height: smmatch && "20px"
                      }}
                    >
                      <Typography fontSize={contentFontSize_16}>
                        {Business.find(e => e.id === selectedType).name}
                      </Typography>
                      <IconButton onClick={() => handleRemove(selectedType)}>
                        <CloseIcon style={{ color: "white", width: matches && "13px" }} />
                      </IconButton>
                    </div>
                  </CustomButton>
                </div>
              </Grid>
            ))}
          </Grid>
        </Box>
      </div>
    </>
  );
}
