import React, { useState, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import Marker from "../Marker";

const GoogleMap = ({ mapCoordinates, setMapCoordinates }) => {
  const [center, setCenter] = useState({
    lat: 28.6139298,
    lng: 77.2088282,
  });

  useEffect(() => {
    if (mapCoordinates) {
      setCenter({
        lat: Number(mapCoordinates.lat),
        lng: Number(mapCoordinates.lng),
      });
    }
  }, [mapCoordinates]);

  // Handle map click to change marker position
  const changeMarkerPosition = (e) => {
    const { lat, lng } = e;
    setCenter({
      lat: Number(lat),
      lng: Number(lng),
    });
    setMapCoordinates({ lat, lng });
  };

  // Handle marker drag end to update coordinates
  const onMarkerDragEnd = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setCenter({
      lat: Number(lat),
      lng: Number(lng),
    });
    setMapCoordinates({ lat, lng });
  };

  return (
    <div style={{ height: "50vh", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyC8fl3_hu8DuU1UUlpRpyLQssuN0ZSYunU" }}
        defaultCenter={center}
        defaultZoom={15}
        center={center}
        onClick={changeMarkerPosition} // Click event to move marker
      >
        <Marker
          draggable={true}
          lat={center.lat}
          lng={center.lng}
          text="My Marker"
          onDragEnd={onMarkerDragEnd} 
        />
      </GoogleMapReact>
    </div>
  );
};

export default GoogleMap;
