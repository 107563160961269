// import React from "react";
// import TextField from "@mui/material/TextField";
// import PlacesAutocomplete, {
// 	geocodeByAddress,
// 	getLatLng,
// } from "react-places-autocomplete";
// import Grid from "@mui/material/Grid";
// class EditLocation extends React.Component {
// 	constructor(props) {
// 		super(props);
// 		this.state = {
// 			address: this.props?.retailerDetails?.data?.retailerdetails?.stores[0]
// 				.storeLocation,
// 				isMobile: window.innerWidth < 768
// 		};
// 	}
// 	updateIsMobile=()=> {
// 		this.setState({
// 			isMobile: window.innerWidth < 768
// 		});
// 	}
	
	
// 	componentWillUnmount() {
// 		window.removeEventListener('resize', this.updateIsMobile);
// 	}
// 	  componentDidMount() {
// 		window.addEventListener('resize', this.updateIsMobile);
// 	  }
// 	handleChange = (address) => {
// 		this.setState({ address });
// 	};

// 	handleSelect = (address) => {
// 		this.setState({ address });
// 		geocodeByAddress(address)
// 			.then((results) => getLatLng(results[0]))
// 			.then((latLng) =>
// 				this.props.getNewLocation({
// 					address: address,
// 					details: this.props.retailerDetails.data,
// 					coordinates: latLng,
// 				})
// 			)
// 			.catch((error) => console.error("Error", error));
// 	};

// 	render() {
// 		const searchOptions = {
// 			componentRestrictions: { country: ["IN"] },
// 		};
// 		return (
// 			this.state.isMobile ?
// <Grid item xs={12}>
// <PlacesAutocomplete
// 				searchOptions={searchOptions}
// 				value={this.state.address}
// 				onChange={(e) => this.handleChange(e)}
// 				onSelect={(e) => this.handleSelect(e)}>
// 				{({ getInputProps, suggestions, getSuggestionItemProps }) => (
// 					<div
// 						// style={{
// 						// 	// margin: "16px 0px 0px",
// 						// 	// padding: "3px 0 25px",
// 						// 	// marginTop: "16px",
// 						// 	width: "100%",
// 						// }}
// 						>
						
// 							<TextField
// 							style={{  position: "relative",width:'100%',marginBottom:'15px',marginTop:'15px'}}
// 							InputProps={{ style: { height: 35 } }}
// 							InputLabelProps={{
// 								shrink: true,
// 							}}
// 								// fullWidth
// 								variant="outlined"
// 								required
// 								label="Store Location *"
// 								{...getInputProps({
// 									className: "location-search-input",
// 								})}
// 							/>
// 						<div
// 						style={{marginLeft:10,width: "550px"}}
// 							className="autocomplete-dropdown-container"
// 							>
// 							{suggestions.map((suggestion) => {
// 								const className = suggestion.active
// 									? "suggestion-item--active"
// 									: "suggestion-item";

// 								const style = suggestion.active
// 									? { backgroundColor: "#fafafa", cursor: "pointer" }
// 									: { backgroundColor: "#ffffff", cursor: "pointer" };
// 								return (
// 									<div
// 										{...getSuggestionItemProps(suggestion, {
// 											className,
// 											style,
// 										})}>
// 										<span>{suggestion.description}</span>
// 									</div>
// 								);
// 							})}
// 						</div>
// 					</div>
// 				)}
// 			</PlacesAutocomplete>
// 						</Grid>


// 			:


// 			<PlacesAutocomplete
// 				searchOptions={searchOptions}
// 				value={this.state.address}
// 				onChange={(e) => this.handleChange(e)}
// 				onSelect={(e) => this.handleSelect(e)}>
// 				{({ getInputProps, suggestions, getSuggestionItemProps }) => (
// 					<div
// 						style={{
// 							margin: "16px 0px 0px",
// 							padding: "3px 0 25px",
// 							marginTop: "16px",
// 							width: "100%",
// 						}}>
// 						<Grid item xs={12} style={{ paddingTop: 50 }}>
// 							<TextField
// 							style={{ zIndex: "1", position: "relative"}}
// 							InputProps={{ style: { height: 35 } }}
// 							InputLabelProps={{
// 								shrink: true,
// 							}}
// 								fullWidth
// 								variant="outlined"
// 								required
// 								label="Store Location *"
// 								{...getInputProps({
// 									className: "location-search-input",
// 								})}
// 							/>
// 						</Grid>
// 						<div
// 						style={{marginLeft:10,width: "550px"}}
// 							className="autocomplete-dropdown-container"
// 							>
// 							{suggestions.map((suggestion) => {
// 								const className = suggestion.active
// 									? "suggestion-item--active"
// 									: "suggestion-item";

// 								const style = suggestion.active
// 									? { backgroundColor: "#fafafa", cursor: "pointer" }
// 									: { backgroundColor: "#ffffff", cursor: "pointer" };
// 								return (
// 									<div
// 										{...getSuggestionItemProps(suggestion, {
// 											className,
// 											style,
// 										})}>
// 										<span>{suggestion.description}</span>
// 									</div>
// 								);
// 							})}
// 						</div>
// 					</div>
// 				)}
// 			</PlacesAutocomplete>
// 		);
// 	}
// }
// export default EditLocation;
 


//new code by berlin

// import React, { useState, useEffect } from "react";
// import TextField from "@mui/material/TextField";
// import PlacesAutocomplete, {
//   geocodeByAddress,
//   getLatLng,
// } from "react-places-autocomplete";
// import StoreLocation4 from "../../utils/images/StoreLocation4.svg";
// import { useMediaQuery } from "../MUIComponents/index";
// import { useTheme } from "../MUIComponents/index";
// import { InputAdornment, Typography } from "../MUIComponents/index";
// import {contentFontSize_16} from '../Typography/index'

// const EditLocation = ({props,setMapaddress,setMapCoordinates,setMapDetails,mapaddress}) => {
//   const [address, setAddress] = useState(
//     props?.retailerDetails?.data?.retailerdetails?.stores[0]?.storeLocation || ""
//   );
  
//   useEffect(() => {
//     if (props?.retailerDetails?.data?.retailerdetails?.stores[0]?.storeLocation) {
//       setAddress(props.retailerDetails.data.retailerdetails.stores[0].storeLocation);
//     }
//   }, [props]);


//   const theme = useTheme();
//   const matches = useMediaQuery(theme.breakpoints.down("md"));

//   const handleChange = (address) => {
//     setAddress(address);
//   };

 
//   const handleSelect = (address) => {
//     setAddress(address);
//     geocodeByAddress(address)
//       .then((results) => {
//         if (results.length === 0) {
//           throw new Error("No results found for the provided address");
//         }
//         return getLatLng(results[0]);
//       })
//       .then((latLng) => {
//         setMapaddress(address);
//         setMapDetails(props.retailerDetails.data);
//         setMapCoordinates(latLng);
//       })
//       .catch((error) => {
//         // Handle errors
//         console.error("Error:", error.message);
//         // You might want to set some state to indicate an error occurred
//       });
//   };
  


  
  

//   const searchOptions = {
//     componentRestrictions: { country: ["IN"] },
//   };

//   return (
//     <PlacesAutocomplete
//       searchOptions={searchOptions}
//       value={address}
//       onChange={(e) => handleChange(e)}
//       onSelect={(e) => handleSelect(e)}
//     >
//       {({ getInputProps, suggestions, getSuggestionItemProps }) => (
//         <div>
// 			<div style={{ display: "flex",flexDirection: "column",gap: "10px"}}>
// 			 <Typography
//              style={{fontFamily:"Roboto",fontWeight:"400"}}
//               fontSize={contentFontSize_16}
//             >
//               Store Location <span style={{ color: " #EA4335" }}>*</span>
//             </Typography>
//           <TextField
          
// 			variant="outlined"
// 			sx={{
// 			  width: '100%',
		  
// 			  '& .MuiOutlinedInput-root': {
// 				'& fieldset': {
// 				  borderColor: '#85BE49', 
// 				},
// 				'&.Mui-focused fieldset': {
// 				  borderColor: '#85BE49', 
// 				},
// 			  },
// 			  '& .MuiInputBase-input': {
// 				// backgroundColor: 'white',
// 				borderRadius:"10px" 
// 			  },
// 			}}
// 			InputProps={{
// 			 sx: {
// 			   fontSize: matches && '13px', 
// 			   background: 'rgba(133, 190, 73, 0.35)'
// 			 },
// 			 endAdornment: (
// 			  <InputAdornment position="start">
// 				<img
// 				src={StoreLocation4}
// 				alt="Store Location"
// 				style={{ marginRight: "8px",}}
// 			  />
			  
// 			  </InputAdornment>
// 			),
// 		 }}
// 			 size={matches ? "small" : "normal"}
          
//             required
           
//             {...getInputProps({
//               className: "location-search-input",
//             })}
//           />
// 		  </div>
//           <div style={{ marginLeft: 10, width: "550px" }} className="autocomplete-dropdown-container">
//             {suggestions.map((suggestion) => {
//               const className = suggestion.active ? "suggestion-item--active" : "suggestion-item";

//               const style = suggestion.active
//                 ? { backgroundColor: "#fafafa", cursor: "pointer" }
//                 : { backgroundColor: "#ffffff", cursor: "pointer" };
//               return (
//                 <div {...getSuggestionItemProps(suggestion, { className, style })}>
//                   <span>{suggestion.description}</span>
//                 </div>
//               );
//             })}
//           </div>
//         </div>
//       )}
//     </PlacesAutocomplete>
//   );
// };

// export default EditLocation;


//new celesta
import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import StoreLocation4 from "../../utils/images/StoreLocation4.svg";
import { useMediaQuery } from "../MUIComponents/index";
import { useTheme } from "../MUIComponents/index";
import { InputAdornment, Typography } from "../MUIComponents/index";
import {contentFontSize_16} from '../Typography/index'

const EditLocation = ({props,setMapaddress,setMapCoordinates,setMapDetails,mapaddress,mapCoordinates}) => {
  const [address, setAddress] = useState(
    props?.retailerDetails?.data?.retailerdetails?.stores[0]?.storeLocation || ""
  );
  
  useEffect(() => {
    if (props?.retailerDetails?.data?.retailerdetails?.stores[0]?.storeLocation) {
      handleSelect(props?.retailerDetails?.data?.retailerdetails?.stores[0]?.storeLocation);
     // setAddress(props?.retailerDetails?.data?.retailerdetails?.stores[0]?.storeLocation);
    }
  }, [props?.retailerDetails?.data?.retailerdetails?.stores[0]?.storeLocation]);
  

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const handleChange = (address) => {
    setAddress(address);
  };

 
  const handleSelect = (address) => {
    setAddress(address);
    geocodeByAddress(address)
      .then((results) => {
        if (results.length === 0) {
          throw new Error("No results found for the provided address");
        }
        return getLatLng(results[0]);
      })
      .then((latLng) => {
        setMapaddress(address);
        setMapDetails(props.retailerDetails.data);
        // setMapCoordinates(latLng);
        // console.log("new address",mapCoordinates)
      })
      .catch((error) => {
        // Handle errors
        console.error("Error:", error.message);
        // You might want to set some state to indicate an error occurred
      });
  };
  


  
  

  const searchOptions = {
    componentRestrictions: { country: ["IN"] },
  };

  return (
    <PlacesAutocomplete
      searchOptions={searchOptions}
      value={address}
      onChange={(e) => handleChange(e)}
      onSelect={(e) => handleSelect(e)}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps }) => (
        <div>
			<div style={{ display: "flex",flexDirection: "column",gap: "10px"}}>
			 <Typography
             style={{fontFamily:"Roboto",fontWeight:"400"}}
              fontSize={contentFontSize_16}
            >
              Store Location <span style={{ color: " #EA4335" }}>*</span>
            </Typography>
          <TextField
          
			variant="outlined"
			sx={{
			  width: '100%',
		  
			  '& .MuiOutlinedInput-root': {
				'& fieldset': {
				  borderColor: '#85BE49', 
				},
				'&.Mui-focused fieldset': {
				  borderColor: '#85BE49', 
				},
			  },
			  '& .MuiInputBase-input': {
				// backgroundColor: 'white',
				borderRadius:"10px" 
			  },
			}}
			InputProps={{
			 sx: {
			   fontSize: matches && '13px', 
			   background: 'rgba(133, 190, 73, 0.35)'
			 },
			 endAdornment: (
			  <InputAdornment position="start">
				<img
				src={StoreLocation4}
				alt="Store Location"
				style={{ marginRight: "8px",}}
			  />
			  
			  </InputAdornment>
			),
		 }}
			 size={matches ? "small" : "normal"}
          
            required
           
            {...getInputProps({
              className: "location-search-input",
            })}
          />
		  </div>
          <div style={{ marginLeft: 10, width: "550px" }} className="autocomplete-dropdown-container">
            {suggestions.map((suggestion) => {
              const className = suggestion.active ? "suggestion-item--active" : "suggestion-item";

              const style = suggestion.active
                ? { backgroundColor: "#fafafa", cursor: "pointer" }
                : { backgroundColor: "#ffffff", cursor: "pointer" };
              return (
                <div {...getSuggestionItemProps(suggestion, { className, style })}>
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default EditLocation;
