import client from "../../../Configurations/apollo";
import { ApolloClient } from "@apollo/client";
import { InMemoryCache } from "@apollo/client";
import {
	GET_ORDER_SEARCH,
	GET_ORDER_DETAILS,
	CHANGE_STATUS,
	REJECTED_STATUS,
	NOTIFICATION_STATUS,
	SET_PAYMENT,
	GET_ESTIMATED_TIME,
	NEWORDERS_LIST,
	CHANGE_TO_ACTIVE_STATUS,
	CHANGE_ORDER_STATUS_TO_READY,
	CHANGE_ORDER_STATUS_TO_DELIVERY,
	CHANGE_ORDER_STATUS_TO_COMPLETED,
	GET_ALL_PENDINGSETTLEMENTS,
	GET_ALL_PENDINGPAYMENTS,
	GET_ALL_COMPLETEDSETTLEMENTS,
	GET_COMPLETEDPAYMENT_SEARCH,
	DEPOSIT_COD
} from "./gqlQuery";

// Client for PUSH NOTIFICATION
const customClient = new ApolloClient({
	uri: `${process.env.REACT_APP_SCHEDULE_ALERT_URL}`,
	cache: new InMemoryCache(),
	defaultOptions: {
		watchQuery: {
			fetchPolicy: "no-cache",
			errorPolicy: "ignore",
		},
		query: {
			fetchPolicy: "no-cache",
			errorPolicy: "all",
		},
	},
  });


const token = localStorage.getItem('token');
export const getSearchResult = (params) => {
	return async (dispatch) => {
		// dispatch({ type: "SEARCH_ORDER_LOADING"});
		function onSuccess(success) {
			dispatch({ type: "SEARCH_SUCCESS", payload: success });
			return success;
			
		}
		function onError(error) {
			dispatch({ type: "SEARCH_FAILED", payload: error });
			return error;
		}
		function onNetworkError() {
			dispatch({ type: "SEARCH_ERROR" });
		}
		const success = await client.query({
			query: GET_ORDER_SEARCH,
			variables: {
				// pass storeID dynamically as params.id
				pagination: { offset: params.offset, first: params.first},
				storeid: localStorage.getItem('storeid'),
				orderNumber: params.orderNumber,
				customerMobile: params.mobileNumber,
				orderstatus: params.status,
				dateFrom: params.dateFrom,
				dateTo: params.dateTo,
				deliveryDateFrom: params.deliveryDateFrom,
				deliveryDateTo: params.deliveryDateTo,
			},
			context: {
				headers: {
				Authorization:'Bearer ' + token
				}
			},
		});
		if (success) {
			return onSuccess(success);
		} else if (success.errors) {
			return onError(success.errors[0]);
		} else return onNetworkError();
	};
};

export const getSearchDetails = (params) => {
	return async (dispatch) => {
		function onSuccess(success) {
			dispatch({ type: "SEARCH_DETAILS_SUCCESS", payload: success });
			return success;
		}
		function onError(error) {
			dispatch({ type: "SEARCH_DETAILS_FAILED", payload: error });
			return error;
		}
		function onNetworkError() {
			dispatch({ type: "SEARCH_DETAILS_ERROR" });
		}
		const success = await client.query({
			query: GET_ORDER_DETAILS,
			variables: {
				orderid: params,
			},
		});
		if (success) {
			return onSuccess(success);
			
		} else if (success.errors) {
			return onError(success.errors[0]);
		} else return onNetworkError();
	};
};

export const setOrderDetails = (params) => {
	return {
		type:"SET_ORDER_DETAILS",
		payload:params
		}
	};
	
	
	
export const postStatusChange = (params) => {
	return (dispatch) => {
		dispatch({
			type: "CHANGING_STATUS",
		});
		client
			.mutate({
				mutation: CHANGE_STATUS,
				variables: {
					orderid: params.orderid,
					stats: { status: params.status },
				},
			})
			.then((res) => {
				if (res.data) {
					dispatch({
						type: "CHANGED_STATUS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "STATUS_CHANGE_FAILED",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "STATUS_CHANGE_FAILED",
					payload: err.message,
				});
			});
	};
};

export const rejectedStatusHandler = (params) => {
	return (dispatch) => {
		dispatch({
			type: "CHANGING_TO_REJECTED",
		});
		client
			.mutate({
				mutation: REJECTED_STATUS,
				variables: {
					commentsSeller: params.commentsSeller,
					orderid: params.orderid,
					stats: params.stats,
				},
			})
			.then((res) => {
				if (res.data) {
					dispatch({
						type: "CHANGED_TO_REJECTED",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "REJECTION_FAILED",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "REJECTION_FAILED",
					payload: err.message,
				});
			});
	};
};
export const scheduleAlert = (params) => {
	return (dispatch) => {
		dispatch({
			type: "SCHEDULING_ALERT",
		});
		customClient
			.mutate({
				mutation: NOTIFICATION_STATUS,
				variables: {
					storeid:localStorage.getItem('storeid'),
					orderid:params.orderid,
					status:params.status,
					deliveryDate:`${params.deliveryDate}`,
					deliveryTime:`${params.deliveryTime}`,
					orderNumber:Number(params.orderNumber),
					shopassistantId:params.shopassistantId
		},	
			})
			.then((res) => {
				if (res) {	
					dispatch({
						type: "SCHEDULING_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "SCHEDULING_DATA_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "SCHEDULING_FAILURE",
					payload: err.message,
				});
			});
	};

}

export const SetPaymentActive = (params) => {
	return (dispatch) => {
		dispatch({
			type: "SETTING_PAYMENT",
		});
		client
			.mutate({
				mutation: SET_PAYMENT,
				variables: {
					orderid:params.orderid,
					finalBillAmount:Number(params.finalBillAmount)
		},	
			})
			.then((res) => {
				if (res) {	
					dispatch({
						type: "SET_PAYMENT_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "SET_PAYMENT_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "SET_PAYMENT_FAILURE",
					payload: err.message,
				});
			});
	};

}
export const GetEstimatedTime = (params) => {
	return (dispatch) => {
		dispatch({
			type: "GETTING_ESTIMATED_TIME",
		});
		client
			.mutate({
				mutation: GET_ESTIMATED_TIME,
				variables: {
					orderid:params
		},	
			})
			.then((res) => {
				if (res) {	
					dispatch({
						type: "GET_ESTIMATED_TIME_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "GET_ESTIMATED_TIME_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "GET_ESTIMATED_TIME_FAILURE",
					payload: err.message,
				});
			});
	};

}
export const ChangetoActiveStatus = (params1,params2) => {
	return (dispatch) => {
		dispatch({
			type: "CHANGE_TO_ACTIVE",
		});
		client
			.mutate({
				mutation: CHANGE_TO_ACTIVE_STATUS,
				variables: {
					orderid:params1,
					preparationTime: Number(params2)
		},	
			})
			.then((res) => {
				if (res) {	
					dispatch({
						type: "CHANGE_TO_ACTIVE_STATUS_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "CHANGE_TO_ACTIVE_STATUS_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "CHANGE_TO_ACTIVE_STATUS_FAILURE",
					payload: err.message,
				});
			});
	};

}
export const ChangetoReadyStatus = (params1,params2) => {
	return (dispatch) => {
		dispatch({
			type: "CHANGE_TO_READY",
		});
		client
			.mutate({
				mutation: CHANGE_ORDER_STATUS_TO_READY,
				variables: {
					orderid:params1,
					products:params2
		},	
			})
			.then((res) => {
				if (res) {	
					dispatch({
						type: "CHANGE_ORDER_STATUS_TO_READY_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "CHANGE_ORDER_STATUS_TO_READY_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "CHANGE_ORDER_STATUS_TO_READY_FAILURE",
					payload: err.message,
				});
			});
			
	};

}

export const changeToOutForDelivery = (params1,params2) => {
	return (dispatch) => {
		dispatch({
			type: "CHANGE_TO_DELIVERY",
		});
		client
			.mutate({
				mutation: CHANGE_ORDER_STATUS_TO_DELIVERY,
				variables: {
					orderid:params1,
					packingImages:params2
		},	
			})
			.then((res) => {
				if (res) {	
					dispatch({
						type: "CHANGE_ORDER_STATUS_TO_DELIVERY_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "CHANGE_ORDER_STATUS_TO_DELIVERY_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "CHANGE_ORDER_STATUS_TO_DELIVERY_FAILURE",
					payload: err.message,
				});
			});
	};

}
export const ChangetoCompletedStatus = (params) => {
	return (dispatch) => {
		dispatch({
			type: "CHANGE_TO_COMPLETED",
		});
		client
			.mutate({
				mutation: CHANGE_ORDER_STATUS_TO_COMPLETED,
				variables: {
					orderid:params.orderid,
					stats:params.stats
		},	
			})
			.then((res) => {
				if (res) {	
					dispatch({
						type: "CHANGE_ORDER_STATUS_TO_COMPLETED_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "CHANGE_ORDER_STATUS_TO_COMPLETED_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "CHANGE_ORDER_STATUS_TO_COMPLETED_FAILURE",
					payload: err.message,
				});
			});
	};

}

export const getAllPendingSettlements =(params) =>{
	return async (dispatch)=>{
		function onSuccess(success) {
            dispatch({ type: "SETTLEMENTS_IN_PROGRESS_SUCCESS", payload: success });
            return success;
        }
		function onError(error) {
            dispatch({ type: "SETTLEMENTS_IN_PROGRESS_FAILED", payload: error });
            return error;
        }
		const success = await client.query({
            query: GET_ALL_PENDINGSETTLEMENTS,
            variables: {
				pagination: { offset: 0, first: params?.first},
				storeid: localStorage.getItem('storeid'),
				orderNumber:params?.orderNumber
            },
        });
		if (success) {
            return onSuccess(success);
        } else if (success.errors) {
            return onError(success.errors[0]);
		}
	}

}
export const getAllPendingPayments =(params) =>{
	return async (dispatch)=>{
		function onSuccess(success) {
            dispatch({ type: "PENDING_PAYMENT_SUCCESS", payload: success });
            return success;
        }
		function onError(error) {
            dispatch({ type: "PENDING_PAYMENT_FAILED", payload: error });
            return error;
        }
		const success = await client.query({
            query: GET_ALL_PENDINGPAYMENTS,
            variables: {
				pagination: { offset: 0, first: params?.first},
				storeid: localStorage.getItem('storeid'),
				orderNumber:params?.orderNumber
            },
        });
		if (success) {
            return onSuccess(success);
        } else if (success.errors) {
            return onError(success.errors[0]);
		}
	}

}
export const getAllCompletedSettlements =(params) =>{
	return async (dispatch)=>{
		function onSuccess(success) {
            dispatch({ type: "COMPLETED_PAYMENT_SUCCESS", payload: success });
            return success;
        }
		function onError(error) {
            dispatch({ type: "COMPLETED_PAYMENT_FAILED", payload: error });
            return error;
        }
		const success = await client.query({
            query: GET_ALL_COMPLETEDSETTLEMENTS,
            variables: {
				pagination: { offset: 0, first: params?.first},
				storeid: localStorage.getItem('storeid'),
            },
        });
		if (success) {
            return onSuccess(success);
        } else if (success.errors) {
            return onError(success.errors[0]);
		}
	}

}
export const getCompletedPaymentSearch =(params) =>{
	return async (dispatch)=>{
		function onSuccess(success) {
            dispatch({ type: "COMPLETED_PAYMENTSEARCH_SUCCESS", payload: success });
            return success;
        }
		function onError(error) {
            dispatch({ type: "COMPLETED_PAYMENTSEARCH_FAILED", payload: error });
            return error;
        }
		const success = await client.query({
            query:GET_COMPLETEDPAYMENT_SEARCH,
            variables: {
				pagination: { offset: 0, first: params?.first},
				storeid: localStorage.getItem('storeid'),
				dateFrom: params.dateFrom,
				dateTo: params.dateTo,
				orderNumber:params.orderNumber
            },
        });
		if (success) {
            return onSuccess(success);
        } else if (success.errors) {
            return onError(success.errors[0]);
		}
	}

}
export const depositCod = (params) => {
	return (dispatch) => {
		dispatch({
			type:"GETTING_DEPOSIT_COD",
		});
		client
			.mutate({
				mutation: DEPOSIT_COD,
				variables: {
					orderId:params
		},	
			})
			.then((res) => {
				if (res) {	
					dispatch({
						type: "DEPOSIT_COD_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "DEPOSIT_COD_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "DEPOSIT_COD_FAILURE",
					payload: err.message,
				});
			});
	};

}