import { gql } from "@apollo/client";

export const GET_PRODUCT_SEARCH = gql`
query(
        $searchKey:String
        $storeid:ID!
        $departmentid:ID
        $categoryid:ID
        $categoryid1:ID
        $categoryid2:ID
        $status:String
        $from:Int
        $size:Int)
        {
            searchproducts(
                searchKey:$searchKey
                storeid:$storeid
                departmentid:$departmentid
                categoryid:$categoryid
                categoryid1:$categoryid1
                categoryid2:$categoryid2
                status:$status
                from:$from
                size:$size)
                {

                    _id
                    _source{
                        productname
                        id
                        price
                        status
                        uom
                        promoprice
                        image{
                          primary
                          secondary
                          thumbnail
                        }
                        salesdepartment{
                          id
                        }
                        salescategory{
                          id
                        }
                        salescategory1
                        {
                          id
                        }
                        salescategory2{
                          id
                        }
                }
        }
    }

`

export const GET_PRODUCT_DETAILS = gql`
query($id:ID!)
{
  salesproducts(id:$id){
    id
    productname
    desc
    mfgname
    price
    promoprice
    quantity
    uom
    brand
    images
    foodType{
      id 
      value
    }
    bxgy{
      couponid
      xvalue
      yvalue
    }
    image{
      primary
      secondary
      thumbnail
    }
    status
    barcode
    displayrank
    storeid {
      id
    }
    salesdepartment{
      id
      name
      childCategoryPresent
    }
    salescategory
    {
      id
      name
    }
    salescategory1{
      id
      name
    }
    salescategory2{
      id
      name
    }
  }
}

`;

export const GET_DEPARTMENT = gql`
query($storeid:ID!){
  salesdepartments(storeid:$storeid){
    id
    name
    totalproductcountdepartment
    totalproductcountdepartmentInactive
    displayrank
    status
    childCategoryPresent
    childCategories{
      id
      name
      status
      childCategoryPresent
      displayrank
       childCategories{
        id
        name
        childCategoryPresent
        status
        displayrank
        childCategories{
          id
          name
          childCategoryPresent
          displayrank
          status
        }
      }
    }
  }
}
`;
export const GET_CATEGORY = gql`
query($storeid:ID!,$id:ID!){
  salescategory(storeid:$storeid,id:$id){
  id
   name
   displayrank
   status
   childCategoryPresent
   
 }
 }
`;


export const UPDATE_PRODUCT_IMG = gql`
mutation(
  $productid:ID!,
  $productname:String!,
	$desc:String!,
	$mfgname:String,
	$brand:String,
  $price:String!,
  $barcode:String!,
	$promoprice:String,
	$quantity:String,
	$uom:String,
	$image:ImagesInput,
  $foodType: foodTypeInput
	$status:String!,
	$displayrank:Int!,
	$salesdepartment:ID!,
	$salescategory:ID,
	$salescategory1:ID,
	$salescategory2:ID
    )
    {
      updateSalesProducts(
            productid:$productid
            productname:$productname,
            desc:$desc,
            mfgname:$mfgname,
            brand:$brand,
            price:$price,
            barcode:$barcode
            promoprice:$promoprice,
            quantity:$quantity,
            uom:$uom,
            image:$image,
            foodType:$foodType
            status:$status,
            displayrank:$displayrank,
            salesdepartment:$salesdepartment,
            salescategory:$salescategory,
            salescategory1:$salescategory1,
            salescategory2:$salescategory2
        )
      {
        id
        productname
        desc
        mfgname
        brand
        price
        barcode
        promoprice
        quantity
        uom
        image{
          primary
          secondary
          thumbnail
        }
        status
        displayrank
        storeid{
          id
        }
        salesdepartment
        {
          id
          name
          childCategories{
            id
            name
          }
        }
        salescategory{
          id
          name
        }
        salescategory1{
          id
          name
        }
        salescategory2{
          id
          name
        }
       }
    }
`;

export const UPDATE_PRODUCT = gql`
mutation(
  $productid:ID!,
  $productname:String!,
	$desc:String!,
	$mfgname:String,
	$brand:String,
  $price:String!,
  $barcode:String!,
	$promoprice:String,
	$quantity:String,
	$uom:String,
  $foodType: foodTypeInput
	$status:String!,
	$displayrank:Int!,
	$salesdepartment:ID!,
	$salescategory:ID,
	$salescategory1:ID,
	$salescategory2:ID
    )
    {
      updateSalesProducts(
            productid:$productid
            productname:$productname,
            desc:$desc,
            mfgname:$mfgname,
            brand:$brand,
            price:$price,
            barcode:$barcode
            promoprice:$promoprice,
            quantity:$quantity,
            uom:$uom,
            foodType:$foodType
            status:$status,
            displayrank:$displayrank,
            salesdepartment:$salesdepartment,
            salescategory:$salescategory,
            salescategory1:$salescategory1,
            salescategory2:$salescategory2
        )
      {
        id
        productname
        desc
        mfgname
        brand
        price
        barcode
        promoprice
        quantity
        uom
        image{
          primary
          secondary
          thumbnail
        }
        status
        displayrank
        storeid{
          id
        }
        salesdepartment
        {
          id
          name
          childCategories{
            id
            name
          }
        }
        salescategory{
          id
          name
        }
        salescategory1{
          id
          name
        }
        salescategory2{
          id
          name
        }
       }
    }
`;


export const GET_MASTER_PRODUCT_DETAILS = gql`
query($productid:ID){
  viewParticularMasterProduct(productid:$productid){
  id
  productname
  desc
  mfgname
  brand
  price
  barcode
  promoprice
  quantity
  uom
  image{
    primary
    secondary
    thumbnail
  }
  status
  displayrank
  }
  }
`;

export const CreateMasterProduct = gql`
mutation
($productname:String!,
  $desc:String,
  $mfgname:String,
  $brand:String,
  $price:String,
  $promoprice:String,
  $image:ImagesInput,
  $quantity:String,
  $uom:String,
  $status:String,
  $displayrank:Int){
  createMasterProduct
  (productname:$productname,
    desc:$desc,
    mfgname:$mfgname,
    brand:$brand,
    price:$price,
    promoprice:$promoprice,
    image:$image,
    quantity:$quantity,
    uom:$uom,
    status:$status,
    displayrank:$displayrank){
  id
  productname
  desc
  mfgname
  brand
  price
  promoprice
  quantity
  uom
  image{
  primary
  secondary
  }
  status
  displayrank
  approved
  barcode
  }
  }`

export const CreateMaster_NoImage = gql`
mutation
($productname:String!,
  $desc:String,
  $mfgname:String,
  $brand:String,
  $price:String,
  $promoprice:String,
  $quantity:String,
  $uom:String,
  $status:String,
  $displayrank:Int){
  createMasterProduct
  (productname:$productname,
    desc:$desc,
    mfgname:$mfgname,
    brand:$brand,
    price:$price,
    promoprice:$promoprice,
    quantity:$quantity,
    uom:$uom,
    status:$status,
    displayrank:$displayrank){
  id
  productname
  desc
  mfgname
  brand
  price
  promoprice
  quantity
  uom
  image{
  primary
  secondary
  }
  status
  displayrank
  approved
  barcode
  }
  }`


export const UPDATE_PRODUCT_STATUS = gql`
mutation(
  $productid:ID!,
	$status:String!
    )
    {
      updateSalesProducts(
            productid:$productid
            status:$status
        )
      {
        id 
    }
  }
`;

export const UPDATE_ALL_PRODUCT_STATUS = gql`
mutation(
  $productIds:[ID],
	$status:String
    )
    {
      productStatusChange(
        productIds:$productIds
            status:$status
        )
      {
        id 
        status
    }
  }
`;

export const DELETE_PRODUCT = gql`
  mutation($id: ID!) {
    deleteSalesProduct(id: $id) {
      id
    }
  }
`;



export const PRODUCT_SEARCH = gql`
query searchProducts($pagination:PaginationArg,
  $storeid:ID!,
  $status:String,
  $searchKey:String,
  $salesdepartment:ID,
  $salescategory:ID,
  $salescategory1:ID,
  $salescategory2:ID){
        searchProducts(pagination:$pagination,
          storeid:$storeid,
          status:$status,
          searchKey:$searchKey,
          salesdepartment:$salesdepartment,
          salescategory:$salescategory,
          salescategory1:$salescategory1,
          salescategory2:$salescategory2)
          {
            count
            hasNext
            items{
              id
              productname
              desc
              price
              status
              uom
              image{
                primary
                secondary
                thumbnail
              }
              promoprice
              salesdepartment{
                id
                name
              }
              salescategory{
                id
                name
              }
              salescategory1{
                id 
                name
              }
              salescategory2{
                id
                name
              }
            }
          }
}
`;