import React, { useEffect } from "react";
import CustomDialog from "../Commoncomponents/dialog";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import Axios from "axios";
import { Box, useMediaQuery, useTheme, Typography } from "@mui/material";
import {
  Step,
  Stack,
  Stepper,
  StepLabel,
  StepConnector,
  stepConnectorClasses,
} from "@mui/material";
import { styled } from "@mui/system";
import CustomButton from "../Commoncomponents/button";
import {
  ButtonFontSize_16,
  ButtonFontSize_17,
  ButtonFontSize_18,
  contentFontsize_14_xs_10,
} from "../Typography";
import "./view.css";
import { deposit_cod } from "../../Redux/Action/OrderDetails";
import { DEPOSIT_COD } from "../../Redux/Action/OrderDetails/gqlQuery";
import client from "../../Configurations/apollo";
import { Verify_Payment } from "./query";
import { Fade } from "@mui/material";

const CustomStep = styled(Step)(({ theme }) => ({
  paddingLeft: "25px",
  "& .MuiStepLabel-root": {
    color: theme.palette.primary.main,
  },
  "& .MuiStepIcon-root": {
    color: theme.palette.secondary.main,
  },
}));

function PendingView(props) {
  const handleClose = () => {
    props?.setView(false);
  };

  const handleDepositClick = (order) => {
    console.log("clicked", order);
    // props.depositCod(order);
    client
      .mutate({
        mutation: DEPOSIT_COD,
        variables: {
          orderId: order,
        },
      })
      .then((res) => {
        //
        if (res.data) {
          const options = {
            key: `${process.env.REACT_APP_RAZORPAY_KEY}`,
            amount: 100,
            currency: "INR",
            name: "NearShopz",
            description: "Grocery delivery from shops near you!",
            order_id: res?.data?.depositCODToNearshopz?.id,
            handler: async (response) => {
              try {
                const paymentId = response.razorpay_payment_id;
                console.log("response", response);
                // alert(response.razorpay_payment_id);
                // alert(response.razorpay_order_id);
                // alert(response.razorpay_signature);
                client
                  .mutate({
                    mutation: Verify_Payment,
                    variables: {
                      orderid: order,
                      razorpayOrderid: `${response?.razorpay_order_id}`,
                      paymentid: `${response?.razorpay_payment_id}`,
                      signature: `${response?.razorpay_signature}`,
                    },
                  })
                  .then((res) => {
                    // setPaid(true);
                    client.reFetchObservableQueries();
                    props.setView(false);
                    props.getAllPendingPayments();
                  })
                  .catch((err) => {
                    alert(err);
                  });
                // console.log("id", paymentId);
                const API_URL = "https://www.nearshopz.com/";
                const url = `${API_URL}capture/${paymentId}`;
                const captureResponse = await Axios.post(url, {});
              } catch (err) {
                console.log(err);
              }
            },
            theme: {
              color: "#89C74A",
            },
          };
          const rzp1 = new window.Razorpay(options);
          rzp1.open();
        } else if (res.errors) {
          alert(res.errors[0].message);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const detailsStyle = {
    backgroundColor: "#f9d6d2",
    padding: "16px",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: "800px",
    paddingLeft: "50px",
    paddingRight: "50px",
  };

  const itemStyle = {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",
  };

  const totalStyle = {
    display: "flex",
    justifyContent: "space-between",
    fontWeight: "bold",
  };

  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const matchessm = useMediaQuery(theme.breakpoints.down("sm"));

  const stats = props?.selectedOrder?.stats || [];
  const transferSettlement = props?.selectedOrder?.transferSettlement || [];

  console.log("prop ind", props);
  console.log("prop illa", props?.selectedOrder);

  const steps = [
    ...[stats[0], stats[stats.length - 1]].map((stat, index) => ({
      label:
        index === 0
          ? `${stat.status.replace(/-/g, " ")} with COD`
          : `Order ${stat.status.replace(/-/g, " ")}`,
      dateTime: `${stat.created} ${new Date(
        `1970-01-01T${stat.createdTime}Z`
      ).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })}`,
    })),
    ...transferSettlement.map((settle) => ({
      label: settle.status.replace(/-/g, " "),
      dateTime: `${settle.created} ${new Date(
        `1970-01-01T${settle.createdTime}Z`
      ).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })}`,
    })),
  ];
  console.log("step ind", steps);

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      width: 260,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#17A64F",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#EA4335",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 2,
      border: 0,
      backgroundColor: "#EA4335",
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    backgroundColor: ownerState.isLastStep
      ? "#EA4335"
      : theme.palette.grey[700],
    zIndex: 1,
    color: "#fff",
    width: 20,
    height: 20,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
      backgroundColor: ownerState.isLastStep ? "#EA4335" : "#17A64F",
    }),
    ...(ownerState.completed && {
      backgroundColor: "#17A64F",
    }),
    padding: 0,
  }));

  function ColorlibStepIcon(props) {
    const { active, completed, className, isLastStep } = props;

    return (
      <ColorlibStepIconRoot
        ownerState={{ active, completed, isLastStep }}
        className={className}
      >
        {completed ? (
          <div className="ColorlibStepIcon-completedIcon" />
        ) : (
          <div className="ColorlibStepIcon-circle" />
        )}
      </ColorlibStepIconRoot>
    );
  }

  return (
    <>
      <style>
        {`
          .custom-list li::marker {
            color: #EA4335; /* Change this to your desired color */
           
          }
        `}
      </style>
      <Dialog
        maxHeight="70%"
        borderRadius="20px"
        open={props?.view}
        titleAlign="center"
        padding="30px"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "790px",
              padding: "10px 0px 30px 0px",
              borderRadius: "20px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{ textAlign: "center", color: "black", marginTop: "20px" }}
        >
          #{props?.selectedOrder.orderNumber}
        </DialogTitle>
        <DialogContent sx={{ padding: "0px" }}>
          <div style={{ paddingTop: "0px" }}>
            <Box sx={{ overflowX: "auto", marginBottom: "20px" }}>
              <Stack sx={{ width: "100%" }} spacing={4}>
                <Stepper
                  alternativeLabel
                  activeStep={1}
                  connector={<ColorlibConnector />}
                >
                  {steps.map((step, index) => (
                    <CustomStep key={step.label}>
                      <StepLabel
                        StepIconComponent={(stepProps) => (
                          <ColorlibStepIcon
                            {...stepProps}
                            isLastStep={index === steps.length - 1}
                          />
                        )}
                      >
                        <Typography
                          style={{ fontFamily: "Roboto", fontWeight: "500" }}
                          fontSize={contentFontsize_14_xs_10}
                        >
                          {step.label}
                        </Typography>
                        <Typography
                          style={{ fontFamily: "Roboto", fontWeight: "400" }}
                          fontSize={contentFontsize_14_xs_10}
                        >
                          {step.dateTime}
                        </Typography>
                      </StepLabel>
                    </CustomStep>
                  ))}
                </Stepper>
              </Stack>
            </Box>
          </div>
          <Box sx={detailsStyle}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "100px",
                  // justifyContent: "space-between",
                  flexGrow: 1,
                }}
              >
                <ul
                  className="custom-list"
                  style={{ listStyleType: "disc", paddingLeft: "20px" }}
                >
                  <li>Product Price</li>
                  <li>Delivery Charge</li>
                  <li style={{ listStyleType: "none", marginTop: "20px" }}>
                    <strong>Total</strong>
                  </li>
                </ul>
                <ul
                  style={{
                    listStyleType: "none",
                    padding: 0,
                    margin: 0,
                    textAlign: "right",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "10px",
                    }}
                  >
                    <li>{" : "}</li>
                    <li style={{ marginTop: "1px" }}>
                      ₹ {props?.selectedOrder.totalPrice.toFixed(2)}
                    </li>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "10px",
                    }}
                  >
                    <li>{" : "}</li>
                    <li style={{ marginTop: "1px" }}>
                      ₹ {props?.selectedOrder.deliveryCharge.toFixed(2)}
                    </li>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "20px",
                      gap: "10px",
                    }}
                  >
                    <li>{" : "}</li>
                    <li style={{ marginTop: "1px" }}>
                      <strong>
                        ₹ {props?.selectedOrder.totalPayable.toFixed(2)}
                      </strong>
                    </li>
                  </div>
                </ul>
              </div>
              <CustomButton
                variant="contained"
                sx={{ marginLeft: "16px", padding: "10px" }}
                borderRadius={"40px"}
                background={"#EA4335"}
                width="160px"
                height="45px"
                onClick={() => handleDepositClick(props?.selectedOrder.id)}
              >
                <Typography
                  fontSize={ButtonFontSize_16}
                  className="button-font-deposite"
                >
                  Deposit
                </Typography>
              </CustomButton>
            </div>
          </Box>

          <div style={{ padding: "20px", textAlign: "center" }}>
            <div style={{ padding: "20px" }}>
              <Typography>
                As per NearShopz policy, kindly deposit the delivery fee and
                platform fee to our account.
              </Typography>
            </div>
          </div>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CustomButton
              onClick={handleClose}
              width="160px"
              height="45px"
              variant={"outlined"}
              background={"transparent"}
              color={"#659B1B"}
              borderRadius="25px"
              borderColor="#659B1B"
            >
              Close
            </CustomButton>
          </div>
        </DialogContent>
      </Dialog>
      {/* ))} */}
    </>
  );
}

export default PendingView;
