import { gql } from "@apollo/client";

export const GET_ORDER_SEARCH = gql`
  query (
    $pagination: PaginationArg
    $storeid: ID!
    $orderNumber: String
    $customerMobile: String
    $orderstatus: String
    $dateFrom: String
    $dateTo: String
    $deliveryDateFrom: String
    $deliveryDateTo: String
  ) {
    searchorder(
      pagination: $pagination
      storeid: $storeid
      orderNumber: $orderNumber
      customerMobile: $customerMobile
      orderstatus: $orderstatus
      dateFrom: $dateFrom
      dateTo: $dateTo
      deliveryDateFrom: $deliveryDateFrom
      deliveryDateTo: $deliveryDateTo
    ) {
      count

      items {
        commentsSeller
        orderNumber
        storeid {
          id
        }

        stats {
          status
          created
        }
        deliveryDate
        deliveryTime
        mobileNumber
        customerName
        specialInstructions
        lastStatus
        id
      }
      hasNext
    }
  }
`;
export const GET_ORDER_DETAILS = gql`
  query ($orderid: ID!) {
    orderdetails(orderid: $orderid) {
      id
      orderNumber
      discountPrice
      deliveryCharge
      totalPayable
      packingCharge
      packingImages
      version
      deliveryPartnerId {
        id
        firstName
        lastName
      }
      shopAssistantId {
        id
        firstName
        lastName
      }
      storeid {
        id
        storeName
        deliveryMaxDistance
        storeCoordinates {
          coordinates
        }
      }
      userid {
        id
        firstName
        lastName
        primaryLatitude
        primaryLongitude
        primaryLocation
        address {
          id
          type
          houseNumber
          landmark
          locationDescription
          locationLat
          locationLng
        }
      }
      products {
        shopAssistantQuantity
        productPrice
        productid {
          id
          productname
          images
          image {
            primary
          }
          desc
          price
          uom
          quantity
        }
        quantity
        price
      }
      paymentStatus
      finalBillAmount
      totalPriceDelivery
      totalPrice
      deliveryAddress
      deliveryDate
      deliveryType
      deliveryTime
      deliveryLat
      deliveryLng
      mobileNumber
      specialInstructions
      deliveryInstructions
      estimatedDeliveryTime
      commentsSeller
      customerName
      total
      packingCharge
      handlingCharge
      totalPayable
      couponID {
        storeid
        name
        couponCode
      }
      travelTime {
        text
        value
      }
      travelDistance {
        text
        value
      }
      stats {
        status
        created
        createdTime
      }
      lastStatus
    }
  }
`;

export const CHANGE_STATUS = gql`
  mutation ($orderid: ID!, $stats: StatusInput!) {
    updateOrderStatus(orderid: $orderid, stats: $stats) {
      id
      orderNumber
    }
  }
`;

export const REJECTED_STATUS = gql`
  mutation ($commentsSeller: String, $orderid: ID!, $stats: StatusInput!) {
    updateOrderStatus(
      commentsSeller: $commentsSeller
      orderid: $orderid
      stats: $stats
    ) {
      id
      orderNumber
      lastStatus
    }
  }
`;
export const CHANGE_TO_ACTIVE_STATUS = gql`
  mutation ($orderid: ID!, $preparationTime: Int!) {
    changeOrderStatusToShopping(
      orderid: $orderid
      preparationTime: $preparationTime
    ) {
      id
      orderNumber
      storeid {
        id
        storeName
      }
      products {
        id
        productid {
          id
          productname
        }
        quantity
        shopAssistantQuantity
        price
        status
        productDiscountPrice
        productPrice
      }
      totalPrice
      deliveryType
      GST
      packingCharge
      handlingCharge
      deliveryTip
      total
      totalPayable
      discountPrice
      preparationTime
      shopAssistantId {
        id
        firstName
      }
      deliveryPartnerId {
        id
        firstName
      }
    }
  }
`;
export const CHANGE_ORDER_STATUS_TO_READY = gql`
  mutation ($orderid: ID!, $products: [ProductsInput]) {
    changeOrderStatusToReady(orderid: $orderid, products: $products) {
      id
      orderNumber
      storeid {
        id
        storeName
      }
      products {
        id
        productid {
          id
          productname
          foodType {
            id
            value
          }
        }
        quantity
        shopAssistantQuantity
        price
        status
        productDiscountPrice
        productPrice
      }
      totalPrice
      deliveryType
      GST
      packingCharge
      handlingCharge
      deliveryTip
      total
      totalPayable
      preparationTime
      shopAssistantId {
        id
        firstName
      }
      deliveryPartnerId {
        id
        firstName
      }
    }
  }
`;

export const CHANGE_ORDER_STATUS_TO_DELIVERY = gql`
  mutation ($orderid: ID!, $packingImages: [String]) {
    changeToOutForDelivery(orderid: $orderid, packingImages: $packingImages) {
      id
      orderNumber
      storeid {
        id
        storeName
      }
      products {
        id
        productid {
          id
          productname
          foodType {
            id
            value
          }
        }
        quantity
        shopAssistantQuantity
        price
        status
        productDiscountPrice
        productPrice
      }
      totalPrice
      deliveryType
      GST
      packingCharge
      handlingCharge
      deliveryTip
      total
      totalPayable
      preparationTime
      shopAssistantId {
        id
        firstName
      }
      deliveryPartnerId {
        id
        firstName
      }
    }
  }
`;

export const CHANGE_ORDER_STATUS_TO_COMPLETED = gql`
  mutation ($orderid: ID!, $stats: StatusInput!) {
    updateOrderStatus(orderid: $orderid, stats: $stats) {
      id
      orderNumber
      storeid {
        id
        storeName
      }
      products {
        id
        productid {
          id
          productname
        }
        quantity
        shopAssistantQuantity
        price
        status
        productDiscountPrice
        productPrice
      }
      totalPrice
      deliveryType
      GST
      packingCharge
      handlingCharge
      deliveryTip
      total
      totalPayable
      preparationTime
      shopAssistantId {
        id
        firstName
      }
      deliveryPartnerId {
        id
        firstName
      }
    }
  }
`;

export const NOTIFICATION_STATUS = gql`
  mutation (
    $storeid: ID!
    $orderid: ID!
    $status: String!
    $deliveryDate: String!
    $deliveryTime: String!
    $orderNumber: Int!
    $shopassistantId: ID
  ) {
    scheduleAlertOnStatus(
      storeid: $storeid
      orderid: $orderid
      status: $status
      deliveryDate: $deliveryDate
      deliveryTime: $deliveryTime
      orderNumber: $orderNumber
      shopassistantId: $shopassistantId
    )
  }
`;

export const SET_PAYMENT = gql`
  mutation ($orderid: ID!, $finalBillAmount: Float!) {
    setPaymentActive(orderid: $orderid, finalBillAmount: $finalBillAmount)
  }
`;
export const GET_ESTIMATED_TIME = gql`
  mutation ($orderid: ID!) {
    findDeliveryTimeAndDistance(orderid: $orderid) {
      travelTime {
        text
        value
      }
      travelDistance {
        text
        value
      }
    }
  }
`;

export const GET_ALL_PENDINGSETTLEMENTS = gql`
  query ($pagination: PaginationArg, $storeid: ID, $orderNumber: Float) {
    listAllPendingSettlements(pagination: $pagination, storeid: $storeid,orderNumber:$orderNumber) {
      count
      items {
        orderNumber
        storeid {
          id
        }
        totalPrice
        deliveryDate
        deliveryTime
        mobileNumber
        customerName
        id
        deliveryCharge
        stats{
          status
          created
          createdTime
        }
        transferSettlement {
          status
          created
          createdTime
          timestamp
        }
        transferSettlementStatus
        totalPayable
        totalPriceDelivery
      }
      hasNext
    }
  }
`;
export const GET_ALL_PENDINGPAYMENTS = gql`
  query ($pagination: PaginationArg, $storeid: ID, $orderNumber: Float) {
    listAllPendingPaymentsBySeller(pagination: $pagination, storeid: $storeid, orderNumber:$orderNumber) {
      count
      items {
        orderNumber
        storeid {
          id
        }
        totalPrice
        deliveryDate
        finalBillAmount
        amountForNearshopz
        amountForNearshopzDeliveryPerson
        extraAmountByNearshopz
        amountForSeller
        amountForCustomer
        deliveryCharge
        deliveryTime
        mobileNumber
        customerName
        id
        stats {
          status
          created
          createdTime
        }
        transferSettlement {
          status
          created
          createdTime
          timestamp
        }
        transferSettlementStatus
        totalPayable
        totalPriceDelivery
      }
      hasNext
    }
  }
`;
export const GET_ALL_COMPLETEDSETTLEMENTS = gql`
  query ($pagination: PaginationArg, $storeid: ID) {
    listAllCompletedSettlements(pagination: $pagination, storeid: $storeid) {
      count
      items {
        orderNumber
        storeid {
          id
        }
        totalPrice
        deliveryDate
        deliveryTime
        mobileNumber
        customerName
        id
        stats {
          status
          created
          createdTime
        }
        transferSettlement {
          status
          created
          createdTime
          timestamp
        }
        transferSettlementStatus
        totalPayable
        totalPriceDelivery
      }
      hasNext
    }
  }
`;
export const GET_COMPLETEDPAYMENT_SEARCH = gql`
  query (
    $pagination: PaginationArg
    $storeid: ID
    $dateFrom: String
    $dateTo: String
    $orderNumber: Float
  ) {
    searchCompletedSettlementsWithFilters(
      pagination: $pagination
      storeid: $storeid
      dateFrom: $dateFrom
      dateTo: $dateTo
      orderNumber: $orderNumber
    ) {
      count
      items {
        orderNumber
        totalPrice
        deliveryDate
        deliveryTime
        mobileNumber
        customerName
        id
        stats {
          status
          created
          createdTime
        }
        transferSettlement {
          status
          created
          createdTime
        }
        transferSettlementStatus
        totalPayable
        totalPriceDelivery
      }

      hasNext
    }
  }
`;
export const DEPOSIT_COD = gql`
  mutation ($orderId: ID) {
    depositCODToNearshopz(orderId: $orderId) {
     id
     entity
     amount
     amount_paid
     amount_due
     currency
     notes
    }
  }
`;