// import React, { useState } from 'react';
// import CustomToggleButton from '../Commoncomponents/toggleButton/index';
// import { useTheme } from "../MUIComponents/index";
// import { useMediaQuery } from "../MUIComponents/index"; 
// import { STORE_WORKING, STORE_WORKING_SUBSCRIPTION } from "./Query";
// import client from "../../Configurations/apollo";
// import { message } from 'antd';
 
// const ToggleSwitch = (props) => {


//   const [open, setOpen] = useState(false);
//   const [error, setError] = useState(null);
  

//   const current = new Date();
// 	const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
  
// 	const handleToggle = (isOnline) => {
// 		// props.storeWorking(isOnline);storeWorkingSubscription
// 		if (props.retailerDetails.data &&
// 			props.retailerDetails.data.retailerdetails &&
// 			props.retailerDetails.data.retailerdetails.stores &&
// 			props.retailerDetails.data.retailerdetails.stores[0].subscription &&
// 			props.retailerDetails.data.retailerdetails.stores[0].subscription.startdate === null) {
// 			//  props.storeWorkingSubscription(Boolean(isOnline))
// 			client
// 				.mutate({
// 					mutation: STORE_WORKING_SUBSCRIPTION,
// 					variables: {
// 						storeWorking: Boolean(isOnline),
// 						storeid: localStorage.getItem("storeid"),
// 						subscription: {
// 							type: 1,
// 							amount: 1000,
// 							startdate: date
// 						}
// 					},
// 				})
// 				.then((res) => {
// 					if (res.data && res.data.updatestore && res.data.updatestore !== null) {
// 						props.getSellerWorking(`${localStorage.getItem("retailerid")}`);
// 						props.getSellerDetail(`${localStorage.getItem('retailerid')}`);
// 					}
// 					if (res.data && res.data.updatestore && res.data.updatestore === null) {
// 						props.getSellerWorking(`${localStorage.getItem("retailerid")}`);
// 						props.getSellerDetail(`${localStorage.getItem('retailerid')}`);
// 						// setIserror(true);
// 						setOpen(true);
// 						setError(res.message);
// 						// console.log("error","res.message")
// 					}
// 				})
// 				.catch((err) => {
// 					props.getSellerWorking(`${localStorage.getItem("retailerid")}`);

// 					setOpen(true);
// 					// setIserror(true)
// 					setError(
// 						"There is no active products or hierarchy present. so store working cannot be updated"
// 					);
// 					// message.error({
// 					// 	content: err.message,
// 					// 	className: "custom-class",
// 					// 	style: {
// 					// 	  marginTop: "20vh",
// 					// 	  marginLeft: "40vh",
// 					// 	},
// 					//   });
// 					// console.log("Catcherror","There is no active products or hierarchy present. so store working cannot be updated")
// 				})
// 		}

// 		else {
// 			client
// 				.mutate({
// 					mutation: STORE_WORKING,
// 					variables: {
// 						storeWorking: Boolean(isOnline),
// 						storeid: localStorage.getItem("storeid"),
// 					},
// 				})
// 				.then((res) => {
// 					if (res.data && res.data.updatestore && res.data.updatestore !== null) {
// 						props.getSellerWorking(`${localStorage.getItem("retailerid")}`);
// 					}
// 					if (res.data && res.data.updatestore && res.data.updatestore === null) {
// 						props.getSellerWorking(`${localStorage.getItem("retailerid")}`);
// 						// setIserror(true);
// 						setOpen(true);
// 						setError(res.message);
// 						// console.log("error","res.message")
// 					}
// 				})
// 				.catch((err) => {
// 					props.getSellerWorking(`${localStorage.getItem("retailerid")}`);

// 					setOpen(true);
// 					// setIserror(true)
// 					setError(
// 						"There is no active products or hierarchy present. so store working cannot be updated"
// 					);
// 					// message.error({
// 					// 	content: err.message,
// 					// 	className: "custom-class",
// 					// 	style: {
// 					// 	  marginTop: "20vh",
// 					// 	  marginLeft: "40vh",
// 					// 	},
// 					//   });
// 					// console.log("Catcherror","There is no active products or hierarchy present. so store working cannot be updated")
// 				})
// 		}
// 	};






//   const theme = useTheme();
//   const matches = useMediaQuery(theme.breakpoints.down("md"));
 
//   const customStyles = {
//     toggleBtnCirStyle: {
//       display: "inline-block",
//       border: '1px solid transparent',
//       borderRadius: '20px',
//       width: matches?"70px":'125px',
//       height:matches?"25px":'39px',
//       padding: matches?"3px":'5px',
//       cursor: "pointer",
//       boxSizing:"border-box"
//     },
//     boxStyle: {
//       display: 'flex',
//       justifyContent: 'space-around',
// 	  paddingLeft:matches?"0px":"10px",
// 	  paddingRight:"10px",
//     },
//     disOfflineStyle: {
//       display: 'flex',
//       justifyContent: 'space-between',
//     },
//     offlineCirStyle: {
//       display: 'block',
//       height: matches?"16.25px":'26.25px',
//       width: matches?"16.25px":'26.25px',
//       borderRadius: '50%',
//       backgroundColor: '#8B8989',
//       marginRight: matches?"8px":'15px',
//     },
//     disOnlineStyle: {
//       display: 'flex',
//       justifyContent: 'space-between',
//     },
//     onlineCirStyle: {
//       display: 'block',
//       height: matches?"16.25px":'26.25px',
//       width: matches?"16.25px":'26.25px',
//       borderRadius: '50%',
//       backgroundColor: '#72B01D',
//       marginLeft: matches?"8px":'15px',
//     },
//     active: {
//       display: "none",
//     },
   
//   };

//   const isRetailerDataAvailable =
//   props.retailerWorking &&
//   props.retailerWorking.data &&
//   props.retailerWorking.data.retailerdetails &&
//   props.retailerWorking.data.retailerdetails.stores &&
//   props.retailerWorking.data.retailerdetails.stores[0] &&
//   props.retailerWorking.data.retailerdetails.stores[0].storeWorking !== undefined;

// //   console.log("store offline",props.retailerWorking &&
// //   props.retailerWorking.data &&
// //   props.retailerWorking.data.retailerdetails &&
// //   props.retailerWorking.data.retailerdetails.stores &&
// //   props.retailerWorking.data.retailerdetails.stores[0] &&
// //   props.retailerWorking.data.retailerdetails.stores[0].storeWorking)
 
//   return (
//     <div>
//       {isRetailerDataAvailable&&<CustomToggleButton initialOnline={props.retailerWorking.data.retailerdetails.stores[0].storeWorking}
//       onClick={handleToggle} textOne="Online" textTwo="Offline" textFont={matches?"12px":"19px" } {...customStyles} />}
//     </div>
//   );
// };
 
// export default ToggleSwitch;


//new code

import React, { useEffect, useState } from 'react';
import CustomToggleButton from '../Commoncomponents/toggleButton/index';
import { useTheme } from "../MUIComponents/index";
import { useMediaQuery } from "../MUIComponents/index";
import { STORE_WORKING, STORE_WORKING_SUBSCRIPTION } from "./Query";
import client from "../../Configurations/apollo";
import { message } from 'antd';
import ReactDOM from "react-dom/client";
import { Switch, Space ,ConfigProvider} from "antd";
import 'antd/dist/reset.css';
function ToggleSwitch(props) {
    const [checked, setChecked] = useState(props?.retailerWorking?.data?.retailerdetails?.stores[0]?.storeWorking);
 const [error, setError] = useState(false);
 const [open, setOpen] = useState(false);
 
 const theme = useTheme();
const matches = useMediaQuery(theme.breakpoints.down("md"));
useEffect(() => {
    if (props?.retailerWorking?.data?.retailerdetails?.stores?.[0]?.storeWorking !== undefined) {
      setChecked(props.retailerWorking.data.retailerdetails.stores[0].storeWorking);
    }
  }, [props?.retailerWorking?.data?.retailerdetails?.stores?.[0]?.storeWorking]);
 
 
 console.log("the error in this toggle",props?.retailerWorking?.data?.retailerdetails?.stores[0]?.storeWorking)
 useEffect(()=>{
    if(error){
        console.log("the error in this toggle im inside")
        setChecked(false)
    }
 
 },[error,checked])
 const current = new Date();
const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
const handleChange = (checked) => {
    setChecked(checked);
        // props.storeWorking(isOnline);storeWorkingSubscription
        if (props.retailerDetails.data &&
            props.retailerDetails.data.retailerdetails &&
            props.retailerDetails.data.retailerdetails.stores &&
            props.retailerDetails.data.retailerdetails.stores[0].subscription &&
            props.retailerDetails.data.retailerdetails.stores[0].subscription.startdate === null) {
            //  props.storeWorkingSubscription(Boolean(isOnline))
            client
                .mutate({
                    mutation: STORE_WORKING_SUBSCRIPTION,
                    variables: {
                        storeWorking: Boolean(checked),
                        storeid: localStorage.getItem("storeid"),
                        subscription: {
                            type: 1,
                            amount: 1000,
                            startdate: date
                        }
                    },
                })
                .then((res) => {
                    if (res.data && res.data.updatestore && res.data.updatestore !== null) {
                        props.getSellerWorking(`${localStorage.getItem("retailerid")}`);
                        props.getSellerDetail(`${localStorage.getItem('retailerid')}`);
                    }
                    if (res.data && res.data.updatestore && res.data.updatestore === null) {
                        props.getSellerWorking(`${localStorage.getItem("retailerid")}`);
                        props.getSellerDetail(`${localStorage.getItem('retailerid')}`);
                        // setIserror(true);
                        setOpen(true);
                        setError(res.message);
                        // console.log("error","res.message")
                    }
                })
                .catch((err) => {
                    props.getSellerWorking(`${localStorage.getItem("retailerid")}`);
 
                    setOpen(true);
                    // setIserror(true)
                    setError(
                        true
                    );
                    message.error({
                        content: err.message,
                        className: "custom-class",
                        style: {
                          marginTop: "20vh",
                          marginLeft: "40vh",
                        },
                      });
                    // console.log("Catcherror","There is no active products or hierarchy present. so store working cannot be updated")
                })
        }
 
        else {
            client
                .mutate({
                    mutation: STORE_WORKING,
                    variables: {
                        storeWorking: Boolean(checked),
                        storeid: localStorage.getItem("storeid"),
                    },
                })
                .then((res) => {
                    if (res.data && res.data.updatestore && res.data.updatestore !== null) {
                        props.getSellerWorking(`${localStorage.getItem("retailerid")}`);
                    }
                    if (res.data && res.data.updatestore && res.data.updatestore === null) {
                        props.getSellerWorking(`${localStorage.getItem("retailerid")}`);
                        // setIserror(true);
                        setOpen(true);
                        setError(res.message);
                        // console.log("error","res.message")
                    }
                })
                .catch((err) => {
                    props.getSellerWorking(`${localStorage.getItem("retailerid")}`);
 
                    setOpen(true);
                    // setIserror(true)
                    setError(
                        true
                    );
                    message.error({
                        content: err.message,
                        className: "custom-class",
                        style: {
                          marginTop: "20vh",
                          marginLeft: "40vh",
                        },
                      });
                    // console.log("Catcherror","There is no active products or hierarchy present. so store working cannot be updated")
                })
        }
    };
 
 
  return (
    <Space direction="vertical">
      <ConfigProvider
  theme={{
    components: {
      Switch: {
        handleBg:checked?"#72B01D":"#8B8989",
        colorTextLightSolid:checked?"#72B01D":"#8B8989",
        colorPrimary:checked?"#72B01D":"#8B8989",
        fontSize:matches?11:18,
        // trackPadding:matches?1:3,
        // trackHeight:matches?22:35,
        // trackMinWidth:matches?40:46, 
		// innerMinMargin:matches?1:3, 
		trackPadding: matches ? 4 : 8,
		trackHeight: matches ? 22 : 40,
		trackMinWidth: matches ? 50 : 20,
		innerMinMargin: matches ? 1 : 3,

       
      },
    },
  }}
>
  	<style>
		{`
		.ant-switch:focus{
		outline:none;
		box-shadow:none;
		border:none;
		}
		`}
	</style>

      <div>
         
        <Switch
            checkedChildren={<span style={{ fontSize: matches?'12px':'19px',padding:matches?"3px":"0px 10px",fontFamily:"Roboto",marginRight:matches?"3px":"5px" ,fontWeight:"400"}}>Online</span>}
            unCheckedChildren={<span style={{ fontSize: matches?'12px':'19px',padding:matches?"3px":"0px 10px",fontFamily:"Roboto",marginLeft:matches?"3px":"5px",fontWeight:"400"}}>Offline</span>}
         // disabled={error}
          checked={checked}
          onChange={handleChange}
         
          style={{
            backgroundColor: "white",
            border: checked?"1px solid #72B01D":"1px solid #8B8989",
            boxShadow: "none",
         
           fontSize:"30px",
            color: checked ? "#72B01D" : "#8B8989",
          }}
       
        />
      </div>
      </ConfigProvider>
    </Space>
  );
}
export default ToggleSwitch;