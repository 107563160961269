import SellerPortal from "./SellerPortal";
import {
	getSellerDetail,
	textEditChanges,
	textStoreChanges,
	checkBoxEdit,
	scheduleEdit,
	uploadNewImage,
	postNewUrl,
	getNewLocation,
	getNewCoordinates,
	doorDeliveryEdit,
	changeMaxDistance,
	editDeliveryCharge,
	resetDetails,
	editRetailer,
	editStore,
	resetPassword,
	closeSnackBars,
	logoutHandler,
	AddProduct,
	AddProduct1,
	uploadImage,
	uploadOrderImage,
	uploadProductImage,
	AddDepartment,
	UpdateDepartment,
	updateImage,
	Delete_Image,
	getShopassistantlist,
	getShopassistantOrders,
	getOrderDetails,
	AssignOrder,
	StoreEdit,
	getshopAssistantID,
	
} from "../../Redux/Action/SignInActions/SignInActions";
import { shopassistantregister,editShopAssistant,deleteShopAssistant } from "../../Redux/Action/SignInActions/SignInActions";
import {
	getSearchResult,
	getSearchDetails,
	postStatusChange,
	rejectedStatusHandler,
	getAllPendingSettlements,
	getAllPendingPayments,
	getAllCompletedSettlements,
	getCompletedPaymentSearch,
	depositCod
} from "../../Redux/Action/OrderDetails";
import {
	getProductSearch,
	getProductDetails,
	UpdateProduct,
	getDepartmentList,
	getCategoryList,
	getCategory1List,
	getCategory2List,
	ProductUpload,
	clearCategories
} from "../../Redux/Action/ProductSearchDetails/newindex";
import { loginAccess, resetpassword,shopAssistantLoginAccess } from "../../Redux/Action/LoginActions";
import { connect } from "react-redux";
import { SelectState } from "./Selector";
import {
	sellerReg,
	sendOTP,
	closeSnack,
	signupForm1,
	signupForm2,
	signupForm3,
	setStoreName,
	setStoreDescription,
	setStoreLocation,
	setStoreCoordinates
} from "../../Redux/Action/NewSignUp/GqlRequestAction"
import './index.css';

const mapStateToProps = (state) => {
	return { ...SelectState(state) };
};

// eslint-disable-next-line max-lines-per-function
const mapDispatchToProps = (dispatch) => {
	return {
		signInClick: (params,params1) => {
			dispatch(loginAccess(params,params1));
		},
		
shopAssistantSignInClick:(params1,params2)=>{
	dispatch(shopAssistantLoginAccess(params1,params2));
},

		resetpassword: (params) => {
			dispatch(resetpassword(params));
		},
		Delete_Image: () => {
			dispatch(Delete_Image());
		},
		getProductSearch: (params) => {
			dispatch(getProductSearch(params));
		},
		getSellerDetail: (params) => {
			dispatch(getSellerDetail(params));
		},
		textEditChanges: (e, params) => {
			dispatch(textEditChanges({ e, params }));
		},
		textStoreChanges: (e,e1, params) => {
			dispatch(textStoreChanges({ e,e1,params }));
		},
		checkBoxEdit: (e, params) => {
			dispatch(checkBoxEdit(e, params));
		},
		scheduleEdit: (params) => {
			dispatch(scheduleEdit(params));
		},
		uploadNewImage: (params) => {
			dispatch(uploadNewImage(params));
		},
		postNewUrl: (params) => {
			dispatch(postNewUrl(params));
		},
		getNewLocation: (params) => {
			dispatch(getNewLocation(params));
		},
		getNewCoordinates: (params) => {
			dispatch(getNewCoordinates(params));
		},
		doorDeliveryEdit: (params) => {
			dispatch(doorDeliveryEdit(params));
		},
		changeMaxDistance: (params) => {
			dispatch(changeMaxDistance(params));
		},
		editDeliveryCharge: (params) => {
			dispatch(editDeliveryCharge(params));
		},
		resetDetails: (params) => {
			dispatch(resetDetails(params));
		},
		editRetailer: (id, params) => {
			dispatch(editRetailer(id, params));
		},
		editStore: (params1, params2) => {
			dispatch(editStore(params1, params2));
		},
		StoreEdit: (params1) => {
			dispatch(StoreEdit(params1));
		},
		closeSnackBars: () => {
			dispatch(closeSnackBars());
		},
		resetPassword: (params) => {
			dispatch(resetPassword(params));
		},
		getSearchResult: (params) => {
			dispatch(getSearchResult(params));
		},
		getAllPendingSettlements:(params)=>{
			dispatch(getAllPendingSettlements(params))
		 },
		 getAllPendingPayments:(params)=>{
			dispatch(getAllPendingPayments(params))
		 },
		 getAllCompletedSettlements:(params)=>{
			dispatch(getAllCompletedSettlements(params))
		 },
		 getCompletedPaymentSearch:(params)=>{
			dispatch(getCompletedPaymentSearch(params))
		 }, 
		 depositCod:(params)=>{
			dispatch(depositCod(params))
		 },
		getSearchDetails: (params) => {
			dispatch(getSearchDetails(params));
		},
		postStatusChange: (params) => {
			dispatch(postStatusChange(params));
		},
		rejectedStatusHandler: (params) => {
			dispatch(rejectedStatusHandler(params));
		},
		logoutHandler: (params1, params2) => {
			dispatch(logoutHandler(params1, params2));
		},
		AddProduct: (params, department, category, category1, category2) => {
			dispatch(AddProduct(params, department, category, category1, category2));
		},
		AddProduct1: (params, department, category, category1, category2) => {
			dispatch(AddProduct1(params, department, category, category1, category2));
		},
		uploadImage: (params) => {
			dispatch(uploadImage(params));
		},
		uploadOrderImage:(params)=>{
			dispatch(uploadOrderImage(params))
		},
		uploadProductImage:(params)=>{
			dispatch(uploadProductImage(params));
		},
		AddDepartment: (params) => {
			dispatch(AddDepartment(params));
		},
		UpdateDepartment: (params) => {
			dispatch(UpdateDepartment(params));
		},
		getProductDetails: (params) => {
			dispatch(getProductDetails(params));
		},
		UpdateProduct: (params, department, category, category1, category2, productid) => {
			dispatch(UpdateProduct(params, department, category, category1, category2, productid));
		},
		getDepartmentList: () => {
			dispatch(getDepartmentList());
		},
		clearCategories: () => {
			dispatch(clearCategories());
		},
		getCategoryList: (params) => {
			dispatch(getCategoryList(params));
		},
		ProductUpload: (params, department, category, category1, category2, productid) => {
			dispatch(ProductUpload(params, department, category, category1, category2, productid));
		},
		updateImage: (params) => {
			dispatch(updateImage(params));
		},
		getCategory1List: (params) => {
			dispatch(getCategory1List(params));
		},
		getCategory2List: (params) => {
			dispatch(getCategory2List(params));
		},
		shopassistantregister: (params1, params2, params3, params4,params5) => {
			dispatch(shopassistantregister(params1, params2, params3, params4,params5));
		},
		editShopAssistant:(params1,params2,params3,params4)=>{
			dispatch(editShopAssistant(params1,params2,params3,params4));
		},
		deleteShopAssistant:(params)=>{
			dispatch(deleteShopAssistant(params));
		},

		getShopassistantlist: (params) => {
			dispatch(getShopassistantlist(params));
		},
		getShopassistantOrders: (params1, params2) => {
			dispatch(getShopassistantOrders(params1, params2));
		},
		getOrderDetails: (params) => {
			dispatch(getOrderDetails(params));
		},
		AssignOrder: (params1, params2) => {
			dispatch(AssignOrder(params1, params2))
		},
		getshopAssistantID: (id) => {
			dispatch(getshopAssistantID(id))
		},
		sellerReg: (params) => {
			dispatch(sellerReg(params))
		},
		sendOTP: (params) => {
			dispatch(sendOTP(params))
		},
		closeSnack: (params) => {
			dispatch(closeSnack(params))
		},
		signupForm1: (params) => {
			dispatch(signupForm1(params))
		},
		signupForm2: (params, params1) => {
			dispatch(signupForm2(params, params1))
		},
		setStoreName: (params) => {
			dispatch(setStoreName(params))
		},
		setStoreDescription: (params) => {
			dispatch(setStoreDescription(params))
		},
		setStoreLocation: (params) => {
			dispatch(setStoreLocation(params))
		},
		setStoreCoordinates: (params) => {
			dispatch(setStoreCoordinates(params))
		},
		signupForm3: (params, params2, params3) => {
			dispatch(signupForm3(params, params2, params3))
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SellerPortal);