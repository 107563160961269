// import { React, useEffect, useState } from "react";
// import { DESCRIPTION, DESCRIPTION_2, SUB_DESCRIPTION } from "./constants";
// import LoginForm from "../LoginForm";
// import { LOGO, ArrowNewcopy } from "../../utils/image";
// import landingpageimage from "../../utils/images/landingpageimage.png";
// import { useMediaQuery, useTheme } from "@mui/material";
// import SellerAppScreens from "../../utils/images/SelelrAppScreens.svg";
// import "./index.css";
// import { Typography } from "../MUIComponents/index";
// import {
//   HeaderFontSize_24,
//   HeaderFontSize_40,
//   HeaderFontSize_45,
//   HeaderFontSize_48,
//   contentFontSize_20,
// } from "../Typography";
// import CustomButton from "../Commoncomponents/button";
// import { Row, Col } from "react-bootstrap";
// import AppleIcon from "../../utils/Icon/AppleIcon.svg";
// import AndroidIcon from "../../utils/Icon/AndroidIcon.svg";
// import LandingCards from "./LandingCards";
// import LandingPageCard from "./LandingPageCard";
// import LandingFooter from "./LandingFooter";
// import LandingHoveringimg from "./LandingHoverimg";
// import LandingCard1 from "./LandingCard1";

// const HomeBanner = (props) => {
//   const desktop = useMediaQuery(useTheme().breakpoints.up("sm"));
//   console.log("thie is desktop", desktop);

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   return (
//     <>
//       <>
//         {desktop ? (
//           <div className="big-wrapper light" id="edaETHHOME">
//             <div className="showcase-area">
//               <div className="containerdiv">
//                 <div className="left">
//                   <div>
//                     <Typography
//                       style={{
//                         color: "#FFFFFF",
//                         fontWeight: "700",
//                         fontFamily: "Roboto",
//                       }}
//                       fontSize={HeaderFontSize_48}
//                     >
//                       Boost Your Local
//                     </Typography>
//                     <Typography
//                       style={{
//                         // color: "#FFFFFF",
//                         fontWeight: "700",
//                         fontFamily: "Roboto",
//                         // marginTop: "30px",
//                       }}
//                       className="changing-text"
//                       fontSize={HeaderFontSize_48}
//                     >
//                     </Typography>
//                     <Typography
//                       style={{
//                         color: "#FFFFFF",
//                         fontWeight: "700",
//                         fontFamily: "Roboto",
//                         // marginTop: "30px",
//                       }}
//                       fontSize={HeaderFontSize_48}
//                     >
//                       {" "}
//                       Business
//                     </Typography>
//                     </div>
//                   <Typography
//                     style={{
//                       color: "#FFFFFF",
//                       fontWeight: "500",
//                       fontFamily: "Roboto",
//                       marginTop: "30px",
//                     }}
//                     fontSize={contentFontSize_20}
//                   >
//                     Welcome to NearShopz, the platform designed to help local
//                     businesses<br/> sell online and shine
//                   </Typography>
//                   <div
//                     style={{
//                       display: "flex",
//                       flexDirection: "column",
//                       gap: "20px",
//                       marginTop: "30px",
//                     }}
//                   >
//                     <div>
//                       <CustomButton
//                         matchPadding={true}
//                         padding="10px 78px"
//                         borderRadius="25px"
//                         background="#13801C"
//                       >
//                         <div style={{ display: "flex", gap: "10px" }}>
//                           <img
//                             src={AndroidIcon}
//                             alt="apple icon"
//                             width="29px"
//                             height="34px"
//                           />
//                           <Typography
//                             style={{
//                               display: "flex",
//                               alignItems: "center",
//                               justifyContent: "center",
//                             }}
//                             fontSize={contentFontSize_20}
//                           >
//                             Download for IOS
//                           </Typography>
//                         </div>
//                       </CustomButton>
//                     </div>
//                     <div>
//                       <CustomButton
//                         matchPadding={true}
//                         padding="10px 60px"
//                         borderRadius="25px"
//                         background="transparent"
//                         borderColor="#FFFFFF"
//                       >
//                         <div style={{ display: "flex", gap: "10px" }}>
//                           <img
//                             src={AppleIcon}
//                             alt="Android icon"
//                             width="29px"
//                             height="34px"
//                           />
//                           <Typography
//                             style={{
//                               display: "flex",
//                               alignItems: "center",
//                               justifyContent: "center",
//                             }}
//                             fontSize={contentFontSize_20}
//                           >
//                             Download for Android
//                           </Typography>
//                         </div>
//                       </CustomButton>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="">
//                   <LoginForm {...props} />
//                 </div>
//               </div>
//             </div>
//           </div>
//         ) : (
//           <LoginForm {...props} />
//         )}
//       </>
//       <div className="big-wrapper1">
//         <LandingCards />
//       </div>
//       <div>
//         <LandingCard1 />
//       </div>
//       <div>
//         <LandingPageCard />
//       </div>
//       <div style={{ marginTop: "100px" }}>
//         <LandingHoveringimg />
//       </div>

//       <div>
//         <LandingFooter />
//       </div>
//     </>
//   );
// };
// export default HomeBanner;
import { React, useEffect, useLayoutEffect, useState } from "react";
import LoginForm from "../LoginForm";
import { useMediaQuery, useTheme } from "@mui/material";
import "./index.css";
import { Typography } from "../MUIComponents/index";
import { HeaderFontSize_48, contentFontSize_20 } from "../Typography";
import CustomButton from "../Commoncomponents/button";
import AppleIcon from "../../utils/Icon/AppleIcon.svg";
import AndroidIcon from "../../utils/Icon/AndroidIcon.svg";
import LandingCards from "./LandingCards";
import LandingPageCard from "./LandingPageCard";
import LandingFooter from "./LandingFooter";
import LandingHoveringimg from "./LandingHoverimg";
import LandingCard1 from "./LandingCard1";
import Register from "../../utils/images/Register1.webp";
import Verify1 from "../../utils/images/Verify1.webp";
import Order1 from "../../utils/images/Order1.webp";
import Employee1 from "../../utils/images/Employee1.webp";
import Catalog1 from "../../utils/images/Catalog1.webp";
import Stream1 from "../../utils/images/Streamline1.webp";
import { useLocation } from "react-router-dom";
import ImageLoader from "./ImageLoader";
import AOS from "aos";

const HomeBanner = (props) => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  });

  const desktop = useMediaQuery(useTheme().breakpoints.up("sm"));

  const location = useLocation();

  const imageUrls = [
    require("../../utils/images/Variant1.png"),
    require("../../utils/images/Variant2.png"),
    require("../../utils/images/Variant3.png"),
    require("../../utils/images/Variant4.png"),
    require("../../utils/images/Variant5.png"),
    require("../../utils/images/Variant6.png"),
  ];

  const interval = 1200;
  const currentBackground = ImageLoader(imageUrls, interval);

  useEffect(() => {
    if (location.pathname === "/") {
      window.history.pushState(null, document.title, window.location.href);
      window.addEventListener("popstate", function (event) {
        window.history.pushState(null, document.title, window.location.href);
      });
    }
    return () => {
      window.removeEventListener("popstate", function (event) {
        window.history.pushState(null, document.title, window.location.href);
      });
    };
  }, [location.pathname]);

  useEffect(() => {
    const setImagesInLocalStorage = () => {
      const images = [
        { name: "Register", src: Register },
        { name: "Verify1", src: Verify1 },
        { name: "Order1", src: Order1 },
        { name: "Employee1", src: Employee1 },
        { name: "Catalog1", src: Catalog1 },
        { name: "Stream1", src: Stream1 },
      ];

      images.forEach((image) => {
        fetch(image.src)
          .then((res) => res.blob())
          .then((blob) => {
            convertToBase64(blob, (base64String) => {
              localStorage.setItem(image.name, base64String);
            });
          })
          .catch((error) => console.error("Error fetching image:", error));
      });
    };

    const convertToBase64 = (file, callback) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => callback(reader.result);
      reader.onerror = (error) =>
        console.error("Error converting to base64:", error);
    };

    setImagesInLocalStorage();
  }, []);

  const handleAndroidClick = () => {
    window.location.href = "http://nearshopz.com/invite";
  };

  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <>
        {desktop ? (
          <div
            // style={{
            //   // width: '100vw',
            //   // height: '100vh',
            //   // padding: "60px 80px",
            //   backgroundImage: `url(${currentBackground})`,
            //   // backgroundSize: 'cover',
            //   // transition: 'background-image ease-in-out 0.5s',
            // }}
            className={`big-wrapper ${imagesLoaded ? "loaded" : ""}`}
            id="idHOME"
          >
            <div className="showcase-area">
              <div className="containerdiv">
                <div className="left">
                  <div className="big-title">
                    <Typography
                      style={{
                        color: "#FFFFFF",
                        fontWeight: "700",
                        fontFamily: "Roboto",
                        lineHeight: "normal",
                      }}
                      fontSize={HeaderFontSize_48}
                    >
                      Boost Your{" "}
                    </Typography>
                    <Typography
                      style={{
                        fontWeight: "700",
                        fontFamily: "Roboto",
                        lineHeight: "normal",
                      }}
                      className="shopsnearby"
                      fontSize={HeaderFontSize_48}
                    ></Typography>
                    <Typography
                      style={{
                        color: "#FFFFFF",
                        fontWeight: "700",
                        fontFamily: "Roboto",
                        lineHeight: "normal",
                      }}
                      fontSize={HeaderFontSize_48}
                    >
                      Business !
                    </Typography>
                  </div>
                  <Typography
                    style={{
                      color: "#FFFFFF",
                      fontWeight: "500",
                      fontFamily: "Roboto",
                      marginTop: "30px",
                    }}
                    fontSize={contentFontSize_20}
                  >
                    Welcome to the E-commerce Market for Local Businesses
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                      marginTop: "30px",
                    }}
                  >
                    <div>
                      <CustomButton
                        matchPadding={true}
                        padding="10px 78px"
                        borderRadius="25px"
                        background="#13801C"
                      >
                        <div style={{ display: "flex", gap: "10px" }}>
                          <img
                            src={AndroidIcon}
                            alt="Nearshopz IOS App"
                            width="29px"
                            height="34px"
                          />
                          <Typography
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            fontSize={contentFontSize_20}
                          >
                            Download for IOS
                          </Typography>
                        </div>
                      </CustomButton>
                    </div>
                    <div>
                      <CustomButton
                        matchPadding={true}
                        padding="10px 60px"
                        borderRadius="25px"
                        background="transparent"
                        borderColor="#FFFFFF"
                      >
                        <div style={{ display: "flex", gap: "10px" }}>
                          <img
                            src={AppleIcon}
                            alt="Nearshopz Android App"
                            width="29px"
                            height="34px"
                          />
                          <Typography
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            fontSize={contentFontSize_20}
                            onClick={handleAndroidClick}
                          >
                            Download for Android
                          </Typography>
                        </div>
                      </CustomButton>
                    </div>
                  </div>
                </div>
                <div >
                  <LoginForm {...props} />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <LoginForm {...props} />
        )}
      </>

      <div>
        <LandingCards />
      </div>

      <div className="new_one">
        <div>
          <LandingCard1 />
        </div>
        <div>
          <LandingPageCard />
        </div>
        <div style={{ marginTop: "100px" }}>
          <LandingHoveringimg />
        </div>

        <div>
          <LandingFooter />
        </div>
      </div>
    </>
  );
};
export default HomeBanner;
