import { Grid,Typography } from '@mui/material'
import React, { useEffect } from 'react'
import Animation from "../../../utils/Icon/Animation.gif"
import { contentFontsize_30 } from "../../Typography/index"
function Loaders(props) {
 
  useEffect((storeid) => {
    props.getActiveOrdertlist(storeid)
  }, []);

  return (
    
    <div style={{ minHeight: '100vh', display: "flex", justifyContent: "center", alignItems: "center" }}>
    <Grid item lg={12} xs={12} sm={12} md={12} xl={12} sx={{ textAlign: 'center' }}>
      <img src={Animation} alt="Loader" />
      <Typography fontSize={contentFontsize_30} sx={{ fontWeight: '500', fontFamily: 'Roboto', marginTop: '20px' }}>
      Your order is now moved to In Progress.
      </Typography>
    </Grid>
  </div>
  )
}

export default Loaders