import React from "react";
import CustomDialog from "../Commoncomponents/dialog";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Box, useMediaQuery, useTheme, Typography } from "@mui/material";
import {
  Step,
  Stack,
  Stepper,
  StepLabel,
  StepConnector,
  stepConnectorClasses,
} from "@mui/material";
import { styled } from "@mui/system";
import CustomButton from "../Commoncomponents/button";
import { contentFontsize_14, contentFontsize_14_xs_10 } from "../Typography";
import { Fade } from "@mui/material";

const CustomStep = styled(Step)(({ theme }) => ({
  paddingLeft: "25px",
  "& .MuiStepLabel-root": {
    color: theme.palette.primary.main,
  },
  "& .MuiStepIcon-root": {
    color: theme.palette.secondary.main,
  },
}));

function View(props) {
  const handleClose = () => {
    props.setView(false);
  };

  const detailsStyle = {
    backgroundColor: "rgba(133, 190, 73, 0.25)",
    padding: "20px",
    borderRadius: "8px",
  };

  const itemStyle = {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",
  };

  const totalStyle = {
    display: "flex",
    justifyContent: "space-between",
    fontWeight: "bold",
  };

  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const matchessm = useMediaQuery(theme.breakpoints.down("sm"));

  const stats = props?.selectedOrder?.stats;
  const transferSettlement = props?.selectedOrder?.transferSettlement;

  const steps = [
    ...[stats[0], stats[stats.length - 1]].map((stat, index) => ({
      label:
        index === 0
          ? `${stat.status.replace(/-/g, " ")} & Payment Received`
          : `Order ${stat.status.replace(/-/g, " ")}`,
      dateTime: `${stat.created} ${new Date(
        `1970-01-01T${stat.createdTime}Z`
      ).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })}`,
    })),
    ...transferSettlement.map((settle) => ({
      label: settle.status.replace(/-/g, " "),
      dateTime: `${settle.created} ${new Date(
        `1970-01-01T${settle.createdTime}Z`
      ).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })}`,
    })),
  ];

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      width: 260,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#17A64F",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#FF9A02",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 2,
      border: 0,
      backgroundColor: "#FF9A02",
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    backgroundColor: ownerState.isLastStep
      ? "#FF9A02" // Orange color for the last step
      : theme.palette.grey[700],
    zIndex: 1,
    color: "#fff",
    width: 20,
    height: 20,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
      backgroundColor: ownerState.isLastStep ? "#FF9A02" : "#17A64F",
    }),
    ...(ownerState.completed && {
      backgroundColor: "#17A64F",
    }),
    padding: 0,
  }));

  function ColorlibStepIcon(props) {
    const { active, completed, className, isLastStep } = props;

    return (
      <ColorlibStepIconRoot
        ownerState={{ active, completed, isLastStep }}
        className={className}
      >
        {completed ? (
          <div className="ColorlibStepIcon-completedIcon" />
        ) : (
          <div className="ColorlibStepIcon-circle" />
        )}
      </ColorlibStepIconRoot>
    );
  }

  const styles = {
    listItem: {
      listStyleType: "disc",
      "&::marker": {
        color: "#17A64F", // Bullet point color
      },
    },
    noBullet: {
      listStyleType: "none",
      marginTop: "20px",
    },
  };

  return (
    <>
      <style>
        {`
          .custom-list li::marker {
            color: #17A64F; /* Change this to your desired color */
          }
        `}
      </style>
      {/* {props.settlement.map((order, index) => ( */}
      <Dialog
        maxHeight="70%"
        borderRadius="20px"
        open={props.view}
        titleAlign="center"
        padding="30px"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "790px",
              padding: "10px 0px 30px 0px",
              borderRadius: "20px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{ textAlign: "center", color: "black", marginTop: "20px" }}
        >
          #{props.selectedOrder.orderNumber}
        </DialogTitle>
        <DialogContent sx={{ padding: "0px" }}>
          <div style={{ paddingTop: "0px" }}>
            <Box sx={{ overflowX: "auto", marginBottom: "20px" }}>
              <Stack sx={{ width: "100%" }} spacing={4}>
                <Stepper
                  alternativeLabel
                  activeStep={1}
                  connector={<ColorlibConnector />}
                >
                  {steps.map((step, index) => (
                    <CustomStep key={step.label}>
                      <StepLabel
                        StepIconComponent={(stepProps) => (
                          <ColorlibStepIcon
                            {...stepProps}
                            isLastStep={index === steps.length - 1}
                          />
                        )}
                      >
                        <Typography
                          style={{ fontFamily: "Roboto", fontWeight: "500" }}
                          fontSize={contentFontsize_14_xs_10}
                        >
                          {step.label}
                        </Typography>
                        <Typography
                          style={{ fontFamily: "Roboto", fontWeight: "400" }}
                          fontSize={contentFontsize_14_xs_10}
                        >
                          {step.dateTime}
                        </Typography>
                      </StepLabel>
                    </CustomStep>
                  ))}
                </Stepper>
              </Stack>
            </Box>
          </div>
          <Box sx={detailsStyle}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                maxWidth: "300px",
              }}
            >
              <ul
                className="custom-list"
                style={{ listStyleType: "disc", paddingLeft: "20px" }}
              >
                <li>Product Price</li>
                <li>Delivery Charge</li>
                <li style={{ listStyleType: "none", marginTop: "20px" }}>
                  <strong>Total</strong>
                </li>
              </ul>
              <ul
                style={{
                  listStyleType: "none",
                  padding: 0,
                  margin: 0,
                  textAlign: "right",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "10px",
                  }}
                >
                  <li>{" : "}</li>
                  <li style={{ marginTop: "1px" }}>
                    ₹ {props.selectedOrder.totalPrice.toFixed(2)}
                  </li>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "10px",
                  }}
                >
                  <li>{" : "}</li>
                  <li style={{ marginTop: "1px" }}>
                    ₹ {props.selectedOrder.deliveryCharge.toFixed(2)}
                  </li>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "20px",
                    gap: "10px",
                  }}
                >
                  <li>{" : "}</li>
                  <li style={{ marginTop: "1px" }}>
                    <strong>
                      ₹ {props?.selectedOrder.totalPayable.toFixed(2)}
                    </strong>
                  </li>
                </div>
              </ul>
            </div>
          </Box>

          <div style={{ padding: "20px", textAlign: "center" }}>
            <div style={{ padding: "20px" }}>
              <Typography>
                Once an online order is successfully completed, NearShopz will
                credit the seller's due amount directly to your registered bank
                account. You can track the status of your payments in this
                section. Please refer to the displayed statuses to monitor the
                progress of your fund transfers.
              </Typography>
            </div>
          </div>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CustomButton
              onClick={handleClose}
              width="160px"
              height="45px"
              variant={"outlined"}
              background={"transparent"}
              color={"#659B1B"}
              borderRadius="25px"
              borderColor="#659B1B"
            >
              Close
            </CustomButton>
          </div>
        </DialogContent>
      </Dialog>
      {/* ))} */}
    </>
  );
}

export default View;
